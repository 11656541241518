import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
} from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import MDDatePicker from "components/MDDatePicker";

import MDButton from "components/MDButton";

import Autocomplete from "@mui/material/Autocomplete";

import cogoToast from "cogo-toast";
import "assets/css/style.css";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CommonConfig } from "constant";
// Authentication layout components

function EditBookAppointment() {
  //  formdata = formdata;
  var formdata = {};
  const Status = [{ label: "All" }, { label: "InProgress" }, { label: "Confirm" }];
  const Mangeby = [{ label: "All" }, { label: "InProgress" }, { label: "Confirm" }];
  const [selectedTime, setSelectedTime] = useState(null);
  const [timeData, setTimeData] = useState(null);
  var Timelistdata = [];

  var statelistdata = [];
  const [stateData, setstateData] = useState(null);
  const [selectedstate, setSelectedstate] = useState(null);
  const navigate = useNavigate();
  if (localStorage.getItem("userData")) {
    // alert("Hello");
  } else {
    // alert("Hello2")
    navigate("/");
  }
  //let formdata = localStorage.getItem("Formdata");
  //formdata = JSON.parse(formdata);
  // console.log("jjjservicesj", typeof formdata.Service);

  var pid = {
    AppointmentId: localStorage.getItem("id"),
  };
  useEffect(() => {
    TimeList();
    getState();
    getData();
  }, []);

  const getData = () => {
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "Appointment/getAppointmentById",
      method: "POST",
      headers: {},

      data: pid,
    })
      .then((res) => {
        if (res.data.success) {
          formdata = res.data.Data[0][0];
          console.log("form..", formdata);
          values.SecurityUserId = formdata.EntityID;
          values.AppointmentId = formdata.AppointmentId;
          values.ModeOfCommunication = formdata.ModeOfCommunication;
          values.CallType = formdata.CallType;
          values.services = formdata.Service;
          values.date = formdata.AppointmentDate && formdata.AppointmentDate.slice(0, -14);
          setSelectedTime(formdata.AppointmentTime);
          values.location = formdata.Location;
          values.officeSite = formdata.AddrLine3;
          // streetAddress = formdata.AddrLine1 ;
          values.streetName = formdata.AddrLine2;
          values.city = formdata.City;
          values.state = formdata.State;
          values.pin = formdata.PostalCode;
          values.country = "Australia";
          values.phoneNumber = formdata.PhoneNumber;
          values.additionalInformation = formdata.AdditionalInformation;
          values.officeSite = formdata.AddrLine3;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const TimeList = () => {
    axios({
      url: CommonConfig.ApiUrl + "Slot/GetSlotDuration",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.data.success === true) {
          console.log("1234", res);
          console.log("time data", res.data.Data);
          Timelistdata = res.data.Data;

          setTimeData(
            Timelistdata.map((item) => ({
              label: item.EndTime + "-" + item.StartTime,
              // id: item.StartTime,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getState = () => {
    axios({
      url: CommonConfig.ApiUrl + "userModule/GetStateList",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.data.success === true) {
          console.log("1234", res);
          console.log("time data", res.data.Data);
          statelistdata = res.data.Data;
          setstateData(
            statelistdata.map((item) => ({
              label: item.StateName,
              // id: item.StartTime,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // let userData = localStorage.getItem("userData");
  // if (userData) {
  //   userData = JSON.parse(userData);
  // }
  if (typeof formdata.Service == "string") {
    formdata.ss = formdata.Service.split(",");
    // formdata.ss = formdata.ss.map(obj=>JSON.parse(obj));
    console.log("==============", formdata.ss);
  }

  const [values, setValues] = React.useState({
    // SecurityUserId: formdata.EntityID || "",
    // AppointmentId: formdata.AppointmentId || "",
    // ModeOfCommunication: formdata.ModeOfCommunication,
    // services: formdata.ss || [],
    // date: (formdata.AppointmentDate && formdata.AppointmentDate.slice(0, -14)) || "",
    // time: formdata.AppointmentTime || "",
    // location: formdata.Location,
    // officeSite: formdata.AddrLine3 || "",
    // // streetAddress: formdata.AddrLine1 || "",
    // streetName: formdata.AddrLine2 || "",
    // city: formdata.City || "",
    // state: "",
    // pin: formdata.PostalCode || "",
    // errorstreetAddress: "",
    // errorstreetName: "",
    // errorcity: "",
    // errorstate: "",
    // country: "Australia",
    // phoneNumber: formdata.PhoneNumber,
    SecurityUserId: "",
    AppointmentId: "",
    ModeOfCommunication: "",
    services: [],
    selectedstatus: "",
    selectedTime: "",
    selectedMangeby: "",
    date: "",
    time: "",
    location: "",
    officeSite: "",
    additionalInformation: "",
    // streetAddress: formdat "",
    streetName: "",
    city: "",
    state: "",
    pin: "",
    errorstreetAddress: "",
    errorstreetName: "",
    errorcity: "",
    errorstate: "",
    country: "Australia",
    phoneNumber: "",
  });
  console.log("phone..", values.phoneNumber);
  let docId;
  if (formdata.ModeOfCommunication == "Audio call") {
    docId = "AudioCall";
  } else if (formdata.ModeOfCommunication == "Video call") {
    docId = " VideoCall";
  } else if (formdata.ModeOfCommunication == "facetofaceappointment") {
    docId = "facetofaceappointment";
  }
  console.log("---------llll", values.date);
  // if(docId){
  //   const element  = document.getElementById("AudioCall");
  //   console.log("styel",element)
  //   element.style.backgroundColor = "green"

  // }
  const [loginCredintials, setLoginCredintialss] = React.useState({
    Email: "",
    Password: "",
  });
  let formIds = localStorage.getItem("formId");
  if (!formIds) {
    formIds = 1;
  } else {
    formIds = parseInt(formIds);
  }
  const [form1, setForm1] = useState(formIds);

  const [success, setSuccess] = useState(false);

  const [todayDate, settodayDate] = useState(new Date().toISOString().slice(0, 10));

  // const [datetime,setDatetime]=React.useState()
  const onClick1 = (e) => {
    setValues({ ...values, ModeOfCommunication: e });
    setForm1(2);
  };

  const formChange = async (formId) => {
    console.log("kkk", JSON.stringify(values));
    await localStorage.setItem("Formdata", JSON.stringify(values));
    let isToken = localStorage.getItem("token");
    if (isToken) {
      formId = 4;
    } else {
      localStorage.setItem("pages", "BookAppointment");
      formId = 3;
    }
    setForm1(formId);
  };

  const backForm = () => {
    setForm1(form1 - 1);
  };
  const handleChange = (event) => {
    // debugger;
    console.log(event.target, "targetsselect");

    setValues({ ...values, [event.target.name]: event.target.value });
    // console.log("======", values);
  };
  const onTagsState = (event, value) => {
    const officeSite = value;
    // let state=values.state
    console.log("--------helll2");

    setValues({ ...values, officeSite: officeSite });
    console.log("---------------", values);
  };
  const handleChange2 = (event) => {
    // console.log(event.target,"targetsselect")

    setLoginCredintialss({ ...loginCredintials, [event.target.name]: event.target.value });
    // console.log("======", values);
  };
  const handledateChange = (event) => {
    console.log("hdhdhd", event);
    let date = new Date(event[0]);
    console.log(date, "hello ");
    let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    date = [date.getFullYear(), mnth, day].join("-");
    console.log(date, "hello ");
    if (date >= todayDate) {
      setValues({ ...values, date: date });
    }
  };
  const handleChangeAppointment = (event) => {
    if (event.target.name === "date") {
      if (event.target.value >= todayDate) {
        console.log(event.target.value, "hello ");
        setValues({ ...values, [event.target.name]: event.target.value });
      }
    }
  };
  //  let formdata= localStorage.getItem("Formdata")
  //  if(
  //    !formdata
  //  ){

  //  }
  // localStorage.setItem("Formdata",{})
  const handleSubmit = () => {
    // debugger;
    console.log(values);
    if (values.streetAddress === "") {
      values.errorstreetAddress = "Please Enter Street Address";
    } else {
      values.errorstreetAddress = "";
    }
    if (values.streetName === "") {
      values.errorstreetName = "Please Enter Street Name";
    } else {
      values.errorstreetName = "";
    }
    if (values.city === "") {
      values.errorcity = "Please Enter City ";
    } else {
      values.errorcity = "";
    }
    if (values.state === "") {
      values.errorstate = "Please Enter State ";
    } else {
      values.errorstate = "";
    }
    // if (values.phoneNumber === "") {
    //   values.errorclientPhone = "Please Enter phoneNumber ";
    // } else {
    //   values.errorclientPhone = "";
    // }

    // if (values.pin === "") {
    //   setValues({ ...values, ["errorpin"]: "Please Enter pin" });
    // } else {
    //   setValues({ ...values, ["errorpin"]: "" });
    // }

    const inputdata = {
      AppointmentId: values.AppointmentId,
      SecurityUserId: values.SecurityUserId,
      Service: values.services,
      ModeOfCommunication: values.ModeOfCommunication,
      AppointmentTime: values.time,
      AppointmentDate: values.date,
      Location: values.location,
      AddrLine1: values.streetAddress,
      AddrLine2: values.streetName,
      AddrLine3: values.officeSite,
      City: values.city,
      State: values.state.label,
      Country: values.country,
      PostalCode: values.pin,
      phoneNumber: values.phoneNumber,
    };

    var temp = "";
    for (let i = 0; i < inputdata.Service.length; i++) {
      console.log("inputdata.Service[i]", inputdata.Service[i]);
      if (inputdata.Service[i].label == undefined) {
        temp += inputdata.Service[i] + "~";
      } else {
        temp += inputdata.Service[i].label + "~";
      }
      // temp += inputdata.Service[i] + "~"
    }
    temp = temp.slice(0, -1); // Masteringjs.io
    inputdata.Service = temp;
    console.log("Services = ", temp);
    console.log("hellll", inputdata);
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "Appointment/addUpdateAppointment",
      method: "POST",
      headers: {},

      // Attaching the form data
      data: inputdata,
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data.success) {
          cogoToast.success("Appointment Updated Successfully");

          navigate("/pages/book-appointment/list");
        }
        console.log("test", res.data);
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  const Submit2 = () => {
    const data = {
      Email: loginCredintials.Email,
      Password: loginCredintials.Password,
    };
    console.log("..........", data);
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "authentication/userLoginAuthenticate",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },

      // Attaching the form data
      data: data,
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Login Successful");
          setForm1(3);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userData", JSON.stringify(res.data.Data[0]));

          // inputdata.EntityId=res.data.Data[0].SecurityUserId;
          //  navigate("/Dashboard");
        }
        console.log("test", res.data);
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        cogoToast.error("Something went wrong");
      });
  };

  const services = [
    { label: "Early Childhood Services" },
    { label: "Foster Care Services" },
    { label: "Therapeutic Services for Children" },
    { label: "Daily Living & Life Skills" },
    { label: "Daily Persional Activities" },
  ];

  const onTagsChange = (event, value) => {
    values.services = value;
    setValues({ ...values, services: value });
  };

  const onStateChange = (event, value) => {
    // debugger;
    values.state = value;
    setValues({ ...values, state: value });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card className="mt-15">
        <CardContent>
          <div className="appointment_edit">
            <h2 className="h2-heading with-tagline align-center" justifyContent="center">
              Edit Book an Appointment
            </h2>
            <Grid container spacing={3}>
              <Grid item sm={6} md={6}>
                <div className="reg_input auto-complete">
                  {/* <FormControl fullWidth className="input-select-outer">
                    <InputLabel htmlFor="selectshipmenttype" className="">
                      Status
                    </InputLabel>
                    <Select value="0">
                      <MenuItem value={10}>Option</MenuItem>
                      <MenuItem value={20}>Option 1</MenuItem>
                      <MenuItem value={30}>Option 2</MenuItem>
                    </Select>
                  </FormControl> */}
                  <Autocomplete
                    id="combo-box-demo"
                    options={Status}
                    defaultValue={values.Status ? values.Status : []}
                    onChange={onTagsChange}
                    filterSelectedOptions
                    formControlProps={{
                      fullWidth: true,
                    }}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="outlined"
                        label="Status"
                        name="Status"
                        value="Status"
                        error={values.errortime}
                        helperText={values.errortime}
                        onChange={(e) => handleChange(e, "time")}
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item sm={6} md={6}>
                <div className="reg_input auto-complete">
                  <Autocomplete
                    options={Mangeby}
                    defaultValue={values.Mangeby ? values.Mangeby : []}
                    onChange={onTagsChange}
                    filterSelectedOptions
                    formControlProps={{
                      fullWidth: true,
                    }}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="outlined"
                        label="ManageBy"
                        name="ManageBy"
                        value="ManageBy"
                        error={values.errortime}
                        helperText={values.errortime}
                        onChange={(e) => handleChange(e, "time")}
                      />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
            <div className="divider"> </div>
            <Grid container spacing={3}>
              <Grid item md="12">
                <div className="reg_input check_value mb-0">
                  <InputLabel>Selected Appointment</InputLabel>
                  <RadioGroup aria-label="ndisplan" name="ndisplan" row="true">
                    <FormControlLabel
                      disabled
                      value="Appointment"
                      control={
                        <Radio checked={values.ModeOfCommunication == "Appointment" && true} />
                      }
                      label="Face-to-Face Appointment"
                      // onChange={(e) => handleChange(e)}
                      // onClick={() => onClick1("Appointment")}
                      name="ModeOfCommunication"
                    />
                    <FormControlLabel
                      value="disabled"
                      disabled
                      control={<Radio checked={values.ModeOfCommunication == "CallBack" && true} />}
                      label="Request a Call Back"
                    />
                    {/* <FormControlLabel
                      value="CallBack"
                      disabled
                      control={<Radio checked={values.ModeOfCommunication == "CallBack" && true} />}
                      label=" Request a Call Back"
                      onChange={(e) => handleChange(e)}
                      onClick={() => onClick1("CallBack")}
                      name="ModeOfCommunication"
                    /> */}
                    <FormControlLabel
                      disabled
                      value="BookAnOppointment"
                      //onClick={() => onClick1("BookAnOppointment")}
                      control={
                        <Radio
                          checked={values.ModeOfCommunication == "BookAnOppointment" && true}
                        />
                      }
                      label="Book an Appointment"
                      //onChange={(e) => handleChange(e)}
                      name="ModeOfCommunication"
                    />
                  </RadioGroup>
                </div>
              </Grid>
              {values.ModeOfCommunication === "CallBack" ? (
                <Grid item md="4">
                  <div className="reg_input">
                    <TextField
                      type="number"
                      value={values.phoneNumber}
                      color="primary"
                      name="phoneNumber"
                      label="Phone Number *"
                      variant="outlined"
                      error={values["errorclientPhone"]}
                      // id="clientPhone"
                      // name="clientPhone"
                      helperText={values["errorclientPhone"]}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="reg_input">
                    <TextField
                      onChange={(e) => handleChange(e)}
                      name="additionalInformation"
                      value={values.additionalInformation}
                      multiline
                      rows={4}
                      maxRows={4}
                      label="Additional Information"
                      id="regFirstName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      required={true}
                    />
                  </div>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <div className="divider"> </div>
            <h4>Services/Supports you would like to avail</h4>
            <Grid container spacing={3}>
              <Grid item md="4">
                <div className="reg_input sec_level">
                  <div className="reg_input auto-complete">
                    <Autocomplete
                      multiple
                      options={services}
                      defaultValue={values.services}
                      onChange={onTagsChange}
                      filterSelectedOptions
                      formControlProps={{
                        fullWidth: true,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="What services are you registered for?"
                          placeholder="Favorites"
                          id="services"
                          name="services"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="divider"> </div>
            <h4>When would you like to</h4>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 2 }}>
              <Grid item md="4">
                <div className="reg_input">
                  <div className="datepicker-wrap">
                    <MDDatePicker
                      disabled
                      // editable={false}
                      // onChange={handledateChange}
                      value={values.date}
                      md-min-date={todayDate}
                      disablePast
                      //defaultValue={values.date ? values.date : ""}
                      input={{ placeholder: "Select a date" }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item md="3">
                <div className="reg_input auto-complete">
                  <Autocomplete
                    disabled
                    id="combo-box-demo"
                    options={timeData ? timeData : ""}
                    value={selectedTime ? selectedTime : ""}
                    onChange={(e, value) => {
                      setSelectedTime(value.label);
                      setValues((prev) => ({ ...prev, time: value.id }));
                    }}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        disabled
                        variant="outlined"
                        label="Time"
                        name="time"
                        value="time"
                        error={values.errortime}
                        helperText={values.errortime}
                        onChange={(e) => handleChange(e, "time")}
                      />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
            {values.ModeOfCommunication === "Appointment" ? (
              <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 2 }}>
                <Grid item md="12">
                  <div className="reg_input check_value mb-0">
                    <InputLabel className="reg_lable mt-15">Location</InputLabel>
                    <RadioGroup aria-label="gender" name="gender1" row="true">
                      <FormControlLabel
                        value="value care office site"
                        onChange={(e) => handleChange(e)}
                        control={
                          <Radio checked={values.location == "value care office site" && true} />
                        }
                        label="Value care's office/site"
                        name="location"
                      />
                      <FormControlLabel
                        value="Australin Address"
                        onChange={(e) => handleChange(e)}
                        control={<Radio checked={values.location == "Australin Address" && true} />}
                        label="Provide an Australian address"
                        name="location"
                      />
                    </RadioGroup>
                  </div>
                  {values.location === "value care office site" && (
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <div className="reg_input auto-complete">
                            <Autocomplete
                              id="combo-box-demo"
                              onChange={onTagsState}
                              value={values.officeSite}
                              name="officeSite"
                              options={["officeSite1", "OfficeSite2", " OfficeSite3"]}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Value care office site"
                                  name="officeSite"
                                  color="success"
                                  error={values.errorofficeSite}
                                  helperText={values.errorofficeSite}
                                  onChange={(e) => console.log("helll off", e.target.value)}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  {values.location === "Australin Address" && (
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                      <Grid item md="12">
                        <InputLabel className="reg_lable mb-20 mt-15">Address</InputLabel>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <TextField
                            onChange={(e) => handleChange(e)}
                            defaultValue={values.streetAddress || ""}
                            name="streetAddress"
                            error={values.errorstreetAddress}
                            helperText={values.errorstreetAddress}
                            label="Unit/Street Address"
                            id="regFirstName"
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <TextField
                            onChange={(e) => handleChange(e)}
                            defaultValue={values.streetName || ""}
                            name="streetName"
                            error={values.errorstreetName}
                            helperText={values.errorstreetName}
                            label="Street Name"
                            id="regFirstName"
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <TextField
                            onChange={(e) => handleChange(e)}
                            defaultValue={values.city || ""}
                            name="city"
                            error={values.errorcity}
                            helperText={values.errorcity}
                            label="City"
                            id="regFirstName"
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item sm={4} md={4}>
                        <div className="reg_input auto-complete">
                          <Autocomplete
                            options={stateData}
                            defaultValue={values.state}
                            onChange={onStateChange}
                            filterSelectedOptions
                            formControlProps={{
                              fullWidth: true,
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="State"
                                // placeholder="Favorites"
                                id="state"
                                name="state"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            )}
                          />
                          {/* <Autocomplete
                            options={stateData}
                            value={selectedstate}
                            onChange={(e, value) => {
                              // setSelectedstate(value.label);
                              // setValues((prev) => ({ ...prev, state: value.label }));
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="State"
                                name="state"
                                value={selectedstate}
                                // onChange={(e) => console.log("helll off", e.target.value)}
                                error={values.errorstate}
                                helperText={values.errorstate}
                              />
                            )}
                          /> */}
                        </div>
                      </Grid>
                      <Grid item sm={4} md={4}>
                        <div className="reg_input">
                          <TextField
                            onChange={(e) => handleChange(e)}
                            defaultValue={values.pin || ""}
                            name="pin"
                            error={values.errorpin}
                            helperText={values.errorpin}
                            label="PIN"
                            id="regFirstName"
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  <div className="reg_input">
                    <TextField
                      value={values.additionalInformation}
                      onChange={(e) => handleChange(e)}
                      name="additionalInformation"
                      multiline
                      rows={4}
                      maxRows={4}
                      label="Additional Information"
                      id="regFirstName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      required={true}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {values.ModeOfCommunication === "BookAnOppointment" ? (
              <Grid item md="12">
                <div className="reg_input check_value mb-0">
                  <InputLabel className="reg_lable mt-15">Mode Of Communication</InputLabel>
                  <RadioGroup aria-label="gender" name="CallType" row="true">
                    <FormControlLabel
                      value="Video Call"
                      onChange={(e) => handleChange(e)}
                      control={<Radio checked={values.CallType === "Video Call" ? true : false} />}
                      label="Video Call"
                      name="CallType"
                    />
                    <FormControlLabel
                      value="Audio Call"
                      onChange={(e) => handleChange(e)}
                      control={<Radio checked={values.CallType === "Audio Call" ? true : false} />}
                      label="Audio Call"
                      name="CallType"
                    />
                  </RadioGroup>
                </div>
                {values.CallType === "Video Call" && (
                  <Grid item md="6">
                    <div className="reg_input">
                      <TextField
                        type="number"
                        color="primary"
                        name="phoneNumber"
                        label="Phone Number *"
                        variant="outlined"
                        // error={values['errorclientPhone']}
                        // id="clientPhone"
                        // name="clientPhone"
                        // helperText={values['errorclientPhone']}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </Grid>
                )}
                {values.CallType === "Audio Call" && (
                  <Grid item md="6">
                    <div className="reg_input">
                      <TextField
                        type="number"
                        color="primary"
                        name="phoneNumber"
                        label="Phone Number *"
                        variant="outlined"
                        // error={values['errorclientPhone']}
                        // id="clientPhone"
                        // name="clientPhone"
                        // helperText={values['errorclientPhone']}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
            ) : (
              ""
            )}

            <Grid container spacing={3}>
              <Grid item>
                <div className="reg_submit">
                  <MDButton variant="contained" color="secondary" onClick={() => handleSubmit()}>
                    Cancel
                  </MDButton>
                  <MDButton variant="contained" color="primary" onClick={() => handleSubmit()}>
                    Submit
                  </MDButton>
                </div>
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default EditBookAppointment;
