/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-github-btn
import GitHubButton from "react-github-btn";

// @mui material components
import Divider from "@mui/material/Divider";
import { ArrowDownward } from "@mui/icons-material";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { CommonConfig } from "constant";
import CloseIcon from "@mui/icons-material/Close";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { DocumentDatatable } from "layouts/component/DocumentDatatable";
// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setOpenConfigurator,
  setMiniSidenav,
  setFixedNavbar,
  setDarkMode,
} from "context";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
  Modal,
  Grid,
  Box,
  Input,
} from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import cogoToast from "cogo-toast";
function Configurator() {
  const { state } = useLocation();

  let navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const [userData, setUserData] = useState(state?.SecurityUserData || null);

  const schema = yup.object().shape({
    // Document: yup.string().required("Please Upload the Document", value =>{return value && value.length}),
    fileName: yup.string().required("Please select the File Name."),
    directoryData: yup.object().required("Please select the document Directory."),
    description: yup.string().required("Description Required."),
  });
  const {
    openConfigurator,
    miniSidenav,
    fixedNavbar,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [disabled, setDisabled] = useState(false);
  const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];
  const style = {
    position: "absolute",
    top: "50%",
    left: "80%",
    transform: "translate(-80%, -80%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();
    getBookAppointmentByID();
    getDirectoryList();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleFixedNavbar = () => setFixedNavbar(dispatch, !fixedNavbar);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);
  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [directoryData, setDirectoryList] = useState(null);
  const [directoryID, setDirectoryID] = useState(null);
  const [directoryLabel, setDirectoryLabel] = useState(null);
  const AppointmentID = localStorage.getItem("AppointmentID");
  const [documentData, setDocumentData] = useState([]);
  const [documentListData, setDocumentListData] = useState([]);
  console.log("documentListData", documentListData);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const getBookAppointmentByID = () => {
    debugger;
    let reqData = {
      AppointmentID: AppointmentID,
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "Appointment/getBookAppointmentById",
      method: "POST",
      headers: {},
      data: reqData,
    })
      .then((res) => {
        if (res.data?.success) {
          let finaldata = res.data.data;
          console.log("finaldata", finaldata);
          setDocumentData(finaldata);
        }
      })
      // Catch errors if any
      .catch((error) => {
        console.log(error);
      });
  };
  const getDirectoryList = () => {
    const formattedData = {
      StringMapType: "DOCUMENTTYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);
          setDirectoryList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getDocumentList = () => {
    debugger;
    let docRequestData = {
      EntityId: AppointmentID,
      EntityType: "Appointment",
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      url: CommonConfig.ApiUrl + "document/getDocumentListByTypeAndId",
      method: "POST",
      headers: {},
      data: docRequestData,
    })
      .then((res) => {
        if (res.data.success) {
          debugger;
          setDocumentListData(res.data.data || []);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onSubmit = (data) => {
    debugger;
    if (!data.files[0]) {
      document.getElementById("fileError").style.display = "block";
      return;
    } else {
      document.getElementById("fileError").style.display = "none";
    }
    let formData = new FormData();
    formData.append("number", documentData.AppointmentNumber);
    formData.append("Directory", data.directoryData.id);
    formData.append(
      "CustomURL",
      "AppointmentDocuments/" + documentData.AppointmentNumber + "/" + data.directoryData.id
    );
    formData.append("AppointmentId", documentData.AppointmentID);
    formData.append("Description", data.description.trim());
    formData.append(
      "FileName",
      `${data.fileName}_${moment(Date())
        .toISOString()
        .replace(/[^0-9]/g, "")
        .slice(0, -3)}` +
        "." +
        `${data.files[0].name.split(".").pop()}`
    );
    formData.append("EntityID", AppointmentID);
    formData.append("EntityType", "Appointment");
    formData.append("document", data.files[0]);
    formData.append("CurrentUser", CommonConfig.getCurrentUserId());
    formData.append("LanguageId", "en-US");
    axios({
      url: CommonConfig.ApiUrl + "document/uploadDocument",
      method: "POST",
      headers: {},
      data: formData,
    })
      .then(async (res) => {
        if (res.data.success === true) {
          cogoToast.success("Document Uploaded Successfully.");
          getDocumentList();
          reset({
            files: "",
            fileName: "",
            directoryData: "",
            description: "",
          });
        } else {
          cogoToast.error("Something Went Wrong.");
        }

        console.log("testttttt", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
          //  onClick={(event) =>{handleCloseConfigurator();setOpenDialog(false)}}
        >
          close
        </Icon>
      </MDBox>

      <Divider />
      <MDButton
        component={Link}
        onClick={(event) => {
          setOpenDialog(true);
          getDocumentList();
        }}
        target="_blank"
        rel="noreferrer"
        color={darkMode ? "light" : "dark"}
        variant="outlined"
        fullWidth
      >
        Documents
      </MDButton>
      <Divider />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MDTypography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </MDTypography>
          <MDTypography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </MDTypography>
        </Box>
      </Modal>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md">
        <DialogTitle>
          Document
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenDialog(false);
              reset({});
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="custom-accordian">
              <Accordion>
                <AccordionSummary expandIcon={<ArrowDownward />}>Upload</AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {/* {!watch('Document') || watch('Document').length === 0 ? */}
                      <div className="reg_input">
                        {/* <input type="file" id="Document"  {...register('Document')} style={{display:'none'}}/>
                        <label htmlFor="fileUpload" style={{cursor:'pointer'}}>Select File</label> */}
                        {/* <Controller
                          name="Document"
                          control={control}
                          {...register('Document')}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                              type="file"
                              style={{
                                width: "250px",
                              }}
                              value={value}
                              
                              onChange={onChange2}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        /> */}
                        <Controller
                          name="files"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => {
                            return (
                              <Input
                                {...field}
                                label="Subir archivo"
                                type="file"
                                variant="outlined"
                                onChange={(event) => field.onChange(event.target?.files)}
                                value={field.target?.fileName}
                              />
                            );
                          }}
                        />
                        <span class="servicesError" id="fileError">
                          Document required
                        </span>
                      </div>
                      {/* :(<strong>{watch('Document')[0].name}</strong>)} */}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <div className="reg_input">
                        <Controller
                          name="fileName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="outlined-read-only-input"
                              label="File Name*"
                              value={value}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="directoryData"
                          // rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="directoryData"
                              name="directoryData"
                              value={value}
                              options={directoryData ? directoryData : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Directory*"
                                  error={errors.directoryData}
                                  helperText={errors.directoryData ? "Directory Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div className="reg_input">
                        <Controller
                          name="description"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="outlined-read-only-input"
                              label="Description*"
                              value={value}
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <div className="reg_submit">
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        reset({});
                        setOpenDialog(false);
                      }}
                    >
                      Cancel
                    </MDButton>
                    <MDButton type="submit" variant="contained" color="primary">
                      Submit
                    </MDButton>
                  </div>
                  <div className="table-custom mt-20">
                    <DocumentDatatable
                      EntityId={AppointmentID}
                      EntityType="Appointment"
                      DocumentListData={documentListData}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      {/* <MDBox pt={0.5} pb={3} px={3}>
        <MDBox>
          <MDTypography variant="h6">Sidenav Colors</MDTypography>

          <MDBox mb={0.5}>
            {sidenavColors.map((color) => (
              <IconButton
                key={color}
                sx={({
                  borders: { borderWidth },
                  palette: { white, dark, background },
                  transitions,
                }) => ({
                  width: "24px",
                  height: "24px",
                  padding: 0,
                  border: `${borderWidth[1]} solid ${darkMode ? background.sidenav : white.main}`,
                  borderColor: () => {
                    let borderColorValue = sidenavColor === color && dark.main;

                    if (darkMode && sidenavColor === color) {
                      borderColorValue = white.main;
                    }

                    return borderColorValue;
                  },
                  transition: transitions.create("border-color", {
                    easing: transitions.easing.sharp,
                    duration: transitions.duration.shorter,
                  }),
                  backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                    linearGradient(gradients[color].main, gradients[color].state),

                  "&:not(:last-child)": {
                    mr: 1,
                  },

                  "&:hover, &:focus, &:active": {
                    borderColor: darkMode ? white.main : dark.main,
                  },
                })}
                onClick={() => setSidenavColor(dispatch, color)}
              />
            ))}
          </MDBox>
        </MDBox>

        <MDBox mt={3} lineHeight={1}>
          <MDTypography variant="h6">Sidenav Type</MDTypography>
          <MDTypography variant="button" color="text">
            Choose between different sidenav types.
          </MDTypography>

          <MDBox
            sx={{
              display: "flex",
              mt: 2,
              mr: 1,
            }}
          >
            <MDButton
              color="dark"
              variant="gradient"
              onClick={handleDarkSidenav}
              disabled={disabled}
              fullWidth
              sx={
                !transparentSidenav && !whiteSidenav
                  ? sidenavTypeActiveButtonStyles
                  : sidenavTypeButtonsStyles
              }
            >
              Dark
            </MDButton>
            <MDBox sx={{ mx: 1, width: "8rem", minWidth: "8rem" }}>
              <MDButton
                color="dark"
                variant="gradient"
                onClick={handleTransparentSidenav}
                disabled={disabled}
                fullWidth
                sx={
                  transparentSidenav && !whiteSidenav
                    ? sidenavTypeActiveButtonStyles
                    : sidenavTypeButtonsStyles
                }
              >
                Transparent
              </MDButton>
            </MDBox>
            <MDButton
              color="dark"
              variant="gradient"
              onClick={handleWhiteSidenav}
              disabled={disabled}
              fullWidth
              sx={
                whiteSidenav && !transparentSidenav
                  ? sidenavTypeActiveButtonStyles
                  : sidenavTypeButtonsStyles
              }
            >
              White
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
          lineHeight={1}
        >
          <MDTypography variant="h6">Navbar Fixed</MDTypography>

          <Switch checked={fixedNavbar} onChange={handleFixedNavbar} />
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MDTypography variant="h6">Sidenav Mini</MDTypography>

          <Switch checked={miniSidenav} onChange={handleMiniSidenav} />
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MDTypography variant="h6">Light / Dark</MDTypography>

          <Switch checked={darkMode} onChange={handleDarkMode} />
        </MDBox>
        <Divider />
        <MDBox mt={3} mb={2}>
          <MDBox mb={2}>
            <MDButton
              component={Link}
              href="https://www.creative-tim.com/product/material-dashboard-pro-react"
              target="_blank"
              rel="noreferrer"
              color="info"
              variant="gradient"
              fullWidth
            >
              buy now
            </MDButton>
          </MDBox>
          <MDBox mb={2}>
            <MDButton
              component={Link}
              href="https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts"
              target="_blank"
              rel="noreferrer"
              color="dark"
              variant="gradient"
              fullWidth
            >
              buy typescript version
            </MDButton>
          </MDBox>
          <MDButton
            component={Link}
            href="https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/"
            target="_blank"
            rel="noreferrer"
            color={darkMode ? "light" : "dark"}
            variant="outlined"
            fullWidth
          >
            view documentation
          </MDButton>
        </MDBox>
        <MDBox display="flex" justifyContent="center">
          <GitHubButton
            href="https://github.com/creativetimofficial/ct-material-dashboard-pro-react"
            data-icon="octicon-star"
            data-size="large"
            data-show-count="true"
            aria-label="Star creativetimofficial/ct-material-dashboard-pro-react on GitHub"
          >
            Star
          </GitHubButton>
        </MDBox>
        <MDBox mt={2} textAlign="center">
          <MDBox mb={0.5}>
            <MDTypography variant="h6">Thank you for sharing!</MDTypography>
          </MDBox>

          <MDBox display="flex" justifyContent="center">
            <MDBox mr={1.5}>
              <MDButton
                component={Link}
                href="//twitter.com/intent/tweet?text=Check%20Material%20Dashboard%202%20PRO%20React%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23react%20%mui&url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fmaterial-dashboard-pro-react"
                target="_blank"
                rel="noreferrer"
                color="dark"
              >
                <TwitterIcon />
                &nbsp; Tweet
              </MDButton>
            </MDBox>
            <MDButton
              component={Link}
              href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-dashboard-pro-react"
              target="_blank"
              rel="noreferrer"
              color="dark"
            >
              <FacebookIcon />
              &nbsp; Share
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox> */}
    </ConfiguratorRoot>
  );
}

export default Configurator;
