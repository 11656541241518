import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";

import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import { autoLogin } from "../../commonAPI/commonAPI";

import { Alert } from "@mui/material";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/valuecare-logo.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;

const schema = yup.object().shape({
  Email: yup.string().required("Please Enter Email Id."),

  NewPassword: yup
    .string()
    .required("Please Enter New Password.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),

  ConfirmPassword: yup
    .string()
    .oneOf([yup.ref("NewPassword"), null], " Confirm Password Do not match"),
});

const ResetPassword = () => {
  const localdata = localStorage.getItem("userData");

  const { state } = useLocation();

  let navigate = useNavigate();
  let clientRef = useRef(false);

  const [passwordShown, setPasswordShown] = useState(false);
  const [cnfpasswordShown, setCnfPasswordShown] = useState(false);
  const [password, setPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);


  console.log("LOCALGET", localStorage.getItem("UserName"));
  console.log("LOCALGET", localStorage.getItem("Password"));
  const UserName = localStorage.getItem("UserName");
  const Password = localStorage.getItem("Password");

  const toggleNewPasswordVisiblity = () => {
    document.getElementById("HideNewEyePassword").style.display = "none";
    document.getElementById("ShowNewEyePassword").style.display = "block";
    setCnfPasswordShown(cnfpasswordShown ? false : true);
  };

  const toggleHideNewPasswordVisiblity = () => {
    document.getElementById("HideNewEyePassword").classList.add("eyeplacepassConfirm");
    document.getElementById("HideNewEyePassword").classList.remove("eyeplaceConfirm");
    document.getElementById("HideNewEyePassword").style.display = "block";
    document.getElementById("ShowNewEyePassword").style.display = "none";
    setCnfPasswordShown(cnfpasswordShown ? false : true);
  };

  const togglecnfpasswordVisiblity = () => {
    document.getElementById("HideConfEyePassword").style.display = "none";
    document.getElementById("ShowConfEyePassword").style.display = "block";
    setPassword(password ? false : true);
  };

  const toggleHidecnfpasswordVisiblity = () => {
    document.getElementById("HideConfEyePassword").classList.add("eyeplacepassConfirm");
    document.getElementById("HideConfEyePassword").classList.remove("eyeplaceConfirm");
    document.getElementById("HideConfEyePassword").style.display = "block";
    document.getElementById("ShowConfEyePassword").style.display = "none";
    setPassword(password ? false : true);
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      //   PersonId: loginUser.PersonId,
    },
  });

  const onSubmit = (data) => {
    console.log("ResetPassword data", data);

  };

  return (
    <>
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              accessible
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            Reset Password
          </MDTypography>
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          <div className="reg_form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="reg_step" id="regStep6">
                <div className="reg_form">
                  <Grid container spacing={3}>
                    <Grid
                      container
                      justifyContent="center"
                      rowSpacing={0}
                      columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                    >
                      <Grid item md="4">
                        <div className="reg_input position_r with-icon">
                          <Controller
                            name="Email"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Email*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="center"
                      rowSpacing={0}
                      columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                    >
                      <Grid item md="4">
                        <div className="reg_input position_r with-icon">
                          <Controller
                            name="NewPassword"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="New Password*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                type={cnfpasswordShown ? "text" : "password"}
                              />
                            )}
                          />
                          <i
                            class="eyeplaceConfirm"
                            id="HideNewEyePassword"
                            onClick={toggleNewPasswordVisiblity}
                          >
                            {/* {eye} */}
                            <VisibilityIcon />
                          </i>

                          <i
                            class="eyeplacepassConfirm hidetext"
                            id="ShowNewEyePassword"
                            onClick={toggleHideNewPasswordVisiblity}
                          >
                            <VisibilityOffIcon />
                          </i>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justifyContent="center"
                      rowSpacing={0}
                      columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                    >
                      <Grid item md="4">
                        <div className="reg_input position_r with-icon">
                          <Controller
                            name="ConfirmPassword"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Confirm Password*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                type={password ? "text" : "password"}
                              />
                            )}
                          />

                          <i
                            class="eyeplaceConfirm"
                            id="HideConfEyePassword"
                            onClick={togglecnfpasswordVisiblity}
                          >
                            {/* {eye} */}
                            <VisibilityIcon />
                          </i>

                          <i
                            class="eyeplacepassConfirm hidetext"
                            id="ShowConfEyePassword"
                            onClick={toggleHidecnfpasswordVisiblity}
                          >
                            <VisibilityOffIcon />
                          </i>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <Grid
                container
                justifyContent="center"
                rowSpacing={0}
                columnSpacing={{ xs: 3, sm: 3, md: 3 }}
              >
                <div className="reg_submit">
                  <MDButton type="submit" variant="contained" color="primary">
                    Submit
                  </MDButton>
                </div>
              </Grid>
            </form>
          </div>
        </MDBox>
      </Card>
      <div className="copyright">
        <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
      </div>
    </>
  );
};

ResetPassword.defaultProps = {};

export default ResetPassword;
