import callBack from "assets/img/call-back.svg";
import audioCall from "assets/img/audio-call.svg";
import bookAppointment from "assets/img/book-appointment.svg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import moment from "moment";
// import DatePicker from "react-date-picker";
import { CommonConfig } from "constant";
import {
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Grid,
  Button,
  TextField,
  Autocomplete,
  CardContent,
  CardActions
  , Typography,
  Card,
  Box
} from "@mui/material";
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);



const schema = yup.object().shape({
  isSelfAppointment: yup.boolean(),
})
export default ({ onFormSelected, AppointmentFor }) => {
  const { state } = useLocation();
  // console.log("State", state);
  let PersonUserId = state ? state.PersonId : null;
  let PersonUserName = state ? state.Name : null;

  
  console.log("PersonUserId", PersonUserId);
  console.log("PersonUserName", PersonUserName);

  const [userData, setUserData] = useState(state?.SecurityUserData || null);
  const [values, setValues] = useState([]);
  const [managedData, setManagedData] = useState(null);
  const [personData, setPersonData] = useState(null);
  console.log("personData",personData);
  const [chManagedBy, setChManagedBy] = useState(null);
  const [chManagedByLabel, setChManagedByLabel] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      isSelfAppointment: userData?.isSelfAppointment === false ? CommonConfig.getCurrentUserId() : chManagedBy,
    },
  });

  let isSelfAppointment = watch("isSelfAppointment", true);
  useEffect(() => {
    getInternalStaff();
    getPersonById();
  }, []);

  const getPersonById = () => {
    let Requestdata ={PersonId : CommonConfig.getCurrentUserId(),CurrentUser: CommonConfig.getCurrentUserId()}
    axios({
      url: CommonConfig.ApiUrl + "contact/getPersonById",
      method: "POST",
      headers: {},
      data: Requestdata,
    })
      .then((res) => {
        if (res.data.success) {
          setPersonData(
            res.data.data.map((item) => ({
              id: item.PersonId,
              personFullName: item.FirstName +' '+ item.LastName +', '+ item.Gender
              // YEsr: (moment().diff(moment(item.DOB, 'YYYYMMDD'), 'years'),
            }))
          );
        }
      })
      .catch((error) => { });
  };

  const getInternalStaff = () => {
    axios({
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {},
    })
      .then((res) => {
        if (res.data.success) {
          setManagedData(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
        }
      })
      .catch((error) => { });
  };

  const onManagedByChangeStatusChanged = (e, value) => {
    setChManagedByLabel(value.label);
    setChManagedBy(value.id);
  };
  const onSubmit = (data) => {
    let AppointmentFor = { AppointmentFor: data.isSelfAppointment === false ? chManagedBy : CommonConfig.getCurrentUserId() }

    // let formattedData = {isSelfAppointment === 1 ?CommonConfig.getCurrentUserId(): chManagedBy}

    axios({
      url: CommonConfig.ApiUrl + "authentication/addUpdateUser",
      // url: "https://uatapi.valuecare.org.au/authentication/addUpdateUser",
      method: "POST",
      headers: {},
      data: AppointmentFor,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Registration Successfully");
        } else {
          cogoToast.error("Something Went Wrong");
        }
      })
      .catch((error) => {
      });
  };

  return (
    <div className="reg_step" id="bookStep1">
      {/* <Card sx={{ minWidth: 75 }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            </Typography>
            <Typography variant="h5" component="div" align="right">
             Appointment For
            </Typography>
            <Typography variant="body2" align="right">
              {personData? personData[0]?.FirstName:''}
            </Typography>
            <Typography variant="body2"  align="right">
            {personData? personData[0]?.Gender:''}
            </Typography>
            <Typography variant="body2"  align="right">
              {personData? CommonConfig.formatDate(personData[0]?.DOB):''}
            </Typography>
          </CardContent>
        </Card> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        
      </form>
      {/* <h2 className="h2-heading with-tagline align-center" justifyContent="center">
        Book an Appointment
      </h2> */}
      <p className="tagline center mb-20">Choose any one option from below.</p>
      <div className="ba-wrap">
        <Grid container spacing={3} justifyContent="center">
          <Grid item md="3">
            <div className="ba-box" onClick={() => onFormSelected("Face to Face", AppointmentFor)}>
              <span>
                <img src={bookAppointment} alt="Audio Call" />
              </span>
              <h3 className="align-center">Face-to-Face Appointment</h3>
            </div>
          </Grid>
          <Grid item md="3">
            <div className="ba-box" onClick={() => onFormSelected("Request Callback", AppointmentFor)}>
              <span>
                <img src={callBack} alt="Call Back" />
              </span>
              <h3 className="align-center">Request a Call Back</h3>
            </div>
          </Grid>
          <Grid item md="3">
            <div className="ba-box" onClick={() => onFormSelected("Audio/Video Conference", AppointmentFor)}>
              <span>
                <img src={audioCall} alt="Call Back" />
              </span>
              <h3 className="align-center">Audio/Video Call</h3>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

