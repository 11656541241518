import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import MDDatePicker from "components/MDDatePicker";

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import { Link } from "react-router-dom";

import Logo from "assets/img/valuecare-logo.png";

import emailIcon from "assets/img/icon-email.svg";
import videoCall from "assets/img/video-call.svg";

import audioCall from "assets/img/audio-call.svg";
import callBack from "assets/img/call-back.svg";
import bookAppointment from "assets/img/book-appointment.svg";
import loginIcon from "assets/img/login-icon.svg";
import MDInput from "components/MDInput";
import cogoToast from "cogo-toast";
import "assets/css/style.css";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { CommonConfig } from "constant";

function BookAppointment() {
  let formdata = localStorage.getItem("Formdata");
  formdata = JSON.parse(formdata);
  if (!formdata) {
    formdata = {};
  }
  console.log("jjjjYYygygygkhkhkkmbj", formdata);

  let userData = localStorage.getItem("userData");
  if (userData) {
    userData = JSON.parse(userData);
  }
  const [values, setValues] = React.useState({
    ModeOfCommunication: formdata.ModeOfCommunication || "",
    services: formdata.services || [],
    date: "",
    time: "",
    location: "value care office site",
    officeSite: "",
    streetAddress: "",
    streetName: "",
    city: "",
    state: "",
    pin: "",
    errorstreetAddress: "",
    errorstreetName: "",
    errorcity: "",
    errorstate: "",
    country: "Australia",
    errortime: "",
  });
  const [errors, setErrors] = useState({});
  const [terms, setTerms] = useState(false);
  const [loginCredintials, setLoginCredintialss] = React.useState({
    Email: "",
    Password: "",
  });
  let formIds = localStorage.getItem("formId");
  if (!formIds) {
    formIds = 1;
  } else {
    formIds = parseInt(formIds);
  }
  const [form1, setForm1] = useState(formIds);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const [todayDate, settodayDate] = useState(new Date().toISOString().slice(0, 10));
  useEffect(() => {
    console.log("-------------", success);
  });

  // const [datetime,setDatetime]=React.useState()
  const onClick1 = (e) => {
    setValues({ ...values, ModeOfCommunication: e });
    setForm1(2);
  };
  const OpenReg = () => {
    localStorage.setItem("Formdata", JSON.stringify(values));
    navigate("/authentication/sign-up/Index");
  };

  const formChange = async (formId) => {
    console.log("kkk", JSON.stringify(values));
    await localStorage.setItem("Formdata", JSON.stringify(values));
    if (formId === 3 && !values.services.length) {
      setErrors({ ...errors, services: "Please select atleast one Service" });
      return false;
    } else {
      setErrors({ ...errors, services: "" });
    }
    let isToken = localStorage.getItem("token");
    if (isToken) {
      formId = 4;
    } else {
      localStorage.setItem("pages", "BookAppointment");
      formId = 3;
    }
    setForm1(formId);
  };

  const backForm = () => {
    setForm1(form1 - 1);
  };
  const handleChange = (event) => {
    // console.log(event.target,"targetsselect")

    setValues({ ...values, [event.target.name]: event.target.value });
    // console.log("======", values);
  };
  const handleChange2 = (event) => {
    // console.log(event.target,"targetsselect")

    setLoginCredintialss({ ...loginCredintials, [event.target.name]: event.target.value });
    // console.log("======", values);
  };
  const handledateChange = (event) => {
    console.log("hdhdhd", event);
    let date = new Date(event[0]);
    console.log(date, "hello ");
    let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    date = [date.getFullYear(), mnth, day].join("-");
    console.log(date, "hello ");
    if (date >= todayDate) {
      setValues({ ...values, date: date });
    }
  };
  //  let formdata= localStorage.getItem("Formdata")
  //  if(
  //    !formdata
  //  ){

  //  }
  // localStorage.setItem("Formdata",{})
  const handleSubmit = () => {
    if (!terms) {
      document.getElementById("errorTC").style.display = "block";

      // setErrors({...errors, terms:"Please select terms"})
      // console.log("termsss",setErrors)
      return false;
    } else {
      document.getElementById("errorTC").style.display = "none";

      setErrors({ ...errors, terms: "" });
      // debugger;
      if (values.date === "") {
        document.getElementById("errorDate").style.display = "block";

        return false;
      } else {
        document.getElementById("errorDate").style.display = "none";
      }
    }
    if (values.time === "") {
      values.errortime = "Please fill Time ";
    } else {
      values.errortime = "";
    }
    console.log(values);
    if (values.streetAddress === "") {
      values.errorstreetAddress = "Please Enter Street Address";
    } else {
      values.errorstreetAddress = "";
    }
    if (values.streetName === "") {
      values.errorstreetName = "Please Enter Street Name";
    } else {
      values.errorstreetName = "";
    }
    if (values.city === "") {
      values.errorcity = "Please Enter City ";
    } else {
      values.errorcity = "";
    }
    if (values.state === "") {
      values.errorstate = "Please Enter State ";
    } else {
      values.errorstate = "";
    }

    if (values.pin === "") {
      setValues({ ...values, ["errorpin"]: "Please Enter pin" });
    } else {
      setValues({ ...values, ["errorpin"]: "" });
    }

    const inputdata = {
      SecurityUserId: userData.SecurityUserId ? userData.SecurityUserId : "",
      Service: values.services,
      ModeOfCommunication: values.ModeOfCommunication,
      AppointmentTime: values.time,
      AppointmentDate: values.date,
      Location: values.location,
      AddrLine1: values.streetAddress,
      AddrLine2: values.streetName,
      AddrLine3: values.officeSite,
      City: values.city,
      State: values.state,
      Country: values.country,
      PostalCode: values.pin,
    };
    var temp = "";
    for (let i = 0; i < inputdata.Service.length; i++) {
      // console.log("inputdata.Service[i]" , inputdata.Service[i])
      temp += inputdata.Service[i].label + "~";
    }
    temp = temp.slice(0, -1); // Masteringjs.io
    inputdata.Service = temp;
    console.log("hellll", inputdata);
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "authentication/bookAppointment",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },

      // Attaching the form data
      data: inputdata,
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Appointment Booked Successfully");

          navigate("/pages/book-appointment/list");
        }
        console.log("test", res.data);
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  const Submit2 = () => {
    const data = {
      Email: loginCredintials.Email,
      Password: loginCredintials.Password,
    };
    console.log("..........", data);
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "authentication/userLoginAuthenticate",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },

      // Attaching the form data
      data: data,
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Login Successful");
          setForm1(4);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userData", JSON.stringify(res.data.Data[0]));

          // inputdata.EntityId=res.data.Data[0].SecurityUserId;
          //  navigate("/Dashboard");
        }
        console.log("test", res.data);
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        cogoToast.error("Something went wrong");
      });
  };

  const services = [
    { label: "Early Childhood Services" },
    { label: "Foster Care Services" },
    { label: "Therapeutic Services for Children" },
    { label: "Daily Living & Life Skills" },
    { label: "Daily Persional Activities" },
  ];

  const onTagsChange = (event, value) => {
    const servi = value;
    // let servi=values.services
    console.log("--------", servi);

    setValues({ ...values, services: servi });
    console.log("---------------", value);
  };

  return (
    <div className="signup-page-outer">
      <CoverLayout className="sign-up-outer">
        <Grid container justify="center" spacing={3}>
          <Grid item className="signup-page-outer2">
            <div className="container">
              <div className="logo">
                <a href="www.google.com">
                  <img alt="Test" src={Logo} />
                </a>
              </div>
              <div className="reg-inner">
                {form1 === 1 && (
                  <div className="reg_step" id="bookStep1">
                    <h2 className="h2-heading with-tagline align-center" justifyContent="center">
                      Book an Appointment
                    </h2>
                    <p className="tagline center">Choose any one option from below</p>
                    <div className="ba-wrap">
                      <Grid container spacing={3} justifyContent="center">
                        <Grid item md="3">
                          <div className="ba-box" onClick={() => onClick1("facetofaceappointment")}>
                            <span>
                              <img src={bookAppointment} alt="Audio Call" />
                            </span>
                            <h3 className="align-center">Face-to-Face Appointment</h3>
                          </div>
                        </Grid>
                        <Grid item md="3">
                          <div className="ba-box" onClick={() => onClick1("callBack")}>
                            <span>
                              <img src={callBack} alt="Call Back" />
                            </span>
                            <h3 className="align-center">Request a Call Back</h3>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} justifyContent="center">
                        <Grid item md="3">
                          <div className="ba-box" onClick={() => onClick1("AudioCall")}>
                            <span>
                              <img src={audioCall} alt="Audio Call" />
                            </span>
                            <h3 className="align-center"> Book an Audio Call</h3>
                          </div>
                        </Grid>
                        <Grid item md="3">
                          <div className="ba-box" onClick={() => onClick1("videoCall")}>
                            <span>
                              <img src={videoCall} alt="Video Call" />
                            </span>
                            <h3 className="align-center"> Book a Video Conference</h3>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                )}
                {form1 === 2 && (
                  <div className="reg_step" id="bookStep2">
                    <h2 className="h2-heading align-center">
                      Services/Options you would like to avail
                    </h2>
                    <Grid
                      container
                      rowSpacing={0}
                      columnSpacing={{ xs: 3, sm: 3, md: 2 }}
                      justifyContent="center"
                    >
                      <Grid item md="8">
                        <div className="reg_input sec_level">
                          <Autocomplete
                            multiple
                            //id="tags-outlined"
                            options={services}
                            value={values.services ? values.services : []}
                            // getOptionLabel={option => option.label}
                            onChange={onTagsChange}
                            filterSelectedOptions
                            formControlProps={{
                              fullWidth: true,
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="What services are you registered for?"
                                placeholder="Favorites"
                                id="services"
                                name="services"
                                error={!!errors.services}
                                helperText={errors.services}
                                // defaultValue={Params}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <div className="reg_submit center">
                      <MDButton variant="contained" color="secondary" onClick={() => backForm()}>
                        Back
                      </MDButton>

                      <MDButton
                        variant="contained"
                        color="primary"
                        onClick={() => formChange(3, services)}
                      >
                        Next
                      </MDButton>
                    </div>
                  </div>
                )}
                {form1 === 3 && (
                  <div className="reg_step" id="bookStep3">
                    <h2>Login/Register</h2>
                    <div className="reg_social">
                      <Grid item md="4">
                        <div className="reg_input">
                          <TextField
                            onChange={(e) => handleChange2(e)}
                            name="Email"
                            // error={values.errorstreetName}
                            // helperText={values.errorstreetName}
                            label="Email"
                            id="regUserName"
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <TextField
                            onChange={(e) => handleChange2(e)}
                            name="Password"
                            // error={values.errorstreetName}
                            // helperText={values.errorstreetName}
                            label="Password"
                            id="regFirstName"
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </div>
                        <Grid item>
                          <div className="reg_submit">
                            <MDButton variant="contained" color="primary" onClick={() => Submit2()}>
                              Login
                            </MDButton>
                            <MDButton
                              variant="contained"
                              color="primary"
                              onClick={() => backForm()}
                            >
                              Back
                            </MDButton>
                          </div>
                        </Grid>
                      </Grid>

                      <MDTypography
                        onClick={() => {
                          OpenReg();
                        }}
                        variant="button"
                        color="primary"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign up
                      </MDTypography>
                    </div>
                  </div>
                )}
                {form1 === 4 && (
                  <div className="reg_step" id="bookStep4">
                    <h2 className="h2-heading align-center">When would you like to</h2>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 2 }}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <div className="datepicker-wrap">
                            <MDDatePicker
                              onChange={handledateChange}
                              md-min-date={todayDate}
                              disablePast
                              input={{ placeholder: "Select a date" }}
                              required={true}
                            />
                            <span id="errorDate" className="errorTC" style={{ display: "none" }}>
                              Please select Appointment Date{" "}
                            </span>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md="3">
                        <div className="reg_input">
                          <TextField
                            onChange={(e) => handleChange(e)}
                            name="time"
                            type="time"
                            label="Time"
                            id="regFirstName"
                            error={values.errortime}
                            helperText={values.errortime}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 2 }}>
                      <Grid item md="12">
                        <div className="reg_input check_value mb-0">
                          <InputLabel className="reg_lable mt-15">Location</InputLabel>
                          <RadioGroup aria-label="gender" name="gender1" row="true">
                            <FormControlLabel
                              value="value care office site"
                              onChange={(e) => handleChange(e)}
                              control={
                                <Radio
                                  checked={
                                    values.location === "value care office site" ? true : false
                                  }
                                />
                              }
                              label="Value care's office/site"
                              name="location"
                            />
                            <FormControlLabel
                              value="Australin Address"
                              onChange={(e) => handleChange(e)}
                              control={
                                <Radio
                                  checked={values.location === "Australin Address" ? true : false}
                                />
                              }
                              label="Provide an Australian address"
                              name="location"
                            />
                          </RadioGroup>
                        </div>
                        {values.location === "value care office site" && (
                          <Grid item sm={2} md={2}>
                            <div className="reg_input reg_select">
                              <FormControl
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              >
                                <InputLabel htmlFor="selectfilter">
                                  Select valuecare office site
                                </InputLabel>
                                <Select
                                  inputProps={{
                                    name: "officeSite",
                                    id: "simple-select",
                                  }}
                                  onChange={(event) => handleChange(event)}
                                >
                                  <MenuItem
                                    value="officesite 1"
                                    name="officeSite"
                                    onChange={(e) => handleChange(e)}
                                  >
                                    OfficeSite 1
                                  </MenuItem>
                                  <MenuItem
                                    value="officesite 2"
                                    name="officeSite"
                                    onChange={(e) => handleChange(e)}
                                  >
                                    Office Site 2
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </Grid>
                        )}
                        {values.location === "Australin Address" && (
                          <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                            <Grid item md="12">
                              <InputLabel className="reg_lable mb-20 mt-15">Address</InputLabel>
                            </Grid>
                            <Grid item md="4">
                              <div className="reg_input">
                                <TextField
                                  onChange={(e) => handleChange(e)}
                                  name="streetAddress"
                                  error={values.errorstreetAddress}
                                  helperText={values.errorstreetAddress}
                                  label="Unit/Street Address"
                                  id="regFirstName"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md="4">
                              <div className="reg_input">
                                <TextField
                                  onChange={(e) => handleChange(e)}
                                  name="streetName"
                                  error={values.errorstreetName}
                                  helperText={values.errorstreetName}
                                  label="Street Name"
                                  id="regFirstName"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item md="4">
                              <div className="reg_input">
                                <TextField
                                  onChange={(e) => handleChange(e)}
                                  name="city"
                                  error={values.errorcity}
                                  helperText={values.errorcity}
                                  label="City"
                                  id="regFirstName"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item sm={4} md={4}>
                              <div className="reg_input reg_select">
                                <FormControl
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                >
                                  <InputLabel htmlFor="selectfilter">State</InputLabel>
                                  <Select
                                    inputProps={{
                                      name: "state",
                                      id: "simple-select",
                                    }}
                                    error={values.errorstate}
                                    helperText={values.errorstate}
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <MenuItem
                                      value="State 1"
                                      name="state"
                                      error={values.errorstate}
                                      helperText={values.errorstate}
                                    >
                                      State 1
                                    </MenuItem>
                                    <MenuItem
                                      value="State 2"
                                      name="state"
                                      error={values.errorstate}
                                      helperText={values.errorstate}
                                    >
                                      State 2
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </Grid>
                            <Grid item sm={2} md={2}>
                              <div className="reg_input">
                                <TextField
                                  onChange={(e) => handleChange(e)}
                                  name="pin"
                                  error={values.errorpin}
                                  helperText={values.errorpin}
                                  label="PIN"
                                  id="regFirstName"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item>
                        <div className="reg_input check_value">
                          <FormControlLabel
                            onChange={(e) => {
                              setTerms(!terms);
                            }}
                            control={<Checkbox name="checkedB" color="primary" />}
                            label="By registering you agree to Value Care’s Terms &amp; Conditions"
                          />
                          <span id="errorTC" className="errorTC" style={{ display: "none" }}>
                            Please accept the terms and condition{" "}
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item>
                        <div className="reg_submit">
                          <MDButton
                            variant="contained"
                            color="secondary"
                            onClick={() => backForm()}
                          >
                            Back
                          </MDButton>

                          <MDButton
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </MDButton>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </CoverLayout>
    </div>
  );
}

export default BookAppointment;
