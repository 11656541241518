/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import React, { useState, useEffect } from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import VideoCallIcon from "@mui/icons-material/VideoCall";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import { CommonConfig } from "constant";
import axios from "axios";
import PieChart from "examples/Charts/PieChart";
import Card from "@mui/material/Card";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import defaultLineChartData from "layouts/dashboards/sales/data/defaultLineChartData";
import ChannelsChart from "layouts/dashboards/sales/components/ChannelsChart";
import { Button as PButton } from "primereact/button";
import cogoToast from "cogo-toast";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function ZoomList() {
  if (localStorage.getItem("userData")) {
    // alert("Hello");
    const loginUser = JSON.parse(localStorage.getItem("userData"));
    var loginUserName = loginUser.FirstName + " " + loginUser.LastName;
    var PersonType = loginUser.PersonType;
    console.log("Testing12aa ", loginUserName);
  }

  const paginatorLeft = <PButton type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <PButton type="button" icon="pi pi-cloud" className="p-button-text" />;
  const [data, setData] = useState(null);
  const [loader, setLoader] = React.useState(false);

  let navigate = useNavigate();
  useEffect(() => {
    getSheduleMeetingList();
  }, []);

  var columns = [];

  columns = [
    {
      name: "Appointment ID",
      selector: "AppointmentNumber",
      filter: true,
      sortable: true,
    },
    {
      name: "Client Name",
      selector: "PatientName",
      filter: true,
      sortable: true,
    },

    {
      name: "Meeting Time",
      // body: (data) => {
      //     if (data.AppointmentDate) {
      //         // return moment(data.StartDate).format("hh:mm a");
      //         return moment(data.AppointmentDate).utc().format("DD-MM-YYYY hh:mm:ss");
      //     }
      //     return "";
      // },
      selector: "AppointmentDateTime",
      filter: true,
      sortable: true,
    },
    {
      name: "Status",
      selector: "Status",
      filter: true,
      sortable: true,
    },
    {
      name: "Action",
      body: (data) => actionTemplate(data),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const actionTemplate = (row) => {
    return (
      <div>
        <PButton
          type="button"
          icon="pi pi-video"
          className="p-button-primary"
          onClick={() => MeetingJoin(row)}
          style={{ marginRight: ".5em" }}
          tooltip="Join Meeting"
          tooltipOptions={{ position: "bottom" }}
        />
        {CommonConfig.isInternalStaff() === 1 && PersonType == "Admin" ? (
          <PButton
            type="button"
            icon="pi pi-pencil"
            className="p-button-warning"
            onClick={() => EditData(row)}
            tooltip="Edit Meeting"
            tooltipOptions={{ position: "bottom" }}
          />
        ) : null}
      </div>
    );
  };

  const getColoumnsRow = () =>
    columns.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  const MeetingJoin = (row) => {
    console.log("rowwww", row);
    if (CommonConfig.isInternalStaff() === 1 || PersonType == "INTERNAL STAFF") {
      window.open(row.ZoommeetinghostURL);
    } else {
      window.open(row.ZoommeetingURL);
    }
  };

  const RemoveMeeting = (row) => {
    console.log("rowdatata", row.id);

    let formattedData = {
      meetingId: row.ZoommeetingID,
    };
    console.log("formattedDataDEl", formattedData);
    axios({
      url: CommonConfig.ZoomUrl + "delete/meeting/",
      method: "DELETE",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        console.log("ressss", res);
        cogoToast.success("Meeting Removed Successfully.");
        getSheduleMeetingList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSheduleMeetingList = () => {
    setLoader(!loader);
    debugger;
    let formattedData = {
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      url: CommonConfig.ApiUrl + "Appointment/getBookAppointmentList",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        setLoader(false);
        console.log("res.data.data.data", res);
        const getFilterMeetingData = res.data.data?.filter((x) => x.ZoommeetingID);
        var newDateTime = new Date();
        for (var i = 0; i < getFilterMeetingData.length; i++) {
          var TestNewDate = new Date(getFilterMeetingData[i].AppointmentDateTime);
          getFilterMeetingData[i].CompareDateTime = TestNewDate;
        }
        const getFinalFilterMeetingData = getFilterMeetingData?.filter(
          (x) => x.AppointmentDateTime >= newDateTime
        );
        console.log("res.data.data", newDateTime);

        if (PersonType == "INTERNAL STAFF") {
          const getFilterMeetingDataByMannagedBy = getFilterMeetingData.filter(
            (x) => x.ManagedByName === loginUserName
          );
          setData(getFilterMeetingDataByMannagedBy);
        } else {
          setData(getFilterMeetingData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const EditData = (selectedRow = {}) => {
    setLoader(!loader);

    let inputData = { AppointmentID: selectedRow.AppointmentID };

    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "Appointment/getBookAppointmentById",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      // Attaching the form data
      data: inputData,
    })
      // Handle the response from backend here
      .then((res) => {
        console.log("resdatatatat", res);
        setLoader(!loader);
        if (res.data?.success) {
          navigate("/pages/book-appointment/UserBookAppointmentToken", {
            state: { PatientData: selectedRow },
          });
        }
      })
      // if()
      // if (res.data.data[0].AppointmentMode == "Face to Face") {
      //   navigate("/pages/book-appointment/Face2FaceEdit", {
      //     state: { PatientData: selectedRow },
      //   });
      // } else if (res.data.data[0].AppointmentMode == "Request Callback") {
      //   // console.log("statemeetingData", res.data.data[0]);
      //   navigate("/pages/book-appointment/RequestCallback", {
      //     state: { PatientData: selectedRow },
      //   });
      // } else {
      //   // console.log("statemeetingData", res.data.data[0]);
      //   navigate("/pages/book-appointment/UserBookAppointmentToken", {
      //     state: { PatientData: selectedRow },
      //   });
      // }
      // navigate("/pages/book-appointment/UserBookAppointmentToken", {
      //   state: { PatientData: res.data.data[0] },
      // });
      // }

      // Catch errors if any
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Card sx={{ height: "100%" }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              format_list_bulleted
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            Zoom Schedule List 
          </MDTypography>
        </MDBox>

        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <div className="table-custom">
            <DataTable
              scrollable
              value={data}
              showGridlines
              scrollHeight="600px"
              scrollWidth="600px"
              responsiveLayout="scroll"
              paginator
              rows={10}
              totalRecords={data ? data.length : 0}
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              rowsPerPageOptions={[10, 20, 30]}
              emptyMessage="No records found"
              paginatorLeft={paginatorLeft}
              paginatorRight={paginatorRight}
              filterDisplay="row"
            >
              {getColoumnsRow()}
            </DataTable>
          </div>
        </MDBox>
      </Card>
      {/* </Grid>
                </Grid>
            </MDBox> */}
      <Footer />
    </DashboardLayout>
  );
}

export default ZoomList;
