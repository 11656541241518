import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Card,
  CardContent,
  Grid,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Button as PButton } from "primereact/button";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import axios from "axios";
import { CommonConfig } from "constant";
import cogoToast from "cogo-toast";
import { Column } from "primereact/column";
import { NotesComponent } from "layouts/component/Notes";
import MDButton from "components/MDButton";
import { ArrowDownward } from "@mui/icons-material";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import PrimeDatatable from "layouts/component/PrimeDatatable";

import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  ComplaintDate: yup.string().required("Date Required."),
  // ReceivedFrom: yup.string().required("Received From required"),
  ReceivedBy: yup.object().required("Received By Required."),
  AssignTo: yup.object().required("Assign To Required."),
  CRStatus: yup.object().required("Status Required."),
  ComplaintDetail: yup.string().required("Details Required."),
});

const schemaNotes = yup.object().shape({
  Notes: yup.string().required("Note Required."),
  NotesType: yup.string().required("NoteType Required."),
});

const ComplaintRegister = () => {
  const [todayDate, settodayDate] = useState(new Date().toISOString().slice(0, 10));
  const [internalStaff, setInternalStaff] = useState(null);
  const [complaintList, setComplaintList] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [complaintStatus, setComplaintStatus] = useState(null);
  const [chNotes, setChNotes] = useState(null);
  const [notesData, setNotesData] = useState([]);
  const [notesDropdownList, setNotesDropdownList] = useState(null);
  const [selectedNotesType, setSselectedNotesType] = useState(null);
  const [inNoteImportant, setinNoteImportant] = useState(false);
  const [values, setValues] = useState([]);
  const [notesEnable, setNotesEnable] = useState(false);
  const [complaintRegisterId, setComplaintRegisterId] = useState(null);
  // const [complaintRegisterNumber, setComplaintRegisterId] = useState(null);
  const [complaintRegisterNumber, setComplaintRegisterNumber] = useState(null);
  const [loader, setLoader] = React.useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      Action: "",
      AssignTo: "",
      ComplaintDate: "",
      ComplaintDetail: "",
      ComplaintRegisterId: "",
      CurrentUser: "",
      EntityID: "",
      EntityType: "",
      ReceivedBy: "",
      RecievedFrom: "",
    },
  });

  const {
    register: registerNote,
    formState: { errors: errorsNote },
    handleSubmit: handleSubmitNote,
    reset: resetNote,
    control: controlNotes,
  } = useForm({
    resolver: yupResolver(schemaNotes),
    defaultValues: {
      NotesType: "",
      Notes: "",
    },
  });

  let navigate = useNavigate();
  let loginUser;
  if (localStorage.getItem("userData")) {
    // alert("Hello");
    loginUser = JSON.parse(localStorage.getItem("userData"));
  } else {
    // alert("Hello2")
    navigate("/");
  }
  // console.log("ERRRR", errorsNote);

  useEffect(() => {
    getInternalStaff();
    getComplaintList();
    getNotesDropdownList();
    getNoteList();
    getComplaintStatus();
  }, []);

  let column = [
    {
      name: "Date",
      body: (data) => {
        if (data.ComplaintDate) {
          return CommonConfig.formatDate(data.ComplaintDate);
        }
        return "";
      },
      selector: "ComplaintDate",
      filter: true,
      sortable: true,
    },
    {
      name: "Received From",
      selector: "ReceivedFrom",
      filter: true,
      sortable: true,
    },
    {
      name: "Received By",
      // body: (data) => {
      //   if (data.ReceivedBy) {
      //     let receivedName = internalStaff?.filter((item) => item.id == data.ReceivedBy);
      //     if (receivedName && receivedName.length > 0) {
      //       return receivedName[0].label;
      //     }
      //     return "";
      //   }
      //   return "";
      // },

      selector: "ReceivedByName",
      filter: true,
      sortable: true,
    },
    {
      name: "Details",
      selector: "ComplaintDetail",
      filter: true,
      sortable: true,
    },
    {
      name: "Action",
      selector: "Action",
      filter: true,
      sortable: true,
    },
    {
      name: "Action Assigned to/Responsibility",
      selector: "AssigneeName",
      filter: true,
      sortable: true,
      // body: (data) => {
      //   if (data.Assignedto) {
      //     let assignedName = internalStaff?.filter((item) => item.id == data.Assignedto);
      //     if (assignedName && assignedName.length > 0) {
      //       return assignedName[0].label;
      //     }
      //     return "";
      //   }
      //   return "";
      // },
    },
    {
      name: "Status",
      selector: "CRStatus",
      filter: true,
      sortable: true,
    },
    {
      name: "Action",
      body: (data) => actionTemplate(data),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const actionTemplate = (row) => {
    return (
      <PButton
        type="button"
        icon="pi pi-pencil"
        className="p-button-warning"
        onClick={() => {
          // setIsEditEnabled(true);
          setShowAdd(true);
          setNotesEnable(true);
          // console.log("ROW", row);
          if (row.ComplaintRegisterId) {
            setComplaintRegisterId(row.ComplaintRegisterId);
            getNoteList(row.ComplaintRegisterId);
          }
          if (row.ComplaintNumber) {
            setComplaintRegisterNumber(row.ComplaintNumber);
          }
          let assignToObj;
          let receivedByObj;
          let CRStatusobj;
          if (row.AssignedTo) {
            assignToObj = internalStaff.filter((item) => item.id == row.AssignedTo);
            // console.log("dsfds", assignToObj);
          }
          if (row.ReceivedBy) {
            receivedByObj = internalStaff.filter((item) => item.id == row.ReceivedBy);
            // console.log("ReceivedBy", receivedByObj);
          }
          if (row.CRStatus) {
            CRStatusobj = complaintStatus.filter((item) => item.id == row.CRStatus);
            // console.log("ReceivedBy", receivedByObj);
          }
          // console.log("DATA", {
          //   ...row,
          //   ReceivedBy: receivedByObj ? receivedByObj[0] : "",
          //   AssignedTo: assignToObj ? assignToObj[0] : "",
          // });
          reset({
            ...row,
            ReceivedBy: receivedByObj ? receivedByObj[0] : "",
            AssignTo: assignToObj ? assignToObj[0] : "",
            CRStatus: CRStatusobj ? CRStatusobj[0] : "",
          });
        }}
        style={{ marginRight: ".5em" }}
        tooltip="Edit Complaint"
        tooltipOptions={{ position: "bottom" }}
      />
    );
  };

  const getColumn = () =>
    column.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  // console.log("Error::", errors);

  const getComplaintList = () => {
    setLoader(!loader);
    let formattedData = {
      ComplaintRegisterId: "",
      CurrentUser: "",
    };

    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "qualityManagment/getComplaintRegisterData",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: formattedData,
    })
      // Handle the response from backend here
      .then((res) => {
        // console.log("RRRRR", res);
        if (res.data.success) {
          setLoader(false);

          var PersonType = loginUser.PersonType;
          var loginUserName = loginUser.FirstName + " " + loginUser.LastName;
          if (PersonType == "INTERNAL STAFF") {
            // setLoader(false);
            var tempstore = [];
            for (var i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].AssigneeName === loginUserName) {
                tempstore.push(res.data.data[i]);
              }
            }
            setComplaintList(tempstore);
          } else {
            setComplaintList(res.data.data);
          }
          // console.log("DSfadsaf");
          // setComplaintList(res.data.data);
        }
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  const getInternalStaff = () => {
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data.success) {
          // cogoToast.success("Appointment Booked Successfully");
          // console.log("Managed Data", res.data.data);

          setInternalStaff(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
          // navigate("/pages/book-appointment/list");
        }
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  const getComplaintStatus = () => {
    const formattedData = {
      StringMapType: "COMPLAINTSTATUS",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ComplaintStatus===>", res.data.data);

          setComplaintStatus(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getNotesDropdownList = () => {
    const formattedData = {
      StringMapType: "NOTETYPE.ComplaintRegister",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("NotesList===>", res.data.data);
          setSselectedNotesType(res.data.data[0].StringMapName);
          // setNotesDropdownList(
          //   res.data.data.map((item) => ({
          //     id: item.StringMapKey,
          //     label: item.StringMapName,
          //   }))
          // );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const saveNotes = () => {
    if (chNotes === null || chNotes === "") {
      document.getElementById("NotesError").style.display = "block";
      return;
    } else {
      document.getElementById("NotesError").style.display = "none";
    }
    const formattedData = {
      EntityType: "ComplaintRegister",
      EntityId: complaintRegisterId ? complaintRegisterId : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
      IsFlag: inNoteImportant ? 1 : 0,
      NoteType: selectedNotesType,
      NoteTitle: "",
      NoteText: chNotes,
    };

    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "note/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          getNoteList(complaintRegisterId);
          setChNotes("");
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNoteList = (values) => {
    // debugger;
    let noteReqData = {
      EntityType: "ComplaintRegister",
      EntityId: values ? values : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "note/getNoteList",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: noteReqData,
    })
      .then((res) => {
        // console.log("Hello147 ", res.data);
        if (res.data?.success) {
          // console.log("-------------", data);
          setNotesData(res.data.data || []);
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    // debugger;

    console.log("onSubmit==>DATA", data);
    let formattedDate = CommonConfig.formatDate(data.ComplaintDate);

    // console.log("Formaateed", formattedDate);

    let assignTo = data.AssignTo?.id;
    let receivedBy = data.ReceivedBy?.id;
    let crstatus = data.CRStatus?.id;

    // console.log("assignTo", assignTo);
    // console.log("receivedBy", receivedBy);

    let formattedData = {
      ...data,
      ReceivedBy: receivedBy,
      AssignedTo: assignTo,
      CRStatus: crstatus,
    };

    // console.log("formattedData", formattedData);
    setLoader(!loader);
    formattedData = {
      ...formattedData,
      ComplaintDate: formattedDate,
      // ComplaintRegisterId: "",
      EntityID: "",
      EntityType: "",
      CurrentUser: CommonConfig.getCurrentUserId(),
    };

    console.log("formattedData::", formattedData);

    axios({
      url: CommonConfig.ApiUrl + "qualityManagment/addUpdateComplaints",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        setLoader(!loader);
        // console.log("res data....", res);
        if (res.data.success) {
          // console.log("Successfull", res.data);
          getComplaintList();
          setShowAdd(false);
          setNotesEnable(false);
          if (complaintRegisterNumber) {
            cogoToast.success("Updated Successfully.");
            setComplaintRegisterNumber(null);
          } else {
            cogoToast.success("Registered Successfully.");
          }
          // navigate("/layout/referal/Referallist");
        } else {
          cogoToast.error("Something Went Wrong.");
        }

        // console.log("test", res.res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmitN = (data) => {
    // if (!data.files[0]) {
    //   document.getElementById("fileError").style.display = "block";
    //   return;
    // } else {
    //   document.getElementById("fileError").style.display = "none";
    // }
    console.log("Datataaaa", data);
    // let formData = new FormData();
    // formData.append("EntityType", data.NotesType);
    // formData.append("EntityId", complaintRegisterId ? complaintRegisterId : "");
    // formData.append("CustomURL", "Notes/" + data.NotesType + "/" + complaintRegisterNumber);
    // formData.append("IsFlag", inNoteImportant ? 1 : 0);
    // formData.append("NoteType", data.NotesType);
    // formData.append(
    //   "FileName",
    //   `${data.fileName}_${moment(Date())
    //     .toISOString()
    //     .replace(/[^0-9]/g, "")
    //     .slice(0, -3)}` +
    //   "." +
    //   `${data.files[0].name.split(".").pop()}`
    // );
    // formData.append("NoteTitle", "");
    // formData.append("NoteText", data.Notes);
    // formData.append("document", data.files[0]);
    // formData.append("CurrentUser", CommonConfig.getCurrentUserId());
    // formData.append("LanguageId", "en-US");
    // console.log("DDDDDDD", formData);

    const formattedData = {
      EntityType: "ComplaintRegister",
      EntityId: complaintRegisterId ? complaintRegisterId : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
      IsFlag: inNoteImportant ? 1 : 0,
      NoteType: data.NotesType,
      NoteTitle: "",
      NoteText: data.Notes,
    };

    // reset({
    //   Notes: "",
    //   NotesTypes: "",
    // });
    // console.log("formattedData", formattedData);

    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "note/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          getNoteList();

          reset({
            NotesType: "",
            Notes: "",
          });
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              assignment_late
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            {showAdd ? "Complaint Register" : "Complaint Register"}
          </MDTypography>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 0, ml: "auto", mr: 2 }}>
            {complaintRegisterNumber ? "Reference Number:" + complaintRegisterNumber : ""}
          </MDTypography>
          <MDButton
            variant="contained"
            sx={{ mt: 1, mb: 0, ml: 2, mr: 2 }}
            color="primary"
            onClick={() => {
              setShowAdd((prev) => !prev);
              setNotesEnable(false);
              reset({});
              setComplaintRegisterNumber(null);
            }}
          >
            {showAdd ? "Show List" : "Add New"}
          </MDButton>
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          {showAdd ? (
            <div className="reg_step quality-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="referral_form_inner">
                  {/* <Controller
                      name="ComplaintNumber"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          id="outlined-read-only-input"
                          label="Reference Number"
                          style={{
                            width: "250px",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                          value={value}
                          onChange={onChange}
                          error={error}
                          helperText={error?.message}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      )}
                    /> */}

                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="ComplaintDate"
                          defaultValue=""
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                label="Complaint Date*"
                                value={value || null}
                                inputFormat="dd/MM/yyyy"
                                onChange={onChange}
                                // minDate={new Date()}
                                maxDate={new Date()}
                                renderInput={(params) => (
                                  <TextField
                                    // helperText={error?.message}
                                    // error={error}
                                    {...params}
                                    error={errors.ComplaintDate}
                                    helperText={errors.ComplaintDate ? "Date Required." : ""}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="ReceivedFrom"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="outlined-read-only-input"
                              label="Received From"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="ReceivedBy"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="ReceivedBy"
                              name="ReceivedBy"
                              value={value}
                              options={internalStaff ? internalStaff : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Received By*"
                                  error={errors.ReceivedBy}
                                  helperText={errors.ReceivedBy ? "Received By Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="AssignTo"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="AssignTo"
                              name="AssignTo"
                              value={value}
                              options={internalStaff ? internalStaff : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Assign To*"
                                  error={errors.AssignTo}
                                  helperText={errors.AssignTo ? "Assign To Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      {/* <div className="reg_input auto-complete">
                          <Autocomplete
                            name="CRStatus"
                            options={complaintStatus ? complaintStatus : []}
                            renderInput={(params) => (
                              <TextField
                                {...register("CRStatus")}
                                {...params}
                                error={errors.Status}
                                defaultValue="Pending"
                                helperText={errors.Status ? "Status required" : ""}
                                label="Status*"
                              />
                            )}
                          />
                        </div> */}

                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="CRStatus"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="CRStatus "
                              name="CRStatus "
                              value={value}
                              options={complaintStatus ? complaintStatus : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Status*"
                                  error={errors.CRStatus}
                                  helperText={errors.CRStatus ? "Status Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="ComplaintDetail"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Details*"
                              color="primary"
                              variant="outlined"
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="Action"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Action"
                              color="primary"
                              variant="outlined"
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <div className="reg_submit">
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setShowAdd(false);
                        setNotesEnable(false);
                        setComplaintRegisterNumber(null);
                      }}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        color: "#fff",
                      }}
                    >
                      {complaintRegisterNumber ? "Update" : "Submit"}
                    </MDButton>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="table-custom">
              <PrimeDatatable data={complaintList} getColumnListing={getColumn} />
            </div>
          )}
        </MDBox>
      </Card>

      {notesEnable && CommonConfig.isInternalStaff() === 1 ? (
        <Card sx={{ width: "100%", mt: 6 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                warning_amber
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              Notes
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            {/* <Grid container spacing={3}>
          <Grid item xs={4}>
            <div className="reg_input">
              <Autocomplete
                options={notesDropdownList ? notesDropdownList : []}
                defaultValue={selectedNotesType ? selectedNotesType : ""}
                onChange={(e, value) => {
                  setSselectedNotesType(value.label);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Notes Type*"
                    name="NotesType"
                    // error={values.errorstate}
                    // helperText={values.errorstate}
                  />
                )}
              />
              <span class="errorTC" id="ServicesError">
                Please select Notes Type.
              </span>
            </div>
          </Grid>
          <Grid item xs={4}>
            <ReportGmailerrorredIcon
              color={inNoteImportant ? "primary" : "secondary"}
              fontSize="large"
              onClick={() => setinNoteImportant((prev) => !prev)}
            />
          </Grid>
        </Grid> */}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {/* <div className="reg_input">
              <TextField
                onChange={(e) => setChNotes(e.target.value)}
                value={chNotes ? chNotes : ""}
                name="notes"
                multiline
                rows={3}
                // maxRows={4}
                label="Notes Text*"
                id="regFirstName"
                fullWidth
              />
              <span class="errorTC" id="NotesError">
                Please Write Notes.
              </span>
            </div> */}
              </Grid>
            </Grid>

            <div className="notes-table">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Notes</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="nt-date">{moment(new Date()).format("DD-MM-YYYY")}</td>
                    <td className="nt-comment">
                      <div className="reg_input mb-0">
                        <TextField
                          onChange={(e) => setChNotes(e.target.value)}
                          value={chNotes ? chNotes : ""}
                          name="notes"
                          multiline
                          rows={2}
                          // maxRows={4}
                          label="Notes Text*"
                          id="regFirstName"
                          fullWidth
                        />
                        <span class="errorTC" id="NotesError">
                          Please Write Notes.
                        </span>
                      </div>
                    </td>
                    <td className="nt-action">
                      <PButton
                        type="button"
                        icon="pi pi-plus"
                        className="p-button-primary AddNotes"
                        onClick={() => saveNotes()}
                        // style={{ marginRight: ".5em" }}
                        tooltip="Add Notes"
                        tooltipOptions={{ position: "bottom" }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <NotesComponent
              entityType="ComplaintRegister"
              entityId={complaintRegisterId}
              NotesData={notesData}
            />
          </MDBox>
        </Card>
      ) : (
        <></>
      )}

      {/* {notesEnable && CommonConfig.isInternalStaff() === 1 ? (
        <div className="custom-accordian mt-2">
          <Accordion>
            <AccordionSummary expandIcon={<ArrowDownward />}>Notes</AccordionSummary>
            <AccordionDetails>
              <form onSubmit={handleSubmitNote(onSubmitN)}>
                <Grid container spacing={3}>
                  <Grid item md="4">
                    <div className="reg_input auto-complete">
                      <Controller
                        control={controlNotes}
                        name="NotesType"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            disablePortal
                            id="NotesType"
                            name="NotesType"
                            value={value}
                            options={notesDropdownList ? notesDropdownList : []}
                            onChange={(e, v) => onChange(v.id)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Note Type*"
                                error={errorsNote.NotesType}
                                helperText={errorsNote.NotesType ? "Note Type required" : ""}
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="reg_input">
                      <InputLabel>Attach Document:</InputLabel>
                      <Controller
                        name="files"
                        control={controlNotes}
                        defaultValue={[]}
                        render={({ field }) => {
                          return (
                            <Input
                              {...field}
                              label="Upload Document"
                              type="file"
                              variant="outlined"
                              onChange={(event) => field.onChange(event.target?.files)}
                              value={field.target?.fileName}
                            />
                          );
                        }}
                      />
                     
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="imp-notes">
                      <ReportGmailerrorredIcon
                        color={inNoteImportant ? "primary" : "secondary"}
                        fontSize="large"
                        cursor="pointer"
                        onClick={() => setinNoteImportant((prev) => !prev)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <div className="reg_input">
                      <Controller
                        name="Notes"
                        control={controlNotes}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Note Text*"
                            color="primary"
                            variant="outlined"
                            rows={3}
                            {...registerNote("Notes", { required: true })}
                            multiline
                            formControlProps={{
                              fullWidth: true,
                            }}
                            value={value}
                            onChange={onChange}
                            error={errorsNote.Notes}
                            helperText={errorsNote.Notes ? "Note required" : ""}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid item md="4" sx={{ mb: 2 }}>
                  <MDButton type="submit" variant="contained" color="primary">
                    Save Notes
                  </MDButton>
                </Grid>
              </form>

              <NotesComponent
                entityType="ComplaintRegister"
                entityId={complaintRegisterId}
                NotesData={notesData}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <></>
      )} */}
    </DashboardLayout>
  );
};

export default ComplaintRegister;
