import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/valuecare-logo.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { autoLogin } from "../../commonAPI/commonAPI";
import { Alert } from "@mui/material";

const schema = yup.object().shape(
  {
    // RelationType: yup.string().required("Relation Required."),
    FirstName: yup
      .string()
      .required("First Name Required.")
      .min(3, "First Name must be at least 3 characters."),
    LastName: yup
      .string()
      .required("Last Name Required.")
      .min(3, "Last Name must be at least 3 characters."),

   

    Email: yup.string().required("Email Required.").email("Incorrect email format."),
    PhoneNumber: yup
      .string()
      .required("Phone Required.")
      .matches("^[0-9]+$", "Phone should be number.")
      .min(10, "Invalid Phone Number.")
      .max(10, "Invalid Phone Number."),

    IsLiving: yup.boolean(),
    GroupHome: yup.string().when("IsLiving", {
      is: true,
      then: yup.string().required("Group Home Required."),
    }),
    AddrLine1: yup.string().required("Street Address Required."),
    // AddrLine2: yup.string().required("Street Name Required"),
    Suburb: yup.string().required("Suburb Required."),

    SupportPersonfname: yup.string().when(["SupportPersonlname", "SupportPersonDob"], {
      is: (SupportPersonlname, SupportPersonDob) =>
        SupportPersonlname !== "" || SupportPersonDob !== "",
      then: yup.string().required("First Name Required."),
    }),
    SupportPersonlname: yup.string().when(["SupportPersonfname", "SupportPersonDob"], {
      is: (SupportPersonfname, SupportPersonDob) =>
        SupportPersonfname !== "" || SupportPersonDob !== "",
      then: yup.string().required("Last Name Required."),
    }),
    SupportPersonDob: yup.string().when(["SupportPersonfname", "SupportPersonlname"], {
      is: (SupportPersonfname, SupportPersonlname) =>
        SupportPersonfname !== "" || SupportPersonlname !== "",
      then: yup.string().required("DOB Required."),
    }),

    SupportPersonPin: yup
      .string()
      .nullable()
      .notRequired()
      .when("SupportPersonPin", {
        is: (value) => value?.length,
        then: yup
          .string()
          .required("Postal Code Required.")
          .matches("^[0-9]+$", "Postal Code should be number.")
          .min(4, "Invalid Postal Code.")
          .max(4, "Invalid Postal Code."),
      }),

    IsNdisDocument: yup.boolean(),
    Document: yup.string().when("IsNdisDocument", {
      is: true,
      then: yup.string().required("Please Select Document."),
      // is: true,
      // then: yup.string().required(""),
    }),

    IsNdisRegistered: yup.boolean(),
    NDISNumber: yup.string().when("IsNdisRegistered", {
      is: true,
      then: yup
        .string()
        .required("NDIS Number Required.")
        .matches("^[0-9]+$", "Should be number.")
        .min(9, "NDIS Number must be at least 9 digit.")
        .max(9, "Invalid NDIS Number."),
    }),
    // SupportPersonState: yup.object().required("State required"),
  },
  [
    ["SupportPersonfname", "SupportPersonlname"],
    ["SupportPersonfname", "SupportPersonDob"],
    ["SupportPersonlname", "SupportPersonDob"],
    ["SupportPersonPin", "SupportPersonPin"],
  ]
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const FamilyMember = () => {
  const { state } = useLocation();

  let navigate = useNavigate();
  let clientRef = useRef(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [values, setValues] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [supportPersonStateLabel, setSupportPersonStateLabel] = useState(
    state?.SecurityUserData?.SupportPersonState || null
  );

  const [userData, setUserData] = useState(state?.SecurityUserData || null);
  const [termsCondition, setTermsCondition] = useState(null);

  const [statelist, setGetstate] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [stateList, setStateList] = useState(null);

  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);

  const [serviceList, setServiceList] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [loader, setLoader] = React.useState(false);

  const onStateChange = (event, value) => {
    console.log("StateValue", value);
    setStateId(value.id);
    setSupportPersonStateLabel(value.label);
  };

  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);
          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (state && JSON.parse(state.SecurityUserData?.Services)) {
      setAutocompleteValues();
    }
  }, []);

  const setAutocompleteValues = () => {
    let dummyArray = [];
    let formattedData = [];
    let array = JSON.parse(state.SecurityUserData.Services);
    for (let i = 0; i < array.length; i++) {
      if (array[i]) {
        dummyArray.push({
          id: array[i],
          label: array[i],
        });
      }
    }
    for (let i = 0; i < array.length; i++) {
      formattedData.push(array[i]);
    }
    debugger;
    setValues(formattedData);
    setServiceAutocompleteValues(dummyArray);
  };

  const onServiceChange = (event, value) => {
    let formattedData = [];
    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].id);
    }
    const servi = formattedData;
    setValues(formattedData);

    console.log("---------------", value);
    setServiceAutocompleteValues(value);
  };

  const getServiceData = () => {
    const formattedData = {
      StringMapType: "SERVICETYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setServiceList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getStateData();
    getServiceData();
  }, []);

  
  const UserName = localStorage.getItem("UserName");
  const Password = localStorage.getItem("Password");
  const FirstName = localStorage.getItem("FirstName");
  const LastName = localStorage.getItem("LastName");
  const EditUserName = userData ? (userData.UserName ? userData.UserName : "") : "";
  const EditPassword = userData ? (userData.passwords ? userData.passwords : "") : "";
  let PersonType = JSON.parse(localStorage.getItem("userData"))
    ? JSON.parse(localStorage.getItem("userData")).PersonType
    : "";

  const statedata = {
    id: userData?.SupportPersonState,
    label: userData?.SupportPersonState,
  };
  console.log("userData = ", userData);

  const getdocment = "";
  
  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // UserTypeId: "4e43a9ae-f848-11ec-86e0-fa163ebffd39", RelationType
      PersonType: "CARER",
      UserType: "EXTERNAL",
      UserName: userData?.UserName ? userData?.UserName : UserName,
      Password: userData?.Password ? userData?.Password : Password,
      Relation: userData?.RelationshipCategory ? userData?.RelationshipCategory : "",
      Email: userData?.Email ? userData?.Email : "",
      FirstName: FirstName ? FirstName : userData?.FirstName ? userData?.FirstName : "",
      MiddleName: userData?.MiddleName ? userData?.MiddleName : "",
      LastName: LastName ? LastName : userData?.LastName ? userData?.LastName : "",
      Gender: userData?.Gender ? userData?.Gender : "Male",
      AddrLine1: userData?.AddrLine1 ? userData?.AddrLine1 : "",
      AddrLine2: userData?.AddrLine2 ? userData?.AddrLine2 : "",
      Suburb: userData?.Suburb ? userData?.Suburb : "",
      //  State: userData?.PersonState ? userData?.PersonState : "",
      PhoneNumber: userData?.PhoneNumber ? userData?.PhoneNumber : "",
      SupportPersonfname: userData?.SupportPersonfname ? userData?.SupportPersonfname : "",
      SupportPersonmname: userData?.SupportPersonmname ? userData?.SupportPersonmname : "",
      SupportPersonlname: userData?.SupportPersonlname ? userData?.SupportPersonlname : "",
      SupportPersonDob: userData?.SupportPersonDob ? userData?.SupportPersonDob : "",
      SupportPersonStreetAddress: userData?.SupportPersonStreetAddress
        ? userData?.SupportPersonStreetAddress
        : "",
      SupportPersonStreetName: userData?.SupportPersonStreetName
        ? userData?.SupportPersonStreetName
        : "",
      SupportPersonSuburb: userData?.SupportPersonSuburb ? userData?.SupportPersonSuburb : "",
      SupportPersonState: userData?.SupportPersonState ? statedata : "",
      SupportPersonPin: userData?.SupportPersonPin ? userData?.SupportPersonPin : "",
      Disabilities: userData?.Disabilities ? userData?.Disabilities : "",
      IsLiving: userData?.IsLiving ? userData?.IsLiving : 0,
      IsNdisRegistered: userData?.IsNdisRegistered ? userData?.IsNdisRegistered : 0,
      IsNdisDocument: userData?.IsNdisDocument ? userData?.IsNdisDocument : 0,
      NDISNumber: userData?.NDISNumber ? userData?.NDISNumber : "",
      Document: userData?.Document ? userData?.Document : "",
      clientManager: userData?.Appointment
        ? userData?.Appointment == 1
          ? "Yes, arrange a call back"
          : "list of services I need"
        : "Yes, arrange a call back",

      RelationType: userData?.RelationshipType ? userData?.RelationshipType : "PrimaryCarer",
      PatientCarerRelationshipID: userData?.PatientCarerRelationshipID
        ? userData?.PatientCarerRelationshipID
        : "",
      GroupHome: userData?.GroupHome ? userData?.GroupHome : "",
      Services: userData?.Services ? userData?.Services : "",
    },
  });

  let IsLiving = watch("IsLiving", true);
  let IsNdisRegistered = watch("IsNdisRegistered", true);
  let IsNdisDocument = watch("IsNdisDocument", true);

  const autoLogin = (email, password) => {
    debugger;
    const inputdata = {
      Username: email,
      Password: password,
    };
    console.log("Input data", inputdata);
    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "authentication/authenticateUser",
      method: "POST",
      headers: {},
      data: inputdata,
    })
      .then((res) => {
        debugger;
        console.log("test.....12", res.data.data[0][0].returnValue);

        // if (res.status === 200) {
        if (res.data.data[0][0].returnValue == "Invalid Username or password") {
          cogoToast.error("Invalid User.");
        } else {
          if (res.data.success === true) {
            // cogoToast.success("Login Successfully");

            var sendData = {
              PersonType: res.data.data[0][0].PersonType,
            };

            localStorage.setItem("token", res.data.data[0][0].Token);
            localStorage.setItem("userData", JSON.stringify(res.data.data[0][0]));

            axios({
              url: CommonConfig.ApiUrl + "authentication/getUserMenus",
              method: "POST",
              headers: {},
              data: sendData,
            })
              .then((res) => {
                console.log("test", res.data.data[0]);

                localStorage.setItem("Menu", JSON.stringify(res.data.data[0]));

                if (res.data.success === true) {
                  navigate("/dashboards/userprofile/Index");
   
                } else {
                  cogoToast.error("Invalid User.");
                }
              })
              .catch((error) => {
                console.log(error);
              });

            // // debugger
            localStorage.setItem("token", res.data.data[0][0].Token);
            localStorage.setItem("userData", JSON.stringify(res.data.data[0][0]));
   
          } else {
            cogoToast.error("Invalid User.");
          }
        }
      })
      .catch((error) => {
        return false;
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    debugger;
    let FState = data.SupportPersonState.id;
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");

    if (values.length === 0) {
      document.getElementById("ServicesError").style.display = "block";
      return;
    } else {
      document.getElementById("ServicesError").style.display = "none";
    }

    if (!userData) {
      if (!termsCondition) {
        document.getElementById("TcError").style.display = "block";
        return;
      } else {
        document.getElementById("TcError").style.display = "none";
      }
    }
    console.log("DATA::", data);
    let formattedData = "";
    // let DobFormat = moment(data.SupportPersonDob).format("YYYY-MM-DD");
    let DobFormat = CommonConfig.formatDate(data.SupportPersonDob);

    let sevicelist = [];
    sevicelist = values;
    formattedData = {
      ...data,
      SupportPersonDob: DobFormat,
      IsLiving: IsLiving == true ? 1 : 0,
      IsNdisRegistered: IsNdisRegistered == true ? 1 : 0,
      IsNdisDocument: IsNdisDocument == true ? 1 : 0,
      Services: sevicelist,
      SupportPersonState: FState,
      clientManager: data.clientManager == "Yes, arrange a call back" ? 1 : 0,
    };
    console.log("formattedData111", formattedData);
    if (userData) {
      formattedData = {
        ...formattedData,
        PersonId: state?.PersonId,
      };
    }
    setLoader(!loader);
    let formattedDataT;
    if (userData) {
      formattedDataT = {
        formattedData,
        CurrentUser: CommonConfig.getCurrentUserId(),
      };
    } else {
      formattedDataT = {
        formattedData,
      };
    }

    console.log("formattedData55.....", formattedDataT);

    axios({
      url: CommonConfig.ApiUrl + "authentication/addUpdateUser",

      method: "POST",
      headers: {},
      data: formattedDataT,
    })
      .then((res) => {
        setLoader(!loader);
        if (res.data.success === true) {
          // cogoToast.success("Registration Successfully");
          if (!userData) {
            cogoToast.success("Registered Successfully.");
          } else {
            cogoToast.success("Updated Successfully.");
          }

          let loginData;

          if (!userData) {
            loginData = autoLogin(UserName, Password);
          } else if (userData && PersonType !== "Admin") {
            loginData = autoLogin(EditUserName, EditPassword);
          } else {
            navigate("/dashboards/analytics");
          }

   
        } else {
          cogoToast.error("Something Went Wrong.");
        }

        console.log("testttttt", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {userData ? (
        <DashboardLayout>
          <DashboardNavbar />
          <Card sx={{ width: "100%", mt: 3 }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="primary"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  accessible
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Edit Profile - Family member/principal carer/relative
              </MDTypography>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: "auto", mr: 2 }}>
                Person# : {userData.PersonIdNumber}
              </MDTypography>
            </MDBox>
            <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="reg_step" id="regStep2">
                  <div className="reg_form">
                    <Grid item md="12">
                      <div className="reg_input check_value">
                        <InputLabel>Are you a*</InputLabel>
                        <Controller
                          name="RelationType"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              <RadioGroup
                                aria-label="RelationType"
                                defaultValue={
                                  userData ? userData?.RelationshipType : "PrimaryCarer"
                                }
                                onChange={onChange}
                                row="true"
                              >
                                <FormControlLabel
                                  aria-errormessage="DShsfkjh"
                                  value="PrimaryCarer"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Primary Carer"
                                />
                                <FormControlLabel
                                  value="FamilyMember"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Family Member"
                                />
                                <FormControlLabel
                                  value="Relative"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Relative"
                                />
                                <FormControlLabel
                                  value="Others"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Others"
                                />
                                <small className="check-hint mt-2">(tick all relevant boxes)</small>
                              </RadioGroup>
                              
                            </>
                          )}
                        />
                      </div>
                    </Grid>

                    <div className="divider"> </div>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                      <Grid item md="12">
                        <InputLabel mt={{ xs: -20, lg: -18 }} className="reg_lable mb-20 mt-15">
                          If related, what is your relationship with the person needing supports or
                          services?{" "}
                        </InputLabel>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Relation"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Enter Relationship"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="FirstName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="First Name*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="MiddleName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Middle Name"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="LastName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Last Name*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <div className="reg_input check_value">
                      <InputLabel>Gender</InputLabel>
                      <Controller
                        name="Gender"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <>
                            {" "}
                            <RadioGroup
                              aria-label="appointment"
                              row="true"
                              defaultValue={userData ? userData?.Gender : "Male"}
                            >
                              <FormControlLabel
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value="Male"
                                control={<Radio />}
                                label="Male"
                              />
                              <FormControlLabel
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value="Female"
                                control={<Radio />}
                                label="Female"
                              />
                              <FormControlLabel
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value="Unknown"
                                control={<Radio />}
                                label="Unknown"
                              />
                            </RadioGroup>
                          </>
                        )}
                      />
                    </div>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="PhoneNumber"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                type="number"
                                label="Phone Number*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                inputProps={{
                                  maxLength: 10,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Email"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                disabled={true}
                                label="Email*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="12">
                        <InputLabel className="reg_lable">Address</InputLabel>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AddrLine1"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Street Address*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AddrLine2"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Street Name"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Suburb"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Suburb*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <div className="reg_box_bordered">
                      <Grid item md="12">
                        <InputLabel className="reg_lable mb-1">
                          Details of the person needing supports or services.
                        </InputLabel>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md="3">
                          <div className="reg_input">
                            <Controller
                              name="SupportPersonfname"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="First Name"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="3">
                          <div className="reg_input">
                            <Controller
                              name="SupportPersonmname"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Middle Name"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item md="3">
                          <div className="reg_input">
                            <Controller
                              name="SupportPersonlname"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Last Name"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item md="3">
                          <div className="reg_input">
                            <Controller
                              name="SupportPersonDob"
                              defaultValue=""
                              control={control}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <LocalizationProvider dateAdapter={DateFnsUtils}>
                                  <DatePicker
                                    label="Select DOB"
                                    value={value || null}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={onChange}
                                    // minDate={new Date("15-08-1947")}
                                    maxDate={new Date()}
                                    renderInput={(params) => (
                                      <TextField
                              
                                        {...params}
                                        error={errors.SupportPersonDob}
                                        helperText={errors.SupportPersonDob ? "DOB Required." : ""}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input check_value">
                            <InputLabel>Are you Living in a Group Home? </InputLabel>
                            <Controller
                              name="IsLiving"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RadioGroup
                                  defaultValue={userData ? userData?.IsLiving : "0"}
                                  aria-label="Living"
                                  name="IsLiving"
                                  onChange={onChange}
                                  row="true"
                                >
                                  <FormControlLabel
                                    value="1"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="0"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              )}
                              rules={{ required: "Email Id required" }}
                            />
                          </div>
                          {IsLiving == 1 ? (
                            <div className="reg_input sec_level">
                              <Controller
                                name="GroupHome"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Group Home*"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>

                      <Grid item md="12">
                        <InputLabel className="reg_lable mb-1">Current Address.</InputLabel>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="SupportPersonStreetAddress"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Street Address"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="SupportPersonStreetName"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Relative Street Name"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="SupportPersonSuburb"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Suburb"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input auto-complete">
                            <Controller
                              control={control}
                              name="SupportPersonState"
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  disablePortal
                                  id="SupportPersonState"
                                  name="SupportPersonState"
                                  value={value}
                                  options={stateList ? stateList : ""}
                                  onChange={(e, v) => onChange(v)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="State"
                                      error={errors.SupportPersonState}
                                      helperText={errors.SupportPersonState ? "State Required" : ""}
                                    />
                                  )}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="2">
                          <div className="reg_input">
                            <Controller
                              name="SupportPersonPin"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Postal Code"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                  inputProps={{
                                    maxLength: 4,
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>

                    <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                      <Grid item md="12">
                        <InputLabel mt={{ xs: -20, lg: -18 }} className="reg_lable mb-20 mt-15">
                          What impairments or disabilities does the person has?{" "}
                        </InputLabel>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Disabilities"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Enter Disabilities"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="6">
                        <div className="reg_input check_value mb-0">
                          <InputLabel>
                            Is the person needing services registered with NDIS?{" "}
                          </InputLabel>
                          <Controller
                            name="IsNdisRegistered"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                aria-label="NDIS"
                                defaultValue={userData ? userData?.IsNdisRegistered : "0"}
                                onChange={onChange}
                                row="true"
                              >
                                <FormControlLabel
                                  value="1"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="0"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                          />
                        </div>
                        {IsNdisRegistered == 1 ? (
                          <div className="reg_input sec_level">
                            <Controller
                              name="NDISNumber"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Enter NDIS Number*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                  inputProps={{
                                    maxLength: 9,
                                  }}
                                />
                              )}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input check_value">
                          <InputLabel>Do you have your NDIS plan document available?</InputLabel>
                          <Controller
                            name="IsNdisDocument"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                defaultValue={userData ? userData?.IsNdisDocument : "0"}
                                aria-label="NDIS Document"
                                onChange={onChange}
                                row="true"
                              >
                                <FormControlLabel
                                  value="1"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="0"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                            rules={{ required: "Select Any One" }}
                          />
                        </div>
                        {IsNdisDocument == 1 ? (
                          <div className="reg_input sec_level">
                            <Controller
                              name="Document"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  type="file"
                                  onChange={onChange}
                                  // label="Select file"
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                            {userData ? (
                              <div>
                                {userData.Document ? <label>{userData.Document} </label> : null}
                              </div>
                            ) : (
                              <div></div>
                            )}

                            
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>

                    <div className="reg_input check_value">
                      <InputLabel>
                        Do you know which services you need or shall we arrange an Appointment with
                        one of our Client Manager?
                      </InputLabel>
                      <Controller
                        name="clientManager"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <RadioGroup
                            aria-label="clientManager"
                            
                            defaultValue={
                              userData
                                ? userData?.Appointment == 1
                                  ? "Yes, arrange a call back"
                                  : "list of services I need"
                                : "Yes, arrange a call back"
                            }
                            onChange={onChange}
                            row="true"
                          >
                            <FormControlLabel
                              aria-errormessage="DShsfkjh"
                              value="Yes, arrange a call back"
                              onChange={onChange}
                              control={<Radio />}
                              label="Yes, arrange a call back."
                            />
                            <FormControlLabel
                              value="list of services I need"
                              onChange={onChange}
                              control={<Radio />}
                              label="List of services I need."
                            />
                          </RadioGroup>
                        )}
                      />
                    </div>
                    <Grid container spacing={3}>
                      <Grid item md="12">
                        <Grid item sm={6} md={6}>
                          <div className="reg_input sec_level">
                            <Autocomplete
                              multiple
                              id="Services"
                              name="Services"
                              options={serviceList ? serviceList : []}
                              value={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                              defaultValue={
                                serviceAutocompleteValues ? serviceAutocompleteValues : []
                              }
                              onChange={onServiceChange}
                              filterSelectedOptions
                              formControlProps={{
                                fullWidth: true,
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="What services are you registered for?*"
                                  placeholder="Favorites"
                            
                                  id="Services"
                                  name="Services"
                            
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              )}
                            />
                            <span class="errorTC" id="ServicesError">
                              Please select any one service.
                            </span>
                          </div>
                        </Grid>
                        {!userData ? (
                          <Grid container spacing={3}>
                            <Grid item>
                              <div className="reg_input check_value">
                                <Checkbox
                                  checked={termsCondition}
                                  onChange={() => setTermsCondition((prev) => !prev)}
                                  color="primary"
                                  label="By registering you agree to Value Care’s Terms &amp; Conditions."
                                />
                                By registering you agree to Value Care’s Terms &amp; Conditions.
                                <br></br>
                                <span class="errorTC" id="TcError">
                                  {!termsCondition &&
                                    "Please Select Terms & Conditions to continue."}
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>

                {userData ? (
                  <div className="reg_submit">
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate("/dashboards/userprofile/Index")}
                    >
                      Cancel
                    </MDButton>
                    <MDButton type="submit" variant="contained" color="primary">
                      Update
                    </MDButton>
                  </div>
                ) : (
                  <div className="reg_submit">
                    <MDButton type="submit" variant="outlined" color="primary">
                      Register
                    </MDButton>
                  </div>
                )}
              </form>
            </MDBox>
          </Card>
          <div className="copyright">
            <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
          </div>
        </DashboardLayout>
      ) : (
        <div className="signup-page-outer">
          <CoverLayout className="sign-up-outer">
            
            <div className="container">
              <div className="logo">
                <a href="www.google.com">
                  <img alt="Test" src={Logo} />
                </a>
              </div>
              <div className="reg-inner">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="reg_step" id="regStep2">
                    <Grid item md="12">
                      <h2 className="h2-heading">
                        I am a family member/principal carer/relative of a person with a disability
                        or in need of supports.
                      </h2>
                    </Grid>
                    <div className="reg_form">
                      <Grid item md="12">
                        <div className="reg_input check_value">
                          <InputLabel>
                            Are you a*{" "}
                            <small className="check-hint">(tick all relevant boxes)</small>
                          </InputLabel>
                          <Controller
                            name="RelationType"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <>
                                <RadioGroup
                                  aria-label="RelationType"
                                  defaultValue={
                                    userData ? userData?.RelationshipType : "PrimaryCarer"
                                  }
                                  onChange={onChange}
                                  row="true"
                                >
                                  <FormControlLabel
                                    aria-errormessage="DShsfkjh"
                                    value="PrimaryCarer"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="Primary Carer"
                                  />
                                  <FormControlLabel
                                    value="FamilyMember"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="Family Member"
                                  />
                                  <FormControlLabel
                                    value="Relative"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="Relative"
                                  />
                                  <FormControlLabel
                                    value="Others"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="Others"
                                  />
                                </RadioGroup>
                                
                              </>
                            )}
                          />
                        </div>
                      </Grid>

                      <div className="divider"> </div>
                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                        <Grid item md="12">
                          <InputLabel mt={{ xs: -20, lg: -18 }} className="reg_lable mb-20 mt-15">
                            If related, what is your relationship with the person needing supports
                            or services?{" "}
                          </InputLabel>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="Relation"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Enter Relationship"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="FirstName"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="First Name*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="MiddleName"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Middle Name"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="LastName"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Last Name*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <div className="reg_input check_value">
                        <InputLabel>Gender</InputLabel>
                        <Controller
                          name="Gender"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              {" "}
                              <RadioGroup
                                aria-label="appointment"
                                row="true"
                                defaultValue={userData ? userData?.Gender : "Male"}
                              >
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Male"
                                  control={<Radio />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Female"
                                  control={<Radio />}
                                  label="Female"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Unknown"
                                  control={<Radio />}
                                  label="Unknown"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </div>

                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="PhoneNumber"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  type="number"
                                  label="Phone Number*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                  inputProps={{
                                    maxLength: 10,
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="Email"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Email*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md="12">
                          <InputLabel className="reg_lable">Address</InputLabel>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="AddrLine1"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Street Address*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="AddrLine2"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Street Name"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="Suburb"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Suburb*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <div className="reg_box_bordered">
                        <Grid item md="12">
                          <InputLabel className="reg_lable mb-1">
                            Details of the person needing supports or services.
                          </InputLabel>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="3">
                            <div className="reg_input">
                              <Controller
                                name="SupportPersonfname"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="First Name"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>

                          <Grid item md="3">
                            <div className="reg_input">
                              <Controller
                                name="SupportPersonmname"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Middle Name"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                          <Grid item md="3">
                            <div className="reg_input">
                              <Controller
                                name="SupportPersonlname"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Last Name"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                          <Grid item md="3">
                            <div className="reg_input">
                              <Controller
                                name="SupportPersonDob"
                                defaultValue=""
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <LocalizationProvider dateAdapter={DateFnsUtils}>
                                    <DatePicker
                                      label="Select DOB"
                                      value={value || null}
                                      inputFormat="dd/MM/yyyy"
                                      onChange={onChange}
                                      // minDate={new Date("15-08-1947")}
                                      maxDate={new Date()}
                                      renderInput={(params) => (
                                        <TextField
                                          
                                          {...params}
                                          error={errors.SupportPersonDob}
                                          helperText={
                                            errors.SupportPersonDob ? "DOB Required." : ""
                                          }
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <div className="reg_input check_value">
                              <InputLabel>Are you Living in a Group Home? </InputLabel>
                              <Controller
                                name="IsLiving"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <RadioGroup
                                    defaultValue={userData ? userData?.IsLiving : "0"}
                                    aria-label="Living"
                                    name="IsLiving"
                                    onChange={onChange}
                                    row="true"
                                  >
                                    <FormControlLabel
                                      value="1"
                                      onChange={onChange}
                                      control={<Radio />}
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value="0"
                                      onChange={onChange}
                                      control={<Radio />}
                                      label="No"
                                    />
                                  </RadioGroup>
                                )}
                                rules={{ required: "Email Id required" }}
                              />
                            </div>
                            {IsLiving == 1 ? (
                              <div className="reg_input sec_level">
                                <Controller
                                  name="GroupHome"
                                  control={control}
                                  defaultValue=""
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      label="Group Home*"
                                      variant="outlined"
                                      value={value}
                                      onChange={onChange}
                                      error={error}
                                      helperText={error?.message}
                                    />
                                  )}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>

                        <Grid item md="12">
                          <InputLabel className="reg_lable mb-1">Current Address.</InputLabel>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="SupportPersonStreetAddress"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Street Address"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>

                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="SupportPersonStreetName"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Relative Street Name"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>

                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="SupportPersonSuburb"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Suburb"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <Controller
                              control={control}
                              name="SupportPersonState"
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  disablePortal
                                  id="SupportPersonState"
                                  name="SupportPersonState"
                                  value={value}
                                  options={stateList ? stateList : ""}
                                  onChange={(e, v) => onChange(v)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="State"
                                      error={errors.SupportPersonState}
                                      helperText={errors.SupportPersonState ? "State Required" : ""}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item md="2">
                            <div className="reg_input">
                              <Controller
                                name="SupportPersonPin"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Postal Code"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                    inputProps={{
                                      maxLength: 4,
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>

                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                        <Grid item md="12">
                          <InputLabel mt={{ xs: -20, lg: -18 }} className="reg_lable mb-20 mt-15">
                            What impairments or disabilities does the person has?{" "}
                          </InputLabel>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="Disabilities"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Enter Disabilities"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input check_value">
                            <InputLabel>
                              Is the person needing services registered with NDIS?{" "}
                            </InputLabel>
                            <Controller
                              name="IsNdisRegistered"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RadioGroup
                                  aria-label="NDIS"
                                  defaultValue={userData ? userData?.IsNdisRegistered : "0"}
                                  onChange={onChange}
                                  row="true"
                                >
                                  <FormControlLabel
                                    value="1"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="0"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              )}
                            />
                          </div>
                          {IsNdisRegistered == 1 ? (
                            <div className="reg_input sec_level">
                              <Controller
                                name="NDISNumber"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Enter NDIS Number*"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                    inputProps={{
                                      maxLength: 9,
                                    }}
                                  />
                                )}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input check_value">
                            <InputLabel>Do you have your NDIS plan document available?</InputLabel>
                            <Controller
                              name="IsNdisDocument"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RadioGroup
                                  defaultValue={userData ? userData?.IsNdisDocument : "0"}
                                  aria-label="NDIS Document"
                                  onChange={onChange}
                                  row="true"
                                >
                                  <FormControlLabel
                                    value="1"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="0"
                                    onChange={onChange}
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              )}
                              rules={{ required: "Select Any One" }}
                            />
                          </div>
                          {IsNdisDocument == 1 ? (
                            <div className="reg_input sec_level">
                              <label classname="test-label">Select Document*</label>
                              <Controller
                                name="Document"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    type="file"
                                    onChange={onChange}
                                    //label="Select file"
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>

                      <div className="reg_input check_value">
                        <InputLabel>
                          Do you know which services you need or shall we arrange an Appointment
                          with one of our Client Manager?
                        </InputLabel>
                        <Controller
                          name="clientManager"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <RadioGroup
                              aria-label="clientManager"
                              defaultValue={
                                userData
                                  ? userData?.Appointment == 0
                                    ? "list of services I need"
                                    : "Yes, arrange a call back"
                                  : "Yes, arrange a call back"
                              }
                              onChange={onChange}
                              row="true"
                            >
                              <FormControlLabel
                                aria-errormessage="DShsfkjh"
                                value="Yes, arrange a call back"
                                onChange={onChange}
                                control={<Radio />}
                                label="Yes, arrange a call back."
                              />
                              <FormControlLabel
                                value="list of services I need"
                                onChange={onChange}
                                control={<Radio />}
                                label="List of services I need."
                              />
                            </RadioGroup>
                          )}
                        />
                      </div>
                      <Grid container spacing={3}>
                        <Grid item md="12">
                          <Grid item sm={6} md={6}>
                            <div className="reg_input sec_level">
                              <Autocomplete
                                multiple
                                id="Services"
                                name="Services"
                                options={serviceList ? serviceList : []}
                                value={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                                defaultValue={
                                  serviceAutocompleteValues ? serviceAutocompleteValues : []
                                }
                                onChange={onServiceChange}
                                filterSelectedOptions
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="What services are you registered for?*"
                                    placeholder="Favorites"
                                    
                                    id="Services"
                                    name="Services"
                                    
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                )}
                              />
                              <span class="errorTC" id="ServicesError">
                                Please select any one service.
                              </span>
                            </div>
                          </Grid>
                          {!userData ? (
                            <Grid container spacing={3}>
                              <Grid item>
                                <div className="reg_input check_value">
                                  <Checkbox
                                    checked={termsCondition}
                                    onChange={() => setTermsCondition((prev) => !prev)}
                                    color="primary"
                                    label="By registering you agree to Value Care’s Terms &amp; Conditions."
                                  />
                                  <small>
                                    By registering you agree to Value Care’s{" "}
                                    <a className="color-red" onClick={handleOpen}>
                                      Terms &amp; Conditions.
                                    </a>
                                  </small>
                                  <br></br>
                                  <span class="errorTC" id="TcError">
                                    {!termsCondition &&
                                      "Please Select Terms & Conditions to continue."}
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography id="modal-modal-title" variant="h6" component="h2">
                            Please Read Carefully Terms &amp; Conditions.
                          </Typography>
                          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Health insurance is a way to ensure you have support for costly medical
                            treatment. Value Care has been designed to tailor a health plan to suit
                            your needs and priorities within your budget. Benefits include
                            hospitalisation and specialist care locally and with world class medical
                            facilities in India. Value Care is available to individuals and families
                            as well as group schemes who are looking for medical insurance at
                            reasonable rates. Benefits provided through this plan are regularly
                            reviewed to meet our customers’ needs.
                          </Typography>
                          <Button
                            style={{ color: "white" }}
                            variant="contained"
                            color="primary"
                            onClick={handleClose}
                          >
                            OK
                          </Button>
                        </Box>
                      </Modal>
                    </div>
                  </div>

                  {userData ? (
                    <div>
                      <MDButton type="submit" variant="contained" color="primary">
                        Update
                      </MDButton>
                      <MDButton
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate("/dashboards/userprofile/Index")}
                      >
                        Cancel
                      </MDButton>
                    </div>
                  ) : (
                    <div className="reg_submit">
                      <MDButton
                        style={{ color: "white" }}
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate("/")}
                      >
                        Cancel
                      </MDButton>
                      <MDButton type="submit" variant="contained" color="primary">
                        Register
                      </MDButton>
                    </div>
                  )}
                </form>
              </div>
            </div>
            {/* </Grid>
            </Grid> */}
          </CoverLayout>
          <div className="copyright">
            <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
          </div>
        </div>
      )}
    </>
  );
};

FamilyMember.defaultProps = {};

export default FamilyMember;
