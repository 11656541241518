import { useLocation, useNavigate } from "react-router-dom";
import { CommonConfig } from "constant";
import "assets/css/style.css";

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Grid from "@mui/material/Grid";
import {
  Add,
  ArrowDownward,
  CancelScheduleSendTwoTone,
  ControlCameraTwoTone,
} from "@mui/icons-material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";
import cogoToast from "cogo-toast";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CardContent from "@mui/material/CardContent";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PButton } from "primereact/button";
import { TextField } from "@mui/material";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
} from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";

function Referalist(props) {
  let navigate = useNavigate();

  if (localStorage.getItem("userData")) {
  } else {
    navigate("/");
  }
  let i = 1;
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [rememberMe, setRememberMe] = useState(true);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [name, setName] = useState("");
  const [cityOrState, setCityOrState] = useState("");
  const [userType, setUserType] = useState("");
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [personIdNumber, setPersonIdNumber] = useState("");
  const [stateList, setStateList] = useState(null);
  const [values, setValues] = useState([]);
  const [stateLabel, setStateLabel] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMediumType, setSelectedMediumType] = useState(false);
  const [appointmentdelID, setappointmentdelID] = useState(null);
  const [loader, setLoader] = React.useState(false);

  let loginUser;

  loginUser = JSON.parse(localStorage.getItem("userData"));

  var userTypeMessage = "Referral List";

  console.log("Testing12 ", loginUser.PersonId);

  const columns = [

    {
      name: "Referral Number",
      selector: "ReferalNumber",
      filter: true,
      sortable: true,
    },
    {
      name: "Full Name",
      selector: "FullName",
      filter: true,
      sortable: true,
    },

    {
      name: "Email",
      selector: "ClientEmail",
      filter: true,
      sortable: true,
    },

    {
      name: "Phone",
      selector: "ClientPhone",
      filter: true,
      sortable: true,
    },

    {
      name: "City",
      selector: "ClientCity",
      filter: true,
      sortable: true,
    },

    {
      name: "Action",
      body: (data) => actionTemplate(data),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const actionTemplate = (data) => {
    return (
      <div>
        <PButton
          type="button"
          icon="pi pi-user-edit"
          className="p-button-warning"
          onClick={() => editUser(data)}
          style={{ marginRight: ".5em" }}
          tooltip="Edit Referral"
          tooltipOptions={{ position: "bottom" }}
        />
        &nbsp;
        <PButton
          type="button"
          icon="pi pi-user-minus"
          className="p-button-danger  "
          onClick={() => {

            selectedRow(data);
          }}
          tooltip="Remove Referral"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    );
  };

  const getColoumnsRow = () =>
    columns.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    UserType: "",
  });

  const onStateChange = (event, value) => {
    console.log("VVV", value.id);
    setValues(value.id);
    setStateLabel(value.label);

    console.log("-------bhanu--------", value);
  };

  const getUserTypeData = () => {
    const formattedData = {
      StringMapType: "USERTYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("UserTypeList", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getData = (props) => {
    setLoader(!loader);
    axios({
      url: CommonConfig.ApiUrl + "Referral/getReferralData",

      method: "POST",
      headers: {

      },
    })
      .then((res) => {
        setLoader(!loader);
        if (res.data?.success === true) {
          console.log(res);
          setLoader(false);
          setData(res.data.data[0]);
          console.log("Data...", data);
        }
        console.log("test...hhghghg", res.data.Data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserTypeData();
    getData();
  }, []);

  const CancelDelete = () => {
    setSelectedMediumType("");
    setOpenDialog(false);
  };
  const selectedRow = (selectedRow = {}) => {
    debugger;
    setappointmentdelID(selectedRow.ReferralId);

    console.log("........,new", appointmentdelID);
    setOpenDialog(true);
  };
  const deleteUser = (selectedRow = {}) => {
    debugger;

    setSelectedMediumType(false);

    console.log("........,,,ddddd", appointmentdelID);


    if (selectedMediumType == "Yes") {
      document.getElementById("ServicesError").style.display = "none";
      let inputData = {
        ReferralId: appointmentdelID,
      };
      console.log("inputData...", inputData);
      axios({

        url: CommonConfig.ApiUrl + "Referral/DeleteReferral",
        method: "POST",
        headers: {},

        data: inputData,
      })

        .then((res) => {
          if (res.data?.success) {
            console.log("test222", res.data);

            if (res.data.success === true) {
              cogoToast.success("Deleted Successfully.");
              setOpenDialog(false);
              getData();
            }
          }
        })
        // Catch errors if any
        .catch((error) => {
          console.log(error);
        });
    } else if (selectedMediumType == "No") {
      document.getElementById("ServicesError").style.display = "none";
      setOpenDialog(false);
    } else {
      document.getElementById("ServicesError").style.display = "block";
    }
  };

  const editUser = async (row = {}) => {
    setLoader(!loader);
    const { ReferralId } = row;
    try {
      const data = await axios(CommonConfig.ApiUrl + "Referral/getReferralData", {
        method: "POST",
        data: { ReferralId },
      }).then((res) => {
        setLoader(!loader);
        var userdata = {
          ...res.data.data[0][0],
          dOC: res.data.data[1],
        };

        var documentdata = [];

        console.log(",,,,,,,,,ggg,,", userdata);
        navigate("/layouts/referal/Index", {
          state: res.data.data[0][0],
        });
      });


    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              person_add
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            {userTypeMessage}
          </MDTypography>
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          <div className="card">
            <div className="table-custom">
              <DataTable
                scrollable
                value={data}
                showGridlines
                scrollHeight="600px"
                scrollWidth="600px"
                responsiveLayout="scroll"
                paginator
                rows={10}
                totalRecords={data ? data.length : 0}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[10, 20, 50]}
                emptyMessage="No records found"

                filterDisplay="row"
              >
                {getColoumnsRow()}
              </DataTable>
            </div>
          </div>
        </MDBox>
      </Card>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="xs">
        <DialogTitle>Remove User</DialogTitle>
        <DialogContent>
          <Grid item md="12">
            <div className="reg_input check_value mb-0">
              <InputLabel className="reg_lable mt-5 mb-2">
                Are You Sure to Remove this User.?
              </InputLabel>
              <div>
                <span class="servicesError" id="ServicesError">
                  Please select any one option.
                </span>
              </div>
              <RadioGroup
                aria-label="remove"
                name="Remove"
                row="true"
                onChange={(e) => setSelectedMediumType(e.target.value)}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio checked={selectedMediumType === "Yes" ? true : false} />}
                  label="Yes"
                  name="Remove"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio checked={selectedMediumType === "No" ? true : false} />}
                  label="No"
                  name="Remove"
                />
              </RadioGroup>

            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton variant="contained" color="secondary" onClick={() => CancelDelete()}>
            Back
          </MDButton>
          <MDButton
            variant="contained"
            color="primary"
            onClick={() => {
              deleteUser();
            }}
          >
            Ok
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Referalist;
