import React, { useEffect, useMemo, useState, useRef } from "react";

import {
  Autocomplete,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Button,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PrimeDatatable from "layouts/component/PrimeDatatable";
import { Column } from "primereact/column";
import { Button as PButton } from "primereact/button";
import { NotesComponent } from "layouts/component/Notes";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { ArrowDownward } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import moment from "moment";

import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Logo from "assets/img/valuecare-logo.png";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const schema = yup.object().shape({
  ImprovementRegisterDate: yup.string().required("Date Required."),
  Client: yup.string().required("Representative/Client Required."),
  ImprovementCategory: yup.object().required("Improvement Category Required."),
  SuggestedImprovement: yup.string().required("Suggested Improvement Required."),
  // Reference: yup.string().required("Other Register ID/Reference required"),
  // CauseOfIssue: yup.string().required("Cause of Issue required"),
  // AssignedTo: yup.object().required("Assessed By required"),
  // CIStatus: yup.object().required("Status required"),
  // DueDate: yup.string().required("Due Date required"),
});

const OfficeRiskAssessment = () => {
  const { state } = useLocation();
  const [todayDate, settodayDate] = useState();
  const [internalStaff, setInternalStaff] = useState(null);
  const [complaintList, setComplaintList] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [improvementRegisterId, setImprovementRegisterId] = useState(null);
  const [improvementRegisterNumber, setImprovementRegisterNumber] = useState(null);
  const [improvementCategoryDD, setImprovementCategoryDD] = useState(null);
  const [statusDD, setStatusDD] = useState(null);
  const [notesEnable, setNotesEnable] = useState(false);
  const [notesDropdownList, setNotesDropdownList] = useState(null);
  const [selectedNotesType, setSelectedNotesType] = useState(null);
  const [inNoteImportant, setinNoteImportant] = useState(false);
  const [chNotes, setChNotes] = useState(null);
  const [values, setValues] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [loader, setLoader] = React.useState(false);

  let navigate = useNavigate();
  let loginUser;
  if (localStorage.getItem("userData")) {
    // alert("Hello");
    loginUser = JSON.parse(localStorage.getItem("userData"));
  } else {
    // alert("Hello2")
    navigate("/");
  }

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ImprovementRegisterId: "",
      CurrentUser: "",
      EntityID: "",
      EntityType: "",

      ImprovementRegisterDate: "",
      Client: "",
      ImprovementCategory: "",
      SuggestedImprovement: "",
      Reference: "",
      CauseOfIssue: "",
      AssignedTo: "",
      CIStatus: "",
      DueDate: "",
    },
  });

  useEffect(() => {
    getInternalStaff();
    getImprovementRegisterList();
    getStatusList();
    getImprovementCategoryDropdownList();
    getNotesDropdownList();
    getNoteList();
  }, []);

  let column = [
    {
      name: "Representative/Client",
      selector: "Client",
      filter: true,
      sortable: true,
    },
    {
      name: "Assigned To",
      // body: (data) => {
      //   if (data.AssignedTo) {
      //     let receivedName = internalStaff?.filter((item) => item.id == data.AssignedTo);
      //     if (receivedName && receivedName.length > 0) {
      //       return receivedName[0].label;
      //     }
      //     return "";
      //   }
      //   return "";
      // },
      selector: "AssigneeName",
      filter: true,
      sortable: true,
    },

    {
      name: "Improvement Register Date",
      // body: (data) => {
      //   if (data.ImprovementRegisterDate) {
      //     return CommonConfig.formatDate(data.ImprovementRegisterDate);
      //   }
      //   return "";
      // },
      selector: "ImprovementRegisterDate",
      filter: true,
      sortable: true,
    },
    {
      name: "Improvement Category",
      selector: "ImprovementCategory",
      filter: true,
      sortable: true,
    },
    {
      name: "Due Date",
      // body: (data) => {
      //   if (data.DueDate) {
      //     return CommonConfig.formatDate(data.DueDate);
      //   }
      //   return "";
      // },
      selector: "DueDate",
      filter: true,
      sortable: true,
    },

    {
      name: "Status",
      selector: "Status",
      filter: true,
      sortable: true,
    },
    {
      name: "Action",
      body: (data) => actionTemplate(data),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const notesColumns = [
    {
      selector: (notesData) => {
        if (notesData.CreatedOn) {
          return moment(notesData.CreatedOn).format("DD-MM-YYYY");
        }
      },
      className: "NotesDates",
    },

    {
      selector: (notesData) => {
        return notesData?.NoteTitle + " " + notesData?.NoteText;
      },
      className: "NotesTitle",
    },

    {
      className: "NotesInfo",
      body: (notesData) => actionTemplate(notesData),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const getNotesColoumns = () =>
    notesColumns.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  const actionTemplate = (row) => {
    return (
      <PButton
        type="button"
        icon="pi pi-pencil"
        className="p-button-warning"
        onClick={() => {
          // setIsEditEnabled(true);
          setShowAdd(true);
          setNotesEnable(true);
          // console.log("ROW", row);
          if (row.ImprovementRegisterId) {
            setImprovementRegisterId(row.ImprovementRegisterId);
            setImprovementRegisterNumber(row.ImprovementNumber);
            getNoteList(row.ImprovementRegisterId);
          }

          let receivedByObj;
          if (row.CIStatus) {
            receivedByObj = statusDD.filter((item) => item.id == row.CIStatus);
          }
          let receivedByObj1;
          if (row.ImprovementCategory) {
            receivedByObj1 = improvementCategoryDD.filter(
              (item) => item.id == row.ImprovementCategory
            );
          }

          let receivedByObj3;
          if (row.AssignedTo) {
            receivedByObj3 = internalStaff.filter((item) => item.id == row.AssignedTo);
          }
          // // console.log("DATA", {
          // //   ...row,
          // //   ReceivedBy: receivedByObj ? receivedByObj[0] : "",
          // //   AssignedTo: assignToObj ? assignToObj[0] : "",
          // // });
          reset({
            ...row,
            CIStatus: receivedByObj ? receivedByObj[0] : "",
            ImprovementCategory: receivedByObj1 ? receivedByObj1[0] : "",
            AssignedTo: receivedByObj3 ? receivedByObj3[0] : "",
          });
        }}
        style={{ marginRight: ".5em" }}
        tooltip="Edit Complaint"
        tooltipOptions={{ position: "bottom" }}
      />
    );
  };

  const getColumn = () =>
    column.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  const getImprovementRegisterList = () => {
    setLoader(!loader);
    let formattedData = {
      ImprovementRegisterId: "",
      CurrentUser: "",
    };

    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "qualityManagment/getImprovementRegisterData",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: formattedData,
    })
      // Handle the response from backend here
      .then((res) => {
        // console.log("RRRRR", res);
        if (res.data.success) {
          setLoader(false);
          console.log("StateList1===>", res.data.data);

          var PersonType = loginUser.PersonType;
          var loginUserName = loginUser.FirstName + " " + loginUser.LastName;
          if (PersonType == "INTERNAL STAFF") {
            // setLoader(false);
            var tempstore = [];
            for (var i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].AssigneeName === loginUserName) {
                tempstore.push(res.data.data[i]);
              }
            }
            setComplaintList(tempstore);
          } else {
            setComplaintList(res.data.data);
          }
          // setComplaintList(res.data.data);
        }
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  const getStatusList = () => {
    const formattedData = {
      StringMapType: "CISTATUS",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);
          setStatusDD(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getImprovementCategoryDropdownList = () => {
    const formattedData = {
      StringMapType: "IMPROVEMENTCATEGORY",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);
          setImprovementCategoryDD(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getInternalStaff = () => {
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
    })
      .then((res) => {
        if (res.data.success) {
          setInternalStaff(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNotesDropdownList = () => {
    const formattedData = {
      StringMapType: "NOTETYPE.ContinuosImprovementRegister",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);
          setSelectedNotesType(res.data.data[0].StringMapName);
          // setNotesDropdownList(
          //   res.data.data.map((item) => ({
          //     id: item.StringMapKey,
          //     label: item.StringMapName,
          //   }))
          // );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  // const refreshPage = () => {
  //   window.location.reload();
  // };

  const saveNotes = () => {
    // if (selectedNotesType === null) {
    //   document.getElementById("ServicesError").style.display = "block";
    //   return;
    // } else {
    //   document.getElementById("ServicesError").style.display = "none";
    // }

    if (chNotes === null || chNotes === "") {
      document.getElementById("NotesError").style.display = "block";
      return;
    } else {
      document.getElementById("NotesError").style.display = "none";
    }
    debugger;
    // ComplaintRegister
    const formattedData = {
      EntityType: selectedNotesType,
      // EntityId: officeRiskAssessmentId ? officeRiskAssessmentId : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
      IsFlag: inNoteImportant ? 1 : 0,
      NoteType: selectedNotesType,
      NoteTitle: "",
      NoteText: chNotes,
    };

    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "note/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          // refreshPage();
          // setShowAdd(false);
          // setNotesEnable(false);
          getNoteList(improvementRegisterId);
          // refreshPage();
          // setSselectedNotesType("");
          setChNotes("");
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        cogoToast.error("Something went wrong.");
      });
  };

  const getNoteList = (values) => {
    // debugger;
    let noteReqData = {
      EntityType: "ContinuosImprovementRegister",
      // EntityId: values ? values : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "note/getNoteList",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: noteReqData,
    })
      .then((res) => {
        console.log("Hello147", res.data);
        if (res.data?.success) {
          // console.log("-------------", data);
          setNotesData(res.data.data || []);
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    setLoader(!loader);
    console.log("data", data);
    let formattedDate = CommonConfig.formatDate(data.ImprovementRegisterDate);
    let formattedDate1 = CommonConfig.formatDate(data.DueDate);

    let improvementCategory = data.ImprovementCategory?.id;
    let status = data.CIStatus?.id;
    let assignedTo = data.AssignedTo?.id;

    let formattedData = {
      ...data,
      ImprovementCategory: improvementCategory,
      CIStatus: status,
      AssignedTo: assignedTo,
      ImprovementRegisterDate: formattedDate,
      DueDate: formattedDate1,
      CurrentUser: CommonConfig.getCurrentUserId(),
    };

    console.log("formattedDataT", formattedData);

    axios({
      url: CommonConfig.ApiUrl + "qualityManagment/addUpdateImprovementRegister",

      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        setLoader(!loader);
        if (res.data.success === true) {
          getImprovementRegisterList();
          setShowAdd(false);
          setNotesEnable(false);
          if (improvementRegisterNumber) {
            cogoToast.success("Updated Successfully.");
            setImprovementRegisterNumber(null);
          } else {
            cogoToast.success("Registered Successfully.");
          }
        } else {
          cogoToast.error("Something Went Wrong.");
        }

        console.log("testttttt", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              accessible
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            Continuous Improvement Register
          </MDTypography>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 0, ml: "auto", mr: 2 }}>
            {improvementRegisterNumber ? "Reference Number:" + improvementRegisterNumber : ""}
          </MDTypography>
          <MDButton
            variant="contained"
            color="primary"
            sx={{ mt: 1, mb: 0, ml: 2, mr: 2 }}
            onClick={() => {
              setShowAdd((prev) => !prev);
              setNotesEnable(false);
              reset({});
              setImprovementRegisterNumber(null);
            }}
          >
            {showAdd ? "Show List" : "Add New"}
          </MDButton>
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          {showAdd ? (
            <div className="reg_step quality-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="referral_form_inner">
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      {/* <div className="reg_input">
                        <Controller
                          name="ImprovementRegisterDate"
                          defaultValue=""
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                label="Date*"
                                // value={value || null}
                                inputFormat="dd/MM/yyyy"
                                onChange={onChange}
                                minDate={new Date()}
                                renderInput={(params) => (
                                  <TextField
                                    helperText={error?.message}
                                    error={error}
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div> */}
                      <div className="reg_input">
                        <Controller
                          name="ImprovementRegisterDate"
                          defaultValue=""
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                label="Date*"
                                value={value || null}
                                inputFormat="dd/MM/yyyy"
                                onChange={onChange}
                                minDate={new Date()}
                                // minDate={new Date("02-01-2022")}
                                // maxDate={new Date("09-05-2022")}
                                renderInput={(params) => (
                                  <TextField
                                    // helperText={error?.message}
                                    // error={error}
                                    {...params}
                                    error={errors.ImprovementRegisterDate}
                                    helperText={
                                      errors.ImprovementRegisterDate ? "Date Required." : ""
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="Client"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Representative/Client*"
                              color="primary"
                              variant="outlined"
                              rows={1}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="ImprovementCategory"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="ImprovementCategory"
                              name="ImprovementCategory"
                              value={value}
                              options={improvementCategoryDD ? improvementCategoryDD : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Improvement Category*"
                                  error={errors.ImprovementCategory}
                                  helperText={
                                    errors.ImprovementCategory
                                      ? "Improvement Category Required."
                                      : ""
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="SuggestedImprovement"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Suggested Improvement*"
                              color="primary"
                              variant="outlined"
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="CauseOfIssue"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Cause of Issue"
                              color="primary"
                              variant="outlined"
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="3">
                      <div className="reg_input">
                        <Controller
                          name="Reference"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Other Register ID/Reference"
                              color="primary"
                              variant="outlined"
                              rows={1}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="3">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="AssignedTo"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="AssignedTo"
                              name="AssignedTo"
                              value={value}
                              options={internalStaff ? internalStaff : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Assigned To "
                                  error={errors.AssignedTo}
                                  helperText={errors.AssignedTo ? "Assigned To required" : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="3">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="CIStatus"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="CIStatus "
                              name="CIStatus "
                              value={value}
                              options={statusDD ? statusDD : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Status"
                                  error={errors.CIStatus}
                                  helperText={errors.CIStatus ? "Status required" : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="3">
                      <div className="reg_input">
                        <Controller
                          name="DueDate"
                          defaultValue=""
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                label="Date"
                                value={value || null}
                                inputFormat="dd/MM/yyyy"
                                onChange={onChange}
                                minDate={new Date()}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={errors.DueDate}
                                    helperText={errors.DueDate ? "" : ""}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className="reg_submit">
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setShowAdd(false);
                        setNotesEnable(false);
                        setImprovementRegisterNumber(null);
                      }}
                    >
                      Cancel
                    </MDButton>
                    <MDButton type="submit" variant="contained" color="primary">
                      {/* Submit */}
                      {improvementRegisterNumber ? "Update" : "Submit"}
                    </MDButton>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="table-custom">
              <PrimeDatatable data={complaintList} getColumnListing={getColumn} />
            </div>
          )}
        </MDBox>
      </Card>

      {notesEnable && CommonConfig.isInternalStaff() === 1 ? (
        <Card sx={{ width: "100%", mt: 6 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                warning_amber
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              Notes
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            {/* <Grid container spacing={3}>
          <Grid item xs={4}>
            <div className="reg_input">
              <Autocomplete
                options={notesDropdownList ? notesDropdownList : []}
                defaultValue={selectedNotesType ? selectedNotesType : ""}
                onChange={(e, value) => {
                  setSselectedNotesType(value.label);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Notes Type*"
                    name="NotesType"
                    // error={values.errorstate}
                    // helperText={values.errorstate}
                  />
                )}
              />
              <span class="errorTC" id="ServicesError">
                Please select Notes Type.
              </span>
            </div>
          </Grid>
          <Grid item xs={4}>
            <ReportGmailerrorredIcon
              color={inNoteImportant ? "primary" : "secondary"}
              fontSize="large"
              onClick={() => setinNoteImportant((prev) => !prev)}
            />
          </Grid>
        </Grid> */}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {/* <div className="reg_input">
              <TextField
                onChange={(e) => setChNotes(e.target.value)}
                value={chNotes ? chNotes : ""}
                name="notes"
                multiline
                rows={3}
                // maxRows={4}
                label="Notes Text*"
                id="regFirstName"
                fullWidth
              />
              <span class="errorTC" id="NotesError">
                Please Write Notes.
              </span>
            </div> */}
              </Grid>
            </Grid>

            <div className="notes-table">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Notes</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="nt-date">{moment(new Date()).format("DD-MM-YYYY")}</td>
                    <td className="nt-comment">
                      <div className="reg_input mb-0">
                        <TextField
                          onChange={(e) => setChNotes(e.target.value)}
                          value={chNotes ? chNotes : ""}
                          name="notes"
                          multiline
                          rows={2}
                          // maxRows={4}
                          label="Notes Text*"
                          id="regFirstName"
                          fullWidth
                        />
                        <span class="errorTC" id="NotesError">
                          Please Write Notes.
                        </span>
                      </div>
                    </td>
                    <td className="nt-action">
                      <PButton
                        type="button"
                        icon="pi pi-plus"
                        className="p-button-primary AddNotes"
                        onClick={() => saveNotes()}
                        // style={{ marginRight: ".5em" }}
                        tooltip="Add Notes"
                        tooltipOptions={{ position: "bottom" }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <Grid item md="4"></Grid>
            {/* {improvementRegisterId ? ( */}
            <NotesComponent
              entityType="ContinuosImprovementRegister"
              entityId={improvementRegisterId}
              NotesData={notesData}
            />
            {/* ) : null} */}
          </MDBox>
        </Card>
      ) : (
        <></>
      )}

      {/* {notesEnable && CommonConfig.isInternalStaff() === 1 ? (
        <div className="custom-accordian mt-2">
          <Accordion>
            <AccordionSummary expandIcon={<ArrowDownward />}>Notes</AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3} sx={{ mb: 2 }}>
                <Grid item xs={3}>
                  <h5>Notes Type: </h5>
                </Grid>
                <Grid item xs={4}>
                  <div className="reg_input">
                    <Autocomplete
                      options={notesDropdownList ? notesDropdownList : []}
                      defaultValue={selectedNotesType ? selectedNotesType : ""}
                      onChange={(e, value) => {
                        setSselectedNotesType(value.label);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Notes Type*"
                          name="NotesType"
                          error={values.errorstate}
                          helperText={values.errorstate}
                        />
                      )}
                    />
                    <span class="errorTC" id="ServicesError">
                      Please select Notes Type.
                    </span>
                    <ReportGmailerrorredIcon
                      color={inNoteImportant ? "primary" : "secondary"}
                      fontSize="large"
                      onClick={() => setinNoteImportant((prev) => !prev)}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ mb: 2 }}>
                <Grid item xs={3}>
                  <h5>Notes Text: </h5>
                </Grid>
                <Grid item xs={4}>
                  <div className="reg_input">
                    <TextField
                      onChange={(e) => setChNotes(e.target.value)}
                      value={chNotes ? chNotes : ""}
                      name="notes"
                      multiline
                      rows={3}
                      style={{
                        marginBottom: "10px",
                      }}
                      // maxRows={4}
                      label="Notes*"
                      id="regFirstName"
                      fullWidth
                    />
                    <span class="errorTC" id="NotesError">
                      Please Write Notes.
                    </span>
                  </div>
                </Grid>
              </Grid>
              <Grid item md="4">
                <MDButton onClick={() => saveNotes()} variant="contained" color="primary">
                  Save Notes
                </MDButton>
              </Grid>
            </AccordionDetails>

            <NotesComponent
              // entityType="OfficeRiskAssessmentId"
              // entityId={officeRiskAssessmentId}
              NotesData={notesData}
            />
          </Accordion>
        </div>
      ) : (
        <></>
      )} */}
    </DashboardLayout>
  );
};
export default OfficeRiskAssessment;
