import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PButton } from "primereact/button";
import axios from "axios";
import { CommonConfig } from "constant";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ArrowDownward } from "@mui/icons-material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export const NotesComponent = ({ entityType, entityId, NotesData = [] }) => {
  const [data, setData] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const paginatorLeft = <PButton type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <PButton type="button" icon="pi pi-cloud" className="p-button-text" />;
  const [loader, setLoader] = React.useState(false);

  const notesColumns = [
    {
      selector: (notesData) => {
        if (notesData.CreatedOn) {
          return moment(notesData.CreatedOn).format("DD-MM-YYYY");
        }
      },
      className: "NotesDates",
    },

    {
      selector: (notesData) => {
        return  notesData?.NoteText;
      },
      className: "NotesTitle",
    },

    {
      className: "NotesInfo",
      body: (notesData) => actionTemplate(notesData),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const getNotesColoumns = () =>
    notesColumns.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  console.log("notesData", notesData);
  const actionTemplate = (notesData) => {
    return (
      <div className="template">
        <PButton
          type="button"
          icon="pi pi-info"
          className="p-button-warning"
          tooltip={notesData.ListedBy}
          tooltipOptions={{ position: "bottom" }}
          // className="p-button-rounded p-button-info p-button-outlined"
          aria-label="Managed By"
        />
      </div>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      getNoteList();
    }, 1000);
  }, []);

  const getNoteList = () => {
    setLoader(!loader);

    // debugger;
    let noteReqData = {
      EntityType: entityType,
      EntityId: entityId,
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "note/getNoteList",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: noteReqData,
    })
      .then((res) => {
        console.log("Hello147 ", res.data);
        if (res.data?.success) {
          console.log("-------------", data);
          setNotesData(res.data.data || []);
          getNotesColoumns();
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    // <Accordion>
    //   <AccordionSummary expandIcon={<ArrowDownward />}>Show Notes</AccordionSummary>
    //   <AccordionDetails>
    <div className="table-custom">
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <DataTable
        scrollable
        className="NotesTableDynamic hidetext"
        id="NotesTableDynamic"
        value={NotesData}
        showGridlines
        scrollHeight="600px"
        scrollWidth="600px"
        responsiveLayout="scroll"
        paginator
        rows={10}
        totalRecords={NotesData ? NotesData.length : 0}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        rowsPerPageOptions={[10, 20, 50]}
        emptyMessage="No records found"
        paginatorLeft={paginatorLeft}
        paginatorRight={paginatorRight}
        // filterDisplay="row"
      >
        {getNotesColoumns()}
      </DataTable>
      {/* <div className="notes-table inner-table"> */}
      {/* <table>
        <thead>
          
        </thead>
        <tbody>
    

          {notesData.map(( listValue, index ) => {
          return (
            <tr key={index}>
              <td className="nt-date">{moment(listValue.CreatedOn).format("DD-MM-YYYY")}</td>
              <td className="nt-comment">{listValue.NoteText}</td>
              <td className="nt-action">
              <PButton
                type="button"
                icon="pi pi-info"
                className="p-button-primary"
                tooltip={listValue.EntityManagedByName}
                tooltipOptions={{ position: "bottom" }}
                // className="p-button-rounded p-button-info p-button-outlined"
                aria-label="Managed By"
              />
              </td>
            </tr>
          );
        })}
            
        
        </tbody>
      </table> */}
      {/* </div> */}
    </div>
    //   </AccordionDetails>
    // </Accordion>
  );
};
