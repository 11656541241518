import React from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";

export default ({ data, getColumnListing }) => {
  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  return (
    <DataTable
      scrollable
      value={data ? data : []}
      showGridlines
      scrollHeight="600px"
      scrollWidth="600px"
      responsiveLayout="scroll"
      paginator
      rows={10}
      totalRecords={data ? data.length : 0}
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      rowsPerPageOptions={[10, 20, 50]}
      emptyMessage="No records found"
      paginatorLeft={paginatorLeft}
      paginatorRight={paginatorRight}
      filterDisplay="row"
    >
      {getColumnListing()}
    </DataTable>
  );
};
