import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonConfig } from "constant";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
export default function VideoInput(props) {
  const { state } = useLocation();
  let fileName = state ? state.data?.Title : null;
  let fileDetails = state ? state.data?.pdffilename : null;
  console.log("fileDetailsurl", fileDetails);
  // https://valuecare.cognisun.net/Backend/documents/video/1678087392473-Development.mp4.mp4
  console.log("checkVideoURL", CommonConfig.dir + "video/" + fileDetails);
  const { width, height } = props;

  const inputRef = React.useRef();

  const [source, setSource] = React.useState();
  const [sourceFileName, setSourceFileName] = useState(null);
  //   const handleFileChange = (event) => {
  //     const file = event.target.files[0];
  //     const url = URL.createObjectURL(file);
  //     setSourceFileName(file?.name);
  //     setSource(url);
  //   };

  const handleFileChange = (event) => {
    // const file = fileDetailsurl;
    // const url = URL.createObjectURL(file);
    // console.log("fileDetailsurlbb", window.webkitURL.createObjectURL(file));
    // setSourceFileName(file?.name);
    // setSource(url);
  };

  useEffect(() => {
    if (fileDetails) {
      handleFileChange();
    }
  }, [fileDetails]);

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ width: "100%", mt: 3 }}>
        <div className="VideoInput">
          <input
            ref={inputRef}
            className="VideoInput_input"
            type="file"
            // onChange={handleFileChange}
            accept=".mov,.mp4"
          />
          {fileName ? (
            <video
              className="VideoInput_video"
              width="75%"
              height={height}
              controls
              src={fileDetails}
              // src={"source"}
              controlsList="nodownload"
            />
          ) : (
            ""
          )}
          <div className="VideoInput_footer">{fileName || "Nothing selectd"}</div>
        </div>
      </Card>
    </DashboardLayout>
  );
}
