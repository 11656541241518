import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { CommonConfig } from "constant";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import React, { useEffect, useState } from "react";
import MDDatePicker from "components/MDDatePicker";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CardContent from "@mui/material/CardContent";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import { Link } from "react-router-dom";

import callBack from "assets/img/call-back.svg";
import bookAppointment from "assets/img/book-appointment.svg";

import MDInput from "components/MDInput";
import cogoToast from "cogo-toast";

import "assets/css/style.css";
import { elementClosest } from "@fullcalendar/react";
import { getStaticWrapperUtilityClass } from "@mui/x-date-pickers/internals/components/PickerStaticWrapper";

function UserBookAppointment() {
  let navigate = useNavigate();

  let formdata = localStorage.getItem("Formdata");
  formdata = JSON.parse(formdata);
  console.log("jjjj", formdata);

  let userData = localStorage.getItem("userData");
  if (userData) {
    userData = JSON.parse(userData);
  } else {
    navigate("/");
  }
  const [data, setData] = useState({});
  var isvalid = false;
  const [values, setValues] = React.useState({
    ModeOfCommunication: "",
    services: [],
    date: "",
    time: "",
    location: "value care office site",
    phoneNumber: "",
    clientPhone: "",
    CallType: "",
    officeSite: "",

    streetAddress: "",
    streetName: "",
    city: "",
    state: "",
    pin: "",
    isValueCareSite: "",
    errorstreetAddress: "",
    errorstreetName: "",
    errorcity: "",
    errorstate: "",
    errorservices: "",
    country: "Australia",
    errorTerms: "",
    errortime: "",
    errorofficeSite: "",
    additionalInfo: "",
  });

  var Timelistdata = [];
  const [errors, setErrors] = useState({});
  const [timeData, setTimeData] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [serviceList, setServiceList] = useState(null);
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);

  var statelistdata = [];
  const [stateData, setstateData] = useState(null);
  const [selectedstate, setSelectedstate] = useState(null);
  const [Servicesvalues, setValuesServices] = useState([]);

  const [terms, setTerms] = useState(false);
  const [stateList, setStateList] = useState(null);
  const [stateId, setStateId] = useState([]);
  const [stateLabel, setStateLabel] = useState();
  const [loginCredintials, setLoginCredintialss] = React.useState({
    Email: "",

    Password: "",
  });
  const [form1, setForm1] = useState(1);
  // const navigate = useNavigate()
  const [success, setSuccess] = useState(false);

  const [todayDate, settodayDate] = useState(new Date().toISOString().slice(0, 10));
  //   useEffect(() => {
  //     getState();
  //   });

  const onStateChange = (event, value) => {
    console.log("State::", value);
    setStateId(value.id);
    setStateLabel(value.label);
  };

  const onServiceChange = (event, value) => {
    console.log("VALUEService", value);
    let formattedData = [];

    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].id);
    }

    // setValues(formattedData);
    setValuesServices(formattedData);
    setValues({ ...values, services: formattedData });
    setServiceAutocompleteValues(value);
  };

  const getState = () => {
    axios({
      url: "https://uatapi.valuecare.org.au/userModule/GetStateList",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.data.success === true) {
          console.log("1234", res);
          console.log("time data", res.data.Data);
          statelistdata = res.data.Data;
          setstateData(
            statelistdata.map((item) => ({
              label: item.StateName,
              // id: item.StartTime,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const [datetime,setDatetime]=React.useState()
  const onClick1 = (e) => {
    setValues({ ...values, ModeOfCommunication: e });
    setForm1(2);
  };
  const onClick2 = (e) => {
    setValues({ ...values, ModeOfCommunication: e });
    setForm1(5);
  };
  const onClick3 = (e) => {
    setValues({ ...values, ModeOfCommunication: e });
    setForm1(6);
  };
  const onClick4 = (e) => {
    setValues({ ...values, ModeOfCommunication: e });
    setForm1(8);
  };
  const onClick5 = (e) => {
    setValues({ ...values, ModeOfCommunication: e });
    setForm1(9);
  };

  const formChange = async (formId) => {
    console.log("kkk", JSON.stringify(values));
    debugger;
    if (formId === 4 && !values.services.length) {
      setErrors({ ...errors, services: "Please select atleast one Service" });
      return false;
    } else {
      setErrors({ ...errors, services: "" });
    }

    await localStorage.setItem("Formdata", JSON.stringify(values));
    let isToken = localStorage.getItem("userData");
    if (isToken) {
      formId = 3;
    } else {
      localStorage.setItem("pages", "BookAppointment");
      formId = 4;
    }
    setForm1(formId);
  };

  const formChange2 = async (formId) => {
    console.log("kkk", JSON.stringify(values));

    if (formId === 6 && !values.services.length) {
      setErrors({ ...errors, services: "Please select atleast one Service" });
      return false;
    } else {
      setErrors({ ...errors, services: "" });
    }
    await localStorage.setItem("Formdata", JSON.stringify(values));
    let isToken = localStorage.getItem("token");
    if (isToken) {
      formId = 3;
    } else {
      localStorage.setItem("pages", "BookAppointment");
      formId = 4;
    }
    setForm1(6);
  };
  const formChange3 = async (formId) => {
    console.log("kkk", JSON.stringify(values));

    if (formId === 5 && !values.services.length) {
      setErrors({ ...errors, services: "Please select atleast one Service" });
      return false;
    } else {
      setErrors({ ...errors, services: "" });
    }

    await localStorage.setItem("Formdata", JSON.stringify(values));
    let isToken = localStorage.getItem("token");
    if (isToken) {
      formId = 3;
    } else {
      localStorage.setItem("pages", "BookAppointment");
      formId = 4;
    }
    setForm1(5);
  };

  const backForm = () => {
    setForm1(form1 - 1);
  };

  const handleChange = (event, name) => {
    console.log(event.target.value, "targetsselectttttttt");
    if (name === "time") {
      values.time = event.target.value;
    }

    setValues({ ...values, [event.target.name]: event.target.value });
    // console.log("======", values);
  };

  const handleChange2 = (event) => {
    // console.log(event.target,"targetsselect")

    setLoginCredintialss({ ...loginCredintials, [event.target.name]: event.target.value });
    // console.log("======", values);
  };

  const handledateChange = (event) => {
    console.log("hdhdhd", event);
    let date = new Date(event[0]);
    console.log(date, "hello ");
    let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    date = [date.getFullYear(), mnth, day].join("-");
    console.log(date, "hello ");
    if (date >= todayDate) {
      setValues({ ...values, date: date });
    }
  };

  const handleSubmit = () => {
    debugger;

    // if (!terms) {
    //   debugger;
    //   document.getElementById("errorTC").style.display = "block";

    //   isvalid = false;
    // } else {
    //   document.getElementById("errorTC").style.display = "none";
    //   isvalid = true;
    //   setErrors({ ...errors, terms: "" });
    // }
    console.log(values);
    if (form1 === 3) {
      if (values.time === "") {
        values.errortime = "Please fill Time ";
        isvalid = false;
      } else {
        values.errortime = "";
        isvalid = true;
      }

      if (values.location === "value care office site" && form1 === 3) {
        if (values.location === "value care office site") {
          if (values.officeSite === "") {
            values.errorofficeSite = "please select OfficeSite";
            isvalid = false;
          } else {
            values.errorofficeSite = "";
            isvalid = true;
          }
        }
      } else if (values.location === "Australin Address" && form1 === 3) {
        if (values.location !== "value care office site") {
          if (values.streetAddress === "") {
            values.errorstreetAddress = "Please Enter Street Address";
            isvalid = false;
          } else {
            values.errorstreetAddress = "";
            isvalid = true;
          }
          if (values.streetName === "") {
            values.errorstreetName = "Please Enter Street Name";
            isvalid = false;
          } else {
            values.errorstreetName = "";
            isvalid = true;
          }
          if (values.city === "") {
            values.errorcity = "Please Enter City ";
            isvalid = false;
          } else {
            values.errorcity = "";
            isvalid = true;
          }
          if (values.state === "") {
            values.errorstate = "Please Enter State ";
            isvalid = false;
          } else {
            values.errorstate = "";
            isvalid = true;
          }
        }
      }

      if (values.date === "" && !terms) {
        document.getElementById("errorDate").style.display = "block";
        document.getElementById("errorTC").style.display = "block";

        //return true;
      } else {
        document.getElementById("errorDate").style.display = "none";
        document.getElementById("errorTC").style.display = "none";
      }
    }

    if (form1 === 6) {
      if (values.time === "") {
        values.errortime = "Please fill Time ";
        isvalid = false;
      } else {
        values.errortime = "";
        isvalid = true;
      }

      if (values.clientPhone === "") {
        values.errorclientPhone = "please enter phone number";
        isvalid = false;
      } else if (values.clientPhone.length !== 9) {
        values.errorclientPhone = "please enter valid phone number";
        isvalid = false;
      } else {
        values.errorclientPhone = "";
        isvalid = true;
      }
      if (values.date === "" && !terms) {
        document.getElementById("errorDate").style.display = "block";
        document.getElementById("errorTC").style.display = "block";

        //return true;
      } else {
        document.getElementById("errorDate").style.display = "none";
        document.getElementById("errorTC").style.display = "none";
      }
    }

    if (form1 === 5) {
      debugger;

      if (values.time === "") {
        values.errortime = "Please fill Time ";
        isvalid = false;
      } else {
        values.errortime = "";
        isvalid = true;
      }
      if (values.clientPhone === "") {
        values.errorclientPhone = "please enter phone number";
        isvalid = false;
      } else if (values.clientPhone.length !== 9) {
        values.errorclientPhone = "please enter valid phone number";
        isvalid = false;
      } else {
        values.errorclientPhone = "";
        isvalid = true;
      }
      if (values.date === "" && !terms) {
        if (values.date === "" && !terms) {
          document.getElementById("errorDate").style.display = "block";
          document.getElementById("errorTC").style.display = "block";

          //return true;
        } else {
          document.getElementById("errorDate").style.display = "none";
          document.getElementById("errorTC").style.display = "none";
        }
      }
      //   if (values.date === "" && terms) {
      //     if (values.date === "" && terms) {
      //       document.getElementById("errorDate").style.display = "block";
      //       //document.getElementById("errorTC").style.display = "block";

      //       //return true;
      //     } else {
      //       document.getElementById("errorDate").style.display = "none";
      //       //document.getElementById("errorTC").style.display = "none";
      //     }
      //   }
      //   if (values.date !== "" && !terms) {
      //     if (values.date !== "" && !terms) {
      //       //document.getElementById("errorDate").style.display = "block";
      //       document.getElementById("errorTC").style.display = "block";

      //       //return true;
      //     } else {
      //       // document.getElementById("errorDate").style.display = "none";
      //       document.getElementById("errorTC").style.display = "none";
      //     }
      //   }
    }

    if (isvalid === true) {
      if (values.location == "value care office site") {
      }
      const inputdata = {
        PersonID: userData.PersonId ? userData.PersonId : "",
        // Service: values.services,
        CallType: values.CallType,
        PhoneForConfirmation: values.clientPhone,
        AppointmentMode: values.ModeOfCommunication,
        AppointmentTime: selectedTime,
        AppointmentDate: values.date,
        Location: values.location,
        AddrLine1: values.streetAddress,
        AddrLine2: values.streetName,
        AddrLine3: values.AddrLine3,
        ValueCareSite: values.officeSite,
        City: values.city,
        State: selectedstate,
        Country: values.country,
        PostalCode: values.pin,
        AdditionalDetail: values.additionalInformation,
        MediumType: "",
      };

      if (values.location == "value care office site") {
        inputdata.isValueCareSite = 1;
        inputdata.finish = "1";
      } else {
        inputdata.isValueCareSite = 0;
        inputdata.finish = "1";
      }

      let formattedDataT = { inputdata, Services: Servicesvalues };

      console.log("formattedDataTTT.....", formattedDataT);

      axios({
        // Endpoint to send files
        url: CommonConfig.ApiUrl + "Appointment/addUpdateAppointment",
        method: "POST",
        headers: {
          // Add any auth token here
          //authorization: "your token comes here",
        },

        // Attaching the form data
        data: formattedDataT,
      })
        // Handle the response from backend here
        .then((res) => {
          if (res.data.success) {
            cogoToast.success("Appointment Booked Successfully");

            navigate("/pages/book-appointment/list");
          }
          console.log("......ggggggggg", res.data.success);
          console.log("test", res.data);
          // cogoToast.error('Something went wrong');
        })

        // Catch errors if any
        .catch((error) => {
          console.log(error);
          // cogoToast.error('Something went wrong');
        });
    } else {
      cogoToast.error("please fill the details");
    }
  };

  const Submit2 = () => {
    const data = {
      Email: loginCredintials.Email,
      Password: loginCredintials.Password,
    };
    console.log("..........", data);
    axios({
      url: "https://uatapi.valuecare.org.au/authentication/userLoginAuthenticate",
      method: "POST",
      headers: {},

      data: data,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Login Successful");
          console.log("tesggggt", res.data.Data[0]);
          setForm1(3);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userData", JSON.stringify(res.data.Data[0]));
        }
        // console.log("tesggggt", res.data.Data[0]);
      })

      .catch((error) => {
        console.log(error);
        cogoToast.error("Something went wrong");
      });
  };

  //   const services = [
  //     { label: "Early Childhood Services" },
  //     { label: "Foster Care Services" },
  //     { label: "Therapeutic Services for Children" },
  //     { label: "Daily Living & Life Skills" },
  //     { label: "Daily Persional Activities" },
  //   ];

  const getServiceData = () => {
    const formattedData = {
      StringMapType: "SERVICETYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setServiceList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onTagsChange = (event, value) => {
    const servi = value;
    // let servi=values.services
    console.log("--------", servi);

    setValues({ ...values, services: servi });
    console.log("---------------", value);
  };
  const onTagsChange2 = (event, value) => {
    const state = value;
    // let state=values.state
    console.log("--------helll2", state);

    setValues({ ...values, state: state });
    console.log("---------------", values);
  };

  const onTagsTime2 = (event, value) => {
    const time = value;
    // let state=values.state
    console.log("--------helll2");

    setValues({ ...values, time: time });
    console.log("---------------", values);
  };
  const onTagsState = (event, value) => {
    const officeSite = value;
    // let state=values.state
    console.log("--------helll2");

    setValues({ ...values, officeSite: officeSite });
    console.log("---------------", values);
  };

  const TimeList = () => {
    axios({
      url: "https://uatapi.valuecare.org.au/Slot/GetSlotDuration",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.data.success === true) {
          console.log("1234", res);
          console.log("time data", res.data.Data);
          Timelistdata = res.data.Data;

          setTimeData(
            Timelistdata.map((item) => ({
              label: item.EndTime + "-" + item.StartTime,
              // id: item.StartTime,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getServiceData();
    TimeList();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card className="mt-15 dashboard-appointment">
          <CardContent>
            {/* <div className=""> */}
            {/* <CoverLayout className="sign-up-outer"> */}
            <Grid container justify="center" spacing={3}>
              <Grid item md="12">
                <div className="reg-inner  no-shadow">
                  {form1 === 1 && (
                    <div className="reg_step" id="bookStep1">
                      <h2 className="h2-heading with-tagline align-center" justifyContent="center">
                        Book an Appointment
                      </h2>
                      <p className="tagline center mb-20">Choose any one option from below.</p>
                      <div className="ba-wrap">
                        <Grid container spacing={3} justifyContent="center">
                          <Grid item md="3">
                            <div className="ba-box" onClick={() => onClick1("Appointment")}>
                              <span>
                                <img src={bookAppointment} alt="Audio Call" />
                              </span>
                              <h3 className="align-center">Face-to-Face Appointment</h3>
                            </div>
                          </Grid>
                          <Grid item md="3">
                            <div className="ba-box" onClick={() => onClick4("CallBack")}>
                              <span>
                                <img src={callBack} alt="Call Back" />
                              </span>
                              <h3 className="align-center">Request a Call Back</h3>
                            </div>
                          </Grid>
                          <Grid item md="3">
                            <div className="ba-box" onClick={() => onClick5("BookAnOppointment")}>
                              <span>
                                <img src={callBack} alt="Call Back" />
                              </span>
                              <h3 className="align-center">Audio/Video Call</h3>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  )}

                  {form1 === 6 && (
                    <div className="reg_step" id="bookStep4">
                      <h2 className="h2-heading align-center">When would you like to11111</h2>
                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 2 }}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <div className="datepicker-wrap">
                              <MDDatePicker
                                //options={{ allowInput: true, minDate:moment(), maxDate:moment().subtract(1, 'days').format("YYYY-MM-DD")}}
                                options={{
                                  allowInput: true,
                                  minDate: moment(Date()).format("YYYY-MM-DD"),
                                  maxDate: moment(),
                                }}
                                onChange={(e) => handledateChange(e)}
                                md-min-date={todayDate}
                                disablePast
                                input={{ placeholder: "Select a date" }}
                              />
                              <span id="errorDate" className="errorTC2" style={{ display: "none" }}>
                                Please select Appointment Date{" "}
                              </span>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <div className="reg_input auto-complete">
                              <Autocomplete
                                id="combo-box-demo"
                                options={timeData ? timeData : ""}
                                value={selectedTime ? selectedTime : ""}
                                onChange={(e, value) => {
                                  setSelectedTime(value.label);
                                  setValues((prev) => ({ ...prev, time: value.id }));
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Time"
                                    name="time"
                                    value="time"
                                    error={values.errortime}
                                    helperText={values.errortime}
                                    onChange={(e) => handleChange(e, "time")}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <TextField
                              color="primary"
                              label="Phone Number *"
                              variant="outlined"
                              error={values["errorclientPhone"]}
                              id="clientPhone"
                              name="clientPhone"
                              helperText={values["errorclientPhone"]}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md="8">
                          <div className="reg_input">
                            <TextField
                              onChange={(e) => handleChange(e)}
                              multiline
                              rows={3}
                              maxRows={6}
                              name="additionalInformation"
                              label="Additional Information"
                              id="regFirstName"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              required={true}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item>
                          <div className="reg_input check_value">
                            <FormControlLabel
                              onChange={(e) => {
                                setTerms(!terms);
                              }}
                              control={<Checkbox name="checkedB" color="primary" />}
                              error={!terms}
                              helperText={errors.terms}
                              label="By registering you agree to Value Care’s Terms &amp; Conditions"
                            />
                            <span id="errorTC" className="errorTC" style={{ display: "none" }}>
                              Please accept the terms and condition{" "}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item>
                          <div className="reg_submit">
                            <MDButton
                              variant="contained"
                              color="secondary"
                              onClick={() => backForm()}
                            >
                              Back
                            </MDButton>

                            <MDButton
                              variant="contained"
                              color="primary"
                              onClick={() => handleSubmit()}
                            >
                              Submit
                            </MDButton>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  )}

                  {form1 === 5 && (
                    <div className="reg_step" id="bookStep1">
                      <Grid container spacing={0}>
                        <Grid item md="12">
                          <div className="reg_input check_value mb-0">
                            <InputLabel className="reg_lable mt-15">
                              Mode Of Communication
                            </InputLabel>
                            <RadioGroup aria-label="gender" name="CallType" row="true">
                              <FormControlLabel
                                value="Video Call"
                                onChange={(e) => handleChange(e)}
                                control={
                                  <Radio
                                    checked={values.CallType === "Video Call" ? true : false}
                                  />
                                }
                                label="Video Call"
                                name="CallType"
                              />
                              <FormControlLabel
                                value="Audio Call"
                                onChange={(e) => handleChange(e)}
                                control={
                                  <Radio
                                    checked={values.CallType === "Audio Call" ? true : false}
                                  />
                                }
                                label="Audio Call"
                                name="CallType"
                              />
                            </RadioGroup>
                          </div>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <div className="reg_input">
                              <div className="datepicker-wrap">
                                <MDDatePicker
                                  onChange={(e) => handledateChange(e)}
                                  md-min-date={todayDate}
                                  disablePast
                                  input={{ placeholder: "Select a date" }}
                                />
                                <span
                                  id="errorDate"
                                  className="errorTC2"
                                  style={{ display: "none" }}
                                >
                                  Please select Appointment Date{" "}
                                </span>
                              </div>
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <div className="reg_input auto-complete">
                                <Autocomplete
                                  id="combo-box-demo"
                                  options={timeData ? timeData : ""}
                                  value={selectedTime ? selectedTime : ""}
                                  onChange={(e, value) => {
                                    setSelectedTime(value.label);
                                    setValues((prev) => ({ ...prev, time: value.id }));
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Time"
                                      name="time"
                                      value="time"
                                      error={values.errortime}
                                      helperText={values.errortime}
                                      onChange={(e) => handleChange(e, "time")}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          {values.CallType === "Video Call" && (
                            <Grid item md="4">
                              <div className="reg_input">
                                <TextField
                                  type="number"
                                  color="primary"
                                  //name="phoneNumber"
                                  label="Phone Number *"
                                  variant="outlined"
                                  error={values["errorclientPhone"]}
                                  id="clientPhone"
                                  name="clientPhone"
                                  helperText={values["errorclientPhone"]}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                            </Grid>
                          )}
                          {values.CallType === "Audio Call" && (
                            <Grid item md="4">
                              <div className="reg_input">
                                <TextField
                                  type="number"
                                  color="primary"
                                  // name="phoneNumber"
                                  label="Phone Number *"
                                  variant="outlined"
                                  error={values["errorclientPhone"]}
                                  id="clientPhone"
                                  name="clientPhone"
                                  helperText={values["errorclientPhone"]}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                            </Grid>
                          )}
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item>
                            <div className="reg_input check_value">
                              <FormControlLabel
                                onChange={(e) => {
                                  setTerms(!terms);
                                }}
                                control={<Checkbox name="checkedB" color="primary" />}
                                error={!terms}
                                helperText={errors.terms}
                                label="By registering you agree to Value Care’s Terms &amp; Conditions"
                              />
                              <span id="errorTC" className="errorTC" style={{ display: "none" }}>
                                Please accept the terms and condition{" "}
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item>
                            <div className="reg_submit">
                              <MDButton
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit()}
                              >
                                Submit
                              </MDButton>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {/* {form1 === 2 && (
                    <div className="reg_step" id="bookStep2">
                      <h2 className="h2-heading align-center">
                        Services/Options you would like to avail -----
                      </h2>
                      <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 3, sm: 3, md: 2 }}
                        justifyContent="center"
                      >
                        <Grid item md="8">
                          <div className="reg_input sec_level">
                            <Autocomplete
                              multiple
                              id="Services"
                              name="Services"
                              options={serviceList ? serviceList : []}
                              // getOptionLabel={(option) => option.label || ""}
                              // inputValue={values ? values : ""}
                              value={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                              defaultValue={
                                serviceAutocompleteValues ? serviceAutocompleteValues : []
                              }
                              // onInputChange={onServiceChange}
                              // !stateLabel
                              // ? stateList
                              //   ? userData?.PersonState
                              //     ? stateList[userData.PersonState - 1]
                              //     : ""
                              //   : ""
                              // : stateLabel
                              // value={
                              //   serviceAutocompleteValues
                              //     ? // ? !values
                              //       serviceAutocompleteValues
                              //     : // : values
                              //       ""
                              // }
                              // defaultValue={
                              //   serviceAutocompleteValues ? serviceAutocompleteValues : []
                              // }
                              onChange={onServiceChange}
                              filterSelectedOptions
                              formControlProps={{
                                fullWidth: true,
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="What services are you registered for?"
                                  placeholder="Favorites"
                                  // error={errorservices}
                                  // helperText={errorservices}
                                  id="Services"
                                  name="Services"
                                  // defaultValue={Params}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <div className="reg_submit center">
                        <MDButton variant="contained" color="secondary" onClick={() => backForm()}>
                          Back
                        </MDButton>

                        <MDButton variant="contained" color="primary" onClick={() => formChange(4)}>
                          Next
                        </MDButton>
                      </div>
                    </div>
                  )} */}

                  {/* {form1 === 8 && (
                    <div className="reg_step" id="bookStep2">
                      <h2 className="h2-heading align-center">
                        Services/Options you would like to avail
                      </h2>
                      <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 3, sm: 3, md: 2 }}
                        justifyContent="center"
                      >
                        <Grid item md="8">
                          <div className="reg_input sec_level">
                            <Autocomplete
                              multiple
                              id="Services"
                              name="Services"
                              options={serviceList ? serviceList : []}
                              // getOptionLabel={(option) => option.label || ""}
                              // inputValue={values ? values : ""}
                              value={serviceAutocompleteValues ? serviceAutocompleteValues : []}
                              defaultValue={
                                serviceAutocompleteValues ? serviceAutocompleteValues : []
                              }
                              // onInputChange={onServiceChange}
                              // !stateLabel
                              // ? stateList
                              //   ? userData?.PersonState
                              //     ? stateList[userData.PersonState - 1]
                              //     : ""
                              //   : ""
                              // : stateLabel
                              // value={
                              //   serviceAutocompleteValues
                              //     ? // ? !values
                              //       serviceAutocompleteValues
                              //     : // : values
                              //       ""
                              // }
                              // defaultValue={
                              //   serviceAutocompleteValues ? serviceAutocompleteValues : []
                              // }
                              onChange={onServiceChange}
                              filterSelectedOptions
                              formControlProps={{
                                fullWidth: true,
                              }}
                              required
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!errors.services}
                                  helperText={errors.services}
                                  label="Select any services"
                                  placeholder="Favorites"
                                  id="services"
                                  name="services"
                                  // defaultValue={Params}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  required
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <div className="reg_submit center">
                        <MDButton variant="contained" color="secondary" onClick={() => backForm()}>
                          Back
                        </MDButton>

                        <MDButton
                          variant="contained"
                          color="primary"
                          //   onClick={() => formChange2(6, services)}
                        >
                          Next
                        </MDButton>
                      </div>
                    </div>
                  )} */}

                  {/* {form1 === 9 && (
                    <div className="reg_step" id="bookStep2">
                      <h2 className="h2-heading align-center">
                        Services/Options you would like to avail
                      </h2>
                      <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 3, sm: 3, md: 2 }}
                        justifyContent="center"
                      >
                        <Grid item md="8">
                          <div className="reg_input sec_level">
                            <Autocomplete
                              multiple
                              //id="tags-outlined"
                              options={values.services}
                              value={values.services ? values.services : []}
                              // getOptionLabel={option => option.label}
                              onChange={onTagsChange}
                              filterSelectedOptions
                              formControlProps={{
                                fullWidth: true,
                              }}
                              required
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!errors.services}
                                  helperText={errors.services}
                                  label="Select any service"
                                  placeholder="Favorites"
                                  id="services"
                                  name="services"
                                  // defaultValue={Params}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  required
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <div className="reg_submit center">
                        <MDButton variant="contained" color="secondary" onClick={() => backForm()}>
                          Back
                        </MDButton>

                        <MDButton
                          variant="contained"
                          color="primary"
                          //   onClick={() => formChange3(5, services)}
                        >
                          Next
                        </MDButton>
                      </div>
                    </div>
                  )} */}

                  {form1 === 4 && (
                    <div className="reg_step" id="bookStep3">
                      <h2>Login/Register</h2>
                      <div className="reg_social">
                        <Grid item md="4">
                          <div className="reg_input">
                            <TextField
                              onChange={(e) => handleChange2(e)}
                              name="Email"
                              // error={values.errorstreetName}
                              // helperText={values.errorstreetName}
                              label="Email"
                              id="regUserName"
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <TextField
                              onChange={(e) => handleChange2(e)}
                              name="Password"
                              // error={values.errorstreetName}
                              // helperText={values.errorstreetName}
                              label="Password"
                              id="regFirstName"
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </div>
                          <Grid item>
                            <div className="reg_submit">
                              <MDButton
                                variant="contained"
                                color="primary"
                                onClick={() => Submit2()}
                              >
                                Login
                              </MDButton>
                              <MDButton
                                variant="contained"
                                color="primary"
                                onClick={() => backForm()}
                              >
                                Back
                              </MDButton>
                            </div>
                          </Grid>
                        </Grid>

                        <MDTypography
                          component={Link}
                          to="/authentication/sign-up/Index"
                          variant="button"
                          color="primary"
                          fontWeight="medium"
                          textGradient
                        >
                          Sign up
                        </MDTypography>
                      </div>
                    </div>
                  )}

                  {form1 === 3 && (
                    <div className="reg_step" id="bookStep4">
                      <h2 className="h2-heading align-center">When would you like to...</h2>
                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <div className="datepicker-wrap">
                              <MDDatePicker
                                options={{
                                  allowInput: true,
                                  minDate: moment(Date()).format("YYYY-MM-DD"),
                                  maxDate: moment(),
                                }}
                                onChange={(e) => handledateChange(e)}
                                md-min-date={todayDate}
                                disablePast
                                input={{ placeholder: "Select a date" }}
                              />
                              <span id="errorDate" className="errorTC2" style={{ display: "none" }}>
                                Please select Appointment Date{" "}
                              </span>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <div className="reg_input auto-complete">
                              <Autocomplete
                                id="combo-box-demo"
                                options={timeData ? timeData : ""}
                                value={selectedTime ? selectedTime : ""}
                                onChange={(e, value) => {
                                  setSelectedTime(value.label);
                                  setValues((prev) => ({ ...prev, time: value.id }));
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Time"
                                    name="time"
                                    value="time"
                                    error={values.errortime}
                                    helperText={values.errortime}
                                    onChange={(e) => handleChange(e, "time")}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md="12">
                          <div className="reg_input check_value mb-0">
                            <InputLabel className="reg_lable mt-15">Location</InputLabel>
                            <RadioGroup aria-label="gender" name="gender1" row="true">
                              <FormControlLabel
                                value="value care office site"
                                onChange={(e) => handleChange(e)}
                                control={
                                  <Radio
                                    checked={
                                      values.location === "value care office site" ? true : false
                                    }
                                  />
                                }
                                label="Value care's office/site"
                                name="location"
                              />
                              <FormControlLabel
                                value="Australin Address"
                                onChange={(e) => handleChange(e)}
                                control={
                                  <Radio
                                    checked={values.location === "Australin Address" ? true : false}
                                  />
                                }
                                label="Provide an Australian address"
                                name="location"
                              />
                            </RadioGroup>
                          </div>
                          {values.location === "value care office site" && (
                            <Grid container spacing={3}>
                              <Grid item md="4">
                                <Autocomplete
                                  disablePortal
                                  id="State"
                                  name="State"
                                  options={stateList ? stateList : []}
                                  onChange={onStateChange}
                                  value={
                                    !stateLabel
                                      ? stateList
                                        ? userData?.PersonState
                                          ? stateList[userData.PersonState - 1]
                                          : ""
                                        : ""
                                      : stateLabel
                                  }
                                  sx={{ width: 300 }}
                                  renderInput={(params) => <TextField label="State" />}
                                />
                              </Grid>
                            </Grid>
                          )}
                          {values.location === "Australin Address" && (
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                              <Grid item md="12">
                                <InputLabel className="reg_lable mb-20 mt-15">Address</InputLabel>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    onChange={(e) => handleChange(e)}
                                    name="streetAddress"
                                    error={values.errorstreetAddress}
                                    helperText={values.errorstreetAddress}
                                    label="Unit/Street Address"
                                    id="regFirstName"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    onChange={(e) => handleChange(e)}
                                    name="streetName"
                                    error={values.errorstreetName}
                                    helperText={values.errorstreetName}
                                    label="Street Name"
                                    id="regFirstName"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    onChange={(e) => handleChange(e)}
                                    name="city"
                                    error={values.errorcity}
                                    helperText={values.errorcity}
                                    label="City"
                                    id="regFirstName"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input auto-complete">
                                  <Autocomplete
                                    options={stateData ? stateData : ""}
                                    value={selectedstate ? selectedstate : ""}
                                    onChange={(e, value) => {
                                      setSelectedstate(value.label);
                                      setValues((prev) => ({ ...prev, state: value.id }));
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        label="State"
                                        name="state"
                                        onChange={(e) => console.log("helll off", e.target.value)}
                                        error={values.errorstate}
                                        helperText={values.errorstate}
                                      />
                                    )}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <TextField
                              type="number"
                              color="primary"
                              //name="phoneNumber"
                              label="Phone Number *"
                              variant="outlined"
                              error={values["errorclientPhone"]}
                              id="clientPhone"
                              name="clientPhone"
                              helperText={values["errorclientPhone"]}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md="8">
                          <div className="reg_input">
                            <TextField
                              onChange={(e) => handleChange(e)}
                              name="additionalInformation"
                              multiline
                              rows={4}
                              maxRows={4}
                              label="Additional Information"
                              id="regFirstName"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              required={true}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item>
                          <div className="reg_input check_value">
                            <FormControlLabel
                              onChange={(e) => {
                                setTerms(!terms);
                              }}
                              control={<Checkbox name="checkedB" color="primary" />}
                              error={!terms}
                              helperText={errors.terms}
                              label="By registering you agree to Value Care’s Terms &amp; Conditions"
                            />
                            <span id="errorTC" className="errorTC" style={{ display: "none" }}>
                              Please accept the terms and condition{" "}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item>
                          <div className="reg_submit">
                            <MDButton
                              variant="contained"
                              color="secondary"
                              onClick={() => backForm()}
                            >
                              Back
                            </MDButton>

                            <MDButton
                              variant="contained"
                              color="primary"
                              onClick={() => handleSubmit()}
                            >
                              Submit
                            </MDButton>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </div>
                {/* </div> */}
              </Grid>
            </Grid>
            {/* </CoverLayout> */}

            {/* </div> */}
          </CardContent>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserBookAppointment;
