import { MenuItem, TextField, FormControl, InputLabel, Select, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import { Button as PButton } from "primereact/button";
import moment from "moment";
import cogoToast from "cogo-toast";
import "assets/css/style.css";
import { CommonConfig } from "constant";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller, useForm } from "react-hook-form";
import { NotesComponent } from "layouts/component/Notes";

function EditContact() {
  let userData = localStorage.getItem("userData");
  if (userData) {
    userData = JSON.parse(userData);
  }
  const { state } = useLocation();
  var ContactData = state ? state?.selectedRow : null;

  const [form1, setForm1] = useState(1);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [managedData, setManagedData] = useState(null);
  const [chManagedBy, setChManagedBy] = useState(null);
  const [chNotes, setChNotes] = useState(null);
  const [inNoteImportant, setinNoteImportant] = useState(false);
  const [loader, setLoader] = React.useState(false);
  const [chManagedByLabel, setChManagedByLabel] = useState(null);
  const [appoinmentStatusList, setAppoinmentStatusList] = useState(null);
  const [chStatus, setChStatus] = useState(null);
  const [managedFilterdValue, setManagedFilterdValue] = useState(null);
  const [notesData, setNotesData] = useState([]);

  const [todayDate, settodayDate] = useState(new Date().toISOString().slice(0, 10));

  const [values, setValues] = React.useState({
    ContactId: ContactData.ContactId,
    Name: ContactData.Name,
    Email: ContactData.Email,
    Phone: ContactData.Phone,
    Message: ContactData.Message,
    Status: ContactData.Status,
    ManagedBy: ContactData.ManagedBy,
  });

  // console.log("valuesckecknow45711", managedFilterdValue?.label);
  // console.log("valuesckecknow1", chStatus);

  const handleChange = (event) => {
    console.log(event.target, "targetsselect");

    setValues({ ...values, [event.target.name]: event.target.value });
    // console.log("======", values);
  };

  const getNoteList = (values) => {
    let noteReqData = {
      EntityType: "ContactUS",
      EntityId: values ? values : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      url: CommonConfig.ApiUrl + "note/getNoteList",
      method: "POST",
      headers: {},
      data: noteReqData,
    })
      .then((res) => {
        if (res.data?.success) {
          setNotesData(res.data.data || []);
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const saveNotes = () => {
    if (chNotes === null || chNotes === "") {
      document.getElementById("NotesError").style.display = "block";
      return;
    } else {
      document.getElementById("NotesError").style.display = "none";
    }

    const formattedData = {
      EntityType: "ContactUS",
      EntityId: ContactData.ContactId ? ContactData.ContactId : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
      IsFlag: inNoteImportant ? 1 : 0,
      NoteType: "ContactUS",
      NoteTitle: "",
      NoteText: chNotes,
    };
    debugger;
    console.log("formatteddata..", formattedData);
    axios({
      url: CommonConfig.ApiUrl + "note/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          getNoteList(ContactData.ContactId);
          setChNotes("");
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = () => {
    console.log(values);
    const inputdata = {
      ContactId: values.ContactId,
      Name: values.Name,
      Email: values.Email,
      Phone: values.Phone,
      Message: values.Message,
      Status: chStatus ? chStatus : values.Status,
      ManagedBy: chManagedBy ? chManagedBy : values.ManagedBy,
      // ManagedBy: managedFilterdValue?.id ? managedFilterdValue?.id : values.ManagedBy,
    };
    console.log("hellll", inputdata);
    axios({
      url: CommonConfig.ApiUrl + "Contact/AddContact",
      method: "POST",
      headers: {},
      data: inputdata,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Contact Updated Successfully");
          navigate("/pages/contact-us/list");
        }
        console.log("test", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInternalStaff = () => {
    axios({
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
    })
      .then((res) => {
        if (res.data.success) {
          console.log("Managed Data", res.data.data);

          setManagedData(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const onManagedByChangeStatusChanged = (e, value) => {
    console.log("VVV", value);
    setChManagedByLabel(value.label);
    setChManagedBy(value.id);
  };

  const getAppoinmentStatus = () => {
    const formattedData = { StringMapType: "APPOINTMENTSTATUS", SortType: "Alpha" };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          let appoinmentStatus = res.data.data;
          setAppoinmentStatusList(
            appoinmentStatus.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onStatusChanged = (e, value) => {
    setChStatus(value.id);
  };

  useEffect(() => {
    getInternalStaff();
    getAppoinmentStatus();
    getNoteList(ContactData.ContactId);
  }, []);

  useEffect(() => {
    const fillterMnagedBy = managedData?.filter((x) => x.id == ContactData?.ManagedBy);
    setManagedFilterdValue(fillterMnagedBy?.[0]);
  }, [managedData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card className="mt-15 dashboard-appointment">
          <CardContent>
            <div className="reg_step edit_contact" id="editContact">
              <div className="edit-contact-inner">
                <h3>Edit Contact Details</h3>
                <Grid container spacing={3}>
                  <Grid item md="3">
                    <div className="reg_input auto-complete">
                      <Autocomplete
                        disablePortal
                        id="Status"
                        name="Status"
                        style={{
                          marginBottom: "10px",
                        }}
                        // value={ContactData.Status}
                        value={chStatus != null ? chStatus : ContactData.Status}
                        options={appoinmentStatusList ? appoinmentStatusList : []}
                        onChange={onStatusChanged}
                        sx={{ width: 200 }}
                        renderInput={(params) => <TextField {...params} label="Status" />}
                      />
                    </div>
                  </Grid>

                  <Grid item md="3">
                    <div className="reg_input auto-complete">
                      <Autocomplete
                        disablePortal
                        id="ManagedBy"
                        name="ManagedBy"
                        style={{
                          marginBottom: "10px",
                        }}
                        // value={managedFilterdValue}
                        value={chManagedByLabel != null ? chManagedByLabel : managedFilterdValue}
                        options={managedData ? managedData : []}
                        onChange={onManagedByChangeStatusChanged}
                        sx={{ width: 200 }}
                        renderInput={(params) => <TextField {...params} label="Managed By" />}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item sm={6} md={6}>
                    <div className="reg_input">
                      <TextField
                        id="outlined-basic"
                        color="primary"
                        label="Name"
                        variant="outlined"
                        name="Name"
                        value={values.Name}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6} md={6}>
                    <div className="reg_input">
                      <TextField
                        id="outlined-basic"
                        color="primary"
                        label="Email"
                        variant="outlined"
                        name="Email"
                        value={values.Email}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={6} md={6}>
                    <div className="reg_input">
                      <TextField
                        id="outlined-basic"
                        color="primary"
                        label="Pnone Number"
                        variant="outlined"
                        name="Phone"
                        value={values.Phone}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6} md={6}>
                    <div className="reg_input">
                      <TextField
                        id="outlined-basic"
                        color="primary"
                        label="Message"
                        variant="outlined"
                        name="Message"
                        value={values.Message}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            {CommonConfig.isInternalStaff() === 1 ? (
                <Card sx={{ width: "100%", mt: 6 }}>
                  <MDBox display="flex">
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="4rem"
                      height="4rem"
                      variant="gradient"
                      bgColor="primary"
                      color="white"
                      shadow="md"
                      borderRadius="xl"
                      ml={3}
                      mt={-2}
                    >
                      <Icon fontSize="medium" color="inherit">
                        warning_amber
                      </Icon>
                    </MDBox>
                    <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                      Notes
                    </MDTypography>
                  </MDBox>
                  <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
                    <div className="notes-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Notes</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="nt-date">
                              {moment(new Date()).format("DD-MM-YYYY")}{" "}
                              <span> {moment(new Date()).format("hh:mm A")}</span>
                            </td>

                            <td className="nt-comment">
                              <div className="reg_input mb-0">
                                <TextField
                                  onChange={(e) => setChNotes(e.target.value)}
                                  value={chNotes ? chNotes : ""}
                                  name="notes"
                                  multiline
                                  rows={2}
                                  // maxRows={4}
                                  label="Notes Text*"
                                  id="regFirstName"
                                  fullWidth
                                />
                                <span class="errorTC" id="NotesError">
                                  Please Write Notes.
                                </span>
                              </div>
                            </td>
                            <td className="nt-action">
                              <PButton
                                type="button"
                                icon="pi pi-plus"
                                className="p-button-primary AddNotes"
                                onClick={() => saveNotes()}
                                tooltip="Add Notes"
                                tooltipOptions={{ position: "bottom" }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <NotesComponent
                      entityType="ContactUS"
                      entityId={ContactData.ContactId}
                      NotesData={notesData}
                    />
                  </MDBox>
                </Card>
              ) : (
                <></>
              )}
            <Grid container spacing={3}>
              <Grid item>
                <div className="reg_submit">
                  <MDButton
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate("/pages/contact-us/list")}
                  >
                    Cancel
                  </MDButton>

                  <MDButton variant="contained" color="primary" onClick={() => handleSubmit()}>
                    Update
                  </MDButton>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditContact;
