import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { CommonConfig } from "constant";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { useEffect } from "react";
import PrimeDatatable from "layouts/component/PrimeDatatable";
import { Button as PButton } from "primereact/button";
import { Column } from "primereact/column";
import { ArrowDownward } from "@mui/icons-material";
import { NotesComponent } from "layouts/component/Notes";
import cogoToast from "cogo-toast";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";

// import { useForm } from "react-hook-form";


// const schema = yup.object().shape({
//   ComplianceRegisterDate: yup.string().required("Date Required."),
//   AssignedTo: yup.object().required("Assigned To Required."),
//   // Risk: yup.object().required("Risk required schema.").nullable(),
//   Risk: yup.object().nullable(),
//   // CurrentRiskRating: yup.string().required("Current Risk Rating required"),
//   // TargetRiskRating: yup.object(),
//   LastActionTaken: yup.string().required("Last Action Taken Required."),
//   NextAction: yup.string().required("Next Planned Action Required."),
// });

export default () => {
  const [todayDate, settodayDate] = useState(new Date().toISOString().slice(0, 10));
  const [complianceRegisterList, setComplianceRegisterList] = useState(null);
  const [riskRegisterList, setRiskRegisterList] = useState(null);
  const [riskRegisterFullList, setRiskRegisterFullList] = useState(null);
  const [internalStaff, setInternalStaff] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [riskRatingDD, setRiskRatingDD] = useState(null);
  const [selectedRisk, setSelectedRisk] = useState(null);
  const [riskDescription, setRiskDescription] = useState(null);
  const [defaultRiskDescription, setDefaultRiskDescription] = useState(null);
  const [riskRegisterId, setRiskRegisterId] = useState(null);
  const [complianceRegisterId, setComplianceRegisterId] = useState(null);
  const [complianceRegisterNumber, setComplianceRegisterNumber] = useState(null);
  const [chNotes, setChNotes] = useState(null);
  const [selectedNotesType, setSelectedNotesType] = useState(null);
  const [inNoteImportant, setinNoteImportant] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [notesDropdownList, setNotesDropdownList] = useState(null);
  const [notesEnable, setNotesEnable] = useState(false);
  const [loader, setLoader] = React.useState(false);
  const paginatorLeft = <PButton type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <PButton type="button" icon="pi pi-cloud" className="p-button-text" />;

  // const { control, errors, clearErrors } = useForm();

  const schema = yup.object().shape({
    ComplianceRegisterDate: yup.date().required("Date Required."),
    AssignedTo: yup.object().required("Assigned To Required."),
    // Risk: yup.object().required("Risk required schema.").nullable(),
    // Risk: yup.object().nullable(),
    // Risk: yup.object().nullable().when('complianceRegisterNumber', {
    //   is: (val) => val === null,
    //   then: yup.object().required('Risk is Required'),
    // }),
    Risk: yup.object().nullable().test('required-if-compliance-number-null', 'Risk is Required', function (value, context) {
      const complianceRegisterNumber = context.parent.complianceRegisterNumber;
      console.log("Schema -=-=-=-=-=-=-=-=-",complianceRegisterNumber)
      if (complianceRegisterNumber === undefined || complianceRegisterNumber === null) {
        console.log("If schema [[[[]]]]]]]]]]]][[[[[[]]",complianceRegisterNumber,"Value==========",value)
        console.log("Selectedd=",selectedRisk)
        if(selectedRisk){
          return true;
        }
        return false;
      }
      // else if (value === undefined || value === null) {
      //   console.log("If schema [[[[]]]]]]]]]]]][[[[[[]]",complianceRegisterNumber,"Value==========",value)
      //   return false;
      // }
      console.log("elseeeeeeeeeeeeeeeeeeeeeeeeee************************",complianceRegisterNumber,"VVVVVVVVVVV",value)
      return true;
    }),
    // CurrentRiskRating: yup.string().required("Current Risk Rating required"),
    // TargetRiskRating: yup.object(),
    LastActionTaken: yup.string().required("Last Action Taken Required."),
    NextAction: yup.string().required("Next Planned Action Required."),
  });

  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      Risk: "",
      // CurrentRiskRating: "",
      TargetRiskRating: "",
    },
  });

  // useEffect(() => {
  //   // Whenever the selectedRisk state changes, clear the error state
  //   clearErrors("Risk");
  // }, [selectedRisk]);





  let navigate = useNavigate();
  let loginUser;
  if (localStorage.getItem("userData")) {
    // alert("Hello");
    loginUser = JSON.parse(localStorage.getItem("userData"));
  } else {
    // alert("Hello2")
    navigate("/");
  }

  console.log("Error::", errors);

  useEffect(() => {
    getRiskRegisterList();
    getRiskRatingDD();
    getInternalStaff();
    getComplianceRegisterList();
    getNotesTypeDropdownList();
  }, []);

  useEffect(() => {
    if (selectedRisk && riskRegisterFullList.length > 0) {
      console.log("RiskRlist", riskRegisterFullList);
      console.log("SElected", selectedRisk);
      let selectedRiskObj = riskRegisterFullList.filter(
        (item) => item.RiskRegisterId == selectedRisk.id
      );
      console.log("selectedRiskObj", selectedRiskObj);
      if (selectedRiskObj) {
        setRiskDescription(selectedRiskObj[0]);
      }
    }
  }, [selectedRisk]);

  let column = [
    {
      name: "No. / Ref",
      selector: "ComplianceNumber",
      filter: true,
      sortable: true,
    },
    {
      name: "Risk",
      selector: "Risk",
      filter: true,
      sortable: true,
    },
    {
      name: "Date Added",
      // body: (data) => {
      //   if (data.ComplianceRegisterDate) {
      //     return CommonConfig.formatDate(data.ComplianceRegisterDate);
      //   }
      //   return "";
      // },
      selector: "ComplianceRegisterDate",
      filter: true,
      sortable: true,
    },
    {
      name: "Person's Responsible",
      selector: "AssigneeName",
      filter: true,
      sortable: true,
    },
    {
      name: "Current Risk Rating(1-9)",
      selector: "RiskRating",
      filter: true,
      sortable: true,
    },
    {
      name: "Target Risk Rating(1-9)",
      selector: "TargetRiskRating",
      filter: true,
      sortable: true,
    },
    {
      name: "Last Action Taken",
      selector: "LastActionTaken",
      filter: true,
      sortable: true,
    },
    {
      name: "Next Planned Action",
      selector: "NextAction",
      filter: true,
      sortable: true,
    },
    {
      name: "Action",
      body: (data) => actionTemplate(data),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const notesColumns = [
    {
      selector: (notesData) => {
        if (notesData.CreatedOn) {
          return moment(notesData.CreatedOn).format("DD-MM-YYYY");
        }
      },
      className: "NotesDates",
    },

    {
      selector: (notesData) => {
        return notesData?.NoteTitle + " " + notesData?.NoteText;
      },
      className: "NotesTitle",
    },

    {
      className: "NotesInfo",
      body: (notesData) => actionTemplate(notesData),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  // added by akm
  const handleRiskChange = (v) => {
    // Check if value is true
    if (v === true) {
      // Clear all errors for the 'Risk' field
      clearErrors('Risk');
    }

    setSelectedRisk(v);
  }

  const getNotesColoumns = () =>
    notesColumns.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });
  const actionTemplate = (row) => {
    return (
      <PButton
        type="button"
        icon="pi pi-pencil"
        className="p-button-warning"
        onClick={() => {
          // setIsEditEnabled(true);
          console.log("ROWWW", row);
          console.log("Risk Value",row.Risk)
          setShowAdd(true);
          setNotesEnable(true);
          // console.log("ROW", row);
          if (row.ComplianceRegisterId) {
            // getNoteList();
            setComplianceRegisterId(row.ComplianceRegisterId);
            setComplianceRegisterNumber(row.ComplianceNumber);
            getNoteList(row.ComplianceRegisterId);
          }
          let assignToObj;
          let targetRiskObj;
          let riskObj;
          console.log("Risk Numberss",riskRegisterList)
          console.log("Risk Full nuberss",riskRegisterFullList)
          const risknum = riskRegisterFullList.map(riskRegisterFullList => riskRegisterFullList.RiskNumber)
          console.log("risk num array ",risknum)
          // let riskRatingToObj;
          if (row.Risk) {
            riskObj = internalStaff.filter((item) => item.id == row.Risk);
            console.log("Risk Id value", riskObj);
          }


          if (row.AssignedTo) {
            assignToObj = internalStaff.filter((item) => item.id == row.AssignedTo);
            console.log("ddddd", assignToObj);
          }

          if (row.TargetRiskRating) {
            targetRiskObj = riskRatingDD.filter((item) => item.id == row.TargetRiskRating);
          }
          // if (row.RiskRating) {
          //   riskRatingToObj = riskRatingDD.filter((item) => item.id == row.RiskRating);
          // }

          // console.log("DATA", {
          //   ...row,
          //   ReceivedBy: receivedByObj ? receivedByObj[0] : "",
          //   AssignedTo: assignToObj ? assignToObj[0] : "",
          // });
          // reset(row);
          setDefaultRiskDescription(row.RiskDescription);
          reset({
            ...row,
            AssignedTo: assignToObj ? assignToObj[0] : "",
            TargetRiskRating: targetRiskObj ? targetRiskObj[0] : "",
            // RiskRating: riskRatingToObj ? riskRatingToObj[0] : "",
          });
        }}
        style={{ marginRight: ".5em" }}
        tooltip="Edit Complaint"
        tooltipOptions={{ position: "bottom" }}
      />
    );
  };

  const getColumn = () =>
    column.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  const getNotesTypeDropdownList = () => {
    const formattedData = {
      StringMapType: "NOTETYPE.ComplianceRegister",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);
          setSelectedNotesType(res.data.data[0].StringMapName);
          // setNotesDropdownList(
          //   res.data.data.map((item) => ({
          //     id: item.StringMapKey,
          //     label: item.StringMapName,
          //   }))
          // );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getNoteList = (values) => {
    // debugger;
    console.log("complianceRegisterId", complianceRegisterId);
    let noteReqData = {
      EntityType: "ComplianceRegister",
      EntityId: values ? values : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "note/getNoteList",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: noteReqData,
    })
      .then((res) => {
        // console.log("Hello147 ", res.data);
        if (res.data?.success) {
          // console.log("-------------", data);
          setNotesData(res.data.data || []);
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRiskRegisterList = () => {
    let formattedData = {
      ComplaintRegisterId: "",
      CurrentUser: "",
    };

    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "qualityManagment/getRiskRegisterData",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: formattedData,
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data.success) {
          console.log("RRRRR", res.data.data);
          setRiskRegisterFullList(res.data.data);

          setRiskRegisterList(
            res.data.data.map((item) => ({
              id: item.RiskRegisterId,
              label: JSON.stringify(item.RiskNumber) + " " + JSON.stringify(item.RiskDescription),
            }))
          );
        }
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  const getInternalStaff = () => {
    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
    })
      // Handle the response from backend here
      .then((res) => {
        if (res.data.success) {
          // cogoToast.success("Appointment Booked Successfully");
          // console.log("Managed Data", res.data.data);

          setInternalStaff(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
          // navigate("/pages/book-appointment/list");
        }
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  const getRiskRatingDD = () => {
    const formattedData = {
      StringMapType: "RISKRATING",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          // console.log("ddddd", res);
          setRiskRatingDD(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getComplianceRegisterList = () => {
    setLoader(!loader);
    let formattedData = {
      ComplaintRegisterId: "",
      CurrentUser: "",
    };

    axios({
      // Endpoint to send files
      url: CommonConfig.ApiUrl + "qualityManagment/getComplianceRegisterData",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
      data: formattedData,
    })
      // Handle the response from backend here
      .then((res) => {
        console.log("RRRRR", res);
        if (res.data.success) {
          setLoader(false);

          var PersonType = loginUser.PersonType;
          var loginUserName = loginUser.FirstName + " " + loginUser.LastName;
          if (PersonType == "INTERNAL STAFF") {
            // setLoader(false);
            var tempstore = [];
            for (var i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].AssigneeName === loginUserName) {
                tempstore.push(res.data.data[i]);
              }
            }
            setComplianceRegisterList(tempstore);
          } else {
            setComplianceRegisterList(res.data.data);
          }
        }
        // cogoToast.error('Something went wrong');
      })

      // Catch errors if any
      .catch((error) => {
        console.log(error);
        // cogoToast.error('Something went wrong');
      });
  };

  const saveNotes = () => {
    // if (selectedNotesType === null) {
    //   document.getElementById("ServicesError").style.display = "block";
    //   return;
    // } else {
    //   document.getElementById("ServicesError").style.display = "none";
    // }

    if (chNotes === null || chNotes === "") {
      document.getElementById("NotesError").style.display = "block";
      return;
    } else {
      document.getElementById("NotesError").style.display = "none";
    }

    const formattedData = {
      EntityType: "ComplianceRegister",
      EntityId: complianceRegisterId ? complianceRegisterId : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
      IsFlag: inNoteImportant ? 1 : 0,
      NoteType: selectedNotesType,
      NoteTitle: "",
      NoteText: chNotes,
    };

    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "note/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          getNoteList(complianceRegisterId);
          setChNotes("");
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // added by akm
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onSubmit = (data) => {

    // event.preventDefault();
    // if (data.Risk === ""){
    //   setFormSubmitted(false);  
    // }
    // else{
    //   setFormSubmitted(true);
    // }
    // console.log("2222222222",data.Risk)
    

    setLoader(!loader);
    console.log("DATA::", data);

    let formattedData = "";

    formattedData = {
      ...data,
      ComplianceRegisterDate: CommonConfig.formatDate(data?.ComplianceRegisterDate),
      RiskRating: riskDescription ? riskDescription.RiskRating : "",
      AssignedTo: data?.AssignedTo?.id,
      RiskDescription: riskDescription ? riskDescription.RiskDescription : "",
      Risk: data?.Risk?.id,
      RiskNumber: data?.Risk?.label,
      RiskRegisterId: riskDescription ? riskDescription.RiskRegisterId : "",
      TargetRiskRating: data.TargetRiskRating ? data.TargetRiskRating.id : "",
    };

    console.log("FormaateddData:::", formattedData);
    

    axios({
      url: CommonConfig.ApiUrl + "qualityManagment/addUpdateCompliance",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        setLoader(!loader);
        // console.log("res data....", res);
        if (res.data.success) {
          console.log("Successfull", res.data);
          getComplianceRegisterList();
          setShowAdd(false);
          setNotesEnable(false);
          if (complianceRegisterNumber) {
            cogoToast.success("Updated Successfully.");
            setComplianceRegisterNumber(null);
          } else {
            cogoToast.success("Registered Successfully.");
          }
          // navigate("/layout/referal/Referallist");
        } else {
          cogoToast.error("Something Went Wrong.");
        }

        // console.log("test", res.res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // if(complianceRegisterId){
  //   debugger
  //   console.log("complianceRegisterId = ",complianceRegisterId);
  //   debugger

  // }
  debugger;

  // const [selectedOption, setSelectedOption] = useState("123");

  // const handleOptionChange = (v) => {
  //   setSelectedOption(v);
  // }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            shadow="md"
            color="white"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="white">
              border_color
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            Compliance Register
          </MDTypography>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 0, ml: "auto", mr: 2 }}>
            {complianceRegisterNumber ? "Reference Number:" + complianceRegisterNumber : ""}
          </MDTypography>
          <MDButton
            variant="contained"
            color="primary"
            sx={{ mt: 1, mb: 0, ml: 2, mr: 2 }}
            onClick={() => {
              setShowAdd((prev) => !prev);
              setNotesEnable(false);
              setDefaultRiskDescription(null);
              reset({});
              setComplianceRegisterNumber(null);
            }}
          >
            {showAdd ? "Show List" : "Add New"}
          </MDButton>
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          {showAdd ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="reg_step quality-form">
                <div className="referral_form_inner">
                  {/* <Controller
                    name="ComplianceNumber"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        id="outlined-read-only-input"
                        label="Reference Number"
                        style={{
                          width: "250px",
                          marginBottom: "15px",
                          marginTop: "15px",
                        }}
                        value={value}
                        onChange={onChange}
                        error={error}
                        helperText={error?.message}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )}
                  /> */}

                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="ComplianceRegisterDate"
                          defaultValue={null}
                          control={control}
                          rules = {{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils} required>
                              <DatePicker
                                label="Date"
                                value={value || null}
                                inputFormat="dd/MM/yyyy"
                                onChange={onChange}
                                renderInput={(params) => (
                                  <TextField
                                    // helperText={error?.message}
                                    // error={error}
                                    {...params}
                                    error={errors.ComplianceRegisterDate}
                                    helperText={
                                      errors.ComplianceRegisterDate ? "Date Required." : ""
                                    }
                                    // required
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="AssignedTo"
                          // rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="ResponsiblePerson"
                              name="ResponsiblePerson"
                              value={value}
                              options={internalStaff ? internalStaff : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Assigned to*"
                                  error={errors.AssignedTo}
                                  helperText={errors.AssignedTo ? "Assigned to Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                      
                        <Controller
                          control={control}
                          name="Risk"
                          // defaultValue={null}
                  
                          render={({ field: { onChange, value } }) =>{
                              const validOption = riskRegisterList.find((option) => option.label.includes(value));
                            
                              if (!validOption) {
                                if (!value) {
                                    value = "";
                                    setSelectedRisk(null);  
                                  }
                                else{
                                  value = value;
                                  setSelectedRisk(value);
                                }  
                              
                                // value = null;
                              }
                              else{
                                // if (!value) {
                                //     value = {};
                                  
                                //   }
                                // else{
                                  value = validOption
                                  // value = validOption.label
                                  setSelectedRisk(value);
                                  // value = {value}
                                console.log("Value Valide: ",validOption.label,"Value:- ",value)
                                console.log(typeof(value),"/////////////////////////////")
                                // const a = value;
                                // const obj = { number: parseInt(a), text: a.substring(2) };
                                // value = obj;
                                // console.log(typeof(value),"++++++++++++++++++++++++++++++++")
                                // }  
                                
                              }
                              return(
                            <Autocomplete
                              disablePortal
                              id="Risk"
                              name="Risk"
                              // defaultValue={value}
                              value={value}
                              // defaultValue={value}
                              options={riskRegisterList ? riskRegisterList : ["abc","bts"]}
                              
                              onChange={(e, v) => {
                                onChange(v);
                                // setSelectedRisk(v);
                                // handleOptionChange(v);
                                // handleRiskChange(v);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Risk"
                                  // defaultValue={value}
                                  // error={!value && formSubmitted}
                                  error={errors.Risk}
                                  helperText={errors.Risk ? "Risk Required." : ""}
                                  
                                  // helperText="Risk Required"
                                  // error={errors.Risk ? true : false}
                                  // helperText={errors.Risk ? errors.Risk.message : ''}
                                  // error={errors.Risk && formSubmitted}
                                  // helperText={
                                  //   formSubmitted && !params.inputProps.value ? "Risk is required." : ""
                                  // }
                                  
                                  // required
                                />
                              )}
                            />
                          )}
                          }
                        />
                        {/* <span>
                          {!riskDescription
                            ? !notesEnable
                              ? ""
                              : defaultRiskDescription
                            : riskDescription.RiskDescription}
                        </span> */}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="RiskRating"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="outlined-read-only-input"
                              label="Current Risk Rating"
                              value={
                                showAdd
                                  ? riskDescription
                                    ? riskDescription.RiskRating
                                    : value
                                  : value
                              }
                              error={error}
                              helperText={error?.message}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="TargetRiskRating"
                          // rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="TargetRiskRating"
                              name="TargetRiskRating"
                              value={value}
                              options={riskRatingDD ? riskRatingDD : []}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Acceptable Risk Rating"
                                  error={errors.TargetRiskRating}
                                  helperText={
                                    errors.TargetRiskRating ? "TargetRiskRating required" : ""
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="LastActionTaken"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="outlined-read-only-input"
                              label="Last Action Taken*"
                              value={value}
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="NextAction"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              id="outlined-read-only-input"
                              label="Next Planned Action*"
                              value={value}
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <div className="reg_submit">
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setShowAdd(false);
                        setNotesEnable(false);
                        setComplianceRegisterNumber(null);
                      }}
                    >
                      Cancel
                    </MDButton>
                    <MDButton type="submit" color="primary" variant="contained">
                      {/* Submit */}

                      {complianceRegisterNumber ? "Update" : "Submit"}
                    </MDButton>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <div className="table-custom">
              <PrimeDatatable data={complianceRegisterList} getColumnListing={getColumn} />
            </div>
          )}
        </MDBox>
      </Card>

      {notesEnable && CommonConfig.isInternalStaff() === 1 ? (
        <Card sx={{ width: "100%", mt: 6 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                warning_amber
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              Notes
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            {/* <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <div className="reg_input">
                      <Autocomplete
                        options={notesDropdownList ? notesDropdownList : []}
                        defaultValue={selectedNotesType ? selectedNotesType : ""}
                        onChange={(e, value) => {
                          setSselectedNotesType(value.label);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Notes Type*"
                            name="NotesType"
                            // error={values.errorstate}
                            // helperText={values.errorstate}
                          />
                        )}
                      />
                      <span class="errorTC" id="ServicesError">
                        Please select Notes Type.
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <ReportGmailerrorredIcon
                      color={inNoteImportant ? "primary" : "secondary"}
                      fontSize="large"
                      onClick={() => setinNoteImportant((prev) => !prev)}
                    />
                  </Grid>
                </Grid> */}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {/* <div className="reg_input">
                      <TextField
                        onChange={(e) => setChNotes(e.target.value)}
                        value={chNotes ? chNotes : ""}
                        name="notes"
                        multiline
                        rows={3}
                        // maxRows={4}
                        label="Notes Text*"
                        id="regFirstName"
                        fullWidth
                      />
                      <span class="errorTC" id="NotesError">
                        Please Write Notes.
                      </span>
                    </div> */}
              </Grid>
            </Grid>

            <div className="notes-table">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Notes</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="nt-date">{moment(new Date()).format("DD-MM-YYYY")}</td>
                    <td className="nt-comment">
                      <div className="reg_input mb-0">
                        <TextField
                          onChange={(e) => setChNotes(e.target.value)}
                          value={chNotes ? chNotes : ""}
                          name="notes"
                          multiline
                          rows={2}
                          // maxRows={4}
                          label="Notes Text*"
                          id="regFirstName"
                          fullWidth
                        />
                        <span class="errorTC" id="NotesError">
                          Please Write Notes.
                        </span>
                      </div>
                    </td>
                    <td className="nt-action">
                      <PButton
                        type="button"
                        icon="pi pi-plus"
                        className="p-button-primary AddNotes"
                        onClick={() => saveNotes()}
                        // style={{ marginRight: ".5em" }}
                        tooltip="Add Notes"
                        tooltipOptions={{ position: "bottom" }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <Grid item md="4"></Grid>
            {complianceRegisterId ? (
              <NotesComponent
                entityType="ComplianceRegister"
                entityId={complianceRegisterId}
                NotesData={notesData}
              />
            ) : //   <DataTable
            //   scrollable
            //   className="NotesTableDynamic hidetext"
            //   id = "NotesTableDynamic"
            //   value={notesData}
            //   showGridlines
            //   scrollHeight="600px"
            //   scrollWidth="600px"
            //   responsiveLayout="scroll"
            //   paginator
            //   rows={10}
            //   totalRecords={notesData ? notesData.length : 0}
            //   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            //   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            //   rowsPerPageOptions={[10, 20, 50]}
            //   emptyMessage="No records found"
            //   paginatorLeft={paginatorLeft}
            //   paginatorRight={paginatorRight}
            //   // filterDisplay="row"
            // >
            //   {getNotesColoumns()}
            // </DataTable>

            null}
          </MDBox>
        </Card>
      ) : (
        <></>
      )}

      {/* {notesEnable ? (
        <div className="custom-accordian mt-2">
          <Accordion>
            <AccordionSummary expandIcon={<ArrowDownward />}>Notes</AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <div className="reg_input">
                    <Autocomplete
                      options={notesDropdownList ? notesDropdownList : []}
                      defaultValue={selectedNotesType ? selectedNotesType : ""}
                      onChange={(e, value) => {
                        setSelectedNotesType(value.label);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Notes Type*"
                          name="NotesType"
                          // error={values.errorstate}
                          // helperText={values.errorstate}
                        />
                      )}
                    />
                    <span class="errorTC" id="ServicesError">
                      Please select Notes Type.
                    </span>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <ReportGmailerrorredIcon
                    color={inNoteImportant ? "primary" : "secondary"}
                    fontSize="large"
                    onClick={() => setinNoteImportant((prev) => !prev)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} sx={{ mb: 2 }}>
                <Grid item xs={3}>
                  <h5>Notes Text: </h5>
                </Grid>
                <Grid item xs={4}>
                  <div className="reg_input">
                    <TextField
                      onChange={(e) => setChNotes(e.target.value)}
                      value={chNotes ? chNotes : ""}
                      name="notes"
                      multiline
                      rows={3}
                      style={{
                        marginBottom: "10px",
                      }}
                      // maxRows={4}
                      label="Notes*"
                      id="regFirstName"
                      fullWidth
                    />
                    <span class="errorTC" id="NotesError">
                      Please Write Notes.
                    </span>
                  </div>
                </Grid>
              </Grid>
              <Grid item md="4">
                <MDButton onClick={() => saveNotes()} variant="outlined" color="primary">
                  Save Notes
                </MDButton>
              </Grid>
            </AccordionDetails>

            <NotesComponent
              entityType="RiskRegister"
              entityId={complianceRegisterId}
              NotesData={notesData}
            />
          </Accordion>
        </div>
      ) : (
        <></>
      )} */}
    </DashboardLayout>
  );
};
