import cogoToast from "cogo-toast";
import MDDatePicker from "components/MDDatePicker";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import { CommonConfig } from "constant";
import MDInput from "components/MDInput";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";

import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";
// import image from "assets/img/left-SIGNUP-image.png";
import Logo from "assets/img/valuecare-logo.png";
// // import MenuItem from "@material-ui/core/MenuItem";
import regIcon1 from "assets/img/reg-icon1.svg";
import regIcon2 from "assets/img/reg-icon2.svg";
import regIcon3 from "assets/img/reg-icon3.svg";
import facebookIcon from "assets/img/icon-fb.svg";
import googleIcon from "assets/img/icon-google.svg";
import emailIcon from "assets/img/icon-email.svg";
import "assets/css/style.css";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { gapi } from "gapi-script";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import input from "assets/theme/components/form/input";
import { item } from "examples/Sidenav/styles/sidenavItem";

function EditUser() {
  var statelistData = [];
  var servicelistData = [];
  const [statelist, setGetstate] = useState(null);
  const [servicelist, setServicelist] = useState(null);
  // const [servicememberlist, setServicememberlist] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedrelativeState, setSelectedrelativeState] = useState(null);
  const [selectedsupportState, setSelectedsupportState] = useState(null);
  const [selectedcoordinatorsState, setSelectedCoordinatorsState] = useState(null);
  const [selectedDataState, setSelectedDataState] = useState(null);
  const [selectedcontractorsState, setSelectedContractorsState] = useState(null);
  const [selecteditemState, setSelectedItemState] = useState(null);

  let navigate = useNavigate();
  if (localStorage.getItem("userData")) {
    // alert("Hello");
  } else {
    // alert("Hello2")
    navigate("/");
  }

  console.log("servicelist-------------", servicelist);
  const getStateData = () => {
    axios({
      url: CommonConfig.ApiUrl + "userModule/GetStateList",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.data.success === true) {
          console.log("StateListData", res.data.Data);
          statelistData = res.data.Data;
          setGetstate(
            statelistData.map((item) => ({
              id: item.StateID,
              label: item.StateName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getServiceData = () => {
    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "Service/ServiceList",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.data.success === true) {
          console.log("StateListData", res.data.Data);
          servicelistData = res.data.Data;
          setServicelist(
            servicelistData.map((item) => ({
              serviceId: item.ServiceId,
              serviceLabel: item.ServiceName,
              serviceType: item.ServiceType,
            }))
          );
          // setServicememberlist(
          //   servicelistData.map((item) => ({
          //     serviceId: item.ServiceId,
          //     serviceLabel: item.ServiceName,
          //     serviceType: item.ServiceType,
          //   }))
          // );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStateData();
    getServiceData();
  }, []);

  const [birthDate, setbirthDate] = React.useState(new Date().toISOString().slice(0, 10));
  const handledateChange = (event) => {
    // debugger;
    let dt1 = new Date().getTime();
    let dt2 = new Date(event[0]).getTime();
    if (dt1 < dt2) {
      alert("BirthDate can not be in Future");
      let DOB = "";
    } else {
      let DOB = new Date(event[0]);
      let mnth = ("0" + (DOB.getMonth() + 1)).slice(-2);
      let day = ("0" + DOB.getDate()).slice(-2);
      DOB = [DOB.getFullYear(), mnth, day].join("-");
      if (DOB <= setbirthDate) {
        setValues({ ...values, DOB: DOB });
      }
    }
  };

  const onState1 = (event, value) => {
    const state = value;
    setValues({ ...values, state: state });
  };

  const onState2 = (event, value) => {
    const relativestate = value;
    setValues({ ...values, relativestate: relativestate });
  };

  const onState3 = (event, value) => {
    const support_person_state = value;
    setMembers({ ...members, support_person_state: support_person_state });
  };

  const onState4 = (event, value) => {
    const state = value;
    setCoordinators({ ...coordinators, state: state });
  };

  const onState5 = (event, value) => {
    const state = value;
    setData({ ...data, state: state });
  };

  const onState6 = (event, value) => {
    const organisationstate = value;
    setContractors({ ...contractors, organisationstate: organisationstate });
  };

  const onState7 = (event, value) => {
    const state = value;
    setItems({ ...items, state: state });
  };

  const { state } = useLocation();
  const useremail = "";
  const userpassword = "";
  const [editdata, setEditdata] = useState({
    isvalid: false,
    firstName: "",
    middleName: "",
    lastName: "",
    DOB: "",
    phone: "",
    email: "",
    living: "",
    grouphome: "",
    streetAddress: "",
    streetName: "",
    suburb: "",
    state: "",
    pin: "",
    NDIS: "",
    formId: "",
    ndisnumber: "",
    ndisdocument: "",
    file: "",
    organisationphone: "",
    organisationemail: "",
    coordinatorName: "",
    coordinatorphone: "",
    coordinatoremail: "",
    relativename: "",
    relativephone: "",
    relativeemail: "",
    relativestreetAddress: "",
    relativestreetName: "",
    relativesuburb: "",
    relativestate: "",
    relativepin: "",
    services: [],
    errorfirstname: "",
    errormiddlename: "",
    errorlastName: "",
    errordob: "",
    errorliving: "",
    errorgrouphome: "",
    errorstreetAddress: "",
    errorstreetName: "",
    errorsuburb: "",
    errorstate: "",
    errorpin: "",
    errorndisnumber: "",
    errorcoordinatortName: "",
    errorcoordinatorphone: "",
    errorcoordinatoremail: "",
    errorrelativename: "",
    errorrelativephone: "",
    errorrelativeemail: "",
    errorrelativestreetAddress: "",
    errorrelativestreetName: "",
    errorrelativesuburb: "",
    errorrelativestate: "",
    errorrelativepin: "",
    errorservices: "",
    validate: "",
    EntityType: [],
  });

  const getData = () => {
    var SecurityUserId = localStorage.getItem("Formdata");
    console.log("ssssss", SecurityUserId);

    //localStorage.removeItem("Formdata");
    var inputdata = {
      SecurityUserId,
    };
    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "userModule/GetRegisterUser",
      method: "POST",
      headers: {},
      data: inputdata,
    })
      .then((res) => {
        // debugger;
        if (res.data.success === true) {
          setEditdata(res.data.Data[0]);
          console.log("setEditdata", setEditdata);

          if (res.data.Data[0].UserTypeId === "3b8e503e-f848-11ec-86e0-fa163ebffd39") {
            var formId = 1;
            document.getElementById("regStep1").style.display = "block";
            var inputdata = { data: res.data.Data[0] };
            console.log("inputt", values.email);

            values.firstName = inputdata.data.FirstName;
            values.middleName = inputdata.data.MiddleName;
            values.lastName = inputdata.data.LastName;
            values.DOB = inputdata.data.DOB;
            values.NDIS = inputdata.data.NDIS;
            values.ndisdocument = inputdata.data.NDISPlanDocument;
            values.ndisnumber = inputdata.data.NDISNumber;
            values.phone = inputdata.data.PersonPhone;
            values.email = inputdata.data.PersonEmail;
            values.living = inputdata.data.GroupHome;
            values.grouphome = inputdata.data.GroupHomeName;
            values.streetAddress = inputdata.data.PersonAddrLine1;
            values.streetName = inputdata.data.PersonAddrLine2;
            values.state = inputdata.data.PersonState;
            values.suburb = inputdata.data.PersonCity;
            values.pin = inputdata.data.PersonPostalCode;
            values.relativename = inputdata.data.RelativeFName;
            values.relativephone = inputdata.data.RelativePhone;
            values.relativeemail = inputdata.data.RelativeEmail;
            values.relativestreetAddress = inputdata.data.RelativeAddrLine1;
            values.relativestreetName = inputdata.data.RelativeAddrLine2;
            values.relativesuburb = inputdata.data.RelativeCity;
            values.relativestate = inputdata.data.RelativeState;
            values.relativepin = inputdata.data.RelativePostalCode;
            values.coordinatorName = inputdata.data.CoordinatorName;
            values.coordinatoremail = inputdata.data.CoordinatorEmail;
            values.coordinatorphone = inputdata.data.CoordinatorPhone;
            values.appointment = inputdata.data.Appointment;
            values.services = inputdata.data.ServiceType;
            console.log("services......", values.services);
            // useremail = inputdata.data.Email;
            // userpassword = inputdata.data.Password;
          } else if (res.data.Data[0].UserTypeId === "4e43a9ae-f848-11ec-86e0-fa163ebffd39") {
            var formId = 2;
            document.getElementById("regStep2").style.display = "block";
            var inputdata = { data: res.data.Data[0] };
            console.log("inputt2", inputdata);
            members.firstName = inputdata.data.FirstName;
            members.middleName = inputdata.data.MiddleName;
            members.lastName = inputdata.data.LastName;
            members.grouphome = inputdata.data.GroupHomeName;
            members.living = inputdata.data.GroupHome;
            members.streetAddress = inputdata.data.PersonAddrLine1;
            members.streetName = inputdata.data.PersonAddrLine2;
            members.city = inputdata.data.PersonCity;
            members.phone = inputdata.data.PersonPhone;
            members.email = inputdata.data.PersonEmail;
            members.state = inputdata.data.PersonState;
            members.support_person_fname = inputdata.data.SupportFName;
            members.support_person_mname = inputdata.data.SupportMName;
            members.support_person_lname = inputdata.data.SupportLName;
            members.support_person_dob = inputdata.data.SupportDOB;
            members.support_person_state = inputdata.data.SupportState;
            members.support_person_streetname = inputdata.data.SupportAddrLine1;
            members.support_person_streetaddress = inputdata.data.SupportAddrLine2;
            members.support_person_city = inputdata.data.SupportCity;
            members.support_person_pin = inputdata.data.SupportPostalCode;
            members.relationship = inputdata.data.Relation;
            members.relationType = inputdata.data.RelationType;
            members.isndisservice = inputdata.data.NDIS;
            members.ndisNumber = inputdata.data.NDISNumber;
            members.isndisdocument = inputdata.data.NDISPlanDocument;
            members.clientManager = inputdata.data.ClientManager;
            members.disability = inputdata.data.Disabilities;
            members.valueCareservices = inputdata.data.ServiceType;

            console.log("member..", inputdata.data.SupportAddrLine1);
          } else if (res.data.Data[0].UserTypeId === "5751a0d5-f848-11ec-86e0-fa163ebffd39") {
            var formId = 3;
            document.getElementById("regStep3").style.display = "block";
            var inputdata = { data: res.data.Data[0] };
            console.log("inputt3", inputdata);

            coordinators.firstName = inputdata.data.FirstName;
            coordinators.middleName = inputdata.data.MiddleName;
            coordinators.lastName = inputdata.data.LastName;
            coordinators.organisationName = inputdata.data.OrganizationName;
            coordinators.streetAddress = inputdata.data.OrganizationAddrLine1;
            coordinators.streetName = inputdata.data.OrganizationAddrLine2;
            coordinators.city = inputdata.data.OrganizationCity;
            coordinators.email = inputdata.data.OrganizationEmail;
            coordinators.phone = inputdata.data.OrganizationPhone;
            coordinators.state = inputdata.data.OrganizationState;
            coordinators.pin = inputdata.data.OrganizationPostalCode;

            coordinators.ndisNumber = inputdata.data.NDISNumber;
            coordinators.website = inputdata.data.Organizationwebsite;
            coordinators.ndisService = inputdata.data.NDIS;
            coordinators.ABNnumber = inputdata.data.ABN_Number;
            coordinators.marketactivities = inputdata.data.Event;
          } else if (res.data.Data[0].UserTypeId === "60ff8aa2-f848-11ec-86e0-fa163ebffd39") {
            var formId = 4;
            document.getElementById("regStep4").style.display = "block";
            var inputdata = { data: res.data.Data[0] };
            console.log("inputt4", inputdata);

            data.firstName = inputdata.data.FirstName;
            data.middleName = inputdata.data.MiddleName;
            data.lastName = inputdata.data.LastName;
            data.streetAddress = inputdata.data.PersonAddrLine1;
            data.streetName = inputdata.data.PersonAddrLine2;
            data.suburb = inputdata.data.PersonCity;
            data.state = inputdata.data.PersonState;
            data.pin = inputdata.data.PersonPostalCode;
            data.phone = inputdata.data.PersonPhone;
            data.email = inputdata.data.PersonEmail;
            data.newsletters = inputdata.data.Event;
          } else if (res.data.Data[0].UserTypeId === "68c3a835-f848-11ec-86e0-fa163ebffd39") {
            var formId = 5;
            document.getElementById("regStep5").style.display = "block";
            var inputdata = { data: res.data.Data[0] };
            console.log("inputt5", inputdata);

            contractors.firstName = inputdata.data.FirstName;
            contractors.middleName = inputdata.data.MiddleName;
            contractors.lastName = inputdata.data.LastName;
            contractors.phone = inputdata.data.PersonPhone;
            contractors.email = inputdata.data.PersonEmail;
            contractors.registringAs = inputdata.data.AreRegistering;
            contractors.organisationName = inputdata.data.OrganizationName;
            contractors.organisationstreetAddress = inputdata.data.OrganizationAddrLine1;
            contractors.organisationstreetName = inputdata.data.OrganizationAddrLine2;
            contractors.organisationcity = inputdata.data.OrganizationCity;
            contractors.organisationstate = inputdata.data.OrganizationState;
            contractors.organisationpin = inputdata.data.OrganizationPostalCode;
            contractors.organisationphone = inputdata.data.OrganizationPhone;
            contractors.organisationemail = inputdata.data.OrganizationEmail;
            contractors.organisationwebsite = inputdata.data.Organizationwebsite;
            contractors.organisationABNnumber = inputdata.data.ABN_Number;
            contractors.listofServices = inputdata.data.ServicesProvide;
            contractors.ndisRegistered = inputdata.data.NDIS;
            contractors.ndisNumber = inputdata.data.NDISNumber;
            contractors.services = inputdata.data.ServiceType;
            contractors.marketactivities = inputdata.data.Event;
          } else if (res.data.Data[0].UserTypeId === "73558196-f848-11ec-86e0-fa163ebffd39") {
            var formId = 6;
            document.getElementById("regStep6").style.display = "block";
            var inputdata = { data: res.data.Data[0] };
            console.log("inputt6", inputdata);

            items.firstName = inputdata.data.FirstName;
            items.middleName = inputdata.data.MiddleName;
            items.lastName = inputdata.data.LastName;
            items.streetAddress = inputdata.data.PersonAddrLine1;
            items.streetName = inputdata.data.PersonAddrLine2;
            items.suburb = inputdata.data.PersonCity;
            items.state = inputdata.data.PersonState;
            items.pin = inputdata.data.PersonPostalCode;
            items.phone = inputdata.data.PersonPhone;
            items.email = inputdata.data.PersonEmail;
            items.role = inputdata.data.Role;
            items.location = inputdata.data.WorkLocation;
            items.resume = inputdata.data.Resume;
            items.newsletters = inputdata.data.Event;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  window.gapi.load("client:auth2", () => {
    window.gapi.client.init({
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      plugin_name: "chat",
    });
  });

  const services = [
    { label: "Early Childhood Services" },
    { label: "Foster Care Services" },
    { label: "Therapeutic Services for Children" },
    { label: "Daily Living & Life Skills" },
    { label: "Daily Persional Activities" },
  ];

  const onTagsChange = (event, value) => {
    const servi = value;
    console.log("--------", servi);
    setValues({ ...values, services: servi });
    console.log("---------------", value);
  };

  const [values, setValues] = React.useState({
    isvalid: false,
    appointment: "",
    firstName: "",
    middleName: "",
    lastName: "",
    DOB: "",
    phone: "",
    email: "",
    living: "",
    grouphome: "",
    streetAddress: "",
    streetName: "",
    suburb: "",
    state: "",
    pin: "",
    NDIS: "",
    formId: "",
    ndisnumber: "",
    ndisdocument: "",
    file: "",
    coordinatorName: "",
    coordinatorphone: "",
    coordinatoremail: "",
    relativename: "",
    relativephone: "",
    relativeemail: "",
    relativestreetAddress: "",
    relativestreetName: "",
    relativesuburb: "",
    relativestate: "",
    relativepin: "",
    services: [],
    errorfirstname: "",
    errormiddlename: "",
    errorlastName: "",
    errorphone: "",
    erroremail: "",
    errordob: "",
    errorliving: "",
    errorgrouphome: "",
    errorstreetAddress: "",
    errorstreetName: "",
    errorsuburb: "",
    errorstate: "",
    errorpin: "",
    errorndisnumber: "",
    errorcoordinatortName: "",
    errorcoordinatorphone: "",
    errorcoordinatoremail: "",
    errorrelativename: "",
    errorrelativephone: "",
    errorrelativeemail: "",
    errorrelativestreetAddress: "",
    errorrelativestreetName: "",
    errorrelativesuburb: "",
    errorrelativestate: "",
    errorrelativepin: "",
    errorservices: "",
    validate: "",
    EntityType: [],
  });
  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    console.log("======;;", event.target);
  };

  const validateValueData = () => {
    // debugger;
    if (values.validate == "") {
      if (values.firstName === "") {
        values.errorfirstname = "Please Enter first Name";
        values.isvalid = true;
      } else {
        values.errorfirstname = "";
        // values.isvalid = false
      }
      if (values.lastName === "") {
        values.errorlastName = "Please Enter Last Name";
        values.isvalid = true;
      } else {
        values.errorlastName = "";
        // values.isvalid = false
      }
      // if (values.DOB === "") {
      //   values.errordob = "Please Enter Date Of Birth";
      //   values.isvalid = true;
      // } else {
      //   values.errordob = "";
      //   // values.isvalid = false
      // }
      if (values.phone === "") {
        values.errorphone = "Please Enter Phone Number";
        values.isvalid = true;
      } else if (values.phone.length != 9) {
        values.errorphone = "Please Enter Valid Phone Number";
        values.isvalid = true;
      } else {
        values.errorphone = "";
        // values.isvalid = false
      }

      if (values.email === "") {
        values.erroremail = "Please Enter Email Id";
        values.isvalid = true;
      } else if (!values.email.match(CommonConfig.RegExp.email)) {
        values.erroremail = "Please Enter Valid Email Id";
        values.isvalid = true;
      } else {
        values.erroremail = "";
        // values.isvalid = false
      }

      if (values.living === "1") {
        if (values.grouphome === "") {
          values.errorgrouphome = "Please Enter Group Home Name";
          values.isvalid = true;
        } else {
          values.errorgrouphome = "";
          // values.isvalid = false
        }
      }

      if (values.streetAddress === "") {
        values.errorstreetAddress = "Please Enter Street Address";
        values.isvalid = true;
      } else {
        values.errorstreetAddress = "";
        // values.isvalid = false
      }

      if (values.streetName === "") {
        values.errorstreetName = "Please Enter Street Name";
        values.isvalid = true;
      } else {
        values.errorstreetName = "";
        // values.isvalid = false
      }

      if (values.state === "") {
        values.errorstate = "Please Enter State";
        values.isvalid = true;
      } else {
        values.errorstate = "";
        // values.isvalid = false
      }

      if (values.pin === "") {
        values.errorpin = "Please Enter Pin Code";
        values.isvalid = true;
      } else {
        values.errorpin = "";
        // values.isvalid = false
      }

      if (values.NDIS === "1") {
        if (values.ndisnumber === "") {
          values.errorndisnumber = "Please Enter NDIS Number";
          values.isvalid = true;
        } else {
          values.errorndisnumber = "";
          // values.isvalid = false
        }
      }

      if (values.ndisdocument === "1") {
        if (values.file === "") {
          values.errorfile = "Please Select NDIS Document";
          values.isvalid = true;
        } else {
          values.errorfile = "";
          // values.isvalid = false
        }
      }

      // var idxDot = values.file.lastIndexOf(".") + 1;
      // var extFile = values.file.substr(idxDot, values.file.length).toLowerCase();
      // if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
      //   values.isvalid = true
      // }else{
      //     alert("Only jpg/jpeg and png files are allowed!");
      // }

      if (values.coordinatorName === "") {
        values.errorcoordinatortName = "Please Enter Name";
        values.isvalid = true;
      } else {
        values.errorcoordinatortName = "";
        // values.isvalid = false
      }

      if (values.coordinatorphone === "") {
        values.errorcoordinatorphone = "Please Enter Phone Number";
        values.isvalid = true;
      } else if (values.coordinatorphone.length != 9) {
        values.errorcoordinatorphone = "Please Enter Valid Phone Number";
        values.isvalid = true;
      } else {
        values.errorcoordinatorphone = "";
        // values.isvalid = false
      }

      if (values.coordinatoremail === "") {
        values.errorcoordinatoremail = "Please Enter Email Id";
        values.isvalid = true;
      } else if (!values.coordinatoremail.match(CommonConfig.RegExp.email)) {
        values.errorcoordinatoremail = "Please Enter Valid Email Id";
        values.isvalid = true;
      } else {
        values.errorcoordinatoremail = "";
        // values.isvalid = false
      }

      if (values.relativename === "") {
        values.errorrelativename = "Please Enter Name";
        values.isvalid = true;
      } else {
        values.errorrelativename = "";
        // values.isvalid = false
      }

      if (values.relativephone === "") {
        values.errorrelativephone = "Please Enter Phone Number";
        values.isvalid = true;
      } else if (values.relativephone.length != 9) {
        values.errorrelativephone = "Please Enter Valid Phone Number";
        values.isvalid = true;
      } else {
        values.errorrelativephone = "";
        // values.isvalid = false
      }

      if (values.relativeemail === "") {
        values.errorrelativeemail = "Please Enter Email Id";
        values.isvalid = true;
      } else if (!values.relativeemail.match(CommonConfig.RegExp.email)) {
        values.errorrelativeemail = "Please Enter Valid Email Id";
        values.isvalid = true;
      } else {
        values.errorrelativeemail = "";
        // values.isvalid = false
      }

      if (values.relativestreetAddress === "") {
        values.errorrelativestreetAddress = "Please Enter Street Address";
        values.isvalid = true;
      } else {
        values.errorrelativestreetAddress = "";
        // values.isvalid = false
      }

      if (values.relativestreetName === "") {
        values.errorrelativestreetName = "Please Enter Street Name";
        values.isvalid = true;
      } else {
        values.errorrelativestreetName = "";
        // values.isvalid = false
      }

      if (values.relativestate === "") {
        values.errorrelativestate = "Please Enter State";
        values.isvalid = true;
      } else {
        values.errorrelativestate = "";
        // values.isvalid = false
      }

      if (values.relativepin === "") {
        values.errorrelativepin = "Please Enter Pin Code";
        values.isvalid = true;
      } else {
        values.errorrelativepin = "";
        // values.isvalid = false
      }

      // if (values.appointment === "1"){
      if (values.services === "") {
        setValues({ ...values, ["errorservices"]: "Please Enter Services" });
      } else {
        setValues({ ...values, ["errorservices"]: "" });
      }
      // }

      if (values.DOB === "") {
        document.getElementById("errorDate").style.display = "block";
        return false;
      } else {
        document.getElementById("errorDate").style.display = "none";
      }
    }

    if (
      values.errorfirstname == "" &&
      values.errorlastName == "" &&
      values.errordob == "" &&
      values.errorphone == "" &&
      values.erroremail == "" &&
      values.errorgrouphome == "" &&
      values.errorstreetAddress == "" &&
      values.errorstreetName == "" &&
      values.errorstate == "" &&
      values.errorpin == "" &&
      values.errorndisnumber == "" &&
      values.errorfile == "" &&
      values.errorcoordinatortName == "" &&
      values.errorcoordinatorphone == "" &&
      values.errorcoordinatoremail == "" &&
      values.errorrelativename == "" &&
      values.errorrelativephone == "" &&
      values.errorrelativeemail == "" &&
      values.errorrelativestreetAddress == "" &&
      values.errorrelativestreetName == "" &&
      values.errorrelativestate == "" &&
      values.errorrelativepin == "" &&
      values.errorservices == ""
    ) {
      values.isvalid = false;
    } else {
      values.isvalid = true;
    }
  };

  const validateMembersData = () => {
    // debugger;
    const regex = {
      phone: /^(0*)?[1-9]{1}[0-9]{10}$/,
      name: /^[a-zA-Z ,-.'&]*$/,
      email: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})$/,
    };
    if (members.validate === "") {
      if (members.relationship === "") {
        members.errorrelationship = "Please Enter Relationship ";
        members.isvalid = true;
      } else {
        members.errorrelationship = "";
        // members.isvalid = false
      }

      if (members.firstName === "") {
        members.errorfirstName = "Please Enter first name ";
        members.isvalid = true;
      } else {
        members.errorfirstName = "";
        // members.isvalid = false
      }

      // if (values.firstName === "") {
      //   values.errorfirstName = "Please Enter First Name";
      // } else if (!regex.name.test(values.firstName)) {
      //   values.errorfirstName = "Please Enter a Valid First Name";
      // } else {
      //   values.errorfirstName = "";
      // }

      if (members.lastName === "") {
        members.errorlastname = "Please Enter Last Name";
        members.isvalid = true;
      } else {
        members.errorlastname = "";
        // members.isvalid = false
      }
      if (members.phone === "") {
        members.errorphone = "Please Enter Phone Number";
        members.isvalid = true;
      } else if (members.phone.length != 9) {
        members.errorphone = "Please Enter Valid Phone Number";
        members.isvalid = true;
      } else {
        members.errorphone = "";
        // values.isvalid = false
      }

      if (members.email === "") {
        members.erroremail = "Please Enter Email Id";
        members.isvalid = true;
      } else if (!members.email.match(CommonConfig.RegExp.email)) {
        members.erroremail = "Please Enter Valid Email Id";
        members.isvalid = true;
      } else {
        members.erroremail = "";
        // values.isvalid = false
      }

      if (members.streetAddress === "") {
        members.errorstreetAddress = "Please Enter Street Address";
        members.isvalid = true;
      } else {
        members.errorstreetAddress = "";
      }
      if (members.streetName === "") {
        members.errorstreetName = "Please Enter Street Name";
        members.isvalid = true;
      } else {
        members.errorstreetName = "";
      }
      if (members.city === "") {
        members.errorcity = "Please Enter City ";
        members.isvalid = true;
      } else {
        members.errorcity = "";
      }
      // if (members.state === "") {
      //   members.errorstate = "Please Enter State ";
      //   members.isvalid = true
      // } else {
      //   members.errorstate = "";
      // }
      if (members.support_person_fname === "") {
        members.errorsupport_person_fname = "Please Enter First Name ";
        members.isvalid = true;
      } else {
        members.errorsupport_person_fname = "";
      }
      if (members.support_person_mname === "") {
        members.errorsupport_person_mname = "Please Enter Middle Name ";
        members.isvalid = true;
      } else {
        members.errorsupport_person_mname = "";
      }
      if (members.support_person_lname === "") {
        members.errorsupport_person_lname = "Please Enter Last Name ";
        members.isvalid = true;
      } else {
        members.errorsupport_person_lname = "";
      }

      if (members.support_person_dob === "") {
        members.errorsupport_person_dob = "Please Enter DOB";
        members.isvalid = true;
      } else {
        members.errorsupport_person_dob = "";
        // members.isvalid = false
      }

      if (members.grouphome === "") {
        members.errorgrouphome = "Please Enter Group Home Name";
        members.isvalid = true;
      } else {
        members.errorgrouphome = "";
      }

      if (members.support_person_streetaddress === "") {
        members.errorsupport_person_streetaddress = "Please Enter Street Address ";
        members.isvalid = true;
      } else {
        members.errorsupport_person_streetaddress = "";
      }
      if (members.support_person_streetname === "") {
        members.errorsupport_person_streetname = "Please Enter Street Name ";
        members.isvalid = true;
      } else {
        members.errorsupport_person_streetname = "";
      }
      if (members.support_person_city === "") {
        members.errorsupport_person_city = "Please Enter City ";
        members.isvalid = true;
      } else {
        members.errorsupport_person_city = "";
      }
      if (members.support_person_state === "") {
        members.errorsupport_person_state = "Please Enter state ";
        members.isvalid = true;
      } else {
        members.errorsupport_person_state = "";
      }
      if (members.support_person_pin === "") {
        members.errorsupport_person_pin = "Please Enter Pin Number ";
        members.isvalid = true;
      } else {
        members.errorsupport_person_pin = "";
      }
      if (members.disability === "") {
        members.errordisability = "Please Enter Disability ";
        members.isvalid = true;
      } else {
        members.errordisability = "";
      }
      if (members.ndisNumber === "") {
        members.errorndisNumber = "Please NDIS Number ";
        members.isvalid = true;
      } else {
        members.errorndisNumber = "";
        // members.isvalid = false
      }

      if (members.valueCareservices === "") {
        setMembers({ ...members, ["errorServices"]: "Please Enter value care Services" });
        members.isvalid = true;
      } else {
        setMembers({ ...members, ["errorServices"]: "" });
      }
    }

    if (
      members.errorfirstName == "" &&
      members.errorlastname == "" &&
      members.errorliving == "" &&
      members.errorgrouphome == "" &&
      members.errorrelationship == "" &&
      members.errorServices == "" &&
      members.errorphone == "" &&
      members.erroremail == "" &&
      members.errorsupport_person_dob == "" &&
      members.errorstreetAddress == "" &&
      members.errorstreetName == "" &&
      members.errorcity == "" &&
      members.errorsupport_person_state == "" &&
      members.errorsupport_person_fname == "" &&
      members.errorsupport_person_mname == "" &&
      members.errorsupport_person_lname == "" &&
      members.errorsupport_person_streetaddress == "" &&
      members.errorsupport_person_streetname == "" &&
      members.errorsupport_person_city == "" &&
      members.errorsupport_person_pin == "" &&
      members.errordisability == "" &&
      members.errorndisNumber == "" &&
      members.errorisndisdocument == "" &&
      members.errorclientManager == ""
    ) {
      members.isvalid = false;
    } else {
      members.isvalid = true;
    }
  };

  const validateCoordinatorsData = () => {
    if (coordinators.validate === "") {
      if (coordinators.firstName === "") {
        coordinators.errorfirstName = "Please Enter First Name";
        coordinators.isvalid = true;
      } else {
        coordinators.errorfirstName = "";
        // coordinators.isvalid = false
      }
      if (coordinators.middleName === "") {
        coordinators.errormiddleName = "Please Enter Middle Name";
        coordinators.isvalid = true;
      } else {
        coordinators.errormiddleName = "";
        // coordinators.isvalid = false
      }
      if (coordinators.lastName === "") {
        coordinators.errorlastName = "Please Enter Last Name";
        coordinators.isvalid = true;
      } else {
        coordinators.errorlastName = "";
        // coordinators.isvalid = false
      }
      if (coordinators.organisationName === "") {
        coordinators.errororganisationName = "Please Enter Organisation Name";
        coordinators.isvalid = true;
      } else {
        coordinators.errororganisationName = "";
        // coordinators.isvalid = false
      }
      if (coordinators.phone === "") {
        coordinators.errorphone = "Please Enter Phone Number";
        coordinators.isvalid = true;
      } else if (coordinators.phone.length !== 9) {
        coordinators.errorphone = "Please Enter Valid Phone Number";
        coordinators.isvalid = true;
      } else {
        coordinators.errorphone = "";
        // values.isvalid = false
      }

      if (coordinators.email === "") {
        coordinators.erroremail = "Please Enter Email Id";
        coordinators.isvalid = true;
      } else if (!coordinators.email.match(CommonConfig.RegExp.email)) {
        coordinators.erroremail = "Please Enter Valid Email Id";
        coordinators.isvalid = true;
      } else {
        coordinators.erroremail = "";
        // values.isvalid = false
      }

      if (coordinators.website === "") {
        coordinators.errorwebsite = "Please Enter website";
        coordinators.isvalid = true;
      } else {
        coordinators.errorwebsite = "";
        // coordinators.isvalid = false
      }
      if (coordinators.streetAddress === "") {
        coordinators.errorstreetAddress = "Please Enter Street Address";
        coordinators.isvalid = true;
      } else {
        coordinators.errorstreetAddress = "";
        //  coordinators.isvalid = false
      }
      if (coordinators.streetName === "") {
        coordinators.errorstreetName = "Please Enter Street Name";
        coordinators.isvalid = true;
      } else {
        coordinators.errorstreetName = "";
        //  coordinators.isvalid = false
      }
      if (coordinators.city === "") {
        coordinators.errorcity = "Please Enter City ";
        coordinators.isvalid = true;
      } else {
        coordinators.errorcity = "";
        // coordinators.isvalid = false
      }
      if (coordinators.state === "") {
        coordinators.errorstate = "Please Enter State ";
        coordinators.isvalid = true;
      } else {
        coordinators.errorstate = "";
        //  coordinators.isvalid = false
      }
      if (coordinators.pin === "") {
        coordinators.errorpin = "Please Enter pin  ";
        coordinators.isvalid = true;
      } else {
        coordinators.errorpin = "";
        //  coordinators.isvalid = false
      }

      if (coordinators.ABNnumber === "") {
        coordinators.errorABNnumber = "Please Enter ABN Number ";
        coordinators.isvalid = true;
      } else {
        coordinators.errorABNnumber = "";
        // coordinators.isvalid = false
      }

      if (coordinators.ndisNumber === "") {
        setCoordinators({ ...coordinators, ["errorndisNumber"]: "Please Enter NDIS number" });
        coordinators.isvalid = true;
      } else {
        setCoordinators({ ...coordinators, ["errorndisNumber"]: "" });
        //  coordinators.isvalid = false
      }
    }

    if (
      coordinators.errorfirstName == "" &&
      coordinators.errormiddleName == "" &&
      coordinators.errorlastName == "" &&
      coordinators.errororganisationName == "" &&
      coordinators.errorstreetAddress == "" &&
      coordinators.errorstreetName == "" &&
      coordinators.errorcity == "" &&
      coordinators.errorstate == "" &&
      coordinators.errorphone == "" &&
      coordinators.erroremail == "" &&
      coordinators.errorpin == "" &&
      coordinators.errorwebsite == "" &&
      coordinators.errorABNnumber == "" &&
      coordinators.errorndisNumber == ""
    ) {
      coordinators.isvalid = false;
    } else {
      coordinators.isvalid = true;
    }
  };

  const validateData = () => {
    if (data.validate == "") {
      if (data.firstName === "") {
        data.errorfirstName = "Please Enter First Name";
        data.isvalid = true;
      } else {
        data.errorfirstName = "";
        // data.isvalid = false
      }

      if (data.lastName === "") {
        data.errorlastName = "Please Enter Last Name";
        data.isvalid = true;
      } else {
        data.errorlastName = "";
        // data.isvalid = false
      }

      if (data.streetAddress === "") {
        data.errorstreetAddress = "Please Enter Street Address";
        data.isvalid = true;
      } else {
        data.errorstreetAddress = "";
        //  data.isvalid = false
      }

      if (data.streetName === "") {
        data.errorstreetName = "Please Enter Street Name";
        data.isvalid = true;
      } else {
        data.errorstreetName = "";
        // data.isvalid = false
      }

      if (data.state === "") {
        data.errorstate = "Please Enter State";
        data.isvalid = true;
      } else {
        data.errorstate = "";
        // data.isvalid = false
      }

      if (data.pin === "") {
        data.errorpin = "Please Enter Pin Code";
        data.isvalid = true;
      } else {
        data.errorpin = "";
        // data.isvalid = false
      }

      if (data.phone === "") {
        data.errorphone = "Please Enter Phone Number";
        data.isvalid = true;
      } else if (data.phone.length != 9) {
        data.errorphone = "Please Enter Valid Phone Number";
        data.isvalid = true;
      } else {
        data.errorphone = "";
        // values.isvalid = false
      }

      if (data.email === "") {
        setData({ ...data, ["erroremail"]: "Please Enter Email Id" });
        data.isvalid = true;
      } else if (!data.email.match(CommonConfig.RegExp.email)) {
        setData({ ...data, ["erroremail"]: "Please Enter Valid Email Id" });
        data.isvalid = true;
      } else {
        setData({ ...data, ["erroremail"]: "" });
        // values.isvalid = false
      }
    }

    if (
      data.errorfirstName == "" &&
      data.errormiddleName == "" &&
      data.errorlastName == "" &&
      data.errorstreetAddress == "" &&
      data.errorstreetName == "" &&
      data.errorsuburb == "" &&
      data.errorstate == "" &&
      data.errorpin == "" &&
      data.errorphone == "" &&
      data.erroremail == ""
    ) {
      data.isvalid = false;
    } else {
      data.isvalid = true;
    }
  };

  const validateContractorsData = () => {
    if (contractors.validate === "") {
      if (contractors.firstName === "") {
        contractors.errorfirstName = "Please Enter First Name";
        contractors.isvalid = true;
      } else {
        contractors.errorfirstName = "";
        // contractors.isvalid = false
      }
      if (contractors.middleName === "") {
        contractors.errormiddleName = "Please Enter Middle Name";
        contractors.isvalid = true;
      } else {
        contractors.errormiddleName = "";
        //  contractors.isvalid = false
      }
      if (contractors.lastName === "") {
        contractors.errorlastName = "Please Enter Last Name";
        contractors.isvalid = true;
      } else {
        contractors.errorlastName = "";
        // contractors.isvalid = false
      }
      if (contractors.organisationName === "") {
        contractors.errororganisationName = "Please Enter Last Name";
        contractors.isvalid = true;
      } else {
        contractors.errororganisationName = "";
        // contractors.isvalid = false
      }
      if (contractors.phone === "") {
        contractors.errorphone = "Please Enter Phone Number";
        contractors.isvalid = true;
      } else if (contractors.phone.length != 9) {
        contractors.errorphone = "Please Enter Valid Phone Number";
        contractors.isvalid = true;
      } else {
        contractors.errorphone = "";
        // values.isvalid = false
      }

      if (contractors.email === "") {
        contractors.erroremail = "Please Enter Email Id";
        contractors.isvalid = true;
      } else if (!contractors.email.match(CommonConfig.RegExp.email)) {
        contractors.erroremail = "Please Enter Valid Email Id";
        contractors.isvalid = true;
      } else {
        contractors.erroremail = "";
        // values.isvalid = false
      }

      if (contractors.organisationphone === "") {
        contractors.errororganisationphone = "Please Enter Phone Number";
        contractors.isvalid = true;
      } else if (contractors.organisationphone.length != 9) {
        contractors.errororganisationphone = "Please Enter Valid Phone Number";
        contractors.isvalid = true;
      } else {
        contractors.errororganisationphone = "";
        // values.isvalid = false
      }

      if (contractors.organisationemail === "") {
        contractors.errororganisationemail = "Please Enter Email Id";
        contractors.isvalid = true;
      } else if (!contractors.organisationemail.match(CommonConfig.RegExp.email)) {
        contractors.errororganisationemail = "Please Enter Valid Email Id";
        contractors.isvalid = true;
      } else {
        contractors.errororganisationemail = "";
        // values.isvalid = false
      }

      if (contractors.organisationwebsite === "") {
        contractors.errororganisationwebsite = "Please Enter website";
        contractors.isvalid = true;
      } else {
        contractors.errororganisationwebsite = "";
        //  contractors.isvalid = false
      }
      if (contractors.organisationstreetAddress === "") {
        contractors.errororganisationstreetAddress = "Please Enter Street Address";
        contractors.isvalid = true;
      } else {
        contractors.errororganisationstreetAddress = "";
        // contractors.isvalid = false
      }
      if (contractors.organisationstreetName === "") {
        contractors.errororganisationstreetName = "Please Enter Street Name";
        contractors.isvalid = true;
      } else {
        contractors.errororganisationstreetName = "";
        // contractors.isvalid = false
      }
      if (contractors.organisationcity === "") {
        contractors.errororganisationcity = "Please Enter City ";
        contractors.isvalid = true;
      } else {
        contractors.errororganisationcity = "";
        // contractors.isvalid = false
      }
      if (contractors.organisationstate === "") {
        contractors.errororganisationstate = "Please Enter State ";
        contractors.isvalid = true;
      } else {
        contractors.errororganisationstate = "";
        //  contractors.isvalid = false
      }
      if (contractors.organisationpin === "") {
        contractors.errororganisationpin = "Please Enter pin  ";
        contractors.isvalid = true;
      } else {
        contractors.errororganisationpin = "";
        //  contractors.isvalid = false
      }

      if (contractors.organisationABNnumber === "") {
        contractors.errororganisationABNnumber = "Please Enter ABN Number ";
        contractors.isvalid = true;
      } else {
        contractors.errororganisationABNnumber = "";
        // contractors.isvalid = false
      }

      if (contractors.listofServices === "") {
        contractors.errorlistofServices = "Please Enter List of services ";
        contractors.isvalid = true;
      } else {
        contractors.errorlistofServices = "";
        // contractors.isvalid = false
      }

      if (contractors.ndisNumber === "") {
        setContractors({
          ...contractors,
          ["errorndisNumber"]: "Please Enter Service you are registered for",
        });
        contractors.isvalid = true;
      } else {
        setContractors({ ...contractors, ["errorndisNumber"]: "" });
        // contractors.isvalid = false
      }
    }

    if (
      contractors.errorfirstName == "" &&
      contractors.errormiddleName == "" &&
      contractors.errorlastName == "" &&
      contractors.errorphone == "" &&
      contractors.erroremail == "" &&
      contractors.errororganisationName == "" &&
      contractors.errororganisationstreetAddress == "" &&
      contractors.errororganisationstreetName == "" &&
      contractors.errororganisationcity == "" &&
      contractors.errororganisationstate == "" &&
      contractors.errororganisationphone == "" &&
      contractors.errororganisationemail == "" &&
      contractors.errororganisationpin == "" &&
      contractors.errororganisationwebsite == "" &&
      contractors.errororganisationABNnumber == "" &&
      contractors.errorlistofServices == "" &&
      contractors.errorndisNumber == ""
    ) {
      contractors.isvalid = false;
    } else {
      contractors.isvalid = true;
    }
  };

  const validateItemData = () => {
    if (items.validate == "") {
      if (items.firstName === "") {
        items.errorfirstName = "Please Enter First Name";
        items.isvalid = true;
      } else {
        items.errorfirstName = "";
        // items.isvalid = false
      }

      if (items.lastName === "") {
        items.errorlastName = "Please Enter Last Name";
        items.isvalid = true;
      } else {
        items.errorlastName = "";
        // items.isvalid = false
      }

      if (items.streetAddress === "") {
        items.errorstreetAddress = "Please Enter Street Address";
        items.isvalid = true;
      } else {
        items.errorstreetAddress = "";
        // items.isvalid = false
      }

      if (items.streetName === "") {
        items.errorstreetName = "Please Enter Street Name";
        items.isvalid = true;
      } else {
        items.errorstreetName = "";
        //  items.isvalid = false
      }

      if (items.state === "") {
        items.errorstate = "Please Enter State";
        items.isvalid = true;
      } else {
        items.errorstate = "";
        // items.isvalid = false
      }

      if (items.pin === "") {
        items.errorpin = "Please Enter Pin Code";
        items.isvalid = true;
      } else {
        items.errorpin = "";
        //  items.isvalid = false
      }

      if (items.phone === "") {
        items.errorphone = "Please Enter Phone Number";
        items.isvalid = true;
      } else if (items.phone.length != 9) {
        items.errorphone = "Please Enter Valid Phone Number";
        items.isvalid = true;
      } else {
        items.errorphone = "";
        // values.isvalid = false
      }

      if (items.role === "") {
        items.errorrole = "Please Enter Role";
        items.isvalid = true;
      } else {
        items.errorrole = "";
        //  items.isvalid = false
      }

      if (items.location === "") {
        items.errorlocation = "Please Enter Location";
        items.isvalid = true;
      } else {
        items.errorlocation = "";
        // items.isvalid = false
      }

      if (items.resume === "") {
        items.errorresume = "Please select Resume";
        items.isvalid = true;
      } else {
        items.errorresume = "";
        //  items.isvalid = false
      }

      if (items.email === "") {
        setItems({ ...items, ["erroremail"]: "Please Enter Email" });
        items.isvalid = true;
      } else if (!items.email.match(CommonConfig.RegExp.email)) {
        setItems({ ...items, ["erroremail"]: "Please Enter Valid Email" });
        items.isvalid = true;
      } else {
        setItems({ ...items, ["erroremail"]: "" });
        // values.isvalid = false
      }
    }

    if (
      items.errorfirstName == "" &&
      items.errormiddleName == "" &&
      items.errorlastName == "" &&
      items.errorstreetAddress == "" &&
      items.errorstreetName == "" &&
      items.errorsuburb == "" &&
      items.errorstate == "" &&
      items.errorpin == "" &&
      items.errorphone == "" &&
      items.erroremail == "" &&
      items.errorrole == "" &&
      items.errorlocation == "" &&
      items.errorresume == ""
    ) {
      items.isvalid = false;
    } else {
      items.isvalid = true;
    }
  };

  const handleSubmit = (form) => {
    // console.log("data.....", values);
    var inputdata = {};
    var SecurityUserId = localStorage.getItem("Formdata");
    if (form === 1) {
      // debugger;
      validateValueData();
      if (
        values.errorfirstname == "" &&
        values.errorlastName == "" &&
        values.errordob == "" &&
        values.errorndisnumber == "" &&
        values.isvalid === false
      ) {
        console.log("data.....", values);
      }

      inputdata = {
        SecurityUserId: SecurityUserId,
        UserName: useremail,
        Password: userpassword,
        FormId: login.formId,
        UserTypeId: "3b8e503e-f848-11ec-86e0-fa163ebffd39",
        FirstName: values.firstName,
        MiddleName: values.middleName,
        LastName: values.lastName,
        DOB: values.DOB,
        Email: values.email,
        PhoneNumber: values.phone,
        IsLiving: values.living,
        GroupHome: values.grouphome,
        AddrLine1: values.streetAddress,
        AddrLine2: values.streetName,
        Suburb: values.suburb,
        State: values.state,
        Country: "India",
        PostalCode: values.pin,
        IsNdisRegistered: values.NDIS,
        NDISNumber: values.ndisnumber,
        IsNdisDocument: values.ndisdocument,
        Document: values.file,
        CoordinatorName: values.coordinatorName,
        CoordinatorPhone: values.coordinatorphone,
        CoordinatorEmail: values.coordinatoremail,
        RelativeName: values.relativename,
        RelativePhone: values.relativephone,
        RelativeEmail: values.relativeemail,
        RelativeStreetAddress: values.relativestreetAddress,
        RelativeStreetName: values.relativestreetName,
        RelativeStreetSuburb: values.relativesuburb,
        RelativeState: values.relativestate,
        RelativePostalCode: values.relativepin,
        Appointment: values.appointment,
        Services: values.services,
      };
    } else if (form === 2) {
      validateMembersData();

      if (
        members.errorfirstName == "" &&
        members.errorlastname == "" &&
        members.errordob == "" &&
        members.isvalid === false
      ) {
        console.log("data.....", members);
      }

      inputdata = {
        SecurityUserId: SecurityUserId,
        UserName: login.username,
        Password: login.password,
        FormId: login.formId,
        UserTypeId: "4e43a9ae-f848-11ec-86e0-fa163ebffd39",
        RelationType: members.relationType,
        Relation: members.relationship,
        Email: members.email,
        FirstName: members.firstName,
        MiddleName: members.middleName,
        LastName: members.lastName,
        AddrLine1: members.streetAddress,
        AddrLine2: members.streetName,
        Suburb: members.city,
        State: members.state,
        PhoneNumber: members.phone,
        SupportPersonfname: members.support_person_fname,
        SupportPersonmname: members.support_person_mname,
        SupportPersonlname: members.support_person_lname,
        SupportPersonDob: members.support_person_dob,
        SupportPersonStreetAddress: members.support_person_streetaddress,
        SupportPersonStreetName: members.support_person_streetname,
        SupportPersonSuburb: members.support_person_city,
        SupportPersonState: members.support_person_state,
        SupportPersonPin: members.support_person_pin,
        Disabilities: members.disability,
        IsNdisRegistered: members.isndisservice,
        NDISNumber: members.ndisNumber,
        IsNdisDocument: members.isndisdocument,
        Document: members.ndisfile,
        ClientManager: members.clientManager,
        Services: members.valueCareservices,
        IsLiving: members.living,
        GroupHome: members.grouphome,
      };
    } else if (form === 3) {
      validateCoordinatorsData();
      if (
        coordinators.errorfirstName == "" &&
        coordinators.errorlastName == "" &&
        coordinators.isvalid === false
      ) {
        console.log("data.....", coordinators);
      }

      inputdata = {
        SecurityUserId: SecurityUserId,
        UserName: login.username,
        Password: login.password,
        FormId: login.formId,
        UserTypeId: "5751a0d5-f848-11ec-86e0-fa163ebffd39",
        FirstName: coordinators.firstName,
        MiddleName: coordinators.middleName,
        LastName: coordinators.lastName,
        OrganisationName: coordinators.organisationName,
        OrganizationStreetAddress: coordinators.streetAddress,
        OrganizationStreetName: coordinators.streetName,
        OrganizationSuburb: coordinators.city,
        OrganizationState: coordinators.state,
        OrganizationPostalCode: coordinators.pin,
        OrganizationPhone: coordinators.phone,
        OrganizationEmail: coordinators.email,
        Website: coordinators.website,
        AbnNo: coordinators.ABNnumber,
        IsNdisRegistered: coordinators.ndisService,
        NDISNumber: coordinators.ndisNumber,
        Event: coordinators.marketactivities,
      };
    } else if (form === 4) {
      validateData();
      if (data.errorfirstName == "" && data.errorlastName == "" && data.isvalid === false) {
        console.log("data.....", data);
      }

      inputdata = {
        SecurityUserId: SecurityUserId,
        UserName: login.username,
        Password: login.password,
        FormId: login.formId,
        UserTypeId: "60ff8aa2-f848-11ec-86e0-fa163ebffd39",
        FirstName: data.firstName,
        MiddleName: data.middleName,
        LastName: data.lastName,
        AddrLine1: data.streetAddress,
        AddrLine2: data.streetName,
        Suburb: data.suburb,
        State: data.state,
        PostalCode: data.pin,
        PhoneNumber: data.phone,
        Email: data.email,
        Event: data.newsletters,
      };
    } else if (form === 5) {
      validateContractorsData();
      if (
        contractors.errorfirstName == "" &&
        contractors.errorlastName == "" &&
        contractors.isvalid === false
      ) {
        console.log("data.....", data);
      }

      inputdata = {
        SecurityUserId: SecurityUserId,
        UserName: login.username,
        Password: login.password,
        FormId: login.formId,
        UserTypeId: "68c3a835-f848-11ec-86e0-fa163ebffd39",
        FirstName: contractors.firstName,
        MiddleName: contractors.middleName,
        LastName: contractors.lastName,
        PhoneNumber: contractors.phone,
        Email: contractors.email,
        AreRegistering: contractors.registringAs,
        OrganisationName: contractors.organisationName,
        OrganizationStreetAddress: contractors.organisationstreetAddress,
        OrganizationStreetName: contractors.organisationstreetName,
        OrganizationSuburb: contractors.organisationcity,
        OrganizationState: contractors.organisationstate,
        OrganizationPostalCode: contractors.organisationpin,
        OrganizationEmail: contractors.organisationphone,
        OrganizationPhone: contractors.organisationemail,
        Website: contractors.organisationwebsite,
        AbnNo: contractors.organisationABNnumber,
        ServiceProvide: contractors.listofServices,
        IsNdisRegistered: contractors.ndisRegistered,
        NDISNumber: contractors.ndisNumber,
        Services: contractors.services,
        Event: contractors.marketactivities,
      };
    } else if (form === 6) {
      validateItemData();
      if (items.errorfirstName == "" && items.errorlastName == "" && items.isvalid === false) {
        console.log("data.....", data);
      }

      inputdata = {
        SecurityUserId: SecurityUserId,
        FormId: login.formId,
        UserName: login.username,
        Password: login.password,
        UserTypeId: "73558196-f848-11ec-86e0-fa163ebffd39",
        FirstName: items.firstName,
        MiddleName: items.middleName,
        LastName: items.lastName,
        AddrLine1: items.streetAddress,
        AddrLine2: items.streetName,
        Suburb: items.suburb,
        State: items.state,
        PostalCode: items.pin,
        PhoneNumber: items.phone,
        Email: items.email,
        Role: items.role,
        WorkLocation: items.location,
        Document: items.resume,
        Event: items.newsletters,
      };
    }
    console.log("test", inputdata);

    // var temp = ""
    // console.log("inp.....", inputdata);
    // for (let i = 0; i < inputdata.Services.length; i++) {
    //   console.log("inputdata.Services[i]", inputdata.Services[i])
    //   if (inputdata.Services[i].label == undefined) {
    //     temp += inputdata.Services[i] + ","
    //   } else {
    //     temp += inputdata.Services[i].label + ","
    //   }
    //   // temp += inputdata.Service[i] + "~"

    // }
    // temp = temp.slice(0, -1); // Masteringjs.io
    // inputdata.Services = temp;
    // console.log("Services..... ", temp)
    // console.log("inp.....", inputdata);

    // debugger;

    if (
      values.isvalid === false &&
      members.isvalid === false &&
      coordinators.isvalid === false &&
      data.isvalid === false &&
      contractors.isvalid === false &&
      items.isvalid === false
    ) {
      // debugger;
      axios({
        url: CommonConfig.ApiUrl + "authentication/addUpdateUser",

        method: "POST",
        headers: {},
        data: inputdata,
      })
        .then((res) => {
          if (res.data.success === true) {
            cogoToast.success("Update Successfully");
            navigate("/dashboards/userprofile/Index");
          } else {
            cogoToast.error("Something Went Wrong");
          }

          console.log("test", res.res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const [data, setData] = React.useState({
    isvalid: false,
    firstName: "",
    middleName: "",
    lastName: "",
    streetAddress: "",
    streetName: "",
    suburb: "",
    state: "",
    pin: "",
    phone: "",
    email: "",
    newsletters: "",

    errorfirstName: "",
    errormiddleName: "",
    errorlastName: "",
    errorstreetAddress: "",
    errorstreetName: "",
    errorsuburb: "",
    errorstate: "",
    errorpin: "",
    errorphone: "",
    erroremail: "",
    validate: "",
  });

  const servicehandleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    console.log("======;;", event.target);
  };

  const [items, setItems] = React.useState({
    isvalid: false,
    firstName: "",
    middleName: "",
    lastName: "",
    streetAddress: "",
    streetName: "",
    suburb: "",
    state: "",
    pin: "",
    phone: "",
    email: "",
    role: "",
    location: "",
    resume: "",
    newsletters: "",

    errorfirstName: "",
    errormiddleName: "",
    errorlastName: "",
    errorstreetAddress: "",
    errorstreetName: "",
    errorsuburb: "",
    errorstate: "",
    errorpin: "",
    errorphone: "",
    erroremail: "",
    errorrole: "",
    errorlocation: "",
    errorresume: "",
    validate: "",
  });

  const carehandleChange = (event) => {
    setItems({ ...items, [event.target.name]: event.target.value });
    console.log("======", items);

    // const regex = {
    //   phone: /^(\+\d{1,3}[- ]?)?\d{9}$/,
    //   name: /^[a-zA-Z ,-.'&]*$/,
    //   email: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})$/,
    //   pin: /^(\d{4}|\d{6})$/,
    // }
    // if (event.target.name === "firstName") {
    //   if (!regex.name.test(event.target.value)) {
    //     setItems({ ...items, ['errorfirstName']: "Please Enter a Valid First Name" });
    //     // values.errorfirstname = "Please Enter a Valid First Name";
    //   } else {
    //     setItems({ ...items, ['errorfirstName']: " " });
    //   }
    // }

    // // if (event.target.name === "middleName") {
    // //     if (!regex.name.test(event.target.value)) {
    // //         setValues({ ...values, ['errormiddleName']: "Please Enter a Valid Middle Name" });
    // //     } else {
    // //         setValues({ ...values, ['errormiddleName']: " " });
    // //     }
    // // }

    // if (event.target.name === "lastName") {
    //   if (!regex.name.test(event.target.value)) {
    //     setItems({ ...items, ['errorlastName']: "Please Enter a Valid Last Name" });
    //   } else {
    //     setItems({ ...items, ['errorlastName']: " " });
    //   }
    // }

    // if (event.target.name === "pin") {
    //   if (!regex.pin.test(event.target.value)) {
    //     setItems({ ...items, ['errorpin']: "Please Enter a Valid Pin Code" });
    //   } else {
    //     setItems({ ...items, ['errorpin']: " " });
    //   }
    // }

    // if (event.target.name === "phone") {
    //   if (!regex.phone.test(event.target.value)) {
    //     setItems({ ...items, ['errorphone']: "Please Enter a Valid Phone Number" });
    //   } else {
    //     setItems({ ...items, ['errorphone']: " " });
    //   }
    // }

    // if (event.target.name === "email") {
    //   if (!regex.email.test(event.target.value)) {
    //     setItems({ ...items, ['erroremail']: "Please Enter a Valid Email Id" });
    //   } else {
    //     setItems({ ...items, ['erroremail']: " " });
    //   }
    // }
  };

  const [members, setMembers] = React.useState({
    isvalid: false,
    relationType: "",
    relationship: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    email: "",
    support_person_dob: "",
    streetAddress: "",
    streetName: "",
    city: "",
    state: "",
    support_person_fname: "",
    support_person_mname: "",
    support_person_lname: "",
    support_person_streetaddress: "",
    support_person_streetname: "",
    support_person_city: "",
    support_person_state: "",
    support_person_pin: "",
    disability: "",
    isndisservice: "",
    ndisNumber: "",
    isndisdocument: "",
    ndisfile: "",
    clientManager: "",
    valueCareservices: [],
    validate: "",
    living: "",
    grouphome: "",

    errorliving: "",
    errorgrouphome: "",
    errorrelationship: "",
    errorfirstName: "",
    errormiddleName: "",
    errorlastname: "",
    errorServices: "",
    errorphone: "",
    erroremail: "",
    errorsupport_person_dob: "",
    errorstreetAddress: "",
    errorstreetName: "",
    errorcity: "",
    errorstate: "",
    errorsupport_person_fname: "",
    errorsupport_person_mname: "",
    errorsupport_person_lname: "",
    errorsupport_person_streetaddress: "",
    errorsupport_person_streetname: "",
    errorsupport_person_city: "",
    errorsupport_person_state: "",
    errorsupport_person_pin: "",
    errordisability: "",
    errorndisService: "0",
    errorndisNumber: "",
    errorisndisdocument: "",
    errorclientManager: "",
  });
  const memberservices = [
    { label: "Early Childhood Services" },
    { label: "Foster Care Services" },
    { label: "Therapeutic Services for Children" },
    { label: "Daily Living & Life Skills" },
    { label: "Daily Persional Activities" },
  ];

  const onTagChange = (event, value) => {
    const servi = value;
    console.log("--------", servi);
    setMembers({ ...members, valueCareservices: servi });
    console.log("---------------", value);
  };
  const memberhandleChange = (event) => {
    setMembers({ ...members, [event.target.name]: event.target.value });
    console.log("======;;", event.target);
  };

  const Services = [
    { label: "Early Childhood Services" },
    { label: "Foster Care Services" },
    { label: "Therapeutic Services for Children" },
    { label: "Daily Living & Life Skills" },
    { label: "Daily Persional Activities" },
  ];

  const onTabChange = (event, value) => {
    const servi = value;
    console.log("--------", servi);
    setContractors({ ...contractors, services: servi });
    console.log("---------------", contractors);
  };

  const [contractors, setContractors] = React.useState({
    isvalid: false,
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    email: "",
    registringAs: "",
    organisationName: "",
    organisationstreetAddress: "",
    organisationstreetName: "",
    organisationcity: "",
    organisationstate: "",
    organisationpin: "",
    organisationphone: "",
    organisationemail: "",
    organisationwebsite: "",
    organisationABNnumber: "",
    listofServices: "",
    ndisRegistered: "0",
    ndisNumber: "",
    services: [],
    marketactivities: "0",
    validate: "",

    errorfirstName: "",
    errormiddleName: "",
    errorlastName: "",
    errorphone: "",
    erroremail: "",
    errororganisationName: "",
    errororganisationstreetAddress: "",
    errororganisationstreetName: "",
    errororganisationcity: "",
    errororganisationstate: "",
    errororganisationphone: "",
    errororganisationemail: "",
    errororganisationpin: "",
    errororganisationwebsite: "",
    errororganisationABNnumber: "",
    errorlistofServices: "",
    errorndisNumber: "",
  });

  const contractorhandleChange = (event) => {
    setContractors({ ...contractors, [event.target.name]: event.target.value });
    console.log("======", contractors);
  };

  const [coordinators, setCoordinators] = React.useState({
    isvalid: false,
    firstName: "",
    middleName: "",
    lastName: "",
    organisationName: "",
    streetAddress: "",
    streetName: "",
    city: "",
    state: "",
    phone: "",
    email: "",
    pin: "",
    website: "",
    ABNnumber: "",
    ndisService: "0",
    ndisNumber: "",
    marketactivities: "0",
    validate: "",

    errorfirstName: "",
    errormiddleName: "",
    errorlastName: "",
    errororganisationName: "",
    errorstreetAddress: "",
    errorstreetName: "",
    errorcity: "",
    errorstate: "",
    errorphone: "",
    erroremail: "",
    errorpin: "",
    errorwebsite: "",
    errorABNnumber: "",
    errorndisNumber: "",
  });

  const coordinatorhandleChange = (event) => {
    setCoordinators({ ...coordinators, [event.target.name]: event.target.value });
    console.log("======", coordinators);
  };

  const [login, setLogin] = React.useState({
    formId: "",
    formType: "",
    isvalid: false,
    username: "",
    password: "",
    confirmpassword: "",
    formType: "",
    errorfirstname: "",
    errormiddlename: "",
    errorlastName: "",

    validate: "",
  });

  return (
    <div className="signup-page-outer">
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pb={3}>
          <Card className="mt-15">
            <CardContent>
              <Grid container justify="center" spacing={3}>
                <Grid item className="signup-page-outer2">
                  <div className="container">
                    <div className="reg_step" id="regStep1" style={{ display: "none" }}>
                      <Grid item md="12">
                        <h2 className="h2-heading">
                          I am a differently-abled person or in need of supports seeking Value
                          Care’s services.
                        </h2>
                      </Grid>
                      <div className="reg_form">
                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                maxLength={2}
                                error={values["errorfirstname"]}
                                id="outlined-basic"
                                color="primary"
                                name="firstName"
                                value={values.firstName}
                                label="First Name"
                                variant="outlined"
                                helperText={values["errorfirstname"]}
                                onChange={(e) => handleChange(e)}
                                onBlur={handleChange}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                error={values["errormiddlename"]}
                                id="outlined-basic"
                                label="Middle Name"
                                value={values.middleName}
                                name="middleName"
                                variant="outlined"
                                helperText={values["errormiddlename"]}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                error={values.errorlastName}
                                id="outlined-basic"
                                label="Last Name"
                                value={values.lastName}
                                name="lastName"
                                variant="outlined"
                                helperText={values.errorlastName}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <div className="reg_input">
                              <div className="datepicker-wrap">
                                <MDDatePicker
                                  value={values.DOB}
                                  onChange={handledateChange}
                                  // md-min-date={birthDate}

                                  md-max-date={new Date()}
                                  input={{ placeholder: "Select Date of Birth" }}
                                  required={true}
                                />
                                <span
                                  id="errorDate"
                                  className="errorTC"
                                  style={{ display: "none" }}
                                >
                                  Please select Date of Birth{" "}
                                </span>
                              </div>

                              {/* <TextField
                                error={values.errordob}
                                value={values.DOB}
                                id="DOB"
                                label="Date of Birth"
                                name="DOB"
                                variant="outlined"
                                helperText={values.errordob}
                                onChange={(e) => handleChange(e)}
                              /> */}
                            </div>
                          </Grid>

                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={values.phone}
                                error={values.errorphone}
                                label="Phone"
                                id="phone"
                                name="phone"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                helperText={values.errorphone}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                error={values.errorrelativeemail}
                                value={values.email}
                                label="Email"
                                id="email"
                                name="email"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                helperText={values.errorrelativeemail}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <div className="reg_input check_value mb-0">
                              <InputLabel>Are you Living in a Group Home? </InputLabel>
                              <RadioGroup
                                aria-label="Living"
                                name="living"
                                row="true"
                                value={values.living}
                              >
                                <FormControlLabel
                                  value="1"
                                  control={<Radio />}
                                  label="Yes"
                                  onChange={(e) => handleChange(e)}
                                />
                                <FormControlLabel
                                  value="0"
                                  control={<Radio />}
                                  label="No"
                                  onChange={(e) => handleChange(e)}
                                />
                              </RadioGroup>
                            </div>
                            {values.living === "1" && (
                              <div className="reg_input sec_level">
                                <TextField
                                  value={values.grouphome}
                                  error={values.errorgrouphome}
                                  label="Group Home Name"
                                  id="grouphome"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  name="grouphome"
                                  helperText={values.errorgrouphome}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                            )}
                          </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item md="12">
                            <InputLabel className="reg_lable">Address</InputLabel>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={values.streetAddress}
                                error={values.errorstreetAddress}
                                id="streetAddress"
                                label="street Address"
                                name="streetAddress"
                                variant="outlined"
                                helperText={values.errorstreetAddress}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={values.streetName}
                                error={values.errorstreetName}
                                label="Street Name"
                                id="streetName"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                name="streetName"
                                helperText={values.errorstreetName}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={values.suburb}
                                error={values.errorsuburb}
                                label="Suburb"
                                id="suburb"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                name="suburb"
                                helperText={values.errorsuburb}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <Autocomplete
                              value={values.state}
                              id="combo-box-demo"
                              options={statelist ? statelist : ""}
                              // value={selectedState ? selectedState : ""}
                              onChange={(e, value) => {
                                setSelectedState(value.label);
                                setValues((prev) => ({ ...prev, state: value.id }));
                              }}
                              renderInput={(params) => (
                                <MDInput
                                  {...params}
                                  variant="standard"
                                  label="State"
                                  name="state"
                                  error={values.errorstate}
                                  helperText={values.errorstate}
                                  onChange={(e) => console.log("helll off", e.target.value)}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md="2">
                            <div className="reg_input">
                              <TextField
                                value={values.pin}
                                error={values.errorpin}
                                label="PIN"
                                id="pin"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                name="pin"
                                helperText={values.errorpin}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <div className="reg_input check_value mb-0">
                              <InputLabel>Are you registered with NDIS? </InputLabel>
                              <RadioGroup
                                aria-label="NDIS"
                                name="NDIS"
                                row="true"
                                value={values.NDIS}
                              >
                                <FormControlLabel
                                  value="1"
                                  control={<Radio />}
                                  label="Yes"
                                  onChange={(e) => handleChange(e)}
                                />
                                <FormControlLabel
                                  value="0"
                                  control={<Radio />}
                                  label="No"
                                  onChange={(e) => handleChange(e)}
                                />
                              </RadioGroup>
                            </div>
                            {values.NDIS === "1" && (
                              <div className="reg_input sec_level">
                                <TextField
                                  value={values.ndisnumber}
                                  error={values.errorndisnumber}
                                  label="What is your NDIS number?"
                                  id="ndisnumber"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  name="ndisnumber"
                                  helperText={values.errorndisnumber}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                            )}
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input check_value mb-0">
                              <InputLabel>
                                Do you have your NDIS Plan document available?
                              </InputLabel>
                              <RadioGroup
                                aria-label="ndisdocument"
                                name="ndisdocument"
                                row="true"
                                value={values.ndisdocument}
                              >
                                <FormControlLabel
                                  value="1"
                                  control={<Radio />}
                                  label="Yes"
                                  onChange={(e) => handleChange(e)}
                                />
                                <FormControlLabel
                                  value="0"
                                  control={<Radio />}
                                  label="No"
                                  onChange={(e) => handleChange(e)}
                                />
                              </RadioGroup>
                            </div>
                            {values.ndisdocument === "1" && (
                              <div className="reg_input reg_upload sec_level">
                                <input
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  value={values.file}
                                  error={values.errorfile}
                                  id="file"
                                  name="file"
                                  helperText={values.errorfile}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="12">
                            <InputLabel className="reg_lable">
                              Your Support Coordinator details
                            </InputLabel>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={values.coordinatorName}
                                error={values.errorcoordinatortName}
                                label="Name"
                                id="coordinatorName"
                                name="coordinatorName"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                helperText={values.errorcoordinatortName}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={values.coordinatorphone}
                                error={values.errorcoordinatorphone}
                                label="Phone"
                                id="coordinatorphone"
                                name="coordinatorphone"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                helperText={values.errorcoordinatorphone}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={values.coordinatoremail}
                                error={values.errorcoordinatoremail}
                                label="Email"
                                id="coordinatoremail"
                                name="coordinatoremail"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                helperText={values.errorcoordinatoremail}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="12">
                            <div className="reg_box_bordered">
                              <Grid container spacing={3}>
                                <Grid item md="12">
                                  <InputLabel className="reg_lable">
                                    Your primary carer/parent/relative’s details
                                  </InputLabel>
                                </Grid>
                                <Grid item md="4">
                                  <div className="reg_input">
                                    <TextField
                                      value={values.relativename}
                                      error={values.errorrelativename}
                                      label="Name"
                                      id="relativename"
                                      name="relativename"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      helperText={values.errorrelativename}
                                      onChange={(e) => handleChange(e)}
                                    />
                                  </div>
                                </Grid>
                                <Grid item md="4">
                                  <div className="reg_input">
                                    <TextField
                                      value={values.relativephone}
                                      error={values.errorrelativephone}
                                      label="Phone"
                                      id="relativephone"
                                      name="relativephone"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      helperText={values.errorrelativephone}
                                      onChange={(e) => handleChange(e)}
                                    />
                                  </div>
                                </Grid>
                                <Grid item md="4">
                                  <div className="reg_input">
                                    <TextField
                                      value={values.relativeemail}
                                      error={values.errorrelativeemail}
                                      label="Email"
                                      id="relativeemail"
                                      name="relativeemail"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      helperText={values.errorrelativeemail}
                                      onChange={(e) => handleChange(e)}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container spacing={3}>
                                <Grid item md="12">
                                  <InputLabel className="reg_lable">Address</InputLabel>
                                </Grid>
                                <Grid item md="4">
                                  <div className="reg_input">
                                    <TextField
                                      value={values.relativestreetAddress}
                                      error={values.errorrelativestreetAddress}
                                      label="Unit/Street Address"
                                      id="relativestreetAddress"
                                      name="relativestreetAddress"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      helperText={values.errorrelativestreetAddress}
                                      onChange={(e) => handleChange(e)}
                                    />
                                  </div>
                                </Grid>
                                <Grid item md="4">
                                  <div className="reg_input">
                                    <TextField
                                      value={values.relativestreetName}
                                      error={values.errorrelativestreetName}
                                      label="Street Num/Street Name"
                                      id="relativestreetName"
                                      name="relativestreetName"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      helperText={values.errorrelativestreetName}
                                      onChange={(e) => handleChange(e)}
                                    />
                                  </div>
                                </Grid>
                                <Grid item md="4">
                                  <div className="reg_input">
                                    <TextField
                                      value={values.relativesuburb}
                                      error={values.errorrelativesuburb}
                                      label="Suburb"
                                      id="relativesuburb"
                                      name="relativesuburb"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      helperText={values.errorrelativesuburb}
                                      onChange={(e) => handleChange(e)}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container spacing={3}>
                                <Grid item md="4">
                                  <Autocomplete
                                    value={values.relativestate}
                                    id="relativestate"
                                    options={statelist ? statelist : ""}
                                    // value={selectedrelativeState ? selectedrelativeState : ""}
                                    onChange={(e, value) => {
                                      setSelectedrelativeState(value.label);
                                      setValues((prev) => ({ ...prev, relativestate: value.id }));
                                    }}
                                    renderInput={(params) => (
                                      <MDInput
                                        {...params}
                                        variant="standard"
                                        label="State"
                                        name="relativestate"
                                        error={values.errorrelativestate}
                                        helperText={values.errorrelativestate}
                                        onChange={(e) => console.log("helll off", e.target.value)}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item sm={2} md={2}>
                                  <div className="reg_input">
                                    <TextField
                                      value={values.relativepin}
                                      error={values.errorrelativepin}
                                      label="PIN"
                                      id="relativepin"
                                      name="relativepin"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      helperText={values.errorrelativepin}
                                      onChange={(e) => handleChange(e)}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="12">
                            <div className="reg_input check_value mb-0">
                              <InputLabel>
                                Do you know which services you need or shall we arrange an
                                Appointment with one of our Client Manager?
                              </InputLabel>
                              <RadioGroup
                                aria-label="appointment"
                                name="appointment"
                                row="true"
                                value={values.appointment}
                              >
                                <FormControlLabel
                                  value="1"
                                  control={<Radio />}
                                  label="Yes"
                                  onChange={(e) => handleChange(e)}
                                />
                                <FormControlLabel
                                  value="0"
                                  control={<Radio />}
                                  label="No"
                                  onChange={(e) => handleChange(e)}
                                />
                              </RadioGroup>
                            </div>
                            <Grid item sm={6} md={6}>
                              {values.appointment === "1" && (
                                <div className="reg_input sec_level">
                                  <Autocomplete
                                    multiple
                                    //id="tags-outlined"
                                    options={services}
                                    // getOptionSelected={option => option.label}
                                    onChange={onTagsChange}
                                    filterSelectedOptions
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="What services are you registered for.?"
                                        placeholder="Favorites"
                                        // defaultValue={values.services ? values.services : []}
                                        value={values.services}
                                        id="services"
                                        name="services"
                                        // defaultValue={Params}
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* <Grid container spacing={3}>
                          <Grid item>
                            <div className="reg_input check_value">
                              <FormControlLabel
                                control={<Checkbox name="checkedB" color="primary" />}
                                label="By registering you agree to Value Care’s Terms &amp; Conditions"
                              />
                            </div>
                          </Grid>
                        </Grid> */}
                        <Grid container spacing={3}>
                          <Grid item>
                            <div className="reg_submit">
                              <MDButton
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit(1)}
                              >
                                Update
                              </MDButton>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>

                    <div className="reg_step" id="regStep2" style={{ display: "none" }}>
                      <Grid item md="12">
                        <h2 className="h2-heading">
                          I am a family member/principal carer/relative of a person with a <br />
                          disability or in need of supports.
                        </h2>
                      </Grid>
                      <div className="reg_form">
                        <div className="reg_input check_value mb-0">
                          <InputLabel className="reg_lable mt-15">Are you a</InputLabel>
                          <RadioGroup
                            aria-label="relationType"
                            name="relationType"
                            row="true"
                            value={members.relationType}
                          >
                            <FormControlLabel
                              value="PrimaryCarer"
                              onChange={(e) => memberhandleChange(e)}
                              control={<Radio />}
                              label="Primary Carer"
                            />
                            <FormControlLabel
                              value="FamilyMember"
                              onChange={(e) => memberhandleChange(e)}
                              control={<Radio />}
                              label="Family Member"
                            />
                            <FormControlLabel
                              value="Relative"
                              onChange={(e) => memberhandleChange(e)}
                              control={<Radio />}
                              label="Relative"
                            />
                            <FormControlLabel
                              value="Others"
                              onChange={(e) => memberhandleChange(e)}
                              control={<Radio />}
                              label="Others"
                            />
                            <small className="check-hint">(tick all relevant boxes)</small>
                          </RadioGroup>
                        </div>
                        <div className="divider"> </div>
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="12">
                            <InputLabel mt={{ xs: -20, lg: -18 }} className="reg_lable mb-20 mt-15">
                              If related, what is your relationship with the person needing supports
                              or services?{" "}
                            </InputLabel>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                error={members.errorrelationship}
                                name="relationship"
                                id="outlined-basic"
                                color="primary"
                                value={members.relationship}
                                label="Enter relationship"
                                variant="outlined"
                                onChange={(e) => memberhandleChange(e)}
                                helperText={members.errorrelationship}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={members.firstName}
                                error={members.errorfirstName}
                                name="firstName"
                                id="outlined-basic"
                                color="primary"
                                label="First Name"
                                variant="outlined"
                                onChange={(e) => memberhandleChange(e)}
                                helperText={members.errorfirstName}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={members.middleName}
                                error={members.errormiddleName}
                                name="middleName"
                                id="outlined-basic"
                                label="Middle Name"
                                variant="outlined"
                                onChange={(e) => memberhandleChange(e)}
                                helperText={members.errormiddleName}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={members.lastName}
                                error={members.errorlastname}
                                name="lastName"
                                id="outlined-basic"
                                color="primary"
                                label="Last Name"
                                variant="outlined"
                                onChange={(e) => memberhandleChange(e)}
                                helperText={members.errorlastname}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={members.phone}
                                error={members.errorphone}
                                name="phone"
                                id="outlined-basic"
                                color="primary"
                                onChange={(e) => memberhandleChange(e)}
                                label="Mobile"
                                variant="outlined"
                                helperText={members.errorphone}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={members.email}
                                error={members.erroremail}
                                id="outlined-basic"
                                name="email"
                                onChange={(e) => memberhandleChange(e)}
                                label="Email"
                                variant="outlined"
                                helperText={members.erroremail}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="12">
                            <InputLabel className="reg_lable mb-20 mt-15">Address</InputLabel>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={members.streetAddress}
                                error={members.errorstreetAddress}
                                helperText={members.errorstreetAddress}
                                name="streetAddress"
                                label="Unit/Street Address"
                                id="regFirstName"
                                onChange={(e) => memberhandleChange(e)}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={members.streetName}
                                error={members.errorstreetName}
                                helperText={members.errorstreetName}
                                name="streetName"
                                onChange={(e) => memberhandleChange(e)}
                                label="Street Name"
                                id="regFirstName"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={members.city}
                                error={members.errorcity}
                                helperText={members.errorcity}
                                name="city"
                                onChange={(e) => memberhandleChange(e)}
                                label="City"
                                id="city"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="12">
                            <div className="reg_box_bordered">
                              <Grid
                                container
                                rowSpacing={0}
                                columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                              >
                                <Grid item md="12">
                                  <InputLabel className="reg_lable mb-20">
                                    Details of the person needing supports or services
                                  </InputLabel>
                                </Grid>
                                <Grid item md="3">
                                  <div className="reg_input">
                                    <TextField
                                      value={members.support_person_fname}
                                      error={members.errorsupport_person_fname}
                                      helperText={members.errorsupport_person_fname}
                                      label="First Name"
                                      name="support_person_fname"
                                      id="support_person_fname"
                                      onChange={(e) => memberhandleChange(e)}
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item md="3">
                                  <div className="reg_input">
                                    <TextField
                                      value={members.support_person_mname}
                                      error={members.errorsupport_person_mname}
                                      helperText={members.errorsupport_person_mname}
                                      name="support_person_mname"
                                      label="Middle Name"
                                      onChange={(e) => memberhandleChange(e)}
                                      id="support_person_mname"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item md="3">
                                  <div className="reg_input">
                                    <TextField
                                      value={members.support_person_lname}
                                      error={members.errorsupport_person_lname}
                                      helperText={members.errorsupport_person_lname}
                                      label="Last Name"
                                      name="support_person_lname"
                                      id="support_person_lname"
                                      onChange={(e) => memberhandleChange(e)}
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item md="3">
                                  <div className="reg_input">
                                    <TextField
                                      value={members.support_person_dob}
                                      error={members.errorsupport_person_dob}
                                      helperText={members.errorsupport_person_dob}
                                      name="support_person_dob"
                                      onChange={(e) => memberhandleChange(e)}
                                      label="Date of Birth"
                                      id="support_person_dob"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                    />
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid container spacing={3}>
                                <Grid item md="4">
                                  <div className="reg_input check_value mb-0">
                                    <InputLabel>Are you Living in a Group Home? </InputLabel>
                                    <RadioGroup
                                      aria-label="Living"
                                      name="living"
                                      row="true"
                                      value={members.living}
                                    >
                                      <FormControlLabel
                                        value="1"
                                        control={<Radio />}
                                        label="Yes"
                                        onChange={(e) => memberhandleChange(e)}
                                      />
                                      <FormControlLabel
                                        value="0"
                                        control={<Radio />}
                                        label="No"
                                        onChange={(e) => memberhandleChange(e)}
                                      />
                                    </RadioGroup>
                                  </div>
                                  {members.living === "1" && (
                                    <div className="reg_input sec_level">
                                      <TextField
                                        error={members.errorgrouphome}
                                        label="Group Home Name"
                                        id="grouphome"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        value={members.grouphome}
                                        name="grouphome"
                                        helperText={members.errorgrouphome}
                                        onChange={(e) => memberhandleChange(e)}
                                      />
                                    </div>
                                  )}
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                rowSpacing={0}
                                columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                              >
                                <Grid item md="12">
                                  <InputLabel className="reg_lable mb-20 mt-15">
                                    Current Address
                                  </InputLabel>
                                </Grid>
                                <Grid item md="4">
                                  <div className="reg_input">
                                    <TextField
                                      value={members.support_person_streetaddress}
                                      error={members.errorsupport_person_streetaddress}
                                      helperText={members.errorsupport_person_streetaddress}
                                      name="support_person_streetaddress"
                                      onChange={(e) => memberhandleChange(e)}
                                      label="Unit/Street Address"
                                      id="support_person_streetaddress"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item md="4">
                                  <div className="reg_input">
                                    <TextField
                                      value={members.support_person_streetname}
                                      error={members.errorsupport_person_streetname}
                                      helperText={members.errorsupport_person_streetname}
                                      name="support_person_streetname"
                                      onChange={(e) => memberhandleChange(e)}
                                      label="Street Name"
                                      id="support_person_streetname"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item md="4">
                                  <div className="reg_input">
                                    <TextField
                                      value={members.support_person_city}
                                      error={members.errorsupport_person_city}
                                      helperText={members.errorsupport_person_city}
                                      name="support_person_city"
                                      onChange={(e) => memberhandleChange(e)}
                                      label="Suburb"
                                      id="support_person_city"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container spacing={3}>
                                <Grid item md="4">
                                  <Autocomplete
                                    onChange={onState3}
                                    value={members.support_person_state}
                                    name="support_person_state"
                                    id="support_person_state"
                                    options={[
                                      "Gujrat",
                                      "Punjab",
                                      "Rajsthan",
                                      "Delhi",
                                      "Uttarakhand",
                                      "Maharastra",
                                    ]}
                                    renderInput={(params) => (
                                      <MDInput
                                        {...params}
                                        variant="standard"
                                        label="State"
                                        name="support_person_state"
                                        error={members.errorsupport_person_state}
                                        helperText={members.errorsupport_person_state}
                                        onChange={(e) => console.log("helll off", e.target.value)}
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item sm={2} md={2}>
                                  <div className="reg_input">
                                    <TextField
                                      value={members.support_person_pin}
                                      error={members.errorsupport_person_pin}
                                      helperText={members.errorsupport_person_pin}
                                      name="support_person_pin"
                                      label="PIN"
                                      onChange={(e) => memberhandleChange(e)}
                                      id="support_person_pin"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="12">
                            <InputLabel className="reg_lable mt-15 mb-20">
                              What impairments or disabilities does the person has?
                            </InputLabel>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={members.disability}
                                error={members.errordisability}
                                helperText={members.errordisability}
                                name="disability"
                                id="regFirstName"
                                onChange={(e) => memberhandleChange(e)}
                                label="Enter impairments or disabilities"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={3}
                          rowSpacing={0}
                          columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                        >
                          <Grid item md="6">
                            <div className="reg_input check_value mb-0">
                              <InputLabel className="reg_lable mt-15">
                                Is the person needing services registered with NDIS?
                              </InputLabel>
                              <RadioGroup
                                aria-label="isndisservice"
                                name="isndisservice"
                                row="true"
                                value={members.isndisservice}
                              >
                                <FormControlLabel
                                  value="1"
                                  onChange={(e) => memberhandleChange(e)}
                                  name="isndisservice"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="0"
                                  onChange={(e) => memberhandleChange(e)}
                                  name="isndisservice"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </div>

                            <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                              <Grid item md="6">
                                {members.isndisservice === "1" && (
                                  <div className="reg_input sec_level">
                                    <TextField
                                      error={members.errorndisNumber}
                                      helperText={members.errorndisNumber}
                                      label="What is your NDIS number?"
                                      onChange={(e) => memberhandleChange(e)}
                                      name="ndisNumber"
                                      value={members.ndisNumber}
                                      id="ndisNumber"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                    />
                                  </div>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item md="6">
                            <div className="reg_input check_value mb-0">
                              <InputLabel className="reg_lable mt-15">
                                Do you have your NDIS Plan document available?
                              </InputLabel>
                              <RadioGroup
                                aria-label="isndisdocument"
                                name="isndisdocument"
                                row="true"
                                value={members.isndisdocument}
                              >
                                <FormControlLabel
                                  value="1"
                                  onChange={(e) => memberhandleChange(e)}
                                  control={<Radio />}
                                  label="Yes"
                                  name="isndisdocument"
                                />
                                <FormControlLabel
                                  value="0"
                                  control={<Radio />}
                                  onChange={(e) => memberhandleChange(e)}
                                  label="No"
                                  name="isndisdocument"
                                />
                              </RadioGroup>
                            </div>
                            {members.isndisdocument === "1" && (
                              <div className="reg_input reg_upload sec_level">
                                <input
                                  type="file"
                                  name="ndisfile"
                                  id="ndisfile"
                                  onChange={(e) => memberhandleChange(e)}
                                />
                              </div>
                            )}
                          </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item md="12">
                            <div className="reg_input check_value mb-0">
                              <InputLabel className="reg_lable mt-15">
                                Do you know which services you need or shall we arrange an
                                Appointment with one of our Client Manager?
                              </InputLabel>
                              <RadioGroup
                                aria-label="clientManager"
                                name="clientManager"
                                row="true"
                                value={members.clientManager}
                              >
                                <FormControlLabel
                                  name="clientManager"
                                  value="1"
                                  control={<Radio />}
                                  label="Yes, arrange a call back"
                                  onChange={(e) => memberhandleChange(e)}
                                />
                                <FormControlLabel
                                  name="clientManager"
                                  value="0"
                                  control={<Radio />}
                                  label="list of services I need"
                                  onChange={(e) => memberhandleChange(e)}
                                />
                              </RadioGroup>
                            </div>
                          </Grid>
                          <Grid item md="9">
                            <div className="reg_input sec_level">
                              <Autocomplete
                                multiple
                                //id="tags-outlined"
                                options={memberservices}
                                // getOptionLabel={option => option.label}
                                onChange={onTagChange}
                                filterSelectedOptions
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="What services are you registered for?"
                                    placeholder="Favorites"
                                    // error={members.errorServices}
                                    // helperText={members.errorServices}
                                    value={members.valueCareservices}
                                    id="valueCareservices"
                                    name="valueCareservices"
                                    // defaultValue={Params}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        {/* <Grid container spacing={3}>
                          <Grid item>
                            <div className="reg_input check_value">
                              <FormControlLabel
                                control={<Checkbox name="checkedB" color="primary" />}
                                label="By registering you agree to Value Care’s Terms &amp; Conditions"
                              />
                            </div>
                          </Grid>
                        </Grid> */}
                        <Grid container spacing={3}>
                          <Grid item>
                            <div className="reg_submit">
                              <MDButton
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit(2)}
                              >
                                Update
                              </MDButton>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>

                    <div className="reg_step" id="regStep3" style={{ display: "none" }}>
                      <Grid item md="12">
                        <h2 className="h2-heading">
                          I am a support coordinator an organisation referring a client to Value
                          Care.
                        </h2>
                      </Grid>
                      <div className="reg_form">
                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={coordinators.firstName}
                                onChange={(e) => coordinatorhandleChange(e)}
                                error={coordinators.errorfirstName}
                                helperText={coordinators.errorfirstName}
                                name="firstName"
                                id="outlined-basic"
                                color="primary"
                                label="First Name"
                                variant="outlined"
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                id="outlined-basic"
                                value={coordinators.middleName}
                                onChange={(e) => coordinatorhandleChange(e)}
                                error={coordinators.errormiddleName}
                                helperText={coordinators.errormiddleName}
                                name="middleName"
                                label="Middle Name"
                                variant="outlined"
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={coordinators.lastName}
                                onChange={(e) => coordinatorhandleChange(e)}
                                name="lastName"
                                error={coordinators.errorlastName}
                                helperText={coordinators.errorlastName}
                                label="Last Name"
                                id="regLastName"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <div className="divider"> </div>
                        <Grid container spacing={3}>
                          <Grid item md="12">
                            <InputLabel className="reg_lable">Organisation’s details</InputLabel>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={coordinators.organisationName}
                                onChange={(e) => coordinatorhandleChange(e)}
                                name="organisationName"
                                error={coordinators.errororganisationName}
                                helperText={coordinators.errororganisationName}
                                id="outlined-basic"
                                color="primary"
                                label="Name"
                                variant="outlined"
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="12">
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                              <Grid item md="12">
                                <InputLabel className="reg_lable mb-20 mt-15">
                                  Current Address
                                </InputLabel>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={coordinators.streetAddress}
                                    onChange={(e) => coordinatorhandleChange(e)}
                                    name="streetAddress"
                                    error={coordinators.errorstreetAddress}
                                    helperText={coordinators.errorstreetAddress}
                                    label="Unit/Street Address"
                                    id="regFirstName"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={coordinators.streetName}
                                    onChange={(e) => coordinatorhandleChange(e)}
                                    name="streetName"
                                    error={coordinators.errorstreetName}
                                    helperText={coordinators.errorstreetName}
                                    label="Street Name"
                                    id="regFirstName"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={coordinators.city}
                                    onChange={(e) => coordinatorhandleChange(e)}
                                    name="city"
                                    error={coordinators.errorcity}
                                    helperText={coordinators.errorcity}
                                    label="city"
                                    id="regFirstName"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item md="4">
                                <Autocomplete
                                  value={coordinators.state}
                                  onChange={onState4}
                                  name="state"
                                  id="state"
                                  options={[
                                    "Gujrat",
                                    "Punjab",
                                    "Rajsthan",
                                    "Delhi",
                                    "Uttarakhand",
                                    "Maharastra",
                                  ]}
                                  renderInput={(params) => (
                                    <MDInput
                                      {...params}
                                      variant="standard"
                                      label="State"
                                      name="state"
                                      error={coordinators.errorstate}
                                      helperText={coordinators.errorstate}
                                      onChange={(e) => console.log("helll off", e.target.value)}
                                    />
                                  )}
                                />
                              </Grid>

                              {/* <Grid item sm={2} md={2}>
                                <div className="reg_input reg_select">
                                  <FormControl
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  >
                                    <InputLabel htmlFor="selectfilter">State</InputLabel>
                                    <Select
                                      value={coordinators.state}
                                      onChange={(e) => coordinatorhandleChange(e)}
                                      inputProps={{
                                        name: "state",
                                        id: "state",
                                      }}
                                    // onChange={(event) => this.handledropdown(event, ServiceID)}
                                    >
                                      <MenuItem value="State 1">Punjab</MenuItem>
                                      <MenuItem value="State 2">Delhi</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </Grid> */}
                              <Grid item sm={2} md={2}>
                                <div className="reg_input">
                                  <TextField
                                    value={coordinators.pin}
                                    onChange={(e) => coordinatorhandleChange(e)}
                                    name="pin"
                                    error={coordinators.errorpin}
                                    helperText={coordinators.errorpin}
                                    label="PIN"
                                    id="regFirstName"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={coordinators.phone}
                                    onChange={(e) => coordinatorhandleChange(e)}
                                    name="phone"
                                    error={coordinators.errorphone}
                                    helperText={coordinators.errorphone}
                                    label="Phone"
                                    id="regFirstName"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={coordinators.email}
                                    onChange={(e) => coordinatorhandleChange(e)}
                                    name="email"
                                    error={coordinators.erroremail}
                                    helperText={coordinators.erroremail}
                                    label="Email"
                                    id="regFirstName"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={coordinators.website}
                                    onChange={(e) => coordinatorhandleChange(e)}
                                    name="website"
                                    error={coordinators.errorwebsite}
                                    helperText={coordinators.errorwebsite}
                                    label="Website"
                                    id="regFirstName"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={coordinators.ABNnumber}
                                    onChange={(e) => coordinatorhandleChange(e)}
                                    name="ABNnumber"
                                    error={coordinators.errorABNnumber}
                                    helperText={coordinators.errorABNnumber}
                                    label="ABN/ACN Number"
                                    id="regFirstName"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={3}
                          rowSpacing={0}
                          columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                        >
                          <Grid item md="6">
                            <div className="reg_input check_value mb-0">
                              <InputLabel className="reg_lable mt-15">
                                Are you registered with ndis?
                              </InputLabel>
                              <RadioGroup
                                aria-label="ndisService"
                                name="ndisService"
                                row="true"
                                value={coordinators.ndisService}
                              >
                                <FormControlLabel
                                  value="1"
                                  onChange={(e) => coordinatorhandleChange(e)}
                                  name="ndisService"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="0"
                                  onChange={(e) => coordinatorhandleChange(e)}
                                  name="ndisService"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </div>
                            {coordinators.ndisService === "1" && (
                              <Grid
                                container
                                rowSpacing={0}
                                columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                              >
                                <Grid item md="6">
                                  <div className="reg_input sec_level">
                                    <TextField
                                      value={coordinators.ndisNumber}
                                      onChange={(e) => coordinatorhandleChange(e)}
                                      name="ndisNumber"
                                      error={coordinators.errorndisNumber}
                                      helperText={coordinators.errorndisNumber}
                                      label="What is your NDIS number?"
                                      id="ndisNumber"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="12">
                            <div className="reg_input check_value mb-0">
                              <InputLabel className="reg_lable mt-15">
                                Would you like to receive our newsletters, event invitations and
                                details of our market activities?
                              </InputLabel>
                              <RadioGroup
                                aria-label="marketactivities"
                                name="marketactivities"
                                row="true"
                                value={coordinators.marketactivities}
                              >
                                <FormControlLabel
                                  value="1"
                                  name="marketactivities"
                                  onChange={(e) => coordinatorhandleChange(e)}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="0"
                                  name="marketactivities"
                                  onChange={(e) => coordinatorhandleChange(e)}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </div>
                          </Grid>
                        </Grid>
                        {/* <Grid container spacing={3}>
                          <Grid item>
                            <div className="reg_input check_value">
                              <FormControlLabel
                                control={<Checkbox name="checkedB" color="primary" />}
                                label="By registering you agree to Value Care’s Terms &amp; Conditions"
                              />
                            </div>
                          </Grid>
                        </Grid> */}
                        <Grid container spacing={3}>
                          <Grid item>
                            <div className="reg_submit">
                              <MDButton
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit(3)}
                              >
                                Update
                              </MDButton>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>

                    <div className="reg_step" id="regStep4" style={{ display: "none" }}>
                      <Grid item md="12">
                        <h2 className="h2-heading">
                          I am interested in Value Care’s services, events and activities
                        </h2>
                      </Grid>
                      <div className="reg_form">
                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                id="outlined-basic"
                                value={data.firstName}
                                error={data["errorfirstName"]}
                                color="primary"
                                label="First Name"
                                // value={data['firstName']}
                                variant="outlined"
                                name="firstName"
                                helperText={data["errorfirstName"]}
                                onChange={(e) => servicehandleChange(e)}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={data.middleName}
                                id="middleName"
                                label="Middle Name"
                                variant="outlined"
                                name="middleName"
                                onChange={(e) => servicehandleChange(e)}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={data.lastName}
                                error={data["errorlastName"]}
                                label="Last Name"
                                // value={data['lastName']}
                                id="regLastName"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                name="lastName"
                                helperText={data["errorlastName"]}
                                onChange={(e) => servicehandleChange(e)}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <div className="divider"> </div>
                        <Grid container spacing={3}>
                          <Grid item md="12">
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                              <Grid item md="12">
                                <InputLabel className="reg_lable mb-20 mt-15">
                                  Current Address
                                </InputLabel>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={data.streetAddress}
                                    error={data.errorstreetAddress}
                                    label="Unit/Street Address"
                                    id="streetAddress"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    name="streetAddress"
                                    helperText={data.errorstreetAddress}
                                    onChange={(e) => servicehandleChange(e)}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={data.streetName}
                                    error={data.errorstreetName}
                                    label="Street Name"
                                    id="streetName"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    name="streetName"
                                    helperText={data.errorstreetName}
                                    onChange={(e) => servicehandleChange(e)}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={data.suburb}
                                    label="Suburb"
                                    id="suburb"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    name="suburb"
                                    onChange={(e) => servicehandleChange(e)}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item md="4">
                                <Autocomplete
                                  value={data.state}
                                  onChange={onState4}
                                  name="state"
                                  id="state"
                                  options={[
                                    "Gujrat",
                                    "Punjab",
                                    "Rajsthan",
                                    "Delhi",
                                    "Uttarakhand",
                                    "Maharastra",
                                  ]}
                                  renderInput={(params) => (
                                    <MDInput
                                      {...params}
                                      variant="standard"
                                      label="State"
                                      name="state"
                                      error={data.errorstate}
                                      helperText={data.errorstate}
                                      onChange={(e) => console.log("helll off", e.target.value)}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item sm={2} md={2}>
                                <div className="reg_input">
                                  <TextField
                                    value={data.pin}
                                    error={data["errorpin"]}
                                    label="PIN"
                                    id="pin"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    // value={data['pin']}
                                    name="pin"
                                    helperText={data["errorpin"]}
                                    onChange={(e) => servicehandleChange(e)}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={data.phone}
                                    error={data.errorphone}
                                    label="Phone"
                                    id="phone"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    // value={data['phone']}
                                    name="phone"
                                    helperText={data.errorphone}
                                    onChange={(e) => servicehandleChange(e)}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={data.email}
                                    error={data.erroremail}
                                    label="Email"
                                    id="email"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    // value={data['email']}
                                    name="email"
                                    helperText={data.erroremail}
                                    onChange={(e) => servicehandleChange(e)}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="12">
                            <div className="reg_input check_value mb-0">
                              <InputLabel className="reg_lable mt-15">
                                Would you like to receive our newsletters, event invitations and
                                details of our market activities?
                              </InputLabel>
                              <RadioGroup
                                aria-label="newsletters"
                                name="newsletters"
                                row="true"
                                value={data.newsletters}
                              >
                                <FormControlLabel
                                  value="1"
                                  name="newsletters"
                                  control={<Radio />}
                                  label="Yes"
                                  onChange={(e) => servicehandleChange(e)}
                                />
                                <FormControlLabel
                                  value="0"
                                  name="newsletters"
                                  control={<Radio />}
                                  label="No"
                                  onChange={(e) => servicehandleChange(e)}
                                />
                              </RadioGroup>
                            </div>
                          </Grid>
                        </Grid>
                        {/* <Grid container spacing={3}>
                          <Grid item>
                            <div className="reg_input check_value">
                              <FormControlLabel
                                control={<Checkbox name="checkedB" color="primary" />}
                                label="By registering you agree to Value Care’s Terms &amp; Conditions"
                              />
                            </div>
                          </Grid>
                        </Grid> */}
                        <Grid container spacing={3}>
                          <Grid item>
                            <div className="reg_submit">
                              <MDButton
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit(4)}
                              >
                                Update
                              </MDButton>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>

                    <div className="reg_step" id="regStep5" style={{ display: "none" }}>
                      <Grid item md="12">
                        <h2 className="h2-heading">
                          I am (or wanting to be) a sub-contractor or a supplier or an associate to
                          Value Care.
                        </h2>
                      </Grid>
                      <div className="reg_form">
                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                id="outlined-basic"
                                value={contractors.firstName}
                                onChange={(e) => contractorhandleChange(e)}
                                error={contractors.errorfirstName}
                                helperText={contractors.errorfirstName}
                                name="firstName"
                                color="primary"
                                label="First Name"
                                variant="outlined"
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                id="outlined-basic"
                                value={contractors.middleName}
                                onChange={(e) => contractorhandleChange(e)}
                                name="middleName"
                                error={contractors.errormiddleName}
                                helperText={contractors.errormiddleName}
                                label="Middle Name"
                                variant="outlined"
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={contractors.lastName}
                                onChange={(e) => contractorhandleChange(e)}
                                name="lastName"
                                error={contractors.errorlastName}
                                helperText={contractors.errorlastName}
                                label="Last Name"
                                id="regLastName"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                id="outlined-basic"
                                color="primary"
                                onChange={(e) => contractorhandleChange(e)}
                                name="phone"
                                value={contractors.phone}
                                error={contractors.errorphone}
                                helperText={contractors.errorphone}
                                label="Mobile"
                                variant="outlined"
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                id="outlined-basic"
                                onChange={(e) => contractorhandleChange(e)}
                                name="email"
                                value={contractors.email}
                                error={contractors.erroremail}
                                helperText={contractors.erroremail}
                                label="Email"
                                variant="outlined"
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <div className="divider"> </div>
                        <Grid container spacing={3}>
                          <Grid item md="12">
                            <div className="reg_input check_value mb-0">
                              <InputLabel className="reg_lable mt-15">
                                Are you registering as a{" "}
                              </InputLabel>
                              <RadioGroup
                                aria-label="registringAs"
                                name="registringAs"
                                id="registringAs"
                                row="true"
                                value={contractors.registringAs}
                              >
                                <FormControlLabel
                                  value="Sole Provider/Professional"
                                  onChange={(e) => contractorhandleChange(e)}
                                  control={<Radio />}
                                  label="Sole Provider/Professional "
                                />
                                <FormControlLabel
                                  value="Representing as an employee of a sub-contractor/supplier/associate of Value Care"
                                  onChange={(e) => contractorhandleChange(e)}
                                  control={<Radio />}
                                  label="Representing as an employee of a sub-contractor/supplier/associate of Value Care"
                                />
                              </RadioGroup>
                            </div>
                          </Grid>
                        </Grid>
                        <div className="divider"> </div>
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="12">
                            <InputLabel className="reg_lable mb-20 mt-15">
                              Organisation’s details
                            </InputLabel>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={contractors.organisationName}
                                id="outlined-basic"
                                onChange={(e) => contractorhandleChange(e)}
                                name="organisationName"
                                error={contractors.errororganisationName}
                                helperText={contractors.errororganisationName}
                                color="primary"
                                label="Name"
                                variant="outlined"
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="12">
                            <InputLabel className="reg_lable mb-20 mt-15">
                              Current Address
                            </InputLabel>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={contractors.organisationstreetAddress}
                                onChange={(e) => contractorhandleChange(e)}
                                name="organisationstreetAddress"
                                error={contractors.errororganisationstreetAddress}
                                helperText={contractors.errororganisationstreetAddress}
                                label="Unit/Street Address"
                                id="organisationstreetAddress"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={contractors.organisationstreetName}
                                onChange={(e) => contractorhandleChange(e)}
                                name="organisationstreetName"
                                error={contractors.errororganisationstreetName}
                                helperText={contractors.errororganisationstreetName}
                                label="Street Name"
                                id="organisationstreetName"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={contractors.organisationcity}
                                onChange={(e) => contractorhandleChange(e)}
                                name="organisationcity"
                                error={contractors.errororganisationcity}
                                helperText={contractors.errororganisationcity}
                                label="City"
                                id="organisationcity"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <Autocomplete
                              value={contractors.organisationstate}
                              onChange={onState6}
                              name="organisationstate"
                              id="organisationstate"
                              options={[
                                "Gujrat",
                                "Punjab",
                                "Rajsthan",
                                "Delhi",
                                "Uttarakhand",
                                "Maharastra",
                              ]}
                              renderInput={(params) => (
                                <MDInput
                                  {...params}
                                  variant="standard"
                                  label="State"
                                  name="organisationstate"
                                  error={contractors.errororganisationstate}
                                  helperText={contractors.errororganisationstate}
                                  onChange={(e) => console.log("helll off", e.target.value)}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item sm={2} md={2}>
                            <div className="reg_input">
                              <TextField
                                value={contractors.organisationpin}
                                onChange={(e) => contractorhandleChange(e)}
                                name="organisationpin"
                                error={contractors.errororganisationpin}
                                helperText={contractors.errororganisationpin}
                                label="PIN"
                                id="organisationpin"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={contractors.organisationphone}
                                onChange={(e) => contractorhandleChange(e)}
                                name="organisationphone"
                                error={contractors.errororganisationphone}
                                helperText={contractors.errororganisationphone}
                                label="Phone"
                                id="organisationphone"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={contractors.organisationemail}
                                onChange={(e) => contractorhandleChange(e)}
                                name="organisationemail"
                                error={contractors.errororganisationemail}
                                helperText={contractors.errororganisationemail}
                                label="Email"
                                id="organisationemail"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={contractors.organisationwebsite}
                                onChange={(e) => contractorhandleChange(e)}
                                name="organisationwebsite"
                                error={contractors.errororganisationwebsite}
                                helperText={contractors.errororganisationwebsite}
                                label="Website"
                                id="organisationwebsite"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item sm={4} md={4}>
                            <div className="reg_input">
                              <TextField
                                value={contractors.organisationABNnumber}
                                onChange={(e) => contractorhandleChange(e)}
                                name="organisationABNnumber"
                                error={contractors.errororganisationABNnumber}
                                helperText={contractors.errororganisationABNnumber}
                                label="ABN/ACN Number"
                                id="organisationABNnumber"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <div className="divider"> </div>
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="12">
                            <InputLabel className="reg_lable mt-15 mb-20">
                              What services do you or your company provide
                            </InputLabel>
                          </Grid>
                          <Grid item md="12">
                            <div className="reg_input">
                              <TextField
                                value={contractors.listofServices}
                                onChange={(e) => contractorhandleChange(e)}
                                name="listofServices"
                                error={contractors.errorlistofServices}
                                helperText={contractors.errorlistofServices}
                                id="listofServices"
                                label="List of services"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={3}
                          rowSpacing={0}
                          columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                        >
                          <Grid item md="6">
                            <div className="reg_input check_value mb-0">
                              <InputLabel className="reg_lable mt-15">
                                Are you registered with NDIS?{" "}
                              </InputLabel>
                              <RadioGroup
                                aria-label="ndisRegistered"
                                name="ndisRegistered"
                                row="true"
                                value={contractors.ndisRegistered}
                              >
                                <FormControlLabel
                                  value="1"
                                  name="ndisRegistered"
                                  onChange={(e) => contractorhandleChange(e)}
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="0"
                                  name="ndisRegistered"
                                  onChange={(e) => contractorhandleChange(e)}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </div>
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                              {contractors.ndisRegistered === "1" && (
                                <Grid item md="6">
                                  <div className="reg_input sec_level">
                                    <TextField
                                      value={contractors.ndisNumber}
                                      onChange={(e) => contractorhandleChange(e)}
                                      error={contractors.errorndisNumber}
                                      helperText={contractors.errorndisNumber}
                                      name="ndisNumber"
                                      id="ndisNumber"
                                      label="Enter NDIS Provider Number"
                                      placeholder="Enter NDIS Provider Number"
                                    />
                                  </div>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md="6">
                          <div className="reg_input sec_level">
                            <Autocomplete
                              multiple
                              //id="tags-outlined"
                              options={Services}
                              // getOptionLabel={option => option.label}
                              onChange={onTabChange}
                              filterSelectedOptions
                              formControlProps={{
                                fullWidth: true,
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="What services are you registered for?"
                                  placeholder="Favorites"
                                  value={contractors.services}
                                  id="services"
                                  name="services"
                                  // defaultValue={Params}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item md="12">
                            <div className="reg_input check_value mb-0">
                              <InputLabel className="reg_lable mt-15">
                                Would you like to receive our newsletters, event invitations and
                                details of our market activities?
                              </InputLabel>
                              <RadioGroup
                                aria-label="marketactivities"
                                name="marketactivities"
                                row="true"
                                value={contractors.marketactivities}
                              >
                                <FormControlLabel
                                  value="1"
                                  name="marketactivities"
                                  control={<Radio />}
                                  label="Yes"
                                  onChange={(e) => contractorhandleChange(e)}
                                />
                                <FormControlLabel
                                  value="0"
                                  name="marketactivities"
                                  control={<Radio />}
                                  label="No"
                                  onChange={(e) => contractorhandleChange(e)}
                                />
                              </RadioGroup>
                            </div>
                          </Grid>
                        </Grid>
                        {/* <Grid container spacing={3}>
                          <Grid item>
                            <div className="reg_input check_value">
                              <FormControlLabel
                                control={<Checkbox name="checkedB" color="primary" />}
                                label="By registering you agree to Value Care’s Terms &amp; Conditions"
                              />
                            </div>
                          </Grid>
                        </Grid> */}
                        <Grid container spacing={3}>
                          <Grid item>
                            <div className="reg_submit">
                              <MDButton
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit(5)}
                              >
                                Update
                              </MDButton>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>

                    <div className="reg_step" id="regStep6" style={{ display: "none" }}>
                      <Grid item md="12">
                        <h2 className="h2-heading">I would like to work for Value Care.</h2>
                      </Grid>
                      <div className="reg_form">
                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                id="outlined-basic"
                                value={items.firstName}
                                error={items["errorfirstName"]}
                                color="primary"
                                label="First Name"
                                variant="outlined"
                                name="firstName"
                                helperText={items["errorfirstName"]}
                                onChange={(e) => carehandleChange(e)}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={items.middleName}
                                id="outlined-basic"
                                label="Middle Name"
                                variant="outlined"
                                name="middleName"
                                onChange={(e) => carehandleChange(e)}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <TextField
                                value={items.lastName}
                                error={items["errorlastName"]}
                                label="Last Name"
                                id="lastName"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                name="lastName"
                                helperText={items["errorlastName"]}
                                onChange={(e) => carehandleChange(e)}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <div className="divider"> </div>
                        <Grid container spacing={3}>
                          <Grid item md="12">
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                              <Grid item md="12">
                                <InputLabel className="reg_lable mb-20 mt-15">
                                  Current Address
                                </InputLabel>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={items.streetAddress}
                                    error={items.errorstreetAddress}
                                    label="Unit/Street Address"
                                    id="streetAddress"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    name="streetAddress"
                                    helperText={items.errorstreetAddress}
                                    onChange={(e) => carehandleChange(e)}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={items.streetName}
                                    error={items.errorstreetName}
                                    label="Street Name"
                                    id="streetName"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    name="streetName"
                                    helperText={items.errorstreetName}
                                    onChange={(e) => carehandleChange(e)}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={items.suburb}
                                    error={items.errorsuburb}
                                    label="Suburb"
                                    id="suburb"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    name="suburb"
                                    helperText={items.errorsuburb}
                                    onChange={(e) => carehandleChange(e)}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item md="4">
                                <Autocomplete
                                  value={items.state}
                                  onChange={onState7}
                                  name="state"
                                  id="state"
                                  options={[
                                    "Gujrat",
                                    "Punjab",
                                    "Rajsthan",
                                    "Delhi",
                                    "Uttarakhand",
                                    "Maharastra",
                                  ]}
                                  renderInput={(params) => (
                                    <MDInput
                                      {...params}
                                      variant="standard"
                                      label="State"
                                      name="state"
                                      error={items.errorstate}
                                      helperText={items.errorstate}
                                      onChange={(e) => console.log("helll off", e.target.value)}
                                    />
                                  )}
                                />
                              </Grid>

                              <Grid item sm={2} md={2}>
                                <div className="reg_input">
                                  <TextField
                                    value={items.pin}
                                    error={items.errorpin}
                                    label="PIN"
                                    id="pin"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    name="pin"
                                    helperText={items.errorpin}
                                    onChange={(e) => carehandleChange(e)}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={items.phone}
                                    error={items.errorphone}
                                    label="Phone"
                                    id="phone"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    name="phone"
                                    helperText={items.errorphone}
                                    onChange={(e) => carehandleChange(e)}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={items.email}
                                    error={items.erroremail}
                                    label="Email"
                                    id="email"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    name="email"
                                    helperText={items.erroremail}
                                    onChange={(e) => carehandleChange(e)}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={items.role}
                                    id="role"
                                    error={items["errorrole"]}
                                    color="primary"
                                    label="Which role would you like to apply for?"
                                    variant="outlined"
                                    name="role"
                                    helperText={items["errorrole"]}
                                    onChange={(e) => carehandleChange(e)}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <TextField
                                    value={items.location}
                                    error={items["errorlocation"]}
                                    helperText={items["errorlocation"]}
                                    id="location"
                                    label="Which location would you like to work?"
                                    variant="outlined"
                                    name="location"
                                    onChange={(e) => carehandleChange(e)}
                                  />
                                </div>
                              </Grid>
                              <Grid item md="4">
                                <div className="reg_input">
                                  <MDInput
                                    value={items.resume}
                                    error={items["errorresume"]}
                                    label="Upload Resume"
                                    id="resume"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    name="resume"
                                    helperText={items["errorresume"]}
                                    onChange={(e) => carehandleChange(e)}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item md="12">
                            <div className="reg_input check_value mb-0">
                              <InputLabel className="reg_lable mt-15">
                                Would you like to receive our newsletters, event invitations and
                                details of our market activities?
                              </InputLabel>
                              <RadioGroup
                                aria-label="newsletters"
                                name="newsletters"
                                row="true"
                                value={items.newsletters}
                              >
                                <FormControlLabel
                                  value="Newsletter"
                                  onChange={(e) => carehandleChange(e)}
                                  control={<Radio />}
                                  label="Newsletter"
                                />
                                <FormControlLabel
                                  value="Event"
                                  onChange={(e) => carehandleChange(e)}
                                  control={<Radio />}
                                  label="Event"
                                />
                                <FormControlLabel
                                  value="Marketing Activities"
                                  onChange={(e) => carehandleChange(e)}
                                  control={<Radio />}
                                  label="Marketing Activities"
                                />
                                <small className="check-hint">
                                  (Select the one which are relevant to you)
                                </small>
                              </RadioGroup>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item>
                            <div className="reg_submit">
                              <MDButton
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit(6)}
                              >
                                Update
                              </MDButton>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    <div className="copyright">
                      <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </MDBox>
      </DashboardLayout>
    </div>
  );
}

export default EditUser;
