/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cogoToast from "cogo-toast";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
// const eye = <FontAwesomeIcon icon={faEye} />;

import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// react-router-dom components
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// import { Grid} from '@mui/material';
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Logo from "assets/img/valuecare-logo.png";
import { CommonConfig } from "constant";

import GoogleLogin from "react-google-login";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { blacklistedBrowsers } from "dropzone";

function ForgotPassword() {
  const [showAlert, setShowAlert] = useState(false);

  let input = document.querySelector("#password");
  const togglePasswordVisiblity = () => {
    if (input.type === "password") {
      input.type = "text";
      document.getElementById("HideEyePassword").style.display = "none";
      document.getElementById("ShowEyePassword").style.display = "block";
    } else {
      input.type = "password";
      document.getElementById("HideEyePassword").style.display = "block";
      document.getElementById("ShowEyePassword").style.display = "none";
    }
  };

  let navigate = useNavigate();
  const { state } = useLocation();

  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [loader, setLoader] = React.useState(false);

  const [login, setLogin] = React.useState({
    isvalid: true,
    email: "",
    password: "",

    erroremail: "",
    errorpassword: "",

    validate: "",
  });

  const loginhandleChange = (event) => {
    setLogin({ ...login, [event.target.name]: event.target.value });
    console.log("======;;", event.target);
  };

  const validateLoginData = () => {
    if (login.email === "") {
      login.erroremail = <span style="color:black">Please Enter Email Id.</span>;

      setLogin({ ...login, ["erroremail"]: "Please Enter Email Id." });
      login.isvalid = true;
    } else {
      login.erroremail = "";
      setLogin({ ...login, ["erroremail"]: "" });
      login.isvalid = false;
    }
  };

  const loginhandleSubmit = () => {
    console.log("data.....", login);
    validateLoginData();

    if (login.isvalid === false && login.erroremail == "") {
      var inputDataUser = {
        username: login.email,
      };

      axios({
        url: CommonConfig.ApiUrl + "authentication/checkUserExist",
        method: "POST",
        headers: {},
        data: inputDataUser,
      })
        .then((res) => {
          debugger;
          console.log("test.....12", res.data.data[0][0].totalUser);
          setLoader(false);
          if (res.data.data[0][0].totalUser === 0) {
            cogoToast.error("This email is not register with Value care");
          } else {
            cogoToast.error("Work in Progress");

            // axios({
            //   url: CommonConfig.ApiUrl + "authentication/SendForgotPasswordMail",
            //   method: "POST",
            //   headers: {},
            //   data: inputDataUser,
            // })
            //   .then((res) => {
            //     debugger;
            //     console.log("test.....12", res.data.data[0][0].totalUser);
            //     setLoader(false);
            //     if (res.data.data[0][0].totalUser === 0) {

            //       cogoToast.error("This email is not register with Value care");

            //     } else {

            //     }
            //   })
            //   .catch((error) => {
            //     console.log(error);
            //   });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // validate();
  };

  return (
    <div className="login-outer">
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {/* <Grid container spacing={3}>
        <Grid item md="4" sm="4"> */}
      <CoverLayout className="login-sec">
        <Card className="login-inner">
          <MDBox
            variant="gradient"
            bgColor="#fff"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={1}
            mb={1}
            textAlign="center"
          >
            <img className="login-logo" alt="Value Care" src={Logo} />
            <MDTypography variant="h4" fontWeight="medium" color="black" mt={1}>
              Forgot Password
            </MDTypography>
          </MDBox>

          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <div className="login-input forgot-password">
                  {/* <label>Email</label> */}
                  <TextField
                    error={login.erroremail}
                    helperText={login.erroremail}
                    onChange={(e) => loginhandleChange(e)}
                    name="email"
                    id="email"
                    type="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    // placeholder="john@example.com"
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </MDBox>

              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="primary"
                  fullWidth
                  onClick={() => loginhandleSubmit()}
                >
                  Forgot Password
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Don&apos;t have an account? &nbsp;
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up/Index"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign Up
                  </MDTypography>
                  {/* &nbsp; &nbsp;
                    <MDTypography
                      component={Link}
                      to="/authentication/sign-up/Indexform"
                      variant="button"
                      color="primary"
                      fontWeight="medium"
                      textGradient
                    >
                      SignUp1
                    </MDTypography> */}
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>
      {/* </Grid>
      </Grid> */}
    </div>
  );
}

export default ForgotPassword;
