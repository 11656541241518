import { useLocation, useNavigate } from "react-router-dom";
import { CommonConfig } from "constant";
import "assets/css/style.css";

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Grid from "@mui/material/Grid";
import { ArrowDownward } from "@mui/icons-material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";
import cogoToast from "cogo-toast";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CardContent from "@mui/material/CardContent";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PButton } from "primereact/button";
import { TextField } from "@mui/material";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
} from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";

function List(props) {
  let navigate = useNavigate();

  if (localStorage.getItem("userData")) {
    // alert("Hello");
  } else {
    // alert("Hello2")
    navigate("/");
  }
  let i = 1;
  const { state } = useLocation();
  const [data, setData] = useState(null);
  const [rememberMe, setRememberMe] = useState(true);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [name, setName] = useState("");
  const [cityOrState, setCityOrState] = useState("");
  const [userType, setUserType] = useState("");
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [personIdNumber, setPersonIdNumber] = useState("");
  const [stateList, setStateList] = useState(null);
  const [values, setValues] = useState([]);
  const [stateLabel, setStateLabel] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMediumType, setSelectedMediumType] = useState(false);
  const [appointmentdelID, setappointmentdelID] = useState(null);
  const [allData, setAllData] = useState(null);
  const [loader, setLoader] = React.useState(false);

  console.log("checkstatestateList", stateList);
  let loginUser;
  var selectedid;
  loginUser = JSON.parse(localStorage.getItem("userData"));

  var userTypeMessage = "";

  if (
    loginUser.PersonType == "CARER" ||
    loginUser.PersonType == "SUPPORT COORDINATOR" ||
    loginUser.PersonType == "INTERESTED VC SERVICES" ||
    loginUser.PersonType == "INTERNAL STAFF"
    // || loginUser.PersonType == "INTERESTED VC SERVICES"
  ) {
    userTypeMessage = "Participant List";
  } else {
    userTypeMessage = "User List";
  }
  console.log("Testing12 ", loginUser.PersonId);

  const columns = [
    {
      name: "Person #",
      selector: "PersonIdNumber",
      filter: true,
      sortable: true,
    },
    // {
    //   name: "Sr. Number",
    //   body: () => {
    //     return i++;
    //   },
    // },
    {
      name: "Name",

      selector: "FullName",
      filter: true,
      sortable: true,
    },
    {
      name: "Email",
      selector: "Email",
      filter: true,
      sortable: true,
    },
    {
      name: "Phone",
      selector: "PhoneNumber",
      filter: true,
      sortable: true,
    },
    // {
    //   name: "City",
    //   selector: "City",
    //   filter: true,
    //   sortable: true,
    // },
    {
      name: "State",
      selector: "State",
      filter: true,
      sortable: true,
    },
    // {
    //   name: "User Type",
    //   selector: "UserType",
    //   filter: true,
    //   sortable: true,
    // },
    {
      name: "User Type",
      selector: "UserTypeName",

      // body: (data) => {
      //   if (data.PersonType === "CARER") {
      //     return "Family Member or Care Person";
      //   } else if (data.PersonType === "DIFFERENTLY ABLED") {
      //     return "Differently-abled person";
      //   } else if (data.PersonType === "SUPPORT COORDINATOR") {
      //     return "Support Coordinator";
      //   } else if (data.PersonType === "INTERESTED VC SERVICES") {
      //     return "Services and Events";
      //   } else if (data.PersonType === "SUB CONTRACTOR") {
      //     return "Sub-Contractor";
      //   } else if (data.PersonType === "JOB SEEKER") {
      //     return "Job Seekers";
      //   } else {
      //     return "Internal Staff";
      //   }
      // },
      filter: true,
      sortable: true,
    },
    {
      name: "Action",
      body: (data) => actionTemplate(data),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const actionTemplate = (data) => {
    return (
      <div>
        <PButton
          type="button"
          icon="pi pi-user-edit"
          className="p-button-warning"
          onClick={() => editUser(data)}
          style={{ marginRight: ".5em" }}
          tooltip="Edit User"
          tooltipOptions={{ position: "bottom" }}
        />
        {data.PersonType === "SUPPORT COORDINATOR" ||
        data.PersonType === "CARER" ||
        data.PersonType === "DIFFERENTLY ABLED" ||
        data.PersonType === "INTERESTED VC SERVICES" ? (
          <PButton
            type="button"
            icon="pi pi-calendar-plus"
            className="p-button-success"
            onClick={() =>
              navigate("/pages/book-appointment/UserBookAppointmentToken", {
                state: { PersonId: data.PersonId, Name: data?.FirstName + " " + data?.LastName },
              })
            }
            // onClick={() => AddUser(data)}
            tooltip="Add Appointment"
            tooltipOptions={{ position: "bottom" }}
          />
        ) : null}
        &nbsp;
        {loginUser.PersonType === "INTERNAL STAFF" || loginUser.PersonType === "Admin" ? (
          <PButton
            type="button"
            icon="pi pi-user-minus"
            className="p-button-danger  "
            onClick={() => selectedRow(data)}
            tooltip="Remove User"
            tooltipOptions={{ position: "bottom" }}
          />
        ) : null}
      </div>
    );
  };

  const getColoumnsRow = () =>
    columns.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
        />
      );
    });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    UserType: "",
  });

  const onStateChange = (event, value) => {
    console.log("VVV", value.id);
    setValues(value.id);
    setStateLabel(value.label);

    console.log("-------bhanu--------", value);
  };

  const getUserTypeData = () => {
    const formattedData = {
      StringMapType: "USERTYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("UserTypeList", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getData = () => {
    const formattedData = {
      CurrentUser: loginUser.PersonId,
      Name: name,
      CityOrState: cityOrState,
      UserType: values.length > 0 ? values : "",
      PhoneOrEmail: phoneOrEmail,
      PersonIdNumber: personIdNumber,
    };
    setLoader(!loader);
    console.log("user", formattedData);
    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "authentication/getUserList",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          console.log("getuserlist", res);
          setLoader(false);
          setData(res.data.data[0]);
          console.log("Data1...", data);
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserTypeData();
    getData();
  }, []);

  const CancelDelete = () => {
    setSelectedMediumType("");
    setOpenDialog(false);
  };

  const selectedRow = (selectedRow = {}) => {
    debugger;
    setappointmentdelID(selectedRow.PersonId);
    selectedid = selectedRow.PersonId;
    console.log("........,new", appointmentdelID);
    setOpenDialog(true);
  };

  const deleteUser = (selectedRow = {}) => {
    debugger;
    console.log("data..", data);
    // setappointmentdelID(selectedRow.PersonId);
    // let pid = selectedRow.PersonId;
    setSelectedMediumType(false);
    //setOpenDialog(true);
    if (selectedMediumType == "") {
      document.getElementById("ServicesError").style.display = "block";
    } else {
      document.getElementById("ServicesError").style.display = "none";
      if (selectedMediumType == "Yes") {
        console.log("selectedMediumType", selectedMediumType);
        debugger;
        console.log("........,,,", selectedid);

        let inputData = {
          PersonId: selectedid ? selectedid : appointmentdelID,
          CurrentUser: CommonConfig.getCurrentUserId(),
        };

        console.log("inputData", inputData);
        axios({
          // Endpoint to send files
          url: CommonConfig.ApiUrl + "authentication/userDelete",
          method: "POST",
          headers: {},

          data: inputData,
        })
          // Handle the response from backend here
          .then((res) => {
            if (res.data?.success === true) {
              cogoToast.success("Deleted Successfully.");
              setOpenDialog(false);

              setTimeout(() => {
                getData();
              }, 1000);

              navigate("/dashboards/userprofile/Index");
            }
          })
          // Catch errors if any
          .catch((error) => {
            console.log(error);
          });
      } else if (selectedMediumType == "No") {
        setOpenDialog(false);
      }
    }
  };

  function editUser(selectedRow) {
    setLoader(!loader);
    debugger;

    console.log("........,,,", selectedRow.PersonId);
    localStorage.setItem("Formdata", JSON.stringify(selectedRow.PersonId));
    // navigate("/dashboards/userprofile/edit");
    console.log("selectedRow....", selectedRow);
    // let apiData = "";

    let formattedData = {
      PersonId: selectedRow.PersonId,
    };

    axios({
      url: CommonConfig.ApiUrl + "contact/getRegistrationFormData",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        setLoader(!loader);
        console.log("getRegistrationFormData::", res);
        setAllData(res);

        // DIFFERENTLY ABLED
        // CARER
        // SUPPORT COORDINATOR
        // INETERESTED VC SERVICES
        // SUB CONTRACTOR
        // JOB SEEKER
        // apiData = res.data.Data[0];
        setTimeout(() => {
          debugger;
          if (selectedRow.PersonType === "CARER") {
            navigate("/authentication/sign-up/FamilyMember", {
              state: { PersonId: selectedRow.PersonId, SecurityUserData: res.data.data[0] },
            });
          } else if (selectedRow.PersonType === "DIFFERENTLY ABLED") {
            if (selectedRow.PersonCategory === "PATIENT") {
              navigate("/authentication/sign-up/AddPatient", {
                state: { PersonId: selectedRow.PersonId, SecurityUserData: res.data.data[0] },
              });
            } else {
              navigate("/authentication/sign-up/SignUpDifferentlyAbled", {
                state: { PersonId: selectedRow.PersonId, SecurityUserData: res.data.data[0] },
              });
            }
          } else if (selectedRow.PersonType === "SUPPORT COORDINATOR") {
            navigate("/authentication/sign-up/SupportCordinator", {
              state: { PersonId: selectedRow.PersonId, SecurityUserData: res.data.data[0] },
            });
          } else if (selectedRow.PersonType === "INTERESTED VC SERVICES") {
            navigate("/authentication/sign-up/ValueCareServices", {
              state: { PersonId: selectedRow.PersonId, SecurityUserData: res.data.data[0] },
            });
          } else if (selectedRow.PersonType === "SUB CONTRACTOR") {
            navigate("/authentication/sign-up/SubContractor", {
              state: { PersonId: selectedRow.PersonId, SecurityUserData: res.data.data[0] },
            });
          } else if (selectedRow.PersonType === "JOB SEEKER") {
            navigate("/authentication/sign-up/WorkForValueCare", {
              state: { PersonId: selectedRow.PersonId, SecurityUserData: res.data.data[0] },
            });
          } else if (selectedRow.PersonType === "CARER") {
            navigate("/authentication/sign-up/AddPatient", {
              state: { PersonId: selectedRow.PersonId, SecurityUserData: res.data.data[0] },
            });
          } else if (selectedRow.PersonType === "INTERNAL STAFF") {
            navigate("/authentication/sign-up/EditInternalStaff", {
              state: { PersonId: selectedRow.PersonId, SecurityUserData: res.data.data[0] },
            });
          }
        }, 1000);
      })
      .catch((err) => console.log("Security User Error::::", err));
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      {/* 
      "Name": "",
  "CityOrState": "",
  "UserType":"",
  "PhoneOrEmail":"",
  "PersonIdNumber":"" */}
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              person
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            {userTypeMessage}
          </MDTypography>
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          <div className="custom-accordian">
            <Accordion>
              <AccordionSummary expandIcon={<ArrowDownward />}>Search Filters</AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item md="4">
                    <div className="reg_input">
                      <MDInput label="Name" onChange={(e) => setName(e.target.value)} />
                    </div>
                  </Grid>
                  <Grid item md="4">
                    <div className="reg_input">
                      <MDInput
                        label="City or State"
                        onChange={(e) => setCityOrState(e.target.value)}
                      />
                    </div>
                  </Grid>
                  {/* <Grid item md="4">
                    <Autocomplete
                      disablePortal
                      id="State"
                      name="State"
                      style={{
                        marginBottom: "10px",
                      }}
                      options={stateList ? stateList : ""}
                      onChange={onStateChange}
                      value=""
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...register("State")}
                          {...params}
                          label="User Type"
                          InputProps={{
                            style: {
                              height: "40px",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid> */}
                  <Grid item md="4">
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={stateList ? stateList : ""}
                      onChange={onStateChange}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="User Type" />}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md="4">
                    <div className="reg_input">
                      <MDInput
                        label="Phone or Email"
                        onChange={(e) => setPhoneOrEmail(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item md="4">
                    <div className="reg_input">
                      <MDInput
                        label="Person Id Number"
                        onChange={(e) => setPersonIdNumber(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item md="4">
                    <MDButton onClick={() => getData()} variant="contained" color="primary">
                      Search
                    </MDButton>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="card">
            <div className="table-custom">
              <DataTable
                scrollable
                value={data}
                showGridlines
                scrollHeight="600px"
                scrollWidth="600px"
                responsiveLayout="scroll"
                paginator
                rows={10}
                totalRecords={data ? data.length : 0}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[10, 20, 50]}
                emptyMessage="No records found"
                // paginatorLeft={paginatorLeft}
                // paginatorRight={paginatorRight}
                filterDisplay="row"
              >
                {getColoumnsRow()}
              </DataTable>
            </div>
          </div>
        </MDBox>
      </Card>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="xs">
        <DialogTitle>Remove User</DialogTitle>
        <DialogContent>
          <Grid item md="12">
            <div className="reg_input check_value mb-0">
              <InputLabel className="reg_lable mt-5 mb-2">
                Are You Sure to Remove this User.?
              </InputLabel>
              <div>
                <span class="servicesError" id="ServicesError">
                  Please select any one option.
                </span>
              </div>

              <RadioGroup
                aria-label="remove"
                name="Remove"
                row="true"
                onChange={(e) => setSelectedMediumType(e.target.value)}
              >
                <FormControlLabel
                  value="Yes"
                  // onClick={() => {
                  //   deleteUser();
                  // }}
                  // onChange={(e) => handleChange(e)}
                  control={<Radio checked={selectedMediumType === "Yes" ? true : false} />}
                  label="Yes"
                  name="Remove"
                />
                <FormControlLabel
                  value="No"
                  // onClick={() => setOpenDialog(false)}
                  // onChange={(e) => handleChange(e)}
                  control={<Radio checked={selectedMediumType === "No" ? true : false} />}
                  label="No"
                  name="Remove"
                />
              </RadioGroup>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton variant="contained" color="secondary" onClick={() => CancelDelete()}>
            Back
          </MDButton>
          <MDButton
            variant="contained"
            color="primary"
            onClick={() => {
              deleteUser();
            }}
          >
            Ok
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default List;
