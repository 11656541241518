import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";

import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { Button as PButton } from "primereact/button";

import { NotesComponent } from "layouts/component/Notes";

import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/valuecare-logo.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { item } from "examples/Sidenav/styles/sidenavItem";

import { isValidDate } from "@fullcalendar/react";
import { Alert } from "@mui/material";

const schema = yup.object().shape(
  {
    clientFName: yup
      .string()
      .required("First Name Required.")
      .min(3, "First Name must be at least 3 characters."),

    clientLName: yup
      .string()
      .required("Last Name Required.")
      .min(3, "Last Name must be at least 3 characters."),

    clientDob: yup.string().required("DOB Required."),

    clientPhone: yup
      .string()
      .required("Phone Required.")
      .matches("^[0-9]+$", "Phone should be number.")
      .min(10, "Invalid Phone Number.")
      .max(10, "Invalid Phone Number."),

    clientEmail: yup
      .string()
      .nullable()
      .notRequired()
      .when("clientEmail", {
        is: (value) => value?.length,
        then: yup.string().email("Incorrect email format."),
      }),
    clientStreetAddress: yup.string().required("Street Address Required."),

    clientStreetCity: yup.string().required("City Required."),
    clientStreetState: yup.object().required("State Required."),
    clientPostCode: yup
      .string()
      .required("Postal Code Required.")
      .matches("^[0-9]+$", "Should be number.")
      .min(4, "Postal Code must be at least 4 digit.")
      .max(4, "Invalid Postal Code."),

    ndisNumber: yup
      .string()
      .required("NDIS Number Required.")
      .matches("^[0-9]+$", "Should be number.")
      .min(9, "NDIS Number must be at least 9 digit.")
      .max(9, "Invalid NDIS Number."),
    ndisPlanStartDate: yup.string().required("NDIS Plan Start Date Required."),
    ndisPlanReviewDate: yup.string().required("NDIS Plan Review Date Required."),

    ndisClientGoals: yup.string().required("Client Goals Required."),

    referalFName: yup
      .string()
      .required("First Name Required.")
      .min(3, "First Name must be at least 3 characters."),

    referalLName: yup
      .string()
      .required("Last Name Required.")
      .min(3, "Last Name must be at least 3 characters."),

    referalPhone: yup
      .string()
      .required("Phone Required.")
      .matches("^[0-9]+$", "Phone should be number.")
      .min(10, "Invalid Phone Number.")
      .max(10, "Invalid Phone Number."),

    referalEmail: yup.string().required("Email Required.").email("Incorrect email format."),

    reasonRelevantMedicalInfo: yup
      .string()
      .required("Reason For Referral/Relevant Medical Information Required."),

    // clientStreetState: yup.object().required("Please Select"),

    // isParticipant: yup.boolean().oneOf([true], "Please Select."),
  },

  [["clientEmail", "clientEmail"]]
);

const AllProjects = () => {
  const { state } = useLocation();

  let navigate = useNavigate();
  let clientRef = useRef(false);
  const MAX_COUNT = 5;

  const [values, setValues] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [chManagedBy, setChManagedBy] = useState(null);
  const [chManagedByLabel, setChManagedByLabel] = useState(null);
  const [managedFilterdValue, setManagedFilterdValue] = useState(null);
  const [managedData, setManagedData] = useState(null);

  const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);
  const [relativeStateId, setRelativeStateId] = useState([]);
  const [uploadfile, setuploadfile] = useState([]);
  const [chNotes, setChNotes] = useState(null);
  const [inNoteImportant, setinNoteImportant] = useState(false);
  const [notesData, setNotesData] = useState([]);

  const [relativeStateLabel, setRelativeStateLabel] = useState(
    state?.SecurityUserData?.RelativeState || null
  );
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [userData, setUserData] = useState(state || null);

  console.log("KKKK", userData);

  const [stateList, setStateList] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [documentdata, setdocumentdata] = useState([]);
  const [serviceList, setServiceList] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [documentValue, setDocumentValue] = useState([]);

  console.log("cheakDocumentValue", documentValue);
  const onStateChange = (event, value) => {
    console.log("State::", value);
    setStateId(value.id);
    setStateLabel(value.label);
  };

  let dir = CommonConfig.dir + "Referals/";

  console.log("uploadedFiles", uploadedFiles);
  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded, ...documentValue);
  };

  const getInternalStaff = () => {
    axios({
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {
        // Add any auth token here
        //authorization: "your token comes here",
      },
    })
      .then((res) => {
        if (res.data.success) {
          console.log("Managed Data", res.data.data);

          setManagedData(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const onManagedByChangeStatusChanged = (e, value) => {
    console.log("VVV", value);
    setChManagedByLabel(value.label);
    setChManagedBy(value.id);
    console.log("chManagedBy = ",chManagedBy)
  };


  const setAutocompleteValues = () => {
    let dummyArray = [];
    let formattedData = [];
    let array = JSON.parse(state.SecurityUserData.Services);
    for (let i = 0; i < array.length; i++) {
      if (array[i]) {
        dummyArray.push({
          id: array[i],
          label: array[i],
        });
      }
    }
    for (let i = 0; i < array.length; i++) {
      formattedData.push(array[i]);
    }
    debugger;
    setValues(formattedData);
    setServiceAutocompleteValues(dummyArray);
  };

  const onServiceChange = (event, value) => {
    console.log("VALUEService", value);
    let formattedData = [];

    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].id);
    }

    setValues(formattedData);
    setServiceAutocompleteValues(value);
    console.log("onService", values);
  };

  const getNoteList = (values) => {
    let noteReqData = {
      EntityType: "Referal",
      EntityId: values ? values : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      url: CommonConfig.ApiUrl + "note/getNoteList",
      method: "POST",
      headers: {},
      data: noteReqData,
    })
      .then((res) => {
        if (res.data?.success) {
          setNotesData(res.data.data || []);
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const saveNotes = () => {
    if (chNotes === null || chNotes === "") {
      document.getElementById("NotesError").style.display = "block";
      return;
    } else {
      document.getElementById("NotesError").style.display = "none";
    }

    const formattedData = {
      EntityType: "Referal",
      EntityId: state?.ReferralId ? state?.ReferralId : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
      IsFlag: inNoteImportant ? 1 : 0,
      NoteType: "Referal",
      NoteTitle: "",
      NoteText: chNotes,
    };
    debugger;
    console.log("formatteddata..", formattedData);
    axios({
      url: CommonConfig.ApiUrl + "note/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          getNoteList(state?.ReferralId);
          setChNotes("");
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getServiceData = () => {
    const formattedData = {
      StringMapType: "SERVICETYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setServiceList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getStateData();
    getInternalStaff();
    getServiceData();
    getNoteList(state?.ReferralId);

    getdocumentlist();
  }, []);

  console.log("UserData = ", userData);
  const statedata = {
    id: userData?.RepresentativeState,
    label: userData?.RepresentativeState,
  };
  const getdocumentlist = () => {
    debugger;
    if (userData !== null) {
      let docReqData = {
        EntityType: "Refferal",
        EntityId: userData.ReferralId ? userData.ReferralId : "",
        CurrentUser: CommonConfig.getCurrentUserId(),
      };

      axios({
        url: CommonConfig.ApiUrl + "document/getDocumentListByTypeAndId",
        method: "POST",
        headers: {},
        data: docReqData,
      })
        .then((res) => {
          if (res.data?.success) {
            debugger;
            setdocumentdata(res.data.data[0] || []);
            setLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const ClientStreetStatedata = {
    id: userData?.ClientState,
    label: userData?.ClientState,
  };

  const fileUpload = (event) => {
    debugger;
    const files = event.target.files[0];
    setuploadfile(files);
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // PersonType: "DIFFERENTLY ABLED",
      // PersonCategory: "PATIENT",
      clientFName: userData?.ClientFName ? userData?.ClientFName : "",
      clientLName: userData?.ClientLName ? userData?.ClientLName : "",
      clientDob: userData?.ClientDOB ? userData?.ClientDOB : "",
      clientPhone: userData?.ClientPhone ? userData?.ClientPhone : "",
      clientEmail: userData?.ClientEmail ? userData?.ClientEmail : "",
      clientStreetAddress: userData?.ClientStreetAddress ? userData?.ClientStreetAddress : "",
      clientStreetCity: userData?.ClientCity ? userData?.ClientCity : "",
      clientStreetState: userData?.ClientState ? ClientStreetStatedata : "",
      clientPostCode: userData?.ClientPostCode ? userData?.ClientPostCode : "",
      representativeFName: userData?.RepresentativeFName ? userData?.RepresentativeFName : "",
      representativeLName: userData?.RepresentativeLName ? userData?.RepresentativeLName : "",
      representativePhone: userData?.RepresentativePhone ? userData?.RepresentativePhone : "",
      representativeEmail: userData?.RepresentativeEmail ? userData?.RepresentativeEmail : "",
      representativeStreetAddress: userData?.RepresentativeStreetAddress
        ? userData?.RepresentativeStreetAddress
        : "",
      representativeCity: userData?.RepresentativeCity ? userData?.RepresentativeCity : "",
      representativeState: userData?.RepresentativeState ? statedata : "",
      representativePostCode: userData?.RepresentativePostalCode
        ? userData?.RepresentativePostalCode
        : "",
      ndisPlan: userData?.NDISPlan ? userData?.NDISPlan : "Plan Managed",
      ndisPlanManagerName: userData?.NDISPlanManagerName ? userData?.NDISPlanManagerName : "",
      ndisPlanManagerAgency: userData?.NDISPlanManagerAgency ? userData?.NDISPlanManagerAgency : "",
      ndisNumber: userData?.NDISNumber ? userData?.NDISNumber : "",
      ndisFunding: userData?.NDISFunding ? userData?.NDISFunding : "",
      ndisPlanStartDate: userData?.NDISPlanStartDate ? userData?.NDISPlanStartDate : "",
      ndisPlanReviewDate: userData?.NDISPlanReviewDate ? userData?.NDISPlanReviewDate : "",
      ndisClientGoals: userData?.NDISClientGoals ? userData?.NDISClientGoals : "",
      referalFName: userData?.ReferalFName ? userData?.ReferalFName : "",
      referalLName: userData?.ReferalLName ? userData?.ReferalLName : "",
      referalAgency: userData?.ReferalAgency ? userData?.ReferalAgency : "",
      referalRole: userData?.ReferalRole ? userData?.ReferalRole : "",
      referalEmail: userData?.ReferalEmail ? userData?.ReferalEmail : "",
      referalPhone: userData?.ReferalPhone ? userData?.ReferalPhone : "",
      isParticipant: userData?.IsParticipant ? userData?.IsParticipant : "",
      referalReason: userData?.ReferalReason ? userData?.ReferalReason : "Physiotherapy",
      reasonRelevantMedicalInfo: userData?.ReasonRelevantMedicalInfo
        ? userData?.ReasonRelevantMedicalInfo
        : "",
      ndisFile: userData?.NDISFile ? userData?.NDISFile : "",
    },
  });

  console.log("eeeeeee", errors);
  let isParticipant = "";
  console.log("Serv", values);
  const onSubmit = (data) => {
    debugger;
    console.log("data", data);
    console.log("uploadedFiles", uploadedFiles);
    var documentlist = [];
    {
      uploadedFiles.map((file) => documentlist.push(file.name));
    }
    console.log("doclist", documentlist);
    if (!userData) {
      if (documentlist.length == 0 && documentdata == 0) {
        document.getElementById("ServicesError").style.display = "block";
        return;
      } else {
        document.getElementById("ServicesError").style.display = "none";
      }
    }
    let SState = data.clientStreetState?.id;
    let FState = data.representativeState?.id;

    let DobFormat = CommonConfig.formatDate(data.clientDob);
    let NdisPlanReviewDate = CommonConfig.formatDate(data.ndisPlanReviewDate);
    let NdisPlanStartDate = CommonConfig.formatDate(data.ndisPlanStartDate);
    console.log("DobFormat", DobFormat);
    let formattedData = "";

    let sevicelist = [];
    sevicelist = values;
    formattedData = {
      ...data,
      documentlist: documentlist,
      CurrentUser: CommonConfig.getCurrentUserId(),
      clientDob: DobFormat,
      ndisPlanReviewDate: NdisPlanReviewDate,
      ndisPlanStartDate: NdisPlanStartDate,
      document: "documents/" + uploadfile.name,


      representativeState: FState,
      clientStreetState: SState,
    };
    console.log("formattedData=", formattedData);

    if (userData) {
      formattedData = {
        ...formattedData,
        ReferralId: state?.ReferralId,
        CurrentUser: CommonConfig.getCurrentUserId(),
      };
    }
    console.log("ISLIVINGS", formattedData);

    console.log("formattedData.....", formattedData);

    let formattedDataT;

    formattedDataT = {
      formattedData,
    };


    if (userData) {
      formattedDataT = {
        formattedData,
        CurrentUser: CommonConfig.getCurrentUserId(),
      };
    }

    console.log("formattedDataTTT.....", formattedDataT);

    var formData = new FormData();
    formData.append("data", JSON.stringify(formattedDataT));

    console.log("uploadedFiles.....", uploadedFiles.length);

    for (let i = 0; i < uploadedFiles.length; i++) {
      console.log("uploadedFiles[i].name.....", uploadedFiles[i].name);

      formData.append("File", uploadedFiles[i], uploadedFiles[i].name);
    }


    if(userData){
      if(chManagedBy){
        formData.ManagedBy = chManagedBy
      }else if(userData.ManagedBy){
        formData.ManagedBy = values.ManagedBy
      }else{
        formData.ManagedBy = ""
      }
    }

    


    debugger;
    console.log("formData.....", formData);
    axios({
      url: CommonConfig.ApiUrl + "Referral/addUpdateReferral",

      method: "POST",
      headers: {},
      data: formData,
    })
      .then((res) => {
        setLoader(false);
        debugger;
        
        if (res.data.success === true) {
          if (formattedData.ReferralId) {
            
            cogoToast.success("Referral Updated Successfully.");
            navigate("/layouts/referal/Referallist");
          } else {
            cogoToast.success("Referral Added Successfully.");
            navigate("/layouts/referal/Referallist");
          }
        } else {
          cogoToast.error("Something Went Wrong.");
        }

        console.log("testttttt", res.data);
      })
      .catch((error) => {
        console.log("AddUpdateErro", error);
      });
  };
  const handlechange = (val) => {
    console.log("valueee", val.target.value);
  };
  useEffect(() => {
    if (documentdata) {
      let getDocumentData = documentdata.map((file) => file.Filename);
      setDocumentValue(getDocumentData);
    }

  }, [documentdata]);

  return (
    <>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      
      <DashboardLayout>
        <DashboardNavbar />
        <Card sx={{ width: "100%", mt: 3 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                add
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              {userData ? "Edit Referral Form." : "Add Referral Form."}
              
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="reg_step" id="regStep1">
                <div className="reg_form">
                  {userData ?(

                      <Grid container spacing={3}>
                      <Grid item md="3">
                      <div className="reg_input auto-complete">
                        <Autocomplete
                          disablePortal
                          id="ManagedBy"
                          name="ManagedBy"
                          style={{
                            marginBottom: "10px",
                          }}
                          
                          value={chManagedByLabel != null ? chManagedByLabel : managedFilterdValue}
                          options={managedData ? managedData : []}
                          onChange={onManagedByChangeStatusChanged}
                          sx={{ width: 200 }}
                          renderInput={(params) => <TextField {...params} label="Managed By" />}
                        />
                      </div>
                      </Grid>
                      </Grid>

                  ):null}
                  
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="clientFName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="First Name*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="clientLName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Last Name*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="clientDob"
                          defaultValue=""
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                label="Date of Birth*"
                                value={value || null}
                                inputFormat="dd/MM/yyyy"
                                onChange={onChange}
                                maxDate={new Date()}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={errors.clientDob}
                                    helperText={errors.clientDob ? "DOB Required." : ""}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="clientPhone"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              type="number"
                              label="Phone Number*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              inputProps={{
                                maxLength: 10,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="clientEmail"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Email"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>


                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input">
                        <Controller
                          name="clientStreetAddress"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Street Address*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="clientStreetCity"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="City*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="clientStreetState"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="State"
                              name="State"
                              value={value}
                              options={stateList ? stateList : ""}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="State*"
                                  error={errors.clientStreetState}
                                  helperText={errors.clientStreetState ? "State Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="clientPostCode"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Postal Code*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              inputProps={{
                                maxLength: 4,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid item md="12">
                    <InputLabel mt={{ xs: -20, lg: -18 }} className="reg_lable mb-20 mt-15">
                      Client Representative Details (If Applicable){" "}
                    </InputLabel>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="representativeFName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="First Name"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="representativeLName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Last Name"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="representativePhone"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              type="number"
                              label="Phone Number"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              inputProps={{
                                maxLength: 10,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="representativeEmail"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Email"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input">
                        <Controller
                          name="representativeStreetAddress"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Street Address"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="representativeCity"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="City"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="representativeState"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="State"
                              name="State"
                              value={value}
                              options={stateList ? stateList : ""}
                              onChange={(e, v) => onChange(v)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="State"

                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="representativePostCode"
                          maxLength={2}
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              type="number"
                              maxLength={2}
                              label="Postal Code"
                              variant="outlined"
                              value={value}
                              onChange={(event) => {
                                onChange(event);
                                handlechange(event);
                              }}
                              error={error}
                              helperText={error?.message}
                              inputProps={{
                                maxLength: 4,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <div className="reg_input check_value">
                    <h3>NDIS Details</h3>
                    <InputLabel>Plan*</InputLabel>
                    <Controller
                      name="ndisPlan"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                          {" "}
                          <RadioGroup
                            aria-label="appointment"
                            row="true"
                            defaultValue={userData ? userData?.NDISPlan : "Plan Managed"}
                          >
                            <FormControlLabel
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                              value="Plan Managed"
                              control={<Radio />}
                              label="Plan Managed"
                            />
                            <FormControlLabel
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                              value="Self Managed"
                              control={<Radio />}
                              label="Self Managed"
                            />
                            <FormControlLabel
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                              value="Agency Managed"
                              control={<Radio />}
                              label="Agency Managed"
                            />
                          </RadioGroup>
                        </>
                      )}
                    />
                  </div>

                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="ndisPlanManagerName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Plan Manager Name (If Applicable)"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="ndisPlanManagerAgency"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Plan Manager Agency (If Applicable)"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="ndisNumber"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="NDIS Number *"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              inputProps={{
                                maxLength: 9,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="6">
                      <div className="reg_input">
                        <Controller
                          name="ndisFunding"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Available/Remaining Funding for Capacity Building Supports"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="ndisPlanStartDate"
                          defaultValue=""
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                label="Plan Start Date *"
                                value={value || null}
                                inputFormat="dd/MM/yyyy"
                                onChange={onChange}
      
                                maxDate={new Date()}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={errors.ndisPlanStartDate}
                                    helperText={
                                      errors.ndisPlanStartDate
                                        ? "NDIS Plan Start Date Required."
                                        : ""
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="ndisPlanReviewDate"
                          defaultValue=""
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={DateFnsUtils}>
                              <DatePicker
                                label="Plan Review Date *"
                                value={value || null}
                                inputFormat="dd/MM/yyyy"
                                onChange={onChange}
      
                                maxDate={new Date()}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={errors.ndisPlanReviewDate}
                                    helperText={
                                      errors.ndisPlanReviewDate
                                        ? "NDIS Plan Review Date Required."
                                        : ""
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="ndisClientGoals"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Client Goals (As stated in the NDIS plan) *"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <h3>Referrer Details (Person Making the Referral)</h3>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="referalFName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="First Name*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="referalLName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Last Name*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="referalAgency"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Agency"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="referalRole"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Role"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="referalEmail"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Email Address *"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="4">
                      <div className="reg_input">
                        <Controller
                          name="referalPhone"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              type="number"
                              label="Phone Number *"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              inputProps={{
                                maxLength: 10,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item>
                      <div className="reg_input check_value">
                        <Controller
                          name="isParticipant"
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
      
                                  defaultChecked={userData?.IsParticipant == 1 ? true : false}
                                  type="checkbox"
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  id="isParticipant"
                                  name="isParticipant"
                                  color="primary"
                                  value={value}
                                />
                              }
                              label="I have obtained consent from the participant to make this referral and provide Compass Physiotherapy with the participant's personal and medical details."
                            />
                          )}
                          rules={{ required: "Please Select Is Participant" }}
                        />


                      </div>
                    </Grid>
                  </Grid>

                  <div className="reg_input check_value">
                    <h3>Reason For Referral</h3>
                    <InputLabel>Referred For *</InputLabel>
                    <Controller
                      name="referalReason"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                          {" "}
                          <RadioGroup
                            aria-label="appointment"
                            row="true"
                            defaultValue={userData ? userData?.ReferalReason : "Physiotherapy"}
                          >
                            <FormControlLabel
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                              value="Physiotherapy"
                              control={<Radio />}
                              label="Physiotherapy"
                            />
                            <FormControlLabel
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                              value="Chiro"
                              control={<Radio />}
                              label="Chiro"
                            />
                            <FormControlLabel
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                              value="Psychologist"
                              control={<Radio />}
                              label="Psychologist"
                            />
                            <FormControlLabel
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                              value="Other"
                              control={<Radio />}
                              label="Other"
                            />
                          </RadioGroup>
                        </>
                      )}
                    />
                  </div>

                  <Grid item md="12">
                    <div className="reg_input">
                      <Controller
                        name="reasonRelevantMedicalInfo"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Reason For Referral/Relevant Medical Information *"
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid item md="4">
                    <div className="reg_input sec_level">
                      <label classname="test-label">Select Document*</label>
                      <Controller
                        name="ndisFile"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          console.log("first", onChange),
                          (
                            <div>
                              <input
                                style={{ color: "transparent", width: "100px" }}
                                id="fileUpload"
                                type="file"
                                multiple
                                accept="application/pdf, image/png"
                                onChange={handleFileEvent}
                                disabled={fileLimit}
                              />
                              <div className="uploaded-files-list">
                                {uploadedFiles.map((file) => (
                                  <div>{file.name}</div>
                                ))}
                              </div>

                              {documentdata
                                ? documentdata.map((file) => (
                                    <div>
                                      <a href={dir + file.Filename} target="_new">
                                        {file.Filename}
                                      </a>
                                    </div>
                                  ))
                                : null}
                            </div>
                          )
                        )}
                      />
                      <span class="servicesError" id="ServicesError">
                        Please Select Document.
                      </span>
                    </div>
                  </Grid>
                </div>
              </div>

              {userData && CommonConfig.isInternalStaff() === 1 ? (
                <Card sx={{ width: "100%", mt: 6 }}>
                  <MDBox display="flex">
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="4rem"
                      height="4rem"
                      variant="gradient"
                      bgColor="primary"
                      color="white"
                      shadow="md"
                      borderRadius="xl"
                      ml={3}
                      mt={-2}
                    >
                      <Icon fontSize="medium" color="inherit">
                        warning_amber
                      </Icon>
                    </MDBox>
                    <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                      Notes
                    </MDTypography>
                  </MDBox>
                  <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
                    <div className="notes-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Notes</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="nt-date">
                              {moment(new Date()).format("DD-MM-YYYY")}{" "}
                              <span> {moment(new Date()).format("hh:mm A")}</span>
                            </td>

                            <td className="nt-comment">
                              <div className="reg_input mb-0">
                                <TextField
                                  onChange={(e) => setChNotes(e.target.value)}
                                  value={chNotes ? chNotes : ""}
                                  name="notes"
                                  multiline
                                  rows={2}
                                  // maxRows={4}
                                  label="Notes Text*"
                                  id="regFirstName"
                                  fullWidth
                                />
                                <span class="errorTC" id="NotesError">
                                  Please Write Notes.
                                </span>
                              </div>
                            </td>
                            <td className="nt-action">
                              <PButton
                                type="button"
                                icon="pi pi-plus"
                                className="p-button-primary AddNotes"
                                onClick={() => saveNotes()}
                                tooltip="Add Notes"
                                tooltipOptions={{ position: "bottom" }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <NotesComponent
                      entityType="Referal"
                      entityId={state?.ReferralId}
                      NotesData={notesData}
                    />
                  </MDBox>
                </Card>
              ) : (
                <></>
              )}

              {userData ? (
                <div className="reg_submit">
                  <MDButton
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate("/layouts/referal/Referallist")}
                  >
                    Cancel
                  </MDButton>
                  <MDButton type="submit" variant="contained" color="primary">
                    Update
                  </MDButton>
                </div>
              ) : (
                <div className="reg_submit">

                  <MDButton type="submit" variant="contained" color="primary">
                    Submit
                  </MDButton>
                </div>
              )}

              <div className="copyright">
                <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
              </div>
            </form>
          </MDBox>
        </Card>
      </DashboardLayout>
      
    </>
  );
};

AllProjects.defaultProps = {};

export default AllProjects;
