import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import { autoLogin } from "../../commonAPI/commonAPI";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/valuecare-logo.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

const schema = yup.object().shape({
  FirstName: yup
    .string()
    .required("First Name Required.")
    .min(3, "First Name must be at least 3 characters."),
  LastName: yup
    .string()
    .required("Last Name Required.")
    .min(3, "Last Name must be at least 3 characters."),
  UserName: yup.string().required("Email Required.").email("Incorrect email format."),
  //   Password: yup
  //     .string()
  //     .required("Password required")
  //     .min(8, "Password must be at least 8 characters"),

  Department: yup.string().required("Department Required."),
  // Department: yup.string().required("Department required"),
  // Role: yup.string().required("Role required"),

  PhoneNumber: yup
    .string()
    .required("Phone Required.")
    .matches("^[0-9]+$", "Phone should be number.")
    .min(10, "Invalid Phone Number.")
    .max(10, "Invalid Phone Number."),
});

const EditInternalStaff = () => {
  const localdata = localStorage.getItem("userData");

  const { state } = useLocation();

  let navigate = useNavigate();
  let clientRef = useRef(false);

  const [phones, setPhones] = useState([]);
  const [emails, setEmails] = useState([]);
  const [values, setValues] = useState([]);
  const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);

  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState([]);

  const [userData, setUserData] = useState(state?.SecurityUserData || null);

  // const [statelist, setGetstate] = useState([]);
  const [selectedState, setSelectedState] = useState(null);

  const [termsCondition, setTermsCondition] = useState(null);

  const [stateList, setStateList] = useState(null);
  if (localStorage.getItem("userData")) {
    // alert("Hello");
  } else {
    // alert("Hello2")
    navigate("/");
  }

  console.log("APIURL===========", CommonConfig.ApiUrl);

  console.log("Service list State-----------", stateList);

  const onStateChange = (event, value) => {
    console.log("VVV", value.id);
    setValues(value.id);
    setStateLabel(value.label);

    console.log("-------bhanu--------", value);
  };

  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStateData();
  }, []);

  const UserName = userData?.Email;
  const Password = userData?.passwords;
  let PersonType = JSON.parse(localStorage.getItem("userData"))
    ? JSON.parse(localStorage.getItem("userData")).PersonType
    : "";

  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      PersonType: "INTERNAL STAFF",
      FirstName: userData?.FirstName ? userData?.FirstName : "",
      MiddleName: userData?.MiddleName ? userData?.MiddleName : "",
      LastName: userData?.LastName ? userData?.LastName : "",
      UserName: userData?.Email ? userData?.Email : "",
      Password: userData?.Password ? userData?.Password : "",
      Department: userData?.Department ? userData?.Department : "",
      Role: userData?.Role ? userData?.Role : "",
      PhoneNumber: userData?.PhoneNumber ? userData?.PhoneNumber : "",
      Designation: userData?.Designation ? userData?.Designation : "",
      DOB: userData?.DOB ? userData?.DOB : "",
      Gender: userData?.Gender ? userData?.Gender : "Male",
    },
  });

  const autoLogin = (email, password) => {
    debugger;
    const inputdata = {
      Username: email,
      Password: password,
    };
    console.log("Input data", inputdata);
    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "authentication/authenticateUser",
      method: "POST",
      headers: {},
      data: inputdata,
    })
      .then((res) => {
        debugger;
        console.log("test.....12", res.data.data[0][0].returnValue);

        // if (res.status === 200) {
        if (res.data.data[0][0].returnValue == "Invalid Username or password") {
          cogoToast.error("Invalid User.");
        } else {
          if (res.data.success === true) {
            // cogoToast.success("Login Successfully");

            var sendData = {
              PersonType: res.data.data[0][0].PersonType,
            };

            localStorage.setItem("token", res.data.data[0][0].Token);
            localStorage.setItem("userData", JSON.stringify(res.data.data[0][0]));
            axios({
              url: CommonConfig.ApiUrl + "authentication/getUserMenus",
              method: "POST",
              headers: {},
              data: sendData,
            })
              .then((res) => {
                console.log("test", res.data.data[0]);

                localStorage.setItem("Menu", JSON.stringify(res.data.data[0]));

                if (res.data.success === true) {
                  navigate("/dashboards/analytics");
                  // return true;
                  // setTimeout(() => {
                  //   let navigate = useNavigate();
                  //   navigate("/pages/book-appointment/UserBookAppointmentToken");
                  // }, 1000);
                } else {
                  cogoToast.error("Invalid User.");
                }
              })
              .catch((error) => {
                console.log(error);
              });

            // // debugger
            localStorage.setItem("token", res.data.data[0][0].Token);
            localStorage.setItem("userData", JSON.stringify(res.data.data[0][0]));
            //    setTimeout(() => {
            //   let navigate = useNavigate();
            //   navigate("/pages/book-appointment/UserBookAppointmentToken");
            // }, 1000);
          } else {
            cogoToast.error("Invalid User.");
          }
        }
      })
      .catch((error) => {
        return false;
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    debugger;
    data.Email = data.UserName;
    let DobFormat = CommonConfig.formatDate(data.DOB);
    data.DOB = DobFormat;
    console.log("DATA:100:", data);
    if (!userData) {
      if (!termsCondition) {
        document.getElementById("TcError").style.display = "block";
        return;
      } else {
        document.getElementById("TcError").style.display = "none";
      }
    }
    let formattedData = "";

    if (userData) debugger;
    formattedData = {
      ...data,
      PersonId: state?.PersonId,
    };

    let formattedDataT = { formattedData };
    console.log("formattedDataT", formattedDataT);

    axios({
      url: CommonConfig.ApiUrl + "authentication/addUpdateUser",
      method: "POST",
      headers: {},
      data: formattedDataT,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Updated Successfully.");
          let loginData;
          if (PersonType != "Admin") {
            loginData = autoLogin(UserName, Password);
          } else {
            navigate("/dashboards/analytics");
          }
        } else {
          cogoToast.error("Something Went Wrong.");
        }

        console.log("testttttt", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {localdata ? (
        <DashboardLayout>
          <DashboardNavbar />
          <Card sx={{ width: "100%", mt: 3 }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="primary"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  account_circle
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Sign Up as Internal Staff
              </MDTypography>
            </MDBox>
            <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="reg_step" id="regStep6">
                  <div className="reg_form">
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="FirstName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="First Name*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="MiddleName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Middle Name"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="LastName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Last Name*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="UserName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Email*"
                                disabled={true}
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      {/* <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Password"
                            control={control}
                            defaultValue=""
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <TextField
                                label="Password*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                type="password"
                              />
                            )}
                          />
                        </div>
                      </Grid> */}
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Department"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Department*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                // type="password"
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Designation"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Designation"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Role"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Role"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="PhoneNumber"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                type="number"
                                label="Phone Number*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                inputProps={{
                                  maxLength: 10,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="DOB"
                            defaultValue=""
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                  label="Select DOB"
                                  value={value || null}
                                  inputFormat="dd/MM/yyyy"
                                  onChange={onChange}
                                  // minDate={new Date("15-08-1947")}
                                  maxDate={new Date()}
                                  renderInput={(params) => (
                                    <TextField
                                      helperText={error?.message}
                                      error={error}
                                      {...params}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input check_value label_inline">
                          <InputLabel>Gender</InputLabel>
                          <Controller
                            name="Gender"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                defaultValue={userData ? userData?.Gender : "Male"}
                                aria-label="Gender"
                                name="Gender"
                                onChange={onChange}
                                row="true"
                              >
                                <FormControlLabel
                                  value="Male"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="Female"
                                  onChange={onChange}
                                  control={<Radio />}
                                  label="Female"
                                />
                              </RadioGroup>
                            )}
                            rules={{ required: "Email Id required" }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={3}>
                        <Grid item md="12">
                          <div className="reg_input check_value">
                            <Checkbox
                              checked={termsCondition}
                              onChange={() => setTermsCondition((prev) => !prev)}
                              color="primary"
                              label="By registering you agree to Value Care’s Terms &amp; Conditions."
                            />
                            <small>
                              By registering you agree to Value Care’s Terms &amp; Conditions.
                            </small>
                            <span class="errorTC" id="TcError">
                              {!termsCondition &&
                                "Please Select Terms & Conditions to continue."}
                            </span>
                          </div>
                        </Grid>
                      </Grid> */}
                  </div>
                </div>
                {userData ? (
                  <div className="reg_submit">
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate("/dashboards/analytics")}
                    >
                      Cancel
                    </MDButton>
                    <MDButton type="submit" variant="contained" color="primary">
                      Update
                    </MDButton>
                  </div>
                ) : (
                  <div className="reg_submit">
                    <MDButton type="submit" variant="contained" color="primary">
                      Register
                    </MDButton>
                  </div>
                )}

                {/* <div>
          <Button
            type="submit"
            style={{ color: "white" }}
            variant="contained"
            color="primary"
          >
            Register
          </Button>
        </div> */}
              </form>
            </MDBox>
          </Card>
          <div className="copyright">
            <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
          </div>
        </DashboardLayout>
      ) : (
        <div className="signup-page-outer">
          <CoverLayout className="sign-up-outer">
            <Grid container justify="center" spacing={3}>
              <Grid item className="signup-page-outer2">
                <div className="container">
                  <div className="logo">
                    <a href="www.google.com">
                      <img alt="Test" src={Logo} />
                    </a>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="reg_step" id="regStep6">
                      <Grid item md="12">
                        <h2 className="h2-heading">Signup as Internal Staff.</h2>
                      </Grid>
                      <div className="reg_form">
                        <Grid container spacing={3}>
                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="FirstName"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="First Name*"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="MiddleName"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Middle Name"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>

                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="LastName"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Last Name*"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="UserName"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Email*"
                                    disabled={true}
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>
                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="Password"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Password*"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                    type="password"
                                  />
                                )}
                              />
                            </div>
                          </Grid>

                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="Department"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Department*"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                    // type="password"
                                  />
                                )}
                              />
                            </div>
                          </Grid>

                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="Designation"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Designation"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>

                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="Role"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Role"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                  />
                                )}
                              />
                            </div>
                          </Grid>

                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="PhoneNumber"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    type="number"
                                    label="Phone Number*"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                    inputProps={{
                                      maxLength: 10,
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </Grid>

                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="DOB"
                                defaultValue=""
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <LocalizationProvider dateAdapter={DateFnsUtils}>
                                    <DatePicker
                                      label="Select DOB"
                                      value={value || null}
                                      inputFormat="dd/MM/yyyy"
                                      onChange={onChange}
                                      // minDate={new Date("15-08-1947")}
                                      maxDate={new Date()}
                                      renderInput={(params) => (
                                        <TextField
                                          helperText={error?.message}
                                          error={error}
                                          {...params}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                            </div>
                          </Grid>

                          <Grid item md="4">
                            <div className="reg_input">
                              <Controller
                                name="Gender"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <RadioGroup
                                    defaultValue={userData ? userData?.Gender : "Male"}
                                    aria-label="Gender"
                                    name="Gender"
                                    onChange={onChange}
                                    row="true"
                                  >
                                    <InputLabel>Gender</InputLabel>
                                    <FormControlLabel
                                      value="Male"
                                      onChange={onChange}
                                      control={<Radio />}
                                      label="Male"
                                    />
                                    <FormControlLabel
                                      value="Female"
                                      onChange={onChange}
                                      control={<Radio />}
                                      label="Female"
                                    />
                                  </RadioGroup>
                                )}
                                rules={{ required: "Email Id required" }}
                              />
                            </div>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item>
                              <div className="reg_input check_value">
                                <Checkbox
                                  checked={termsCondition}
                                  onChange={() => setTermsCondition((prev) => !prev)}
                                  color="primary"
                                  label="By registering you agree to Value Care’s Terms &amp; Conditions."
                                />
                                By registering you agree to Value Care’s Terms &amp; Conditions.
                                <br></br>
                                <span>
                                  {!termsCondition &&
                                    "Please Select Terms & Conditions to continue."}
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <div className="divider"> </div>
                      </div>
                    </div>
                    {userData ? (
                      <div>
                        <Button
                          type="submit"
                          style={{ color: "white" }}
                          variant="contained"
                          color="primary"
                        >
                          Update
                        </Button>
                        <Button
                          style={{ color: "red" }}
                          variant="contained"
                          color="primary"
                          onClick={() => navigate("/dashboards/analytics")}
                        >
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Button
                          type="submit"
                          style={{ color: "white" }}
                          variant="contained"
                          color="primary"
                        >
                          Register
                        </Button>
                      </div>
                    )}

                    {/* <div>
                <Button
                  type="submit"
                  style={{ color: "white" }}
                  variant="contained"
                  color="primary"
                >
                  Register
                </Button>
              </div> */}

                    <div className="copyright">
                      <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
                    </div>
                  </form>
                </div>
              </Grid>
            </Grid>
          </CoverLayout>
        </div>
      )}
    </>
  );
};

EditInternalStaff.defaultProps = {};

export default EditInternalStaff;
