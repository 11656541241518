import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import { autoLogin } from "../../commonAPI/commonAPI";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/valuecare-logo.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { Alert } from "@mui/material";

const schema = yup.object().shape({
  FirstName: yup
    .string()
    .required("First Name Required.")
    .min(3, "First Name must be at least 3 characters."),
  LastName: yup
    .string()
    .required("Last Name Required.")
    .min(3, "Last Name must be at least 3 characters."),
  Email: yup.string().required("Email Required.").email("Incorrect email format."),
  PhoneNumber: yup
    .string()
    .required("Phone Required.")
    .matches("^[0-9]+$", "Phone should be number.")
    .min(10, "Invalid Phone Number.")
    .max(10, "Invalid Phone Number."),
  AddrLine1: yup.string().required("Street Address Required."),
  // AddrLine2: yup.string().required("Street Name Required"),
  Suburb: yup.string().required("Suburb Required."),
  PostalCode: yup
    .string()
    .required("Postal Code Required.")
    .matches("^[0-9]+$", "Postal Code should be number.")
    .min(4, "Postal Code must be at least 4 digit.")
    .max(4, "Invalid Postal Code."),
  IsSubscription: yup.boolean(),
  State: yup.object().required("State Required."),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ValueCareServices = () => {
  const { state } = useLocation();

  let navigate = useNavigate();
  let clientRef = useRef(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [values, setValues] = useState([]);
  const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);

  const [userData, setUserData] = useState(state?.SecurityUserData || null);
  const [stateList, setStateList] = useState(null);

  const [termsCondition, setTermsCondition] = useState(null);

  const [statelist, setGetstate] = useState([]);
  const [selectedState, setSelectedState] = useState(null);

  const [serviceList, setServiceList] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [loader, setLoader] = React.useState(false);

  console.log("APIURL===========", CommonConfig.ApiUrl);

  console.log("Service list State-----------", serviceList);

  const onStateChange = (event, value) => {
    console.log("VVV", value.id);
    setValues(value.id);
    setStateLabel(value.label);

    console.log("-------bhanu--------", value);
  };

  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStateData();
  }, []);
  console.log("userData111", userData);
  console.log("LOCALGET", localStorage.getItem("UserName"));
  console.log("LOCALGET", localStorage.getItem("Password"));
  const UserName = localStorage.getItem("UserName");
  const Password = localStorage.getItem("Password");
  const FirstName = localStorage.getItem("FirstName");
  const LastName = localStorage.getItem("LastName");
  const EditUserName = userData ? (userData.UserName ? userData.UserName : "") : "";
  const EditPassword = userData ? (userData.passwords ? userData.passwords : "") : "";
  let PersonType = JSON.parse(localStorage.getItem("userData"))
    ? JSON.parse(localStorage.getItem("userData")).PersonType
    : "";

  const statedata = {
    id: userData?.State,
    label: userData?.State,
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // UserTypeId: "60ff8aa2-f848-11ec-86e0-fa163ebffd39",
      PersonType: "INTERESTED VC SERVICES",
      UserType: "EXTERNAL",
      UserName: userData?.UserName ? userData?.UserName : UserName,
      Password: userData?.Password ? userData?.Password : Password,
      FirstName: FirstName ? FirstName : userData?.FirstName ? userData?.FirstName : "",
      MiddleName: userData?.MiddleName ? userData?.MiddleName : "",
      LastName: LastName ? LastName : userData?.LastName ? userData?.LastName : "",
      Gender: userData?.Gender ? userData?.Gender : "Male",
      AddrLine1: userData?.AddrLine1 ? userData?.AddrLine1 : "",
      AddrLine2: userData?.AddrLine2 ? userData?.AddrLine2 : "",
      Suburb: userData?.Suburb ? userData?.Suburb : "",
      State: userData?.State ? statedata : "",
      PostalCode: userData?.PostalCode ? userData?.PostalCode : "",
      PhoneNumber: userData?.PhoneNumber ? userData?.PhoneNumber : "",
      Email: userData?.Email ? userData?.Email : "",
      IsSubscription: userData?.IsSubscription ? userData?.IsSubscription : 0,
    },
  });

  let IsSubscription = watch("IsSubscription", true);

  const autoLogin = (email, password) => {
    debugger;
    const inputdata = {
      Username: email,
      Password: password,
    };
    console.log("Input data", inputdata);
    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "authentication/authenticateUser",
      method: "POST",
      headers: {},
      data: inputdata,
    })
      .then((res) => {
        debugger;
        console.log("test.....12", res.data.data[0][0].returnValue);

        // if (res.status === 200) {
        if (res.data.data[0][0].returnValue == "Invalid Username or password") {
          cogoToast.error("Invalid User.");
        } else {
          if (res.data.success === true) {
            // cogoToast.success("Login Successfully");

            var sendData = {
              PersonType: res.data.data[0][0].PersonType,
            };

            localStorage.setItem("token", res.data.data[0][0].Token);
            localStorage.setItem("userData", JSON.stringify(res.data.data[0][0]));
            axios({
              url: CommonConfig.ApiUrl + "authentication/getUserMenus",
              method: "POST",
              headers: {},
              data: sendData,
            })
              .then((res) => {
                console.log("test", res.data.data[0]);

                localStorage.setItem("Menu", JSON.stringify(res.data.data[0]));

                if (res.data.success === true) {
                  navigate("/dashboards/userprofile/Index");
                  // return true;
                  // setTimeout(() => {
                  //   let navigate = useNavigate();
                  //   navigate("/pages/book-appointment/UserBookAppointmentToken");
                  // }, 1000);
                } else {
                  cogoToast.error("Invalid User.");
                }
              })
              .catch((error) => {
                console.log(error);
              });

            // // debugger
            localStorage.setItem("token", res.data.data[0][0].Token);
            localStorage.setItem("userData", JSON.stringify(res.data.data[0][0]));
            //    setTimeout(() => {
            //   let navigate = useNavigate();
            //   navigate("/pages/book-appointment/UserBookAppointmentToken");
            // }, 1000);
          } else {
            cogoToast.error("Invalid User.");
          }
        }
      })
      .catch((error) => {
        return false;
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    debugger;
    let FState = data.State?.id;
    localStorage.removeItem("FirstName");
    localStorage.removeItem("LastName");

    if (!userData) {
      if (!termsCondition) {
        document.getElementById("TcError").style.display = "block";
        return;
      } else {
        document.getElementById("TcError").style.display = "none";
      }
    }
    setLoader(!loader);
    console.log("DATA::", data);
    let formattedData = "";

    // formattedData = {
    //   ...data,
    //   IsSubscription: IsSubscription == true ? 1 : 0,
    // };

    if (userData) {
      formattedData = {
        ...data,
        PersonId: state?.PersonId,
        State: FState,
        IsSubscription: IsSubscription == true ? 1 : 0,
      };
      console.log("formattedData1", formattedData);
    } else {
      formattedData = {
        ...data,
        State: FState,
        IsSubscription: IsSubscription == true ? 1 : 0,
      };
    }

    let formattedDataT = { formattedData };
    if (userData) {
      formattedDataT = {
        formattedData,
        CurrentUser: CommonConfig.getCurrentUserId(),
      };
    }

    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "authentication/addUpdateUser",
      method: "POST",
      headers: {},
      data: formattedDataT,
    })
      .then((res) => {
        setLoader(!loader);
        if (res.data.success === true) {
          // cogoToast.success("Registration Successfully");
          if (!userData) {
            cogoToast.success("Registered Successfully.");
          } else {
            cogoToast.success("Updated Successfully.");
          }

          let loginData;

          if (!userData) {
            loginData = autoLogin(UserName, Password);
          } else if (userData && PersonType !== "Admin") {
            loginData = autoLogin(EditUserName, EditPassword);
          } else {
            navigate("/dashboards/analytics");
          }
        } else {
          cogoToast.error("Something Went Wrong.");
        }

        console.log("testttttt", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {userData ? (
        <DashboardLayout>
          <DashboardNavbar />
          <Card sx={{ width: "100%", mt: 3 }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="primary"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  accessible
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Edit Value Care’s services, events and activities
              </MDTypography>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: "auto", mr: 2 }}>
                Person# : {userData.PersonIdNumber}
              </MDTypography>
            </MDBox>
            <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="reg_step" id="regStep4">
                  <div className="reg_form">
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="FirstName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="First Name*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="MiddleName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Middle Name"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="LastName"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Last Name*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <div className="reg_input check_value mb-0">
                      <InputLabel>Gender</InputLabel>
                      <Controller
                        name="Gender"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <>
                            {" "}
                            <RadioGroup
                              aria-label="appointment"
                              row="true"
                              defaultValue={userData ? userData?.Gender : "Male"}
                            >
                              <FormControlLabel
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value="Male"
                                control={<Radio />}
                                label="Male"
                              />
                              <FormControlLabel
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value="Female"
                                control={<Radio />}
                                label="Female"
                              />
                              <FormControlLabel
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value="Unknown"
                                control={<Radio />}
                                label="Unknown"
                              />
                            </RadioGroup>
                          </>
                        )}
                      />
                    </div>
                    <div className="divider"> </div>

                    <Grid container spacing={3}>
                      <Grid item md="12">
                        <InputLabel className="reg_lable">Current Address.</InputLabel>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AddrLine1"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Street Address*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AddrLine2"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Street Name"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Suburb"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Suburb*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="State"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="State"
                                name="State"
                                value={value}
                                options={stateList ? stateList : ""}
                                onChange={(e, v) => onChange(v)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="State*"
                                    error={errors.State}
                                    helperText={errors.State ? "State Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="2">
                        <div className="reg_input">
                          <Controller
                            name="PostalCode"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Postal Code*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                inputProps={{
                                  maxLength: 4,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="PhoneNumber"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                type="number"
                                label="Phone Number*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                                inputProps={{
                                  maxLength: 10,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>

                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="Email"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                disabled={true}
                                label="Email*"
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item md="12">
                        <div className="reg_input check_value">
                          <InputLabel>
                            Would you like to receive our newsletters, event invitations and details
                            of our market activities?
                          </InputLabel>
                          <Controller
                            name="IsSubscription"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <RadioGroup
                                aria-label="appointment"
                                name="IsSubscription"
                                row="true"
                                defaultValue={userData ? userData?.IsSubscription : "0"}
                              >
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="1"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="0"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            )}
                            rules={{ required: "Select any one" }}
                          />
                        </div>
                        {!userData ? (
                          <Grid container spacing={3}>
                            <Grid item>
                              <div className="reg_input check_value">
                                <Checkbox
                                  checked={termsCondition}
                                  onChange={() => setTermsCondition((prev) => !prev)}
                                  color="primary"
                                  label="By registering you agree to Value Care’s Terms &amp; Conditions."
                                />
                                By registering you agree to Value Care’s Terms &amp; Conditions.
                                <br></br>
                                <span class="servicesError" id="TcError">
                                  {!termsCondition &&
                                    "Please Select Terms & Conditions to continue."}
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
                {userData ? (
                  <div className="reg_submit">
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate("/dashboards/userprofile/Index")}
                    >
                      Cancel
                    </MDButton>
                    <MDButton type="submit" variant="contained" color="primary">
                      Update
                    </MDButton>
                  </div>
                ) : (
                  <div className="reg_submit">
                    <MDButton
                      variant="contained"
                      color="secondary"
                      className="reg_submit"
                      onClick={() => navigate("/")}
                    >
                      Cancel
                    </MDButton>
                    <MDButton type="submit" variant="contained" color="primary">
                      Register
                    </MDButton>
                  </div>
                )}
              </form>
            </MDBox>
          </Card>
          <div className="copyright">
            <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
          </div>
        </DashboardLayout>
      ) : (
        <div className="signup-page-outer">
          <CoverLayout className="sign-up-outer">
            {/* <Grid container justify="center" spacing={3}>
              <Grid item className="signup-page-outer2"> */}
            <div className="container">
              <div className="logo">
                <a href="www.google.com">
                  <img alt="Test" src={Logo} />
                </a>
              </div>
              <div className="reg-inner">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="reg_step" id="regStep4">
                    <Grid item md="12">
                      <h2 className="h2-heading">
                        I am interested in Value Care’s services, events and activities.
                      </h2>
                    </Grid>
                    <div className="reg_form">
                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="FirstName"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="First Name*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="MiddleName"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Middle Name"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="LastName"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Last Name*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <div className="reg_input check_value mb-0">
                        <InputLabel>Gender</InputLabel>
                        <Controller
                          name="Gender"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              {" "}
                              <RadioGroup
                                aria-label="appointment"
                                row="true"
                                defaultValue={userData ? userData?.Gender : "Male"}
                              >
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Male"
                                  control={<Radio />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Female"
                                  control={<Radio />}
                                  label="Female"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Unknown"
                                  control={<Radio />}
                                  label="Unknown"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </div>
                      <div className="divider"> </div>

                      <Grid container spacing={3}>
                        <Grid item md="12">
                          <InputLabel className="reg_lable">Current Address.</InputLabel>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="AddrLine1"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Street Address*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="AddrLine2"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Street Name"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="Suburb"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Suburb*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input auto-complete">
                            <Controller
                              control={control}
                              name="State"
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  disablePortal
                                  id="State"
                                  name="State"
                                  value={value}
                                  options={stateList ? stateList : ""}
                                  onChange={(e, v) => onChange(v)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="State*"
                                      error={errors.State}
                                      helperText={errors.State ? "State Required." : ""}
                                    />
                                  )}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        <Grid item md="2">
                          <div className="reg_input">
                            <Controller
                              name="PostalCode"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Postal Code*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                  inputProps={{
                                    maxLength: 4,
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="PhoneNumber"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  type="number"
                                  label="Phone Number*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                  inputProps={{
                                    maxLength: 10,
                                  }}
                                />
                              )}
                            />
                          </div>
                        </Grid>

                        <Grid item md="4">
                          <div className="reg_input">
                            <Controller
                              name="Email"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Email*"
                                  variant="outlined"
                                  value={value}
                                  onChange={onChange}
                                  error={error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md="12">
                          <div className="reg_input check_value">
                            <InputLabel>
                              Would you like to receive our newsletters, event invitations and
                              details of our market activities?
                            </InputLabel>
                            <Controller
                              name="IsSubscription"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RadioGroup
                                  aria-label="appointment"
                                  name="IsSubscription"
                                  row="true"
                                  defaultValue={userData ? userData?.IsSubscription : "0"}
                                >
                                  <FormControlLabel
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    value="1"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    value="0"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              )}
                              rules={{ required: "Select any one" }}
                            />
                          </div>
                          {!userData ? (
                            <Grid container spacing={3}>
                              <Grid item>
                                <div className="reg_input check_value">
                                  <Checkbox
                                    checked={termsCondition}
                                    onChange={() => setTermsCondition((prev) => !prev)}
                                    color="primary"
                                    label="By registering you agree to Value Care’s Terms &amp; Conditions."
                                  />
                                  <small>
                                    By registering you agree to Value Care’s{" "}
                                    <a className="color-red" onClick={handleOpen}>
                                      Terms &amp; Conditions.
                                    </a>
                                  </small>
                                  <div className="clearfix"></div>
                                  <span class="errorTC" id="TcError">
                                    {!termsCondition &&
                                      "Please Select Terms & Conditions to continue."}
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography id="modal-modal-title" variant="h6" component="h2">
                            Please Read Carefully Terms &amp; Conditions.
                          </Typography>
                          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Health insurance is a way to ensure you have support for costly medical
                            treatment. Value Care has been designed to tailor a health plan to suit
                            your needs and priorities within your budget. Benefits include
                            hospitalisation and specialist care locally and with world class medical
                            facilities in India. Value Care is available to individuals and families
                            as well as group schemes who are looking for medical insurance at
                            reasonable rates. Benefits provided through this plan are regularly
                            reviewed to meet our customers’ needs.
                          </Typography>
                          <Button
                            style={{ color: "white" }}
                            variant="contained"
                            color="primary"
                            onClick={handleClose}
                          >
                            OK
                          </Button>
                        </Box>
                      </Modal>
                    </div>
                  </div>
                  {userData ? (
                    <div>
                      <Button
                        type="submit"
                        style={{ color: "white" }}
                        variant="contained"
                        color="primary"
                      >
                        Update
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        style={{ color: "white" }}
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("/dashboards/userprofile/Index")}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <div className="reg_submit">
                      <MDButton
                        style={{ color: "white" }}
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate("/")}
                      >
                        Cancel
                      </MDButton>
                      <MDButton type="submit" variant="contained" color="primary">
                        Register
                      </MDButton>
                    </div>
                  )}
                </form>
              </div>
            </div>
            {/* </Grid>
            </Grid> */}
          </CoverLayout>
          <div className="copyright">
            <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
          </div>
        </div>
      )}
    </>
  );
};

ValueCareServices.defaultProps = {};

export default ValueCareServices;
