import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import { autoLogin } from "../../commonAPI/commonAPI";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import regIcon1 from "assets/img/reg-icon1.svg";
import regIcon2 from "assets/img/reg-icon2.svg";
import regIcon3 from "assets/img/referral.png";
import regIcon4 from "assets/img/events.png";
import regIcon5 from "assets/img/supplier.png";
import regIcon6 from "assets/img/job.png";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import emailIcon from "assets/img/icon-email.svg";
import { Alert } from "@mui/material";

import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/valuecare-logo.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;

const schema = yup.object().shape({
  UserName: yup.string().required("Email Required.").email("Please Enter Valid Email."),

  Password: yup
    .string()
    .required("Password Required.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
    ),
  confirmpassword: yup.string().oneOf([yup.ref("Password"), null], "Passwords must match."),
});

const Cover = () => {
  const { state } = useLocation();
  let navigate = useNavigate();

  const [passwordShown, setPasswordShown] = useState(false);
  const [cnfpasswordShown, setCnfPasswordShown] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [loader, setLoader] = React.useState(false);

  const [login, setLogin] = React.useState({
    formId: "",
    formType: "",
  });

  const handleformEnable = (id, value) => {
    if (id == 1 || id == 2 || id == 3 || id == 4 || id == 5 || id == 6) {
      // login.formType = value;
      // login.formId = id;
      setLogin({ ...login, ["formType"]: value, ["formId"]: id });

      // FormType = 1
      // Usertypeid="3b8e503e-f848-11ec-86e0-fa163ebffd39"
      document.getElementById("regStep").style.display = "none";
      document.getElementById("regSignup").style.display = "block";
    }

    if (id == "regStep-login") {
      document.getElementById("regSignup").style.display = "none";
      document.getElementById("regStep-login").style.display = "block";
    }
  };

  const [loginData, setLoginData] = React.useState(
    localStorage.getItem("loginData") ? JSON.parse(localStorage.getItem("loginData")) : null
  );

  const handleFailure = (result) => {
    console.log("login data", localStorage.getItem("loginData"));
    console.log("client id", process.env.REACT_APP_GOOGLE_CLIENT_ID);
    // alert(JSON.stringify(result));
  };

  // const onSuccess = (googleData) => {
  //   console.log("TEST....", googleData);

  //   axios({
  //     url: CommonConfig.ApiUrl + "authentication/googleSignup",

  //     method: "POST",
  //     body: JSON.stringify({
  //       dtoken: googleData.tokenI,
  //     }),
  //     headers: {},
  //     data: googleData,
  //     "Content-Type": "application/json",
  //   })
  //     .then((res) => {
  //       debugger;
  //       console.log("res", res);
  //       const data = res.data.data;
  //       if (data === "redirect register page") {
  //         document.getElementById("regSignup").style.display = "none";

  //         if (login.formId === 1) {
  //           localStorage.setItem("FirstName", googleData.profileObj.givenName);
  //           localStorage.setItem("LastName", googleData.profileObj.familyName);
  //           localStorage.setItem("UserName", googleData.profileObj.email);
  //           localStorage.setItem("Password", "Cognisun@123");
  //           navigate("/authentication/sign-up/SignUpDifferentlyAbled");
  //         }

  //         if (login.formId === 2) {
  //           localStorage.setItem("FirstName", googleData.profileObj.givenName);
  //           localStorage.setItem("LastName", googleData.profileObj.familyName);
  //           localStorage.setItem("UserName", googleData.profileObj.email);
  //           localStorage.setItem("Password", "Cognisun@123");
  //           navigate("/authentication/sign-up/FamilyMember");
  //         }

  //         if (login.formId === 3) {
  //           localStorage.setItem("FirstName", googleData.profileObj.givenName);
  //           localStorage.setItem("LastName", googleData.profileObj.familyName);
  //           localStorage.setItem("UserName", googleData.profileObj.email);
  //           localStorage.setItem("Password", "Cognisun@123");
  //           navigate("/authentication/sign-up/SupportCordinator");
  //         }

  //         if (login.formId === 4) {
  //           localStorage.setItem("FirstName", googleData.profileObj.givenName);
  //           localStorage.setItem("LastName", googleData.profileObj.familyName);
  //           localStorage.setItem("UserName", googleData.profileObj.email);
  //           localStorage.setItem("Password", "Cognisun@123");
  //           navigate("/authentication/sign-up/ValueCareServices");
  //         }

  //         if (login.formId === 5) {
  //           localStorage.setItem("FirstName", googleData.profileObj.givenName);
  //           localStorage.setItem("LastName", googleData.profileObj.familyName);
  //           localStorage.setItem("UserName", googleData.profileObj.email);
  //           localStorage.setItem("Password", "Cognisun@123");
  //           navigate("/authentication/sign-up/SubContractor");
  //         }

  //         if (login.formId === 6) {
  //           localStorage.setItem("FirstName", googleData.profileObj.givenName);
  //           localStorage.setItem("LastName", googleData.profileObj.familyName);
  //           localStorage.setItem("UserName", googleData.profileObj.email);
  //           localStorage.setItem("Password", "Cognisun@123");
  //           navigate("/authentication/sign-up/WorkForValueCare");
  //         }
  //       } else {
  //         cogoToast.error(data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const onSuccess = (googleData) => {
    console.log("TEST....", googleData);

    axios({
      url: CommonConfig.ApiUrl + "authentication/googleSignup",

      method: "POST",
      body: JSON.stringify({
        dtoken: googleData.tokenI,
      }),
      headers: {},
      data: googleData,
      "Content-Type": "application/json",
    })
      .then((res) => {
        debugger;
        console.log("res", res);
        const data = res.data.data;
        if (data === "redirect register page") {
          document.getElementById("regSignup").style.display = "none";

          var inputDataUser = {
            username: googleData.profileObj.email,
          };

          axios({
            url: CommonConfig.ApiUrl + "authentication/checkUserExist",
            method: "POST",
            headers: {},
            data: inputDataUser,
          })
            .then((res) => {
              debugger;
              console.log("test.....12", res.data.data[0][0].totalUser);
              if (res.data.data[0][0].totalUser === 0) {
                if (login.formId === 1) {
                  localStorage.setItem("FirstName", googleData.profileObj.givenName);
                  localStorage.setItem("LastName", googleData.profileObj.familyName);
                  localStorage.setItem("UserName", googleData.profileObj.email);
                  localStorage.setItem("Password", "Cognisun@123");
                  navigate("/authentication/sign-up/SignUpDifferentlyAbled");
                }

                if (login.formId === 2) {
                  localStorage.setItem("FirstName", googleData.profileObj.givenName);
                  localStorage.setItem("LastName", googleData.profileObj.familyName);
                  localStorage.setItem("UserName", googleData.profileObj.email);
                  localStorage.setItem("Password", "Cognisun@123");
                  navigate("/authentication/sign-up/FamilyMember");
                }

                if (login.formId === 3) {
                  localStorage.setItem("FirstName", googleData.profileObj.givenName);
                  localStorage.setItem("LastName", googleData.profileObj.familyName);
                  localStorage.setItem("UserName", googleData.profileObj.email);
                  localStorage.setItem("Password", "Cognisun@123");
                  navigate("/authentication/sign-up/SupportCordinator");
                }

                if (login.formId === 4) {
                  localStorage.setItem("FirstName", googleData.profileObj.givenName);
                  localStorage.setItem("LastName", googleData.profileObj.familyName);
                  localStorage.setItem("UserName", googleData.profileObj.email);
                  localStorage.setItem("Password", "Cognisun@123");
                  navigate("/authentication/sign-up/ValueCareServices");
                }

                if (login.formId === 5) {
                  localStorage.setItem("FirstName", googleData.profileObj.givenName);
                  localStorage.setItem("LastName", googleData.profileObj.familyName);
                  localStorage.setItem("UserName", googleData.profileObj.email);
                  localStorage.setItem("Password", "Cognisun@123");
                  navigate("/authentication/sign-up/SubContractor");
                }

                if (login.formId === 6) {
                  localStorage.setItem("FirstName", googleData.profileObj.givenName);
                  localStorage.setItem("LastName", googleData.profileObj.familyName);
                  localStorage.setItem("UserName", googleData.profileObj.email);
                  localStorage.setItem("Password", "Cognisun@123");
                  navigate("/authentication/sign-up/WorkForValueCare");
                }
              } else {
                cogoToast.error("User already register with Value Care.");
                setTimeout(() => {
                  navigate("/");
                }, 1000);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          cogoToast.error(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLogout = () => {
    localStorage.removeItem("loginData");
    setLoginData(null);
  };

  const responseFacebook = (response) => {
    console.log("Facebooklogin...", response);
    var email = response.email;

    axios({
      url: CommonConfig.ApiUrl + "authentication/facebookSignup",
      method: "POST",
      headers: {},
      data: email,
    })
      .then((res) => {
        const data = res.data.data;
        if (data === "redirect register page") {
          data.UserName = email;

          if (login.formId === 1) {
            navigate("/authentication/sign-up/SignUpDifferentlyAbled");
          }

          if (login.formId === 2) {
            navigate("/authentication/sign-up/FamilyMember");
          }

          if (login.formId === 3) {
            navigate("/authentication/sign-up/SupportCordinator");
          }

          if (login.formId === 4) {
            navigate("/authentication/sign-up/ValueCareServices");
          }

          if (login.formId === 5) {
            navigate("/authentication/sign-up/SubContractor");
          }

          if (login.formId === 6) {
            navigate("/authentication/sign-up/WorkForValueCare");
          }
        } else {
          cogoToast.error(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const componentClicked = (data) => {
    console.warn(data);
  };

  const togglePasswordVisiblity = () => {
    document.getElementById("HideEyePassword").style.display = "none";
    document.getElementById("ShowEyePassword").style.display = "block";
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleHidePasswordVisiblity = () => {
    document.getElementById("HideEyePassword").classList.add("eyeplacepass");
    document.getElementById("HideEyePassword").classList.remove("eyeplace");
    document.getElementById("HideEyePassword").style.display = "block";
    document.getElementById("ShowEyePassword").style.display = "none";
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleCnfPasswordVisiblity = () => {
    document.getElementById("HideComfEyePassword").style.display = "none";
    document.getElementById("ShowComfEyePassword").style.display = "block";
    setCnfPasswordShown(cnfpasswordShown ? false : true);
  };
  const toggleHideCnfPasswordVisiblity = () => {
    document.getElementById("HideComfEyePassword").classList.add("eyeplacepass");
    document.getElementById("HideComfEyePassword").classList.remove("eyeplace");
    document.getElementById("HideComfEyePassword").style.display = "block";
    document.getElementById("ShowComfEyePassword").style.display = "none";
    setCnfPasswordShown(cnfpasswordShown ? false : true);
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = (data) => {
    debugger;
    setLoader(!loader);
    console.log("signup data", data);
    debugger;
    var inputDataUser = {
      username: data.UserName,
    };
    axios({
      url: CommonConfig.ApiUrl + "authentication/checkUserExist",
      method: "POST",
      headers: {},
      data: inputDataUser,
    })
      .then((res) => {
        debugger;
        console.log("test.....12", res.data.data[0][0].totalUser);
        setLoader(!loader);
        if (res.data.data[0][0].totalUser === 0) {
          if (login.formId === 1) {
            localStorage.setItem("UserName", data.UserName);
            localStorage.setItem("Password", data.Password);
            navigate("/authentication/sign-up/SignUpDifferentlyAbled");
          }

          if (login.formId === 2) {
            localStorage.setItem("UserName", data.UserName);
            localStorage.setItem("Password", data.Password);
            navigate("/authentication/sign-up/FamilyMember");
          }

          if (login.formId === 3) {
            localStorage.setItem("UserName", data.UserName);
            localStorage.setItem("Password", data.Password);
            navigate("/authentication/sign-up/SupportCordinator");
          }

          if (login.formId === 4) {
            localStorage.setItem("UserName", data.UserName);
            localStorage.setItem("Password", data.Password);
            navigate("/authentication/sign-up/ValueCareServices");
          }

          if (login.formId === 5) {
            localStorage.setItem("UserName", data.UserName);
            localStorage.setItem("Password", data.Password);
            navigate("/authentication/sign-up/SubContractor");
          }

          if (login.formId === 6) {
            localStorage.setItem("UserName", data.UserName);
            localStorage.setItem("Password", data.Password);
            navigate("/authentication/sign-up/WorkForValueCare");
          }
        } else {
          setLoader(false);
          return setShowAlert(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div className="signup-page-outer">
        <CoverLayout className="sign-up-outer">
          {/* <Grid container justify="center" spacing={3}>
            <Grid item className="signup-page-outer2"> */}
          <div className="container">
            <div className="logo">
              <a>
                <img alt="Test" src={Logo} />
              </a>
            </div>
            <div className="reg-inner" id="regStep">
              <div className="reg_step">
                <h2>What type of engagement do you anticipate with Value Care?</h2>
                <div className="reg-boxes">
                  <Grid container spacing={3}>
                    <Grid item sm={4} md={4}>
                      <div
                        className="reg_inner_box"
                        onClick={() =>
                          handleformEnable(
                            1,
                            " I am a differently-abled person or in need of supports seeking Value Care’s services."
                          )
                        }
                      >
                        <div className="reg_icon">
                          <span>
                            <img alt="Test" src={regIcon1} />
                          </span>
                        </div>
                        <div className="reg_box-content">
                          <p>
                            I am a differently-abled person or in need of supports seeking Value
                            Care’s services.
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={4} md={4}>
                      <div
                        className="reg_inner_box"
                        onClick={() =>
                          handleformEnable(
                            2,
                            "I am a family member/principal carer/relative of a person with a disability or in need of supports"
                          )
                        }
                      >
                        <div className="reg_icon">
                          <span>
                            <img alt="Test" src={regIcon2} />
                          </span>
                        </div>
                        <div className="reg_box-content">
                          <p>
                            I am a family member/principal carer/relative of a person with a
                            disability or in need of supports.
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={4} md={4}>
                      <div
                        className="reg_inner_box"
                        onClick={() =>
                          handleformEnable(
                            3,
                            "I am a support coordinator or an organisation referring a client to Value Care"
                          )
                        }
                      >
                        <div className="reg_icon">
                          <span>
                            <img alt="Test" src={regIcon3} />
                          </span>
                        </div>
                        <div className="reg_box-content">
                          <p>
                            I am a support coordinator or an organisation referring a client to
                            Value Care.
                          </p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="4" sm="4">
                      <div
                        className="reg_inner_box"
                        onClick={() =>
                          handleformEnable(
                            4,
                            "I am interested in Value Cares services, events and activities"
                          )
                        }
                      >
                        <div className="reg_icon">
                          <span>
                            <img alt="Test" src={regIcon4} />
                          </span>
                        </div>
                        <div className="reg_box-content">
                          <p>I am interested in Value Cares services, events and activities.</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md="4" sm="4">
                      <div
                        className="reg_inner_box"
                        onClick={() =>
                          handleformEnable(
                            5,
                            "I am (or wanting to be) a sub-contractor or a supplier or an associate to Value Care"
                          )
                        }
                      >
                        <div className="reg_icon">
                          <span>
                            <img alt="Test" src={regIcon5} />
                          </span>
                        </div>
                        <div className="reg_box-content">
                          <p>
                            I am (or wanting to be) a sub-contractor or a supplier or an associate
                            to Value Care.
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md="4" sm="4">
                      <div
                        className="reg_inner_box"
                        onClick={() => handleformEnable(6, "I would like to work for Value Care")}
                      >
                        <div className="reg_icon">
                          <span>
                            <img alt="Test" src={regIcon6} />
                          </span>
                        </div>
                        <div className="reg_box-content">
                          <p>I would like to work for Value Care.</p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>

            <div className="reg_step" id="regSignup" style={{ display: "none" }}>
              <div className="reg-inner">
                <h2>{login.formType}</h2>
                <div className="reg_social">
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    buttonText="Signup with Google"
                    onSuccess={onSuccess}
                    onFailure={handleFailure}
                    cookiePolicy={"single_host_origin"}
                  ></GoogleLogin>
                  <div className="fb-login">
                    <FacebookIcon />
                    <FacebookLogin
                      textButton="Signup with Facebook"
                      // autoLoad={true}
                      // appId="1412472369236604"
                      appId="442300934729562"
                      fields="name,email,picture"
                      onClick={componentClicked}
                      callback={responseFacebook}
                    />
                  </div>
                  <span className="or">OR</span>
                  <a
                    onClick={() => handleformEnable("regStep-login")}
                    className="social_signin_btn"
                  >
                    <img src={emailIcon} alt="temp" /> Sign up with Email
                  </a>
                </div>
              </div>
            </div>

            <div className="reg_step" id="regStep-login" style={{ display: "none" }}>
              <div className="reg_form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container justifyContent="center" spacing={3}>
                    <Grid item md="9">
                      <div className="reg-inner">
                        <Grid
                          container
                          justifyContent="center"
                          rowSpacing={0}
                          columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                        >
                          <Grid item md="12">
                            <h2 className="mb-20">User Sign Up</h2>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          justifyContent="center"
                          rowSpacing={0}
                          columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                        >
                          <Grid item md="6">
                            <div className="reg_input">
                              <Controller
                                name="UserName"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Email*"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    error={error}
                                    helperText={error?.message}
                                    inputProps={{
                                      autoComplete: "new-password",
                                    }}
                                  />
                                )}
                              />
                              {showAlert ? (
                                <Alert className="lg-alert" variant="outlined" color="error">
                                  Email Already Registered with Value Care.
                                </Alert>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          justifyContent="center"
                          rowSpacing={0}
                          columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                        >
                          <Grid item md="6">
                            <div className="reg_input">
                              <div className="relative">
                                <Controller
                                  name="Password"
                                  control={control}
                                  defaultValue=""
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      type={passwordShown ? "text" : "password"}
                                      label="Password*"
                                      variant="outlined"
                                      value={value}
                                      onChange={onChange}
                                      error={error}
                                      helperText={error?.message}
                                      inputProps={{
                                        autoComplete: "new-password",
                                      }}
                                    />
                                  )}
                                />
                                <i
                                  class="eyeplace"
                                  id="HideEyePassword"
                                  onClick={togglePasswordVisiblity}
                                >
                                  <VisibilityIcon />
                                </i>
                                <i
                                  class="eyeplacepass hidetext"
                                  id="ShowEyePassword"
                                  onClick={toggleHidePasswordVisiblity}
                                >
                                  <VisibilityOffIcon />
                                </i>
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          justifyContent="center"
                          rowSpacing={0}
                          columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                        >
                          <Grid item md="6">
                            <div className="reg_input">
                              <div className="relative">
                                <Controller
                                  name="confirmpassword"
                                  control={control}
                                  defaultValue=""
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      type={cnfpasswordShown ? "text" : "password"}
                                      label="Confirm Password*"
                                      variant="outlined"
                                      value={value}
                                      onChange={onChange}
                                      error={error}
                                      helperText={error?.message}
                                    />
                                  )}
                                />
                                <i
                                  class="eyeplaceConf"
                                  id="HideComfEyePassword"
                                  onClick={toggleCnfPasswordVisiblity}
                                >
                                  {/* {eye} */}
                                  <VisibilityIcon />
                                </i>

                                <i
                                  class="eyeplacepassConf hidetext"
                                  id="ShowComfEyePassword"
                                  onClick={toggleHideCnfPasswordVisiblity}
                                >
                                  <VisibilityOffIcon />
                                </i>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item md="12">
                          <div className="reg_submit center">
                            <MDButton type="submit" variant="contained" color="primary">
                              Sign UP
                            </MDButton>
                          </div>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </div>
          </div>
          {/* </Grid>
          </Grid> */}
        </CoverLayout>
      </div>
    </>
  );
};

Cover.defaultProps = {};

export default Cover;
