import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller, set } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MDEditor from "components/MDEditor";
import { NotesComponent } from "layouts/component/Notes";

import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/valuecare-logo.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { Button as PButton } from "primereact/button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { item } from "examples/Sidenav/styles/sidenavItem";

import { isValidDate } from "@fullcalendar/react";
import { Alert } from "@mui/material";
let userLogin = JSON.parse(localStorage.getItem("userData"));
console.log("test = ", localStorage.getItem("userData"));
console.log("UserLogin = ", userLogin);
const schema = yup.object().shape({
  //InspectionDateTime: yup.string().required("InspectionDate Date required."),
  FirstName: yup.string().required("First Name Required."),

  LastName: yup.string().required("Last Name Required."),

  PostCode: yup
    .string()
    .required("Post Code Required.")
    .matches("^[0-9]+$", "Post Code should be number.")
    .min(4, "Invalid Post Code.")
    .max(4, "Invalid Post Code."),
  // State: yup.object().required("State Required."),
  // PropertyName: yup.object().required("Property Name Required."),
  Email: yup.string().required("Email Required.").email("Incorrect email format."),
  Phone: yup
    .string()
    .required("Phone Required.")
    .matches("^[0-9]+$", "Phone should be number.")
    .min(10, "Invalid Phone Number.")
    .max(10, "Invalid Phone Number."),
  Message: yup.string().required("Message Required."),
});

const EditBookProperty = () => {
  const defaultFeatures = ["Dining Area"];
  const defaultAmenities = ["Air Condition"];
  const defaultAccessibility = ["Wheelchair accessible"];
  const defaultSDA = ["Highly physical support"];

  const { state } = useLocation();

  let navigate = useNavigate();
  let clientRef = useRef(false);
  const [managedData, setManagedData] = useState(null);
  const [propertyData, setPropertyData] = useState(state || null);
  console.log("propertyDataQAQ", propertyData);
  const [values, setValues] = useState();
  const [stateId, setStateId] = useState([]);
  const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);
  const [relativeStateId, setRelativeStateId] = useState([]);
  const [relativeStateLabel, setRelativeStateLabel] = useState(
    state?.SecurityUserData?.RelativeState || null
  );
  const [chNotes, setChNotes] = useState(null);
  const [appoinmentStatusList, setAppoinmentStatusList] = useState(null);
  const [chStatus, setChStatus] = useState(null);
  const [imageurl, setimageurl] = useState([]);
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);
  const [Bookingtype, setBookingtype] = useState("");
  const [userData, setUserData] = useState(state?.SecurityUserData || null);
  const [isEditEnabled, setIsEditEnabled] = useState(state ? true : false);
  const [stateList, setStateList] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [featureCheckboxlist, setFeatureCheckboxlist] = useState([]);
  const [amenityCheckboxlist, setAmenityCheckboxlist] = useState([]);
  const [accessibilityCheckboxlist, setAccessibilityCheckboxlist] = useState([]);
  const [sdaCheckboxlist, setSdaCheckboxlist] = useState([]);
  const [Propertynamelist, setPropertynamelist] = useState(null);
  const [citylist, setcitylist] = useState(null);
  const [serviceList, setServiceList] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [value, setValue] = React.useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMediumType, setSelectedMediumType] = useState(false);
  const [TimeList, setTimeList] = useStateWithCallbackLazy(null);
  const [riskRegisterId, setRiskRegisterId] = useState(null);
  const [inNoteImportant, setinNoteImportant] = useState(false);
  const [notesEnable, setNotesEnable] = useState(false);
  const [selectedNotesType, setSselectedNotesType] = useState(null);
  const [selectedpropertylocation, setselectedpropertylocation] = useState(
    null || propertyData.City
  );
  const [selectedpropertyname, setselectedpropertyname] = useState(
    null || propertyData.PropertyName
  );
  const [chManagedByLabel, setChManagedByLabel] = useState(null);
  const [getpropertyData, setgetpropertyData] = useState([]);
  const [visittime, setvisittime] = useState("" || propertyData.VsitingTime);
  const [datetimeshow, setdatetimeshow] = useState(false);
  const [visitselecteddate, setvisitselecteddate] = useState("");
  const [chManagedBy, setChManagedBy] = useState(null);
  const [notesData, setNotesData] = useState([]);
  const PropertyLocationData = citylist;
  const PropertyNameData = Propertynamelist;
  const BookInspectionID = propertyData?.BookInspectionID ? propertyData?.BookInspectionID : "";
  const PropertyID = propertyData?.PropertyID ? propertyData?.PropertyID : "";

  console.log("CheckDataDate", visitselecteddate);
  console.log("propertyDataQAQ", propertyData);
  const EnquiryAboutdata = {
    EnquiryAbout: propertyData?.EnquiryAbout?.replace(/-/g, ",").split(","),
  };

  console.log("EnquiryAboutdata", EnquiryAboutdata.EnquiryAbout);
  const AreYoudata = {
    AreYou: propertyData?.AreYou?.replace(/-/g, ",").split(","),
  };

  const [checkedValues, setCheckedValues] = useStateWithCallbackLazy(
    propertyData ? EnquiryAboutdata.EnquiryAbout : []
  );

  const [areYouCheckedValues, setAreYouCheckedValues] = useStateWithCallbackLazy(
    propertyData ? AreYoudata.AreYou : []
  );

  const handleSelect = (checkedName) => {
    const newNames = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    console.log("newNames", newNames);
    setCheckedValues(newNames);

    return checkedValues;
  };
  console.log("checkedValuesQQ", checkedValues);

  const AreYouHandleSelect = (checkedName) => {
    const AreYou = areYouCheckedValues?.includes(checkedName)
      ? areYouCheckedValues?.filter((name) => name !== checkedName)
      : [...(areYouCheckedValues ?? []), checkedName];
    console.log("AreYou", AreYou);
    setAreYouCheckedValues(AreYou);

    return areYouCheckedValues;
  };
  console.log("areYouCheckedValuesQQ", areYouCheckedValues);

  const onManagedByChangeStatusChanged = (e, value) => {
    console.log("VVV", value);
    setChManagedByLabel(value.label);
    setChManagedBy(value.id);
  };
  const getAppoinmentStatus = () => {
    const formattedData = { StringMapType: "APPOINTMENTSTATUS", SortType: "Alpha" };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          let appoinmentStatus = res.data.data;
          setAppoinmentStatusList(
            appoinmentStatus.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInternalStaff = () => {
    axios({
      url: CommonConfig.ApiUrl + "contact/getInternalStaffDropdown",
      method: "POST",
      headers: {},
    })
      .then((res) => {
        if (res.data.success) {
          console.log("Managed Data", res.data.data);
          setManagedData(
            res.data.data.map((item) => ({
              id: item.PersonId,
              label: item.FullName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onStatusChanged = (e, value) => {
    setChStatus(value.id);
  };
  const displaydatetime = (e) => {
    console.log("datefortime", CommonConfig.formatDate(e));
    var Selecteddate = CommonConfig.formatDate(e);
    var currentDate = new Date().toJSON().slice(0, 10);
    setvisitselecteddate(Selecteddate);
    console.log("currentdate", currentDate);
    if (Selecteddate === currentDate) {
      getTimeSlot(1);
    } else {
      getTimeSlot(0);
    }

    var dates = document.getElementById("DateValue");
    var dates2 = document.getElementById("DateValue").value;
    console.log("Dates = ", dates, " || ", dates2);
    // console.log("currentdate1111", Selecteddate);
    // setvisitselecteddate(Selecteddate);
    console.log("Appi=", visitselecteddate);
    debugger;
    // alert("Hello = " , dates)
  };
  const getTimeSlot = (n) => {
    axios({
      url: CommonConfig.ApiUrl + "Appointment/getTimeSlotValue",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.data?.success) {
          var list = [];
          var list2 = [];
          console.log("TimeSlotListRES===>");
          console.log("11111", res.data.data);
          var dt = Date();

          var newdate = dt.slice(15, -37);
          console.log("data.AppointmentDate", n);
          debugger;
          if (n === 1) {
            res.data.data.map((item1) => {
              console.log("iiii", item1.TimeValue.slice(0, -6));
              var date = item1.TimeValue.slice(0, -6);
              console.log("date", date);
              console.log("newdate", newdate);
              if (parseInt(date) <= parseInt(newdate)) {
                list.push(item1);
                console.log("//////1111");
                setTimeList(
                  list.map((item) => ({
                    id: item.TimeValue,
                    label: item.TimeLabel,
                  }))
                );
              } else if (parseInt(date) >= parseInt(newdate)) {
                list2.push(item1);
                console.log("//////2222");
                setTimeList(
                  list2.map((item) => ({
                    id: item.TimeValue,
                    label: item.TimeLabel,
                  }))
                );
              } else {
                setTimeList();
                // list2.push();
                // setTimeList(
                //   list2.map((item) => ({
                //     id: item.TimeValue,
                //     label: item.TimeLabel,
                //   }))
                // );
              }
            });
            console.log("list", list);
            console.log("list2", list2);
          } else {
            setTimeList(
              res.data.data.map((item) => ({
                id: item.TimeValue,
                label: item.TimeLabel,
              }))
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const bookingconfirm = () => {
    setBookingtype("confirm");
    // UpdateAvalibleBedroomCount();
    onsubmit();
  };
  const UpdateAvalibleBedroomCount = () => {
    var PropertyId = {
      id: PropertyID,
    };
    debugger;
    axios({
      url: CommonConfig.ApiUrl + "Property/UpdateAvalibleBedroomCount",
      method: "POST",
      headers: {},
      data: PropertyId,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const bookingvisit = () => {
    debugger;
    console.log("Helllloo");
    setBookingtype("visit");
    onSubmit();
  };
  const getLocationDropdown = () => {
    axios({
      url: CommonConfig.ApiUrl + "Property/getcitylist",
      method: "Get",
      headers: {},
      data: "",
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("1StateList===>", res.data.data[0]);
          setcitylist(
            res.data.data[0].map((item) => ({
              id: item.City,
              label: item.City,
            }))
          );
        }
        console.log("PropertyLocationData===>", PropertyLocationData);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const getPropertyCheckboxlist = () => {
    axios({
      url: CommonConfig.ApiUrl + "Property/getPropertyCheckboxlist",
      method: "Get",
      headers: {},
      data: "",
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("PropertyCheckboxlist", res.data.data);
          var list1 = [];
          res.data.data[2].map((item) => {
            list1.push(item.Feature);
          });
          setFeatureCheckboxlist(list1);

          var list2 = [];
          res.data.data[1].map((item) => {
            list2.push(item.Amenity);
          });
          setAmenityCheckboxlist(list2);

          var list3 = [];
          res.data.data[0].map((item) => {
            list3.push(item.Accesibility);
          });
          setAccessibilityCheckboxlist(list3);

          var list4 = [];
          res.data.data[3].map((item) => {
            list4.push(item.SDA);
          });
          setSdaCheckboxlist(list4);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const display = (e) => {
    debugger;
    console.log("e...", e.label);
    setselectedpropertylocation(e.label);

    console.log("array", getpropertyData);
    var name = e.label;
    GetpopertynameByCity(name);
  };
  const getpropertydetails = (name) => {
    setLoader(true);
    //setPropertyData([]);
    getpropertyData.pop();
    imageurl.length = 0;
    console.log("name....", imageurl);
    console.log("first", selectedpropertylocation);
    setselectedpropertyname(name.label);
    var inputdata = {
      city: selectedpropertylocation ? selectedpropertylocation : propertyData.City,
      name: name.label ? name.label : propertyData.PropertyName,
    };
    axios({
      url: CommonConfig.ApiUrl + "Property/getPropertydetails",
      method: "POST",
      headers: {},
      data: inputdata,
    })
      .then((res) => {
        debugger;
        if (res.data?.success) {
          var url = "";
          //setgetpropertyData(res.data.data[1][0]);
          getpropertyData.push(res.data.data[1][0]);
          console.log("getpropertyData..", getpropertyData);
          console.log("document", res.data.data[0]);

          for (var i = 0; i < res.data.data[0].length; i++) {
            url = CommonConfig.dir + "Property/" + res.data.data[0][i].Filename;
            imageurl.push(url);
            // setimageurl(url);
            console.log("imageurl11", imageurl);
          }
          setLoader(false);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const GetpopertynameByCity = (name) => {
    //setLoader(true);
    var selectedcity = {
      id: name,
    };
    axios({
      url: CommonConfig.ApiUrl + "Property/GetpopertynameByCity",
      method: "POST",
      headers: {},
      data: selectedcity,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data[0][0]);

          setPropertynamelist(
            res.data.data[0].map((item) => ({
              id: item.PropertyName,
              label: item.PropertyName,
            }))
          );
          // setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getInternalStaff();
    getAppoinmentStatus();
    getStateData();
    getPropertyCheckboxlist();
    getLocationDropdown();
    getpropertydetails("name");
    getNoteList(propertyData.BookInspectionID);
    //GetpopertynameByCity(propertyData?.City);
  }, []);

  console.log("UserData = ", propertyData);
  console.log("date...11", propertyData.ADateTime);
  const propertyloaction = {
    id: propertyData?.City,
    label: propertyData?.City,
  };
  const statedata = {
    id: userData?.State,
    label: userData?.State,
  };
  const {
    register,
    handleSubmit,

    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // InspectionDateTime: isEditEnabled
      //   ? propertyData?.InspectionDateTime
      //     ? moment(propertyData?.InspectionDateTime).format("YYYY-MM-DD")
      //     : ""
      //   : "",
      visitingDate: propertyData?.InspectionDateTime
        ? moment(propertyData.InspectionDateTime).format("dd/MM/yyyy")
        : "",
      FirstName: propertyData?.FirstName ? propertyData?.FirstName : "",
      LastName: propertyData?.LastName ? propertyData?.LastName : "",
      Phone: propertyData?.Phone ? propertyData?.Phone : "",
      Email: propertyData?.Email ? propertyData?.Email : "",
      Message: propertyData?.Message ? propertyData?.Message : "",
      PostCode: propertyData?.PostCode ? propertyData?.PostCode : "",
      //PropertyLocation: propertyData?.City ? propertyData?.City : "",

      PropertyLocationData: propertyloaction ? propertyloaction : "",
      AppointmentTime: propertyData?.VsitingTime ? propertyData?.VsitingTime : "",
      PropertyName: propertyData?.PropertyName ? propertyData?.PropertyName : "",
      visitingDate: propertyData?.ADateTime ? propertyData?.ADateTime : "",

      //BookInspectionID: propertyData?.BookInspectionID ? propertyData?.BookInspectionID : "",
      TotalAvailableBedroomCount: propertyData?.TotalAvailableBedroomCount
        ? propertyData?.TotalAvailableBedroomCount
        : "",
      TotalBedroomCount: propertyData?.TotalBedroomCount ? propertyData?.TotalBedroomCount : "",
      AppointmentDate: propertyData?.ADateTime
        ? moment(propertyData.ADateTime).format("dd/MM/yyyy")
        : "",
      Status: propertyData?.ManageStatus ? propertyData?.ManageStatus : "",
      ManageBy: propertyData?.managebyname ? propertyData?.managebyname : "",

      Contact: propertyData?.Contact ? propertyData?.Contact : "Email",
      ContactTime: propertyData?.ContactTime ? propertyData?.ContactTime : "Morning",
    },
  });

  const getNoteList = (values) => {
    let noteReqData = {
      EntityType: "AssignProperty",
      EntityId: values ? values : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
    };
    axios({
      url: CommonConfig.ApiUrl + "note/getNoteList",
      method: "POST",
      headers: {},
      data: noteReqData,
    })
      .then((res) => {
        if (res.data?.success) {
          setNotesData(res.data.data || []);
          document.getElementById("NotesTableDynamic").style.display = "block";
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const saveNotes = () => {
    if (chNotes === null || chNotes === "") {
      document.getElementById("NotesError").style.display = "block";
      return;
    } else {
      document.getElementById("NotesError").style.display = "none";
    }

    const formattedData = {
      EntityType: "AssignProperty",
      EntityId: propertyData.BookInspectionID ? propertyData.BookInspectionID : "",
      CurrentUser: CommonConfig.getCurrentUserId(),
      IsFlag: inNoteImportant ? 1 : 0,
      NoteType: "AssignProperty",
      NoteTitle: "",
      NoteText: chNotes,
    };
    debugger;
    console.log("formatteddata..", formattedData);
    axios({
      url: CommonConfig.ApiUrl + "note/addNote",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data.success === true) {
          cogoToast.success("Notes Added Successfully.");
          getNoteList(propertyData.BookInspectionID);
          setChNotes("");
        }
        console.log("test...", res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    console.log("datatatatatata", Bookingtype);
    console.log("visitselecteddateQA", visitselecteddate);
    debugger;
    if (Bookingtype !== "confirm") {
      if (visitselecteddate === "") {
        setOpenDialog(true);
      } else {
        let formattedData = "";
        formattedData = {
          ...data,
          BookInspectionId: propertyData.BookInspectionID ? propertyData.BookInspectionID : "",
          InspectionDateTime: moment(visitselecteddate).format("YYYY-MM-DD"),
          visitingDate: moment(visitselecteddate).format("dd/MM/yyyy"),
          visitingtime: visittime,
          PropertyId: getpropertyData[0] ? getpropertyData[0].PropertyID : "  ",
          manageby: data.ManageBy.id ? data.ManageBy.id : propertyData?.ManageBy,
          Status: data.Status.id ? data.Status.id : propertyData?.ManageStatus,
          bookingtype: "visit",
          boooking: "visit",

          Contact: data.Contact,
          ContactTime: data.ContactTime,
          EnquiryAbout: checkedValues.toString().replace(/,/g, "-"),
          AreYou: areYouCheckedValues.toString().replace(/,/g, "-"),
        };
        console.log("formattedData", formattedData);
        axios({
          url: CommonConfig.ApiUrl + "Property/AddupdateAssignProperty",
          method: "POST",
          headers: {},
          data: formattedData,
        })
          .then((res) => {
            setLoader(!loader);
            if (res.data.success === true) {
              cogoToast.success("Property updated Successfully.");
              navigate("/layouts/Property/BookPropertyList");
            } else {
              cogoToast.error("Something Went Wrong.");
            }
            console.log("testttttt", res.data);
          })
          .catch((error) => {
            console.log("AddUpdateErro", error);
          });
      }
    } else {
      let formattedData = "";

      formattedData = {
        ...data,
        BookInspectionId: propertyData.BookInspectionID ? propertyData.BookInspectionID : "",
        InspectionDateTime: moment(new Date()).format("YYYY-MM-DD"),
        PropertyId: getpropertyData[0] ? getpropertyData[0].PropertyID : "  ",
        bookingtype: Bookingtype,
        boooking: "confirm",

        Contact: data.Contact,
        ContactTime: data.ContactTime,
        EnquiryAbout: checkedValues.toString().replace(/,/g, "-"),
        AreYou: areYouCheckedValues.toString().replace(/,/g, "-"),
      };
      console.log("formattedDatacH", formattedData);
      axios({
        url: CommonConfig.ApiUrl + "Property/AddupdateAssignProperty",
        method: "POST",
        headers: {},
        data: formattedData,
      })
        .then((res) => {
          setLoader(!loader);
          if (res.data.success === true) {
            cogoToast.success("Booking Confirmed Successfully.");
            navigate("/layouts/Property/BookPropertyList");
          } else {
            cogoToast.error("Something Went Wrong.");
          }
          console.log("testttttt", res.data);
        })
        .catch((error) => {
          console.log("AddUpdateErro", error);
        });
    }
  };

  return (
    <>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <DashboardLayout>
        <DashboardNavbar />
        <Card sx={{ width: "100%", mt: 3 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                add
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              Edit Assign Property
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="reg_step" id="regStep1">
                <div className="reg_form">
                  <Grid container spacing={3}>
                    {CommonConfig.isInternalStaff() === 1 && userLogin.PersonType === "Admin" ? (
                      <Grid item md="3">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="ManageBy"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="ManageBy"
                                name="ManageBy"
                                value={value}
                                options={managedData ? managedData : []}
                                onChange={(e, v) => {
                                  onChange(v);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Manage By"
                                    error={errors.ManageBy}
                                    helperText={errors.ManageBy ? "ManageBy Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    ) : null}

                    <Grid item md="3">
                      <div className="reg_input">
                        <div className="reg_input auto-complete">
                          <Controller
                            control={control}
                            name="Status"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                disablePortal
                                id="Status"
                                name="Status"
                                value={value}
                                options={appoinmentStatusList ? appoinmentStatusList : []}
                                onChange={(e, v) => {
                                  onChange(v);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Status"
                                    error={errors.Status}
                                    helperText={errors.Status ? "Status Required." : ""}
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="5">
                      <div className="reg_input">
                        <Controller
                          name="FirstName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="First Name"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              // disabled={propertyData ? true : false}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="5">
                      <div className="reg_input">
                        <Controller
                          name="LastName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Last Name"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              //disabled={propertyData ? true : false}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="5">
                      <div className="reg_input">
                        <Controller
                          name="Email"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Email*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              //disabled={propertyData ? true : false}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="5">
                      <div className="reg_input">
                        <Controller
                          name="Phone"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              type="number"
                              label="Phone Number"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              //disabled={propertyData ? true : false}
                              inputProps={{
                                maxLength: 10,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="2">
                      <div className="reg_input">
                        <Controller
                          name="PostCode"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Post Code"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              //disabled={propertyData ? true : false}
                              inputProps={{
                                maxLength: 4,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="10">
                      <div className="reg_input">
                        <Controller
                          name="Message"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Message*"
                              variant="outlined"
                              value={value}
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input check_value">
                        <InputLabel>How would you like us to contact you?*</InputLabel>
                        <Controller
                          name="Contact"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              {" "}
                              <RadioGroup
                                aria-label="appointment"
                                row="true"
                                defaultValue={propertyData ? propertyData?.Contact : "Email"}
                              >
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Email"
                                  control={<Radio />}
                                  label="Email"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="SMS"
                                  control={<Radio />}
                                  label="SMS"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Phone"
                                  control={<Radio />}
                                  label="Phone"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="6">
                      <div className="reg_input check_value">
                        <InputLabel>When would you like us to contact you?*</InputLabel>
                        <Controller
                          name="ContactTime"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              {" "}
                              <RadioGroup
                                aria-label="appointment"
                                row="true"
                                defaultValue={propertyData ? propertyData?.ContactTime : "Morning"}
                              >
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Morning"
                                  control={<Radio />}
                                  label="Morning"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Afternoon"
                                  control={<Radio />}
                                  label="Afternoon"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input check_value">
                        <label>What is your enquiry about?*</label>
                        <div className="arrageCheckBox">
                          {[
                            "Disability Employment Services",
                            "Short Term Accommodation and Respite",
                            "Community Services",
                            "Allied Health",
                            "Australian Disability Enterprises",
                            "Careers",
                            "School Leavers Employment Services",
                            "Supported Independent Living",
                            "Lifestyle Centres",
                            "Support Coordination",
                            "Media Enquiries and Partnerships",
                            "Other Enquiries",
                          ].map((name) => (
                            <FormControlLabel
                              control={
                                <Controller
                                  name="EnquiryAbout"
                                  render={({ onChange: onCheckChange }) => {
                                    return (
                                      <Checkbox
                                        checked={checkedValues?.includes(name)}
                                        onChange={() => onCheckChange(handleSelect(name))}
                                      />
                                    );
                                  }}
                                  control={control}
                                />
                              }
                              key={name}
                              label={name}
                            />
                          ))}
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input check_value">
                        <label>Are you a?*</label>
                        <div className="inline-checkbox">
                          {[
                            "NDIS Participant",
                            "Current Afford Client",
                            "Carer / Family / Guardian",
                            "Referrer",
                            "Other",
                          ].map((name) => (
                            <FormControlLabel
                              control={
                                <Controller
                                  name="AreYou"
                                  render={({ onChange: onCheckChange }) => {
                                    return (
                                      <Checkbox
                                        checked={areYouCheckedValues?.includes(name)}
                                        onChange={() => onCheckChange(AreYouHandleSelect(name))}
                                      />
                                    );
                                  }}
                                  control={control}
                                />
                              }
                              key={name}
                              label={name}
                            />
                          ))}
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="12">
                      {propertyData.BookingStatus !== "confirm" ? (
                        <div className="reg_input">
                          <h4>Visiting Date & Time</h4>
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item md="4">
                      {propertyData.BookingStatus !== "confirm" ? (
                        <div className="reg_input">
                          <Controller
                            name="visitingDate"
                            defaultValue=""
                            id="visitingDate"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                  label="Date*"
                                  value={value}
                                  inputFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    onChange(date);
                                    displaydatetime(date);
                                  }}
                                  minDate={new Date()}
                                  renderInput={(params) => (
                                    <TextField
                                      id="visitingDate"
                                      name="visitingDate"
                                      // autoComplete="off"
                                      {...params}
                                      error={errors.visitingDate}
                                      helperText={
                                        errors.visitingDate ? "visitingDate Date Required." : ""
                                      }
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item md="4">
                      {propertyData.BookingStatus !== "confirm" ? (
                        <div className="reg_input">
                          <div className="reg_input auto-complete">
                            <Controller
                              control={control}
                              name="AppointmentTime"
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  disablePortal
                                  id="AppointmentTime"
                                  name="AppointmentTime"
                                  value={value}
                                  options={TimeList ? TimeList : []}
                                  onChange={(e, v) => {
                                    onChange(v);
                                    setvisittime(v.label);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Time*"
                                      error={errors.AppointmentTime}
                                      helperText={
                                        errors.AppointmentTime ? "Appointment Time Required." : ""
                                      }
                                    />
                                  )}
                                />
                              )}
                            />
                          </div>
                        </div>
                      ) : null}
                    </Grid>

                    <Grid item md="5">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="PropertyLocation"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="PropertyLocation"
                              name="PropertyLocation"
                              value={value || propertyloaction.label}
                              options={PropertyLocationData ? PropertyLocationData : ""}
                              onChange={(e, v) => {
                                onChange(v);
                                display(v);
                              }}
                              //    onClose={(e, v) => display(value)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Property Location*"
                                  value={selectedpropertylocation}
                                  error={errors.selectedpropertylocation}
                                  helperText={
                                    errors.selectedpropertylocation
                                      ? "Property Location Required."
                                      : ""
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="5">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="PropertyName"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="PropertyName"
                              name="PropertyName"
                              value={value}
                              options={PropertyNameData ? PropertyNameData : ""}
                              onChange={(e, v) => {
                                onChange(v);
                                getpropertydetails(v);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  value={selectedpropertyname}
                                  label="Property Name*"
                                  error={errors.selectedpropertyname}
                                  helperText={
                                    errors.selectedpropertyname ? "Property Name Required." : ""
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid>
                    <div className="reg_input">
                      <h5>Property Details</h5>
                    </div>
                    <div className="pd-row">
                      {getpropertyData[0] ? (
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="6">
                            <div className="pd-box">
                              <p>
                                <span>Name:</span>
                                {getpropertyData[0] ? getpropertyData[0].PropertyName : "  "}
                              </p>
                            </div>
                          </Grid>
                          <Grid item md="6">
                            <div className="pd-box">
                              <p>
                                <span>Type:</span>
                                {getpropertyData[0] ? getpropertyData[0].PropertyType : "  "}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                    </div>
                    <div className="pd-row">
                      {getpropertyData[0] ? (
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="12">
                            <div className="pd-box">
                              <p>
                                <span>Address: </span>
                                {getpropertyData[0].AddrLine1 +
                                  " ," +
                                  getpropertyData[0].AddrLine2 +
                                  " ," +
                                  getpropertyData[0].AddrLine3 +
                                  " ," +
                                  getpropertyData[0].City +
                                  " ," +
                                  getpropertyData[0].State +
                                  "," +
                                  getpropertyData[0].Country +
                                  " ," +
                                  getpropertyData[0].PostalCode}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                    </div>
                    <div className="pd-row">
                      {getpropertyData[0] ? (
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="6">
                            <div className="pd-box">
                              <p>
                                <span>Total Bedrooms:</span> {getpropertyData[0].TotalBedroomCount}
                              </p>
                            </div>
                          </Grid>
                          <Grid item md="6">
                            <div className="pd-box">
                              <p>
                                <span>Avalible Bedrooms:</span>{" "}
                                {getpropertyData[0].TotalAvailableBedroomCount}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                    </div>
                    <div className="pd-row ">
                      {getpropertyData[0] ? (
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="6">
                            <div className="pd-box">
                              <p>
                                <span>Features:</span> {getpropertyData[0].PropertyFeature}
                              </p>
                            </div>
                          </Grid>
                          <Grid item md="6">
                            <div className="pd-box">
                              <p>
                                <span>Amenities:</span> {getpropertyData[0].PropertyAminities}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                    </div>
                    <div className="pd-row last">
                      {getpropertyData[0] ? (
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                          <Grid item md="6">
                            <div className="pd-box">
                              <p>
                                <span>Accessibility:</span>{" "}
                                {getpropertyData[0].PropertyAccesibility}
                              </p>
                            </div>
                          </Grid>
                          <Grid item md="6">
                            <div className="pd-box">
                              <p>
                                <span>SDA:</span> {getpropertyData[0].PropertySDA}
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                    </div>
                  </Grid>
                </div>
              </div>
              {getpropertyData[0] ? (
                <Grid container spacing={3}>
                  <Grid item md="12">
                    <div>
                      <h5>Property Images</h5>
                      <div className="added-property-images">
                        {imageurl
                          ? imageurl.map((name) => (
                              <span>
                                <img src={name}></img>
                              </span>
                            ))
                          : null}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              {CommonConfig.isInternalStaff() === 1 ? (
                <Card sx={{ width: "100%", mt: 6 }}>
                  <MDBox display="flex">
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="4rem"
                      height="4rem"
                      variant="gradient"
                      bgColor="primary"
                      color="white"
                      shadow="md"
                      borderRadius="xl"
                      ml={3}
                      mt={-2}
                    >
                      <Icon fontSize="medium" color="inherit">
                        warning_amber
                      </Icon>
                    </MDBox>
                    <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                      Notes
                    </MDTypography>
                  </MDBox>
                  <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
                    <div className="notes-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Notes</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="nt-date">
                              {moment(new Date()).format("DD-MM-YYYY")}{" "}
                              <span> {moment(new Date()).format("hh:mm A")}</span>
                            </td>

                            <td className="nt-comment">
                              <div className="reg_input mb-0">
                                <TextField
                                  onChange={(e) => setChNotes(e.target.value)}
                                  value={chNotes ? chNotes : ""}
                                  name="notes"
                                  multiline
                                  rows={2}
                                  // maxRows={4}
                                  label="Notes Text*"
                                  id="regFirstName"
                                  fullWidth
                                />
                                <span class="errorTC" id="NotesError">
                                  Please Write Notes.
                                </span>
                              </div>
                            </td>
                            <td className="nt-action">
                              <PButton
                                type="button"
                                icon="pi pi-plus"
                                className="p-button-primary AddNotes"
                                onClick={() => saveNotes()}
                                tooltip="Add Notes"
                                tooltipOptions={{ position: "bottom" }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <NotesComponent
                      entityType="AssignProperty"
                      entityId={propertyData.BookInspectionID}
                      NotesData={notesData}
                    />
                  </MDBox>
                </Card>
              ) : (
                <></>
              )}
              <div>
                <div className="reg_submit mt-20">
                  <MDButton
                    onClick={() => navigate("/layouts/Property/BookPropertyList")}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    type="submit"
                    onClick={bookingvisit}
                    variant="contained"
                    color="primary"
                  >
                    update
                  </MDButton>
                  <MDButton
                    type="submit"
                    onClick={bookingconfirm}
                    variant="contained"
                    color="success"
                  >
                    Confirm Booking
                  </MDButton>
                </div>
              </div>
              <div className="copyright">
                <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
              </div>
            </form>
          </MDBox>
        </Card>
      </DashboardLayout>
    </>
  );
};

//EditBookPrperty.defaultProps = {};

export default EditBookProperty;
