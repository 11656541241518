import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CommonConfig } from "constant";
import "assets/css/style.css";
import { useForm, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Grid from "@mui/material/Grid";
import {
  Add,
  ArrowDownward,
  CancelScheduleSendTwoTone,
  ControlCameraTwoTone,
  NoBackpackSharp,
} from "@mui/icons-material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import axios from "axios";
import cogoToast from "cogo-toast";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CardContent from "@mui/material/CardContent";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as PButton } from "primereact/button";
import { TextField } from "@mui/material";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
} from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/material/Autocomplete";

function BookPropertyList(props) {
  let navigate = useNavigate();

  if (localStorage.getItem("userData")) {
    // alert("Hello");
  } else {
    // alert("Hello2")
    navigate("/");
  }
  let i = 1;
  const { state } = useLocation();
  const [apiData, setApiData] = useState([]);
  const [rememberMe, setRememberMe] = useState(true);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [name, setName] = useState("");
  const [cityOrState, setCityOrState] = useState("");
  const [userType, setUserType] = useState("");
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [personIdNumber, setPersonIdNumber] = useState("");
  const [stateList, setStateList] = useState(null);
  const [values, setValues] = useState([]);
  const [stateLabel, setStateLabel] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMediumType, setSelectedMediumType] = useState(false);
  const [appointmentdelID, setappointmentdelID] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [conformationData, setConformationData] = useState(null);
  const [bookingAllStatus, setBookingAllStatus] = useState(null);

  let loginUser;

  loginUser = JSON.parse(localStorage.getItem("userData"));

  var userTypeMessage = "Assign Property List";

  console.log("Testing12 ", loginUser.PersonId);

  console.log("bookingAllStatus", bookingAllStatus);

  const columns = [
    {
      name: "Assign Number",
      selector: "BookID",
      filter: true,
      sortable: true,
    },
    {
      name: "Address",
      selector: "Address",
      filter: true,
      sortable: true,
    },
    //selector: "AddrLine1",

    {
      name: "Name",
      selector: "FirstName",
      filter: true,
      sortable: true,
    },

    {
      name: "Email",
      selector: "Email",
      filter: true,
      sortable: true,
    },
    {
      name: "Phone",
      selector: "Phone",
      filter: true,
      sortable: true,
    },
    {
      name: "Booking Status",
      selector: "BookingStatus",
      filter: true,
      sortable: true,
    },
    {
      name: "Date",
      // body: (data) => {
      //   if (data.ADateTime) {
      //     return moment(data.ADateTime).format("MM/DD/YYYY");
      //   }
      //   return "";
      // },
      selector: "ADateTime",
      filter: true,
      sortable: true,
    },
    {
      name: "Time",
      selector: "VsitingTime",
      filter: true,
      sortable: true,
      rowReorderIcon: null,
      filtericon: false,
    },
    {
      name: "Action",
      body: (data) => actionTemplate(data),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const bookingStatus = () => {
    setConformationData("Active");
    setTimeout(() => {
      setConformationData(null);
    }, 3000);
  };
  useEffect(() => {
    console.log("checkupdatenstatus", bookingAllStatus);
    if (bookingAllStatus) {
      getColoumnsRow();
    }
  }, [bookingAllStatus]);

  const actionTemplate = (data) => {
    return (
      <div>
        <PButton
          type="button"
          icon="pi pi-user-edit"
          className="p-button-warning"
          onClick={() => editUser(data)}
          style={{ marginRight: ".5em" }}
          tooltip="Edit Assign Property"
          tooltipOptions={{ position: "bottom" }}
        />
        &nbsp;
        <PButton
          type="button"
          icon="pi pi-user-minus"
          className="p-button-danger"
          onClick={() => {
            // deleteUser(data);
            selectedRow(data);
          }}
          tooltip="Remove Book Property"
          tooltipOptions={{ position: "bottom" }}
        />
        &nbsp;
        {/* {data.BookingStatus === "confirm" ? ( */}
        {bookingAllStatus === null || bookingAllStatus === "confirm" ? (
          <PButton
            type="button"
            icon="pi pi-calendar-plus"
            className="p-button-success"
            disabled={conformationData}
            onClick={() => {
              // deleteUser(data);
              ConfirmRow(data);
            }}
            // onClick={() => AddUser(data)}
            tooltip="Confirm Booking"
            tooltipOptions={{ position: "bottom" }}
          />
        ) : null}
        {/* {data.BookingStatus === "Booked" ? ( */}
        {bookingAllStatus === "Complete" ? (
          <span>
            <PButton
              type="button"
              icon="pi pi-check"
              className="p-button-success"
              disabled={conformationData}
              onClick={() => {
                StatusRow(data, "Complete");
              }}
              tooltip="Complete Booking"
              tooltipOptions={{ position: "bottom" }}
            />
            &nbsp;
            <PButton
              type="button"
              icon="pi pi-times"
              className="p-button-danger"
              onClick={() => {
                StatusRow(data, "Cancelled");
              }}
              tooltip="Cancel Booking"
              tooltipOptions={{ position: "bottom" }}
            />
          </span>
        ) : null}
      </div>
    );
  };

  const getColoumnsRow = () =>
    columns.map((item, id) => {
      return (
        <Column
          style={{ minWidth: "200px" }}
          key={id}
          sortable={item.sortable}
          body={item?.body}
          filter={item.filter}
          field={item.selector}
          header={item.name}
          icon="pi pi-filter-icon pi-filter"
        />
      );
    });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    UserType: "",
  });

  const getData = (props) => {
    setLoader(!loader);
    debugger;
    axios({
      url: CommonConfig.ApiUrl + "Property/getBookInspectionlist",

      method: "get",
      headers: {
        // https://uatapi.valuecare.org.au/Referral/getReferralList
        // Add any auth token here
        //authorization: "your token comes here",
      },
    })
      .then((res) => {
        setLoader(!loader);
        if (res.data?.success === true) {
          console.log("resdata", res.data.data[0]);
          setLoader(false);
          if (res.data.data[0].length > 0) {
            console.log("resdata33", res.data.data[0]);
            const mapData = res.data.data[0].map((tempData) => {
              tempData.IsMultiLivingOption = "";
              tempData.IsPreferred = "";
              tempData.BookingStatus = "";
              return tempData;
            });
            setApiData(mapData);
            console.log("Data...1", mapData);
          }
          // console.log("Data...1", mapData);
        }
        // console.log("test...hhghghg", res.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const CancelDelete = () => {
    setSelectedMediumType("");
    setOpenDialog(false);
  };

  const StatusRow = (StatusRowData, StatusType) => {
    var PropertyId = {
      id: StatusRowData.PropertyID,
      bookID: StatusRowData.BookInspectionID,
      statusdata: StatusType,
    };

    axios({
      url: CommonConfig.ApiUrl + "Property/UpdateAvalibleBedroomCount",
      method: "POST",
      headers: {},
      data: PropertyId,
    })
      .then((res) => {
        if (res.data?.success) {
          cogoToast.success("Booking " + StatusType + " successfully");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ConfirmRow = (Confirmdata) => {
    console.log("Confirmdata = ", Confirmdata?.BookingStatus);
    setBookingAllStatus(Confirmdata?.BookingStatus);
    UpdateAvalibleBedroomCount(Confirmdata);
    bookingStatus();
  };

  const UpdateAvalibleBedroomCount = (PropertyId) => {
    var PropertyId = {
      id: PropertyId.PropertyID,
      bookID: PropertyId.BookInspectionID,
    };
    debugger;
    axios({
      url: CommonConfig.ApiUrl + "Property/UpdateAvalibleBedroomCount",
      method: "POST",
      headers: {},
      data: PropertyId,
    })
      .then((res) => {
        if (res.data?.success) {
          cogoToast.success("Room alloted successfully");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectedRow = (selectedRow = {}) => {
    debugger;
    setappointmentdelID(selectedRow.BookInspectionID);

    console.log("........,new", appointmentdelID);
    setOpenDialog(true);
  };
  const deleteUser = (selectedRow = {}) => {
    debugger;
    // setappointmentdelID(selectedRow.ReferralId);
    setSelectedMediumType(false);
    //setOpenDialog(true);
    console.log("........,,,ddddd", appointmentdelID);

    // if (selectedMediumType == "") {
    //   document.getElementById("ServicesError").style.display = "block";
    //   return;
    // } else {
    //   document.getElementById("ServicesError").style.display = "none";
    // }

    if (selectedMediumType == "Yes") {
      document.getElementById("ServicesError").style.display = "none";
      let inputData = {
        id: appointmentdelID,
      };
      console.log("inputData...", inputData);
      axios({
        // Endpoint to send files
        url: CommonConfig.ApiUrl + "Property/deleteBookInspection",
        method: "POST",
        headers: {},

        data: inputData,
      })
        // Handle the response from backend here
        .then((res) => {
          if (res.data?.success) {
            console.log("test222", res.data);

            if (res.data.success === true) {
              cogoToast.success("Deleted Successfully.");
              setOpenDialog(false);
              getData();
            }
          }
        })
        // Catch errors if any
        .catch((error) => {
          console.log(error);
        });
    } else if (selectedMediumType == "No") {
      document.getElementById("ServicesError").style.display = "none";
      setOpenDialog(false);
    } else {
      document.getElementById("ServicesError").style.display = "block";
    }
  };

  const editUser = async (row = {}) => {
    debugger;
    setLoader(!loader);
    const BookInspectionID = row.BookInspectionID;
    var rowdata = row;
    console.log("PropertyID////0", row?.BookingStatus);

    navigate("/layouts/Property/EditBookProperty", {
      state: rowdata,
    });
    // var id = { id: BookInspectionID };
    // // console.log("idddddddddddd", id);
    // // {"id":"c7604f2b-6996-11ed-9f52-fa163e3ffd3c"}
    // try {
    //   const data = await axios(CommonConfig.ApiUrl + "Property/getBookPropertylistById", {
    //     method: "POST",
    //     data: id,
    //   }).then((res) => {
    //     console.log("ressss", res);
    //     setLoader(!loader);
    //     var userdata = res.data.data[0][0];
    //     //   dOC: res.data.data[1],

    //     var documentdata = [];

    // documentdata.push(res.data.data[1]);
    // var newdata = userdata.concat(documentdata);
    // documentdata.map((value) => {
    //   userdata.push(value);
    // });

    // });

    // navigate("/layouts/referal/refEdit", {
    //   state: data,
    // });
    // } catch (error) {
    //   console.log({ error });
    // }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {/* 
      "Name": "",
  "CityOrState": "",
  "UserType":"",
  "PhoneOrEmail":"",
  "PersonIdNumber":"" */}
      <Card sx={{ width: "100%", mt: 3 }}>
        <MDBox display="flex">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="primary"
            color="white"
            shadow="md"
            borderRadius="xl"
            ml={3}
            mt={-2}
          >
            <Icon fontSize="medium" color="inherit">
              person_add
            </Icon>
          </MDBox>
          <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
            {userTypeMessage}
          </MDTypography>
        </MDBox>
        <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
          <div className="card">
            <div className="table-custom">
              <DataTable
                scrollable
                value={apiData}
                showGridlines
                scrollHeight="600px"
                scrollWidth="600px"
                responsiveLayout="scroll"
                paginator
                rows={10}
                totalRecords={apiData ? apiData.length : 0}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[10, 20, 50]}
                emptyMessage="No records found"
                // paginatorLeft={paginatorLeft}
                // paginatorRight={paginatorRight}
                filterDisplay="row"
              >
                {getColoumnsRow()}
              </DataTable>
            </div>
          </div>
        </MDBox>
      </Card>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="xs">
        <DialogTitle>Remove Assign Property</DialogTitle>
        <DialogContent>
          <Grid item md="12">
            <div className="reg_input check_value mb-0">
              <InputLabel className="reg_lable mt-5 mb-2">
                Are You Sure to Remove this booking.?
              </InputLabel>
              <div>
                <span class="servicesError" id="ServicesError">
                  Please select any one option.
                </span>
              </div>
              <RadioGroup
                aria-label="remove"
                name="Remove"
                row="true"
                onChange={(e) => setSelectedMediumType(e.target.value)}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio checked={selectedMediumType === "Yes" ? true : false} />}
                  label="Yes"
                  name="Remove"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio checked={selectedMediumType === "No" ? true : false} />}
                  label="No"
                  name="Remove"
                />
              </RadioGroup>
              <span class="errorTC" id="ServicesError">
                Please select any one service
              </span>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton variant="contained" color="secondary" onClick={() => CancelDelete()}>
            Back
          </MDButton>
          <MDButton
            variant="contained"
            color="primary"
            onClick={() => {
              deleteUser();
            }}
          >
            Ok
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default BookPropertyList;
