import React, { useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import cogoToast from "cogo-toast";
import axios from "axios";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
// import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDInput from "components/MDInput";
import { CommonConfig } from "constant";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MDEditor from "components/MDEditor";
// import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import {
  FormControlLabel,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Radio,
  Select,
  RadioGroup,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Logo from "assets/img/valuecare-logo.png";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { item } from "examples/Sidenav/styles/sidenavItem";

import { isValidDate } from "@fullcalendar/react";
import { Alert } from "@mui/material";
import { redirect } from "react-router";

const schema = yup.object().shape({
  FirstName: yup.string().required("First Name Required."),

  LastName: yup.string().required("Last Name Required."),

  PostCode: yup
    .string()
    .required("Postal Code Required.")
    .matches("^[0-9]+$", "Postal Code should be number.")
    .min(4, "Invalid Postal Code.")
    .max(4, "Invalid Postal Code."),
  // State: yup.object().required("State Required."),
  PropertyName: yup.object().required("Property Name Required."),
  Email: yup.string().required("Email Required.").email("Incorrect email format."),
  Phone: yup
    .string()
    .required("Phone Required.")
    .matches("^[0-9]+$", "Phone should be number.")
    .min(10, "Invalid Phone Number.")
    .max(10, "Invalid Phone Number."),
  Message: yup.string().required("Message Required."),
  PropertyLocation: yup.object().required("Property Location Required."),
  NumberOfBedrooms: yup.object().required("Number of Bedrooms Required."),
  //VisitingDate: yup.object().required("please Enter date."),

  // EnquiryAbout: yup.string().when(, {
  //   is: true,
  //   then: yup.string().required("Field is required"),
  //   otherwise: yup.string(),
  // }),
});

const AssignProperty = () => {
  const defaultFeatures = ["Dining Area"];
  const defaultAmenities = ["Air Condition"];
  const defaultAccessibility = ["Wheelchair accessible"];
  const defaultSDA = ["Highly physical support"];

  const { state } = useLocation();

  let navigate = useNavigate();
  let clientRef = useRef(false);
  const [propertyData, setPropertyData] = useState(state || null);
  const [values, setValues] = useState();
  const [stateId, setStateId] = useState([]);
  const [stateLabel, setStateLabel] = useState(state?.SecurityUserData?.State || null);
  const [relativeStateId, setRelativeStateId] = useState([]);
  const [relativeStateLabel, setRelativeStateLabel] = useState(
    state?.SecurityUserData?.RelativeState || null
  );
  const [serviceAutocompleteValues, setServiceAutocompleteValues] = useState(null);
  const [TimeList, setTimeList] = useStateWithCallbackLazy(null);
  const [userData, setUserData] = useState(state?.SecurityUserData || null);
  const [citylist, setcitylist] = useState(null);
  const [Propertynamelist, setPropertynamelist] = useState(null);
  const [selectedpropertylocation, setselectedpropertylocation] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [datetimeshow, setdatetimeshow] = useState(false);
  const [visitselecteddate, setvisitselecteddate] = useState("");
  const [visittime, setvisittime] = useState("");
  const [bookingtype, setbookingtype] = useState("");
  const [redirect, setredirect] = useState(true);
  const [BookInspectionId, setBookInspectionId] = useState("");

  const [getpropertyData, setgetpropertyData] = useState([]);
  const [stateList, setStateList] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [featureCheckboxlist, setFeatureCheckboxlist] = useState([]);
  const [amenityCheckboxlist, setAmenityCheckboxlist] = useState([]);
  const [accessibilityCheckboxlist, setAccessibilityCheckboxlist] = useState([]);
  const [sdaCheckboxlist, setSdaCheckboxlist] = useState([]);
  const [showsavebutton, setshowsavebutton] = React.useState(false);
  const [serviceList, setServiceList] = useState(null);
  const [loader, setLoader] = React.useState(false);
  const [value, setValue] = React.useState();
  const [imageurl, setimageurl] = useState([]);
  const [chackedValue, setChackedValue] = useState(null);
  const [areyouChackedValue, setAreyouChackedValue] = useState(null);
  console.log("chackedValuedddd", chackedValue);

  const PropertyFeaturedata = {
    // Feature: propertyData?.PropertyFeature.split(","),
  };
  const PropertyAminitiesdata = {
    //Feature: propertyData?.PropertyAminities.split(","),
  };
  const PropertyAccesibilitydata = {
    // Feature: propertyData?.PropertyAccesibility.split(","),
  };
  const PropertySDAdata = {
    //Feature: propertyData?.PropertySDA.split(","),
  };

  console.log("PropertyFeaturedata", propertyData);

  const [checkedValues, setCheckedValues] = useStateWithCallbackLazy([]);

  const [areYouCheckedValues, setAreYouCheckedValues] = useStateWithCallbackLazy([]);
  const [accessibilityCheckedValues, setAccessibilityCheckedValues] = useStateWithCallbackLazy(
    PropertyAccesibilitydata.Feature ? PropertyAccesibilitydata.Feature : []
  );
  const [sdaCheckedValues, setSdaCheckedValues] = useStateWithCallbackLazy(
    PropertySDAdata.Feature ? PropertySDAdata.Feature : []
  );
  const RoomsData = [{ label: 1 }, { label: 2 }, { label: 3 }, { label: 4 }, { label: 5 }];
  const PropertyLocationData = citylist;
  const PropertyNameData = Propertynamelist;
  // let dir = "https://services.valuecare.org.au/Backend/documents/Property/";
 
  // let dir = CommonConfig.dir + "Property/";
  // const GetData = (e, value) => {
  //   var setoffice = e.target.value;
  //   console.log("GetData=", setoffice);
  //   var dataSET = {
  //     officeSite: setoffice,
  //   };
  // };

  const handleSelect = (checkedName) => {
    const newNames = checkedValues?.includes(checkedName)
      ? checkedValues?.filter((name) => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    console.log("newNames", newNames);
    setCheckedValues(newNames);

    return checkedValues;
  };
  console.log("checkedValuesQQ", checkedValues);

  const AreYouHandleSelect = (checkedName) => {
    const AreYou = areYouCheckedValues?.includes(checkedName)
      ? areYouCheckedValues?.filter((name) => name !== checkedName)
      : [...(areYouCheckedValues ?? []), checkedName];
    console.log("AreYou", AreYou);
    setAreYouCheckedValues(AreYou);

    return areYouCheckedValues;
  };
  console.log("areYouCheckedValuesQQ", areYouCheckedValues);

  const AccessibilityHandleSelect = (checkedName) => {
    const AccessibilityNames = accessibilityCheckedValues?.includes(checkedName)
      ? accessibilityCheckedValues?.filter((name) => name !== checkedName)
      : [...(accessibilityCheckedValues ?? []), checkedName];
    console.log("accessibilityNames", AccessibilityNames);
    setAccessibilityCheckedValues(AccessibilityNames);

    return accessibilityCheckedValues;
  };
  console.log("accessibilityCheckedValuesQQ", accessibilityCheckedValues);

  const SdaHandleSelect = (checkedName) => {
    const SdaNames = sdaCheckedValues?.includes(checkedName)
      ? sdaCheckedValues?.filter((name) => name !== checkedName)
      : [...(sdaCheckedValues ?? []), checkedName];
    console.log("accessibilityNames", SdaNames);
    setSdaCheckedValues(SdaNames);

    return sdaCheckedValues;
  };

  console.log("checkedValues", checkedValues);
  const display = (e) => {
    console.log("e...", e.label);
    setselectedpropertylocation(e.label);
    setgetpropertyData([]);
    var name = e.label;
    var selectedcity = {
      id: name,
    };
    setLoader(true);
    //GetpopertynameByCity;
    axios({
      url: CommonConfig.ApiUrl + "Property/GetpopertynameByCity",
      method: "POST",
      headers: {},
      data: selectedcity,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data[0][0]);

          setPropertynamelist(
            res.data.data[0].map((item) => ({
              id: item.PropertyName,
              label: item.PropertyName,
            }))
          );
          setLoader(false);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const getpropertydetails = (name) => {
    setLoader(true);
    getpropertyData.pop();
    imageurl.length = 0;
    console.log("name....", name);
    console.log("first", selectedpropertylocation);
    var inputdata = {
      city: selectedpropertylocation,
      name: name.label,
    };
    axios({
      url: CommonConfig.ApiUrl + "Property/getPropertydetails",
      method: "POST",
      headers: {},
      data: inputdata,
    })
      .then((res) => {
        var url = "";
        if (res.data?.success) {
          getpropertyData.push(res.data.data[1][0]);

          console.log("document...", res.data.data[0]);

          for (var i = 0; i < res.data.data[0].length; i++) {
            url = CommonConfig.dir + "Property/" + res.data.data[0][i].Filename;
            imageurl.push(url);
            console.log("checkimgValue", imageurl);
          }
          setLoader(false);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const getStateData = () => {
    const formattedData = {
      StringMapType: "STATE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);

          setStateList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const getLocationDropdown = () => {
    axios({
      url: CommonConfig.ApiUrl + "Property/getcitylist",
      method: "Get",
      headers: {},
      data: "",
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("1StateList===>", res.data.data[0]);
          setcitylist(
            res.data.data[0].map((item) => ({
              id: item.City,
              label: item.City,
            }))
          );
        }
        console.log("PropertyLocationData===>", PropertyLocationData);
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const getPropertyCheckboxlist = () => {
    axios({
      url: CommonConfig.ApiUrl + "Property/getPropertyCheckboxlist",
      method: "Get",
      headers: {},
      data: "",
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("PropertyCheckboxlist", res.data.data);
          var list1 = [];
          res.data.data[2].map((item) => {
            list1.push(item.Feature);
          });
          setFeatureCheckboxlist(list1);

          var list2 = [];
          res.data.data[1].map((item) => {
            list2.push(item.Amenity);
          });
          setAmenityCheckboxlist(list2);

          var list3 = [];
          res.data.data[0].map((item) => {
            list3.push(item.Accesibility);
          });
          setAccessibilityCheckboxlist(list3);

          var list4 = [];
          res.data.data[3].map((item) => {
            list4.push(item.SDA);
          });
          setSdaCheckboxlist(list4);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const setdatetime = (data) => {
    if (visitselecteddate && visittime) console.log("data...", visitselecteddate);
    console.log("data...", visittime);
    debugger;
    setdatetimeshow(true);
    setOpenDialog(false);
    setshowsavebutton(true);
  };
  const CancelDelete = () => {
    setOpenDialog(false);
  };

  const displaydatetime = (e) => {
    console.log("datefortime", CommonConfig.formatDate(e));
    var Selecteddate = CommonConfig.formatDate(e);
    var currentDate = new Date().toJSON().slice(0, 10);
    console.log("currentdate", currentDate);
    if (Selecteddate === currentDate) {
      getTimeSlot(1);
    } else {
      getTimeSlot(0);
    }

    var dates = document.getElementById("DateValue");
    var dates2 = document.getElementById("DateValue").value;
    console.log("Dates = ", dates, " || ", dates2);

    console.log("Appi=", dates2);
    setvisitselecteddate(Selecteddate);
    // alert("Hello = " , dates)
  };

  const getTimeSlot = (n) => {
    axios({
      url: CommonConfig.ApiUrl + "Appointment/getTimeSlotValue",
      method: "GET",
      headers: {},
    })
      .then((res) => {
        if (res.data?.success) {
          var list = [];
          var list2 = [];
          console.log("TimeSlotListRES===>");
          console.log("11111", res.data.data);
          var dt = Date();

          var newdate = dt.slice(15, -37);
          console.log("data.AppointmentDate", n);
          debugger;
          if (n === 1) {
            res.data.data.map((item1) => {
              console.log("iiii", item1.TimeValue.slice(0, -6));
              var date = item1.TimeValue.slice(0, -6);
              console.log("date", date);
              console.log("newdate", newdate);
              if (parseInt(date) <= parseInt(newdate)) {
                list.push(item1);
                console.log("//////1111");
                setTimeList(
                  list.map((item) => ({
                    id: item.TimeValue,
                    label: item.TimeLabel,
                  }))
                );
              } else if (parseInt(date) >= parseInt(newdate)) {
                list2.push(item1);
                console.log("//////2222");
                setTimeList(
                  list2.map((item) => ({
                    id: item.TimeValue,
                    label: item.TimeLabel,
                  }))
                );
              } else {
                setTimeList();
                // list2.push();
                // setTimeList(
                //   list2.map((item) => ({
                //     id: item.TimeValue,
                //     label: item.TimeLabel,
                //   }))
                // );
              }
            });
            console.log("list", list);
            console.log("list2", list2);
          } else {
            setTimeList(
              res.data.data.map((item) => ({
                id: item.TimeValue,
                label: item.TimeLabel,
              }))
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getStateData();
    getPropertyCheckboxlist();
    getLocationDropdown();
  }, []);

  console.log("UserData = ", propertyData);
  const statedata = {
    id: userData?.State,
    label: userData?.State,
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    // errors,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      //   names: defaultNames ? defaultNames : "",
      //   State: userData?.State ? statedata : "",
      FirstName: propertyData?.FirstName ? propertyData?.FirstName : "",
      LastName: propertyData?.LastName ? propertyData?.LastName : "",
      Phone: propertyData?.Phone ? propertyData?.Phone : "",
      Email: propertyData?.Email ? propertyData?.Email : "",
      PostCode: propertyData?.PostCode ? propertyData?.PostCode : "",
      Contact: "Email",
      ContactTime: "Morning",
    },
  });
  const bookingconfirm = () => {
    setbookingtype("confirm");
    onsubmit();
  };
  const bookingsave = () => {
    if (checkedValues.length === 0) {
      document.getElementById("checkedValuesError").style.display = "block";
      return;
    } else {
      document.getElementById("checkedValuesError").style.display = "none";
    }

    if (areYouCheckedValues.length === 0) {
      document.getElementById("areYoucheckedValuesError").style.display = "block";
      return;
    } else {
      document.getElementById("areYoucheckedValuesError").style.display = "none";
    }

    setbookingtype("save");
    //  UpdateAvalibleBedroomCount();
    onsubmit();
  };
  const UpdateAvalibleBedroomCount = () => {
    var PropertyId = {
      id: getpropertyData[0] ? getpropertyData[0].PropertyID : "  ",
    };
    debugger;
    axios({
      url: CommonConfig.ApiUrl + "Property/UpdateAvalibleBedroomCount",
      method: "POST",
      headers: {},
      data: PropertyId,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("StateList===>", res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("checkedValues.toString()", checkedValues.toString().replace(/,/g, "-"));

  const onSubmit = (data) => {
    console.log("checkData", data);
    console.log("datatatata", data);
    debugger;
    if (bookingtype === "save") {
      let formattedData = "";
      formattedData = {
        ...data,
        visitingDate: moment(new Date()).format("YYYY-MM-DD"),
        //  visitingtime: '',
        PropertyId: getpropertyData[0] ? getpropertyData[0].PropertyID : "  ",
        PostCode: data.PostCode.toString(),
        //  bookingtype: bookingtype,
        NumberOfBedrooms: data.NumberOfBedrooms.label,
        PropertyLocation: data.PropertyLocation.label,
        PropertyName: data.PropertyName.label,
        // PropertyFeature: checkedValues.toString(),
        // PropertyAminities: amenitiesCheckedValues.toString(),
        PropertyAccesibility: accessibilityCheckedValues.toString(),
        PropertySDA: sdaCheckedValues.toString(),
        Contact: data.Contact,
        ContactTime: data.ContactTime,
        EnquiryAbout: checkedValues.toString().replace(/,/g, "-"),
        AreYou: areYouCheckedValues.toString().replace(/,/g, "-"),
        EmailData: data.Email,
      };
      console.log("formattedDataQA", formattedData);
      setLoader(true);
      axios({
        url: CommonConfig.ApiUrl + "Property/AddupdateAssignProperty",
        method: "POST",
        headers: {},
        data: formattedData,
      })
        .then((res) => {
          //  setLoader(!loader);
          if (res.data.success === true) {
            setLoader(false);
            cogoToast.success("Property save Successfully.");

            setshowsavebutton(true);
            setbookingtype("");
            setBookInspectionId(res.data.data[0][0].BookInspectionID);
            //  console.log("testttttt", res.data.data[0][0].BookInspectionID);
            // navigate("/layouts/Property/BookPropertyList");
          } else {
            cogoToast.error("Something Went Wrong.");
          }
          // console.log("testttttt", res.data[0][0].BookInspectionID);
        })
        .catch((error) => {
          console.log("AddUpdateErro", error);
        });
    } else if (bookingtype !== "confirm") {
      debugger;
      if (visitselecteddate === "" && visittime === "") {
        setOpenDialog(true);
        setshowsavebutton(true);
      } else if (visitselecteddate !== "" && visittime !== "") {
        if (redirect === true) {
          let formattedData = "";
          formattedData = {
            ...data,
            BookInspectionId: BookInspectionId ? BookInspectionId : "",
            InspectionDateTime: moment(visitselecteddate).format("YYYY-MM-DD"),
            visitingtime: visittime,
            PropertyId: getpropertyData[0] ? getpropertyData[0].PropertyID : "  ",
            PostCode: data.PostCode.toString(),
            bookingtype: "visit",
            NumberOfBedrooms: data.NumberOfBedrooms.label,
            PropertyLocation: data.PropertyLocation.label,
            EmailData: data.Email,
            PropertyName: data.PropertyName.label,
            // PropertyFeature: checkedValues.toString(),
            // PropertyAminities: amenitiesCheckedValues.toString(),
            PropertyAccesibility: accessibilityCheckedValues.toString(),
            PropertySDA: sdaCheckedValues.toString(),
            CurrentUser: CommonConfig.getCurrentUserId(),

            Contact: data.Contact,
            ContactTime: data.ContactTime,
            EnquiryAbout: checkedValues.toString(),
            AreYou: areYouCheckedValues.toString(),
          };
          console.log("formattedData", formattedData);
          axios({
            url: CommonConfig.ApiUrl + "Property/AddupdateAssignProperty",
            method: "POST",
            headers: {},
            data: formattedData,
          })
            .then((res) => {
              setLoader(!loader);
              if (res.data.success === true) {
                cogoToast.success("Property Booked for visit Successfully.");
                navigate("/layouts/Property/BookPropertyList");
              } else {
                cogoToast.error("Something Went Wrong.");
              }
              console.log("testttttt", res.data);
            })
            .catch((error) => {
              console.log("AddUpdateErro", error);
            });
        }
      }
    } else {
      let formattedData = "";
      formattedData = {
        ...data,
        BookInspectionId: BookInspectionId ? BookInspectionId : "",
        visitingDate: moment(new Date()).format("YYYY-MM-DD"),
        visitingtime: visittime,
        PropertyId: getpropertyData[0] ? getpropertyData[0].PropertyID : "  ",
        PostCode: data.PostCode.toString(),
        bookingtype: bookingtype,
        NumberOfBedrooms: data.NumberOfBedrooms.label,
        PropertyLocation: data.PropertyLocation.label,
        PropertyName: data.PropertyName.label,
        // PropertyFeature: checkedValues.toString(),
        // PropertyAminities: amenitiesCheckedValues.toString(),
        PropertyAccesibility: accessibilityCheckedValues.toString(),
        PropertySDA: sdaCheckedValues.toString(),
        EmailData: data.Email,

        Contact: data.Contact,
        ContactTime: data.ContactTime,
        EnquiryAbout: checkedValues.toString(),
        AreYou: areYouCheckedValues.toString(),
      };
      console.log("formattedData", formattedData);
      axios({
        url: CommonConfig.ApiUrl + "Property/AddupdateAssignProperty",
        method: "POST",
        headers: {},
        data: formattedData,
      })
        .then((res) => {
          setLoader(!loader);
          if (res.data.success === true) {
            cogoToast.success("Property Booked Successfully.");
            navigate("/layouts/Property/BookPropertyList");
          } else {
            cogoToast.error("Something Went Wrong.");
          }
          console.log("testttttt", res.data);
        })
        .catch((error) => {
          console.log("AddUpdateErro", error);
        });
    }
  };

  return (
    <>
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <DashboardLayout>
        <DashboardNavbar />
        <Card sx={{ width: "100%", mt: 3 }}>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="primary"
              color="white"
              shadow="md"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="medium" color="inherit">
                add
              </Icon>
            </MDBox>
            <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
              Assign Property
            </MDTypography>
          </MDBox>
          <MDBox sx={{ pt: 4, pb: 2, pl: 2, pr: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="reg_step" id="regStep1">
                <div className="reg_form">
                  <Grid container spacing={3}>
                    <Grid item md="5">
                      <div className="reg_input">
                        <Controller
                          name="FirstName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="First Name*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              disabled={propertyData ? true : false}
                            />
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="5">
                      <div className="reg_input">
                        <Controller
                          name="LastName"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Last Name*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              disabled={propertyData ? true : false}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="5">
                      <div className="reg_input">
                        <Controller
                          name="Phone"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              type="number"
                              label="Phone Number*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              disabled={propertyData ? true : false}
                              inputProps={{
                                maxLength: 10,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="5">
                      <div className="reg_input">
                        <Controller
                          name="Email"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Email*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              disabled={propertyData ? true : false}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="5">
                      <div className="reg_input">
                        <Controller
                          name="PostCode"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Postal Code*"
                              variant="outlined"
                              value={value}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                              disabled={propertyData ? true : false}
                              inputProps={{
                                maxLength: 4,
                              }}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="10">
                      <div className="reg_input">
                        <Controller
                          name="Message"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Message*"
                              variant="outlined"
                              value={value}
                              rows={3}
                              multiline
                              formControlProps={{
                                fullWidth: true,
                              }}
                              onChange={onChange}
                              error={error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="6">
                      <div className="reg_input check_value">
                        <InputLabel>How would you like us to contact you?*</InputLabel>
                        <Controller
                          name="Contact"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              {" "}
                              <RadioGroup aria-label="appointment" row="true" defaultValue="Email">
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Email"
                                  control={<Radio />}
                                  label="Email"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="SMS"
                                  control={<Radio />}
                                  label="SMS"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Phone"
                                  control={<Radio />}
                                  label="Phone"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </div>
                    </Grid>

                    <Grid item md="6">
                      <div className="reg_input check_value">
                        <InputLabel>When would you like us to contact you?*</InputLabel>
                        <Controller
                          name="ContactTime"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              {" "}
                              <RadioGroup
                                aria-label="appointment"
                                row="true"
                                defaultValue="Morning"
                              >
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Morning"
                                  control={<Radio />}
                                  label="Morning"
                                />
                                <FormControlLabel
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  value="Afternoon"
                                  control={<Radio />}
                                  label="Afternoon"
                                />
                              </RadioGroup>
                            </>
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input check_value">
                        <label>What is your enquiry about?*</label>
                        {/* <div className="inline-checkbox"> */}
                        <div class="arrageCheckBox">
                          {[
                            "Disability Employment Services",
                            "Short Term Accommodation and Respite",
                            "Community Services",
                            "Allied Health",
                            "Australian Disability Enterprises",
                            "Careers",
                            "School Leavers Employment Services",
                            "Supported Independent Living",
                            "Lifestyle Centres",
                            "Support Coordination",
                            "Media Enquiries and Partnerships",
                            "Other Enquiries",
                          ].map((name) => (
                            <FormControlLabel
                              control={
                                <Controller
                                  name="EnquiryAbout"
                                  render={({ onChange: onCheckChange }) => {
                                    // render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    return (
                                      <Checkbox
                                        checked={checkedValues.includes(name)}
                                        onChange={() => onCheckChange(handleSelect(name))}
                                      />
                                      // </div>
                                    );
                                  }}
                                  control={control}
                                />
                              }
                              key={name}
                              label={name}
                            />
                          ))}
                        </div>
                        <span class="errorTC" id="checkedValuesError">
                          Please select any one enquiry.
                        </span>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item md="12">
                      <div className="reg_input check_value">
                        <label>Are you a?*</label>
                        <div className="inline-checkbox">
                          {[
                            "NDIS Participant",
                            "Current Afford Client",
                            "Carer / Family / Guardian",
                            "Referrer",
                            "Other",
                          ].map((name) => (
                            <FormControlLabel
                              control={
                                <Controller
                                  name="AreYou"
                                  render={({ onChange: onCheckChange }) => {
                                    return (
                                      <Checkbox
                                        // checked={YouCheckedValues.includes(name)}
                                        checked={areYouCheckedValues.includes(name)}
                                        onChange={() => onCheckChange(AreYouHandleSelect(name))}
                                      />
                                    );
                                  }}
                                  control={control}
                                />
                              }
                              key={name}
                              label={name}
                            />
                          ))}
                        </div>
                        <span class="errorTC" id="areYoucheckedValuesError">
                          Please select any one enquiry.
                        </span>
                      </div>
                    </Grid>
                  </Grid>

                  {visitselecteddate ? (
                    <Grid item md="12">
                      {/* <div className="pd-box">
                        <p>
                          <span>VisitingDate:</span>
                          {visitselecteddate ? visitselecteddate : " "}
                          <br />
                          <span>VisitingTime:</span>
                          {visittime ? visittime : " "}
                        </p>
                      </div> */}
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="AppointmentDate"
                            defaultValue=""
                            id="AppointmentDate"
                            // autocomplete="new-password"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={DateFnsUtils}>
                                <DatePicker
                                  label="Date*"
                                  disabled={true}
                                  // autocomplete="new-password"
                                  value={value || null}
                                  inputFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    onChange(date);
                                    displaydatetime(date);
                                  }}
                                  // onClose = {display}
                                  //onClose={(e, v) => displaydatetime(value)}
                                  minDate={new Date()}
                                  // maxDate={new Date("09-05-2022")}
                                  renderInput={(params) => (
                                    <TextField
                                      id="DateValue"
                                      autoComplete="off"
                                      // helperText={error?.message}
                                      // error={error}
                                      {...params}
                                      error={errors.AppointmentDate}
                                      helperText={
                                        errors.AppointmentDate ? "Appointment Date Required." : ""
                                      }
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md="4">
                        <div className="reg_input">
                          <Controller
                            name="time"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Time*"
                                variant="outlined"
                                value={visittime}
                                // onChange={onChange}
                                //error={error}
                                //helperText={error?.message}
                                disabled={true}
                                inputProps={{
                                  maxLength: 10,
                                }}
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid>
                        {" "}
                        <MDButton
                          variant="contained"
                          onClick={() => setOpenDialog(true)}
                          color="primary"
                        >
                          {"edit"}
                        </MDButton>{" "}
                      </Grid>
                      {/* <Grid item md="4">
                        <div className="reg_input">
                          <div className="reg_input auto-complete">
                            <Controller
                              control={control}
                              name="AppointmentTime"
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  disablePortal
                                  id="AppointmentTime"
                                  name="AppointmentTime"
                                  disabled={true}
                                  value={visittime}
                                  //options={TimeList ? TimeList : []}
                                  // onChange={(e, v) => {
                                  //   onChange(v);
                                  //   setvisittime(v.label);
                                  // }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Time*"
                                      error={errors.AppointmentTime}
                                      helperText={
                                        errors.AppointmentTime ? "Appointment Time Required." : ""
                                      }
                                    />
                                  )}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Grid> */}
                    </Grid>
                  ) : null}
                  <div className="reg_input">
                    <h5>Assign Property</h5>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item md="5">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="PropertyLocation"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="PropertyLocation"
                              name="PropertyLocation"
                              value={value}
                              options={PropertyLocationData ? PropertyLocationData : ""}
                              onChange={(e, v) => {
                                onChange(v);
                                display(v);
                              }}
                              //    onClose={(e, v) => display(value)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Property Location*"
                                  error={errors.PropertyLocation}
                                  helperText={
                                    errors.PropertyLocation ? "Property Location Required." : ""
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item md="5">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="PropertyName"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disabled={selectedpropertylocation ? false : true}
                              id="PropertyName"
                              name="PropertyName"
                              value={value}
                              options={PropertyNameData ? PropertyNameData : ""}
                              onChange={(e, v) => {
                                onChange(v);
                                getpropertydetails(v);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Property Name*"
                                  error={errors.PropertyName}
                                  helperText={errors.PropertyName ? "Property Name Required." : ""}
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md="4">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="NumberOfBedrooms"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="NumberOfBedrooms"
                              name="NumberOfBedrooms"
                              value={value}
                              options={RoomsData ? RoomsData : ""}
                              onChange={(e, v) => onChange(v)}
                              // onBlur={GetData}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Number of Bedrooms*"
                                  error={errors.NumberOfBedrooms}
                                  helperText={
                                    errors.NumberOfBedrooms ? "Number of Bedrooms Required." : ""
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className="reg_input">
                    <h5>Property Details</h5>
                  </div>
                  <div className="pd-row">
                    {getpropertyData[0] ? (
                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>Name:</span>
                              {getpropertyData[0] ? getpropertyData[0].PropertyName : "  "}
                            </p>
                          </div>
                        </Grid>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>Type:</span>
                              {getpropertyData[0] ? getpropertyData[0].PropertyType : "  "}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                  <div className="pd-row">
                    {getpropertyData[0] ? (
                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                        <Grid item md="12">
                          <div className="pd-box">
                            <p>
                              <span>Address: </span>
                              {getpropertyData[0].AddrLine1 +
                                " ," +
                                getpropertyData[0].AddrLine2 +
                                " ," +
                                getpropertyData[0].City +
                                " ," +
                                getpropertyData[0].State +
                                "," +
                                //getpropertyData[0].Country +
                                "Australia ," +
                                getpropertyData[0].PostalCode}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                  <div className="pd-row">
                    {getpropertyData[0] ? (
                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>Total Bedrooms:</span>
                              {getpropertyData[0] ? getpropertyData[0].TotalBedroomCount : "  "}
                            </p>
                          </div>
                        </Grid>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>Avalible Bedrooms:</span>{" "}
                              {getpropertyData[0]
                                ? getpropertyData[0].TotalAvailableBedroomCount
                                : "  "}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                  <div className="pd-row ">
                    {getpropertyData[0] ? (
                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>Features:</span>{" "}
                              {getpropertyData[0] ? getpropertyData[0].PropertyFeature : "  "}
                            </p>
                          </div>
                        </Grid>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>Amenities:</span>{" "}
                              {getpropertyData[0] ? getpropertyData[0].PropertyAminities : ""}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                  <div className="pd-row last">
                    {getpropertyData[0] ? (
                      <Grid container rowSpacing={0} columnSpacing={{ xs: 3, sm: 3, md: 3 }}>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>Accessibility:</span>{" "}
                              {getpropertyData[0] ? getpropertyData[0].PropertyAccesibility : ""}
                            </p>
                          </div>
                        </Grid>
                        <Grid item md="6">
                          <div className="pd-box">
                            <p>
                              <span>SDA:</span>{" "}
                              {getpropertyData[0] ? getpropertyData[0].PropertySDA : ""}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                </div>
              </div>

              {getpropertyData[0] ? (
                <Grid container spacing={3}>
                  <Grid item md="12">
                    <div>
                      <h5>Property Images</h5>
                      <div className="added-property-images">
                        {imageurl
                          ? imageurl.map((name) => (
                              <span>
                                <img src={name}></img>
                              </span>
                            ))
                          : null}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              {openDialog ? (
                <Grid item md="3">
                  <div>
                    <InputLabel className="reg_lable mt-2 mb-4">
                      Select a date and time for visit.
                    </InputLabel>

                    <div className="reg_input">
                      <Controller
                        name="VisitingDate"
                        defaultValue=""
                        id="VisitingDate"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <LocalizationProvider dateAdapter={DateFnsUtils}>
                            <DatePicker
                              label="Date*"
                              value={value || null}
                              inputFormat="dd/MM/yyyy"
                              onChange={(date) => {
                                onChange(date);
                                displaydatetime(date);
                              }}
                              minDate={new Date()}
                              renderInput={(params) => (
                                <TextField
                                  id="DateValue"
                                  autoComplete="off"
                                  {...params}
                                  error={errors.VisitingDate}
                                  helperText={
                                    errors.VisitingDate ? "VisitingDate Date Required." : ""
                                  }
                                />
                              )}
                            />
                            {visitselecteddate ? null : (
                              <div className="pd-box">
                                <p>
                                  <span>Select a date for visit.</span>
                                </p>
                              </div>
                            )}
                          </LocalizationProvider>
                        )}
                      />
                    </div>

                    <div className="reg_input">
                      <div className="reg_input auto-complete">
                        <Controller
                          control={control}
                          name="AppointmentTime"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disablePortal
                              id="AppointmentTime"
                              name="AppointmentTime"
                              value={value}
                              options={TimeList ? TimeList : []}
                              onChange={(e, v) => {
                                onChange(v);
                                setvisittime(v.label);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Time*"
                                  error={errors.AppointmentTime}
                                  helperText={
                                    errors.AppointmentTime ? "Appointment Time Required." : ""
                                  }
                                />
                              )}
                            />
                          )}
                        />
                        {visittime ? null : (
                          <div className="pd-box">
                            <p>
                              <span>Select a time for visit.</span>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              ) : null}

              <div className="reg_submit mt-20">
                <MDButton
                  onClick={() => navigate("/layouts/Property/BookPropertyList")}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </MDButton>
                {/* <MDButton onClick={bookingvisit} variant="contained" color="primary">
                  save
                </MDButton> */}
                {showsavebutton ? (
                  <MDButton type="submit" variant="contained" color="primary">
                    Book a visit
                  </MDButton>
                ) : (
                  <MDButton type="submit" variant="contained" color="primary" onClick={bookingsave}>
                    save
                    {/* {showsavebutton ? "Book a visit" : "save"} */}
                  </MDButton>
                )}
                <MDButton
                  type="submit"
                  onClick={bookingconfirm}
                  variant="contained"
                  color="success"
                >
                  Confirm Booking
                </MDButton>
              </div>

              <div className="copyright">
                <p>Copyright &copy; 2022 All Rights Reserved | Powered by Cognisun</p>
              </div>
            </form>
          </MDBox>
        </Card>
        {/* <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="xs">
          <DialogTitle>Book a Visit</DialogTitle>
          <DialogContent>
            <Grid item md="12">
              <div className="reg_input check_value mb-0">
                <InputLabel className="reg_lable mt-2 mb-4">
                  Select a date and time for visit.
                </InputLabel>

                <div className="reg_input">
                  <Controller
                    name="AppointmentDate"
                    defaultValue=""
                    id="AppointmentDate"
                    // autocomplete="new-password"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <LocalizationProvider dateAdapter={DateFnsUtils}>
                        <DatePicker
                          label="Date*"
                          // autocomplete="new-password"
                          value={value || null}
                          inputFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            onChange(date);
                            displaydatetime(date);
                          }}
                          // onClose = {display}
                          //onClose={(e, v) => displaydatetime(value)}
                          minDate={new Date()}
                          // maxDate={new Date("09-05-2022")}
                          renderInput={(params) => (
                            <TextField
                              id="DateValue"
                              autoComplete="off"
                              // helperText={error?.message}
                              // error={error}
                              {...params}
                              error={errors.AppointmentDate}
                              helperText={
                                errors.AppointmentDate ? "Appointment Date Required." : ""
                              }
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </div>

                <div className="reg_input">
                  <div className="reg_input auto-complete">
                    <Controller
                      control={control}
                      name="AppointmentTime"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          disablePortal
                          id="AppointmentTime"
                          name="AppointmentTime"
                          value={value}
                          options={TimeList ? TimeList : []}
                          onChange={(e, v) => {
                            onChange(v);
                            setvisittime(v.label);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Time*"
                              error={errors.AppointmentTime}
                              helperText={
                                errors.AppointmentTime ? "Appointment Time Required." : ""
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <MDButton variant="contained" color="secondary" onClick={() => CancelDelete()}>
              Back
            </MDButton>
            <MDButton onClick={() => setdatetime(value)} variant="contained" color="primary">
              save
            </MDButton>
          </DialogActions>
        </Dialog> */}
      </DashboardLayout>
    </>
  );
};

AssignProperty.defaultProps = {};

export default AssignProperty;
