import React, { useEffect, useState } from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import { CommonConfig } from "constant";
import axios from "axios";

export default ({ goBack, Submit, selectedServices, selectedAppointment }) => {
  const [serviceList, setServiceList] = useState(null);
  const [SelectedServices, setSelectedServices] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    getServiceData();
  }, []);

  let navigate = useNavigate();
  const validateSelection = () => {
    if (SelectedServices && SelectedServices.length != 0) {
      setIsEmpty(false);
      console.log("SelectedAppoinment", selectedAppointment);
      if (selectedAppointment) {
        localStorage.setItem("AppointmentType", selectedAppointment);
        // localStorage.setItem("setSelectedServices",setSelectedServices);
        // break;

        if (selectedAppointment == "Face to Face") {
          navigate("/pages/book-appointment/FaceToFace");

          // Submit(3);
        } else if (selectedAppointment == "Request Callback") {
          navigate("/pages/book-appointment/RequestCallback");
          // Submit(4);
        } else if (selectedAppointment == "Audio/Video Conference") {
          navigate("/pages/book-appointment/AudioVideoConference");
          // Submit(5);
        }
      }
    } else {
      setIsEmpty(true);
      return;
    }
  };

  const getServiceData = () => {
    const formattedData = {
      StringMapType: "SERVICETYPE",
      SortType: "Alpha",
    };

    axios({
      url: CommonConfig.ApiUrl + "Utils/getDropDownValues",
      method: "POST",
      headers: {},
      data: formattedData,
    })
      .then((res) => {
        if (res.data?.success) {
          console.log("ServiceList===>", res.data.data);
          setServiceList(
            res.data.data.map((item) => ({
              id: item.StringMapKey,
              label: item.StringMapName,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onServiceChange = (event, value) => {
    console.log("VALUEService", value);
    let formattedData = [];

    for (let i = 0; i < value.length; i++) {
      formattedData.push(value[i].id);
    }
    localStorage.setItem("setSelectedServices", formattedData);
    setSelectedServices(formattedData);
    selectedServices(formattedData);
  };

  return (
    <div className="reg_step" id="bookStep2">
      {/* <h2 className="h2-heading align-center">Services you would like to avail -----</h2> */}
      <Grid
        container
        rowSpacing={0}
        columnSpacing={{ xs: 3, sm: 3, md: 2 }}
        justifyContent="center"
      >
        <Grid item md="8">
          <div className="reg_input sec_level">
            <Autocomplete
              multiple
              id="Services"
              name="Services"
              options={serviceList ? serviceList : []}
              onChange={onServiceChange}
              filterSelectedOptions
              formControlProps={{
                fullWidth: true,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="What services are you like for?*"
                  placeholder="Favorites"
                  // error={errorservices}
                  // helperText={errorservices}
                  id="Services"
                  name="Services"
                  // defaultValue={Params}
                  error={isEmpty}
                  helperText={isEmpty ? "Please select a service to continue." : ""}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              )}
            />
          </div>
        </Grid>
      </Grid>
      <div className="reg_submit center">
        <MDButton variant="contained" color="secondary" onClick={() => goBack()}>
          Back
        </MDButton>

        <MDButton variant="contained" color="primary" onClick={() => validateSelection()}>
          Next
        </MDButton>
      </div>
    </div>
  );
};
