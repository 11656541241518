/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cogoToast from "cogo-toast";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
// const eye = <FontAwesomeIcon icon={faEye} />;

import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// import { Grid} from '@mui/material';
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Logo from "assets/img/valuecare-logo.png";
import { CommonConfig } from "constant";

import GoogleLogin from "react-google-login";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { item } from "examples/Sidenav/styles/sidenavItem";

// Images

function Cover() {


  let input = document.querySelector("#password");
  const togglePasswordVisiblity = () => {
    if (input.type === "password") {
      input.type = "text";
      document.getElementById("HideEyePassword").style.display = "none";
      document.getElementById("ShowEyePassword").style.display = "block";
    } else {
      input.type = "password";
      document.getElementById("HideEyePassword").style.display = "block";
      document.getElementById("ShowEyePassword").style.display = "none";
    }


  };

  let navigate = useNavigate();
  const { state } = useLocation();

  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [loader, setLoader] = React.useState(false);

  const [login, setLogin] = React.useState({
    isvalid: true,
    email: "",
    password: "",

    erroremail: "",
    errorpassword: "",

    validate: "",
  });

  const loginhandleChange = (event) => {
    setLogin({ ...login, [event.target.name]: event.target.value });
    console.log("======;;", event.target);
  };

  const validateLoginData = () => {
    if (login.email === "") {
      login.erroremail = <span className="color-red">Please Enter Email Id.</span>;
      setLogin({ ...login, ["erroremail"]: "Please Enter Email Id" });
      login.isvalid = true;
    } else {
      login.erroremail = "";
      setLogin({ ...login, ["erroremail"]: "" });
      login.isvalid = false;
    }
    if (login.password === "") {
      setLogin({
        ...login,
        ["errorpassword"]: <span className="color-red">Please Enter Password.</span>,
      });
      login.isvalid = true;
    } else {
      setLogin({ ...login, ["errorpassword"]: "" });
      login.isvalid = false;
    }

    debugger;
    
  };
  const onSuccess = (googleData) => {
    console.log("TEST....", googleData);

    axios({
      url: CommonConfig.ApiUrl + "authentication/googleSignup",

      method: "POST",
      body: JSON.stringify({
        dtoken: googleData.tokenI,
      }),
      headers: {},
      data: googleData,
      "Content-Type": "application/json",
    })
      .then((res) => {
        debugger;
        console.log("res", res);
        const transferdata = res.data.data;
        if (transferdata === "redirect register page") {
          // document.getElementById("regSignup").style.display = "none";

          var inputDataUser = {
            username: googleData.profileObj.email,
          };

          axios({
            url: CommonConfig.ApiUrl + "authentication/checkUserExist",
            method: "POST",
            headers: {},
            data: inputDataUser,
          })
            .then((res) => {
              debugger;
              console.log("test.....12", res.data.data[0][0].totalUser);
              if (res.data.data[0][0].totalUser === 0) {
                cogoToast.error("User not register with ValueCare.");
                setTimeout(() => {
                  navigate("/authentication/sign-up/Index");
                }, 2000);
              } else {
                let loginData = autoLogin(
                  res.data.data[0][0].Username,
                  res.data.data[0][0].Password
                );

                console.log(
                  "res.data.data[0][0].totalUser ",
                  res.data.data[0][0].Password,
                  " || ",
                  res.data.data[0][0].Username
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          cogoToast.error(transferdata);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const autoLogin = (email, password) => {
    debugger;
    const inputdata = {
      Username: email,
      Password: password,
    };
    console.log("Input data", inputdata);
    // debugger;
    axios({
      url: CommonConfig.ApiUrl + "authentication/authenticateUser",
      method: "POST",
      headers: {},
      data: inputdata,
    })
      .then((res) => {
        debugger;
        console.log("test.....12", res.data.data[0][0].returnValue);

        // if (res.status === 200) {
        if (res.data.data[0][0].returnValue === "Invalid Username or password.") {
          cogoToast.error("Invalid Username or password.");
        } else {
          if (res.data.success === true) {
            // cogoToast.success("Login Successfully");

            var sendData = {
              PersonType: res.data.data[0][0].PersonType,
            };

            localStorage.setItem("token", res.data.data[0][0].Token);
            localStorage.setItem("userData", JSON.stringify(res.data.data[0][0]));

            axios({
              url: CommonConfig.ApiUrl + "authentication/getUserMenus",
              method: "POST",
              headers: {},
              data: sendData,
            })
              .then((res) => {
                console.log("test", res.data.data[0]);
                debugger;
                localStorage.setItem("Menu", JSON.stringify(res.data.data[0]));

                if (res.data.success === true) {
                  // setTimeout(() => {
                  //   let navigate = useNavigate();
                  //   navigate("/pages/book-appointment/UserBookAppointmentToken");
                  // }, 1000);
                  navigate("/dashboards/userprofile/Index");
                } else {
                  cogoToast.error();
                }
              })
              .catch((error) => {
                console.log(error);
              });

            // // debugger
            localStorage.setItem("token", res.data.data[0][0].Token);
            localStorage.setItem("userData", JSON.stringify(res.data.data[0][0]));
            //    setTimeout(() => {
            //   let navigate = useNavigate();
            //   navigate("/pages/book-appointment/UserBookAppointmentToken");
            // }, 1000);
          } else {
            cogoToast.error(res.data.data[0][0].returnValue);
          }
        }
      })
      .catch((error) => {
        return false;
        console.log(error);
      });
  };

  const handleFailure = (result) => {
    console.log("login data", localStorage.getItem("loginData"));
    console.log("client id", process.env.REACT_APP_GOOGLE_CLIENT_ID);
    alert(JSON.stringify(result));
  };

  const loginhandleSubmit = () => {
    console.log("data.....", login);
    // debugger;
    validateLoginData();

    if (login.isvalid === false && login.erroremail == "") {
      // debugger;
      setLoader(!loader);
      const inputdata = {
        Username: login.email,
        Password: login.password,
      };
      console.log("Input data", inputdata);
      // debugger;
      axios({
        url: CommonConfig.ApiUrl + "authentication/authenticateUser",
        method: "POST",
        headers: {},
        data: inputdata,
      })
        .then((res) => {
          setLoader(!loader);
          debugger;
          console.log("test.....12", res.data.data[0][0].returnValue);

          // if (res.status === 200) {
          if (res.data.data[0][0].returnValue == "Invalid Username or password") {
            setLoader(false);
            cogoToast.error(res.data.data[0][0].returnValue);
          } else {
            if (res.data.success === true) {
              setLoader(false);
              cogoToast.success("Login Successfully.");

              var sendData = {
                PersonType: res.data.data[0][0].PersonType,
              };

              axios({
                url: CommonConfig.ApiUrl + "authentication/getUserMenus",
                method: "POST",
                headers: {},
                data: sendData,
              })
                .then((res) => {
                  console.log("test", res.data.data[0]);
                  debugger;
                  var menudata = [];
                  if (sendData.PersonType === "INTERESTED VC SERVICES") {
                    res.data.data[0].map((item) => {
                      if (item.name !== "Participant List") {
                        menudata.push(item);
                      }
                    });
                    localStorage.setItem("Menu", JSON.stringify(menudata));
                  } else {
                    localStorage.setItem("Menu", JSON.stringify(res.data.data[0]));
                  }
                  if (res.data.success === true) {
                  } else {
                    cogoToast.error("Invalid User.");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });

              // // debugger
              console.log("res.data.data[0][0].PersonType", res.data.data[0][0].PersonType);
              localStorage.setItem("token", res.data.data[0][0].Token);
              localStorage.setItem("userData", JSON.stringify(res.data.data[0][0]));
              setTimeout(() => {
                // navigate("/pages/book-appointment/UserBookAppointmentToken");
                if (res.data.data[0][0].PersonType == "JOB SEEKER") {
                  navigate("/dashboards/userprofile/Index");
                } else if (res.data.data[0][0].PersonType == "DIFFERENTLY ABLED") {
                  navigate("/pages/book-appointment/UserBookAppointmentToken");
                } else {
                  navigate("/dashboards/analytics");
                }
              }, 1000);
            } else {
              cogoToast.error("Invalid User.");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // validate();
  };

  return (
    <div className="login-outer">
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {/* <Grid container spacing={3}>
        <Grid item md="4" sm="4"> */}
      <CoverLayout className="login-sec">
        <Card className="login-inner">
          <MDBox
            variant="gradient"
            bgColor="#fff"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={1}
            mb={1}
            textAlign="center"
          >
            <a href = "https://valuecare.org.au/"><img className="login-logo" alt="Value Care" src={Logo} /></a>
            <MDTypography variant="h4" fontWeight="medium" color="black" mt={1}>
              Sign in
            </MDTypography>
          </MDBox>
          <div className="google-signin-btn">
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Sign in with Google"
              onSuccess={onSuccess}
              onFailure={handleFailure}
              cookiePolicy={"single_host_origin"}
            ></GoogleLogin>
          </div>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <div className="login-input">
                  <MDInput
                    error={login.erroremail}
                    helperText={login.erroremail}
                    onChange={(e) => loginhandleChange(e)}
                    name="email"
                    id="email"
                    type="email"
                    label="Email"
                    variant="standard"
                    fullWidth
                    // placeholder="john@example.com"
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </MDBox>
              <MDBox mb={2}>
                <div className="EyeIcon-outer">
                  {/* <div className="login-input">  */}
                  {/* //<div> */}
                  <div>
                    <MDInput
                      error={login.errorpassword}
                      helperText={login.errorpassword}
                      onChange={(e) => loginhandleChange(e)}
                      id="password"
                      name="password"
                      type="password"
                      label="Password"
                      variant="standard"
                      fullWidth
                      // placeholder="************"
                      InputLabelProps={{ shrink: true }}
                    />
                    <div className="EyeIcon">
                      <i
                        class="showtextLogin"
                        id="HideEyePassword"
                        onClick={togglePasswordVisiblity}
                      >
                        <VisibilityIcon />
                      </i>
                      <i
                        class="hidetextLogin"
                        id="ShowEyePassword"
                        onClick={togglePasswordVisiblity}
                        // onClick={toggleHidePasswordVisiblity}
                      >
                        <VisibilityOffIcon />
                      </i>
                    </div>
                  </div>
                </div>
              </MDBox>

              <MDBox display="flex" alignItems="center" ml={-1}>
                {/* <Switch checked={rememberMe} onChange={handleSetRememberMe} /> */}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up/ForgotPassword"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  // onClick="/authentication/sign-up/ForgotPassword"
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  Forgot Password
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="primary"
                  fullWidth
                  onClick={() => loginhandleSubmit()}
                >
                  sign in
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Don&apos;t have an account? &nbsp;
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up/Index"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign Up
                  </MDTypography>
                    
                </MDTypography>
                
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>

    </div>
  );
}

export default Cover;
